import { createSlice } from "@reduxjs/toolkit";

const preferencesSlice = createSlice({
  name: "preferences",
  initialState: {
    page: 0,
    pageSize: 10,
    // TODO: sortModel should be array {field/sort} for multicolumn sorting
    sortModel: {
      field: "ts",
      sort: "desc",
    },
  },
  reducers: {
    setPreferencesPage(state, action) {
      state.page = action.payload;
    },
    resetPreferencesPage(state) {
      state.page = 0;
    },

    setPreferencesPageSize(state, action) {
      state.pageSize = action.payload;
    },

    resetPreferencesPageSize(state) {
      state.pageSize = 10;
    },

    setPreferencesSortModel(state, action) {
      state.sortModel = action.payload;
    },

    resetPreferencesSortModel(state) {
      state.sortModel = {
        field: "ts",
        sort: "desc",
      };
    },
  },
});

export const {
  setPreferencesPage,
  resetPreferencesPage,
  setPreferencesPageSize,
  resetPreferencesPageSize,
  setPreferencesSortModel,
  resetPreferencesSortModel,
} = preferencesSlice.actions;

export const preferences = preferencesSlice.reducer;

export const selectPreferences = (state) => state.preferences;
export const selectPage = (state) => state.preferences.page;
export const selectPageSize = (state) => state.preferences.pageSize;
export const selectSortModel = (state) => state.preferences.sortModel;
