import { api, providesList } from "../api/apiSlice";

export const tasksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTasks: build.query({
      query({ page, pageSize, sortModel }) {
        var params = {
          p: page,
          rpp: pageSize,
        };

        if (sortModel) {
          params.sortField = sortModel.field;
          params.sortDir = sortModel.sort;
        }

        return {
          url: "task",
          method: "GET",
          params,
        };
      },
      providesTags: (result) => providesList(result && result.items, "Tasks"),
    }),
    getTaskByRegistrationId: build.query({
      query: (registrationId) => `task/registration/${registrationId}`,
    }),
    getTask: build.query({
      query: (id) => `task/${id}`,
      providesTags: (_result, _error, { id }) => [{ type: "Tasks", id }],
    }),
    saveTask: build.mutation({
      query(task) {
        return {
          url: "task" + (task.id ? "/" + task.id : ""),
          method: task.id ? "PUT" : "POST",
          body: task,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Tasks", id }],
    }),
    deleteTask: build.mutation({
      query(task) {
        return {
          url: `task/${task.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Tasks", id },
        { type: "Tasks", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTaskQuery,
  useGetTaskByRegistrationIdQuery,
  useSaveTaskMutation,
  useDeleteTaskMutation,
} = tasksApi;
