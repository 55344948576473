import React, { useMemo } from "react";

import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
  Divider,
  Button,
  Tooltip,
} from "@mui/material";

import { LoadingCircularProgress, StyledCard } from "../../components";
import { useGetMyAccountQuery } from "../account";

export const ProfileImage = () => {
  const title = "Avatar";

  const { isLoading, data: account } = useGetMyAccountQuery();

  const profile = useMemo(() => {
    if (isLoading) {
      return;
    }
    return account.profile;
  }, [account, isLoading]);

  if (isLoading) {
    return <LoadingCircularProgress />;
  }

  return (
    <StyledCard>
      <CardHeader title={title} />
      <Divider />
      <CardContent
        sx={{ display: "flex", margin: "auto", justifyContent: "center" }}
      >
        <div>
          <Avatar
            sx={{
              display: "flex",
              height: 100,
              width: 100,
              flexShrink: 0,
              flexGrow: 0,
            }}
            src={profile.avatar}
          />
        </div>
      </CardContent>
      <Divider />
      <Tooltip title="coming soon">
        <CardActions>
          <Box
            sx={{ display: "flex", marginLeft: "auto", alignItems: "flex-end" }}
          >
            <Button variant="text" disabled={true} sx={{ marginRight: 1 }}>
              Remove
            </Button>
          </Box>
          <div hidden={true}>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              disabled={true}
              sx={{ display: "none" }}
            />
          </div>
          <label htmlFor="contained-button-file">
            <Button
              variant="text"
              color="primary"
              component="span"
              disabled={true}
              sx={{ marginRight: 1 }}
            >
              Upload
            </Button>
          </label>
        </CardActions>
      </Tooltip>
    </StyledCard>
  );
};
