import React from "react";

import { Card, CardContent, Grid, Typography } from "@mui/material";

import { formatISO8601AsDateTime } from "../../helpers";

import {
  BillingPortalButton,
  PlanFeatures,
  StripeMode,
  useBilling,
} from "../billing";

export const CurrentSubscription = () => {
  const billing = useBilling();

  const title = "Subscription Plan";

  return (
    <Card sx={{ padding: 0 }}>
      <CardContent>
        <Grid container spacing={1} sx={{ padding: 0 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs={7} sm={8} md={9} lg={9} xl={10}>
            <StripeMode
              isTrial={billing.isTrial}
              trialDescription={billing.trialDescription}
              liveMode={billing.liveMode}
            />
            {billing.providerType === "stripe" ? (
              <Typography>
                Your <strong>{billing.name}</strong> subscription plan will
                renew on{" "}
                {formatISO8601AsDateTime(billing.renewal, "MMMM DD, YYYY")}.
              </Typography>
            ) : (
              <Typography>
                Your <strong>{billing.name}</strong> subscription plan will not
                expire.
              </Typography>
            )}
            <PlanFeatures features={billing.features} />
          </Grid>
          <Grid item xs={5} sm={4} md={3} lg={3} xl={2}>
            <div hidden={billing.providerType !== "stripe"}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <BillingPortalButton />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
