/* eslint-disable no-unused-vars */
import Logger from "js-logger";

const logger = Logger.get("queries");

export const parseQuery = (q) => {
  if (!q) {
    return { query: "", fields: [] };
  }

  const kvPattern = /(\w*)[=:](['"].*?['"]|\S*)/g;

  var query = q;
  var fields = [];

  const matches = q.match(kvPattern);
  if (matches && matches.length > 0) {
    for (const m of q.match(kvPattern)) {
      var pair = m.split(":");
      if (pair.length === 1) {
        pair = m.split("=");
      }

      query = query.replace(m, "").trim();
      var value = pair[1].trim();

      if (pair[0] === "q") {
        query += value;
      } else {
        pair[1] = value;
        fields.push(pair);
      }
    }
  }

  // logger.debug("parseQuery result:", q, query, fields);
  return {
    query,
    fields,
  };
};

export const parseParameters = (parameters) => {
  var f = [];
  if (parameters) {
    f = Object.entries(parameters).filter(([k, v]) => k !== "q");
  }

  var q = [];
  if (parameters && parameters["q"] && parameters["q"].length > 0) {
    q = parameters["q"];
  }

  return {
    query: q,
    fields: f,
  };
};

export const requote = (value) => {
  if (value) {
    const trimmed = value.trim();
    if (trimmed.length > 0) {
      return `"${trimmed.replace(/['"]/g, "")}"`;
    }
  }
  return "";
};

export const mapToQuery = (query, fields) => {
  var result =
    (Array.isArray(query) ? (query.length > 0 ? query.join(" ") : "") : query) +
    " " +
    (fields &&
      fields
        .map(([k, v]) => {
          if (Array.isArray(v)) {
            return v
              .map((vv) => {
                const value = `${k}:${requote(vv)}`;
                return value;
              })
              .join(" ");
          } else {
            const value = `${k}:${requote(v)}`;
            return value;
          }
        })
        .join(" "));
  result = result.trim();

  // logger.debug(
  //   "mapToQuery: query, fields, result: '" +
  //     query +
  //     "', '" +
  //     fields +
  //     "', '" +
  //     result +
  //     "'"
  // );

  return result;
};
