import React from "react";

import { Button, Typography } from "@mui/material";

export const Troubleshoot = () => {
  return (
    <Typography variant="body1" sx={{ paddingTop: 2 }}>
      If you are having any problems, please do not hesitate to contact{" "}
      <Button
        variant="text"
        color="primary"
        target="_top"
        rel="noopener noreferrer"
        href={`mailto:support@builtstream.com`}
        // classes={{
        //   label: classes.supportButton,
        // }}
        sx={{ textTransform: "none", padding: 0, margin: 0 }}
      >
        support@builtstream.com
      </Button>
      .
    </Typography>
  );
};
