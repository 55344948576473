import React from "react";
import { useParams } from "react-router-dom";

import { Box, Grid } from "@mui/material";

import { OrganizationForm, MemberChart, MemberSummary } from ".";

export const Overview = () => {
  const { tabId } = useParams();

  if (tabId !== undefined && tabId !== "overview") {
    return <></>;
  }

  return (
    <Box sx={{ paddingTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} xl={6} xs={6}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <OrganizationForm />
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <MemberSummary />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} xl={6} xs={6}>
          <MemberChart chartType="PieChart" />
        </Grid>
      </Grid>
    </Box>
  );
};
