import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

import { RecentListingGrid, MemberListingGrid } from ".";

export const Recent = () => {
  const { tabId, shareId } = useParams();

  const isViewingAsset = useMemo(() => {
    return shareId !== undefined;
  }, [shareId]);

  if (tabId !== "recent") {
    return <></>;
  }

  if (isViewingAsset) {
    return (
      <Box sx={{ paddingTop: 2 }}>
        <MemberListingGrid />
      </Box>
    );
  }

  return (
    <Box sx={{ paddingTop: 2 }}>
      <RecentListingGrid />
    </Box>
  );
};
