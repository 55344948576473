import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
} from "@mui/material";

import { LoadingCircularProgress, StyledCard } from "../../components";
import { useGetMyAccountQuery } from "../account";
import { useSaveMyAccountProfile } from ".";

export const ProfileForm = () => {
  const [title] = useState("Overview");
  const [profile, setProfile] = useState({
    avatar: "",
    email: "",
    name: "",
    phoneNumber: "",
  });

  const { isLoading, data: account } = useGetMyAccountQuery();

  const saveMyAccountProfile = useSaveMyAccountProfile();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setProfile({ ...account.profile });
  }, [account, isLoading]);

  function handleChange(event) {
    const { name, value } = event.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  }

  const handleSave = () => {
    // event.preventDefault();
    if (
      account.profile.name == profile.name &&
      account.profile.email == profile.email &&
      account.profile.phoneNumber == profile.phoneNumber
    ) {
      return;
    }

    dispatch(saveMyAccountProfile(account.id, profile));
  };

  if (isLoading) {
    return <LoadingCircularProgress />;
  }

  return (
    <StyledCard>
      <CardHeader title={title} />
      <Divider />
      <CardContent>
        <TextField
          fullWidth
          label="Name"
          margin="dense"
          size="small"
          name="name"
          disabled={false}
          onBlur={handleSave}
          onChange={handleChange}
          required={true}
          value={profile.name}
          variant="outlined"
          sx={{
            paddingBottom: 2,
          }}
        />

        <TextField
          fullWidth
          label="Phone"
          margin="dense"
          size="small"
          name="phoneNumber"
          disabled={false}
          onBlur={handleSave}
          onChange={handleChange}
          required={false}
          value={profile.phoneNumber}
          variant="outlined"
          sx={{
            paddingBottom: 2,
          }}
        />

        <Box disabled={true}>
          <Typography variant="body2">Email</Typography>
          <Typography variant="caption">{profile.email}</Typography>
        </Box>
      </CardContent>
    </StyledCard>
  );
};
