/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import { Box, CardContent, CardHeader, Typography } from "@mui/material";
import FileIcon from "@mui/icons-material/DescriptionOutlined";
import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import AssetsIcon from "@mui/icons-material/PagesOutlined";
import SharedAssetsIcon from "@mui/icons-material/ShareOutlined";
import OpenIcon from "@mui/icons-material/OpenInBrowserOutlined";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";
import PreviewIcon from "@mui/icons-material/OpenInNewOutlined";

import { NoMaxWidthTooltip, StyledCard } from "../../components";
import {
  convertToByteUnit,
  convertByteNumberByUnit,
  formatISO8601AsDateTime,
  formatISO8601FromNow,
} from "../../helpers";
import { useGetMyOrganizationQuery } from "../organization";
import {
  useOpenBlob,
  useDownloadBlob,
  usePreviewBlob,
  isFolder,
  isPointCloud,
} from "../blobs";
import { useOpenShareAsset } from "../asset";
import { SearchActionMenu } from ".";

export const BlobResult = ({ item }) => {
  const theme = useTheme();

  const { data: organization } = useGetMyOrganizationQuery();

  const openBlob = useOpenBlob();
  const downloadBlob = useDownloadBlob();
  const previewBlob = usePreviewBlob();
  const openShareAsset = useOpenShareAsset();

  const handleClick = () => {
    openBlob(item);
  };

  const options = useMemo(() => {
    var result = [];

    if (!isFolder(item)) {
      result.push({
        id: "open-blob",
        component: (
          <Box sx={{ display: "flex", flex: 1 }}>
            <NoMaxWidthTooltip
              title={<div>Open &quot;{item.name}&quot;</div>}
              sx={{ paddingRight: 1 }}
            >
              <div>{<OpenIcon />}</div>
            </NoMaxWidthTooltip>
            <Typography variant="body1">Open</Typography>
          </Box>
        ),
        action: openBlob(item),
      });

      if (isPointCloud(item)) {
        result.push({
          id: "preview-blob",
          component: (
            <Box sx={{ display: "flex", flex: 1 }}>
              <NoMaxWidthTooltip
                title={<div>Preview &quot;{item.name}&quot;</div>}
                sx={{ paddingRight: 1 }}
              >
                <div>{<PreviewIcon />}</div>
              </NoMaxWidthTooltip>
              <Typography variant="body1">Preview</Typography>
            </Box>
          ),
          action: previewBlob(item),
        });
      }

      result.push({
        id: "download-blob",
        component: (
          <Box sx={{ display: "flex", flex: 1 }}>
            <NoMaxWidthTooltip
              title={<div>Download &quot;{item.name}&quot;</div>}
              sx={{ paddingRight: 1 }}
            >
              <div>{<DownloadIcon />}</div>
            </NoMaxWidthTooltip>
            <Typography variant="body1">Download</Typography>
          </Box>
        ),
        action: downloadBlob([item]),
      });
    }

    if (item && item.assetId) {
      const shareAsset = {
        asset: {
          id: item.assetId,
          name: item.containerName,
          shareId: item.shareId,
        },
        assetId: item.assetId,
      };

      result.push({
        id: "open-blob-built-asset",
        component: (
          <Box sx={{ display: "flex", flex: 1 }}>
            <NoMaxWidthTooltip
              title={
                <div>Open built asset &quot;{item.containerName}&quot;</div>
              }
              sx={{ paddingRight: 1 }}
            >
              <div>{<OpenIcon />}</div>
            </NoMaxWidthTooltip>
            <Typography variant="body1">Open Built Asset</Typography>
          </Box>
        ),
        action: () => openShareAsset(shareAsset),
      });
    }
    return result;
  }, [downloadBlob, item, openShareAsset, openBlob, previewBlob]);

  const {
    assetIcon,
    assetName,
    assetTitle,
    blobIcon,
    blobName,
    size,
    title,
    units,
  } = useMemo(() => {
    const isOrganizationAsset = item.organizationId === organization.id;
    const organizationName = isOrganizationAsset
      ? organization.hidden
        ? "" // `orgid-${organization.id}`
        : organization.name
      : item.organizationName;

    const assetIcon = isOrganizationAsset ? (
      <AssetsIcon />
    ) : (
      <SharedAssetsIcon />
    );
    const assetName = item.containerName;
    const assetTitle =
      assetName +
      (isOrganizationAsset ? "" : " (shared from " + organizationName + ")");

    const isFolder = item.metadata && item.metadata.type === "folder";
    const blobIcon = isFolder ? <FolderIcon /> : <FileIcon />;
    const blobName = item.name.replace("/.bsikeep", "");
    const title = blobName;

    const units = isFolder
      ? ""
      : convertToByteUnit(item.properties.contentLength);
    const size = isFolder
      ? ""
      : convertByteNumberByUnit(item.properties.contentLength, units, 1);

    return {
      assetIcon,
      assetName,
      assetTitle,
      blobIcon,
      blobName,
      size,
      title,
      units,
    };
  }, [item, organization]);

  return (
    <StyledCard
      sx={{
        height: "100%",
        width: "100%",
        display: "block",
        padding: 1,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <CardHeader
        sx={{
          margin: 0,
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
        title={
          <Box sx={{ display: "flex", flex: 1 }}>
            <Box sx={{ height: 24, width: 24 }}>{blobIcon}</Box>
            <Box sx={{ paddingLeft: 1 }}>
              <NoMaxWidthTooltip title={title}>
                <Typography sx={{ paddingLeft: 1 }} variant="h5" noWrap={true}>
                  {blobName}
                </Typography>
              </NoMaxWidthTooltip>
            </Box>
            <Box sx={{ paddingLeft: 1 }}>
              <NoMaxWidthTooltip
                title={
                  "Last modified " +
                  formatISO8601AsDateTime(item.properties.lastModified)
                }
              >
                <Typography variant="body1" noWrap={true}>
                  {formatISO8601FromNow(item.properties.lastModified)}
                </Typography>
              </NoMaxWidthTooltip>
            </Box>
            <Box sx={{ paddingLeft: 1 }}>
              <NoMaxWidthTooltip
                title={
                  <div>
                    {size !== "" ? "Size" : ""} {size} {units}
                  </div>
                }
              >
                <Typography scope="row" variant="body1" noWrap={true}>
                  {size} {units}
                </Typography>
              </NoMaxWidthTooltip>
            </Box>
          </Box>
        }
        action={<SearchActionMenu options={options} />}
      />
      <CardContent
        sx={{
          margin: 0,
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: "flex", flex: 1 }}>
          <Box sx={{ height: 24, width: 24 }}>{assetIcon}</Box>
          <Box sx={{ paddingLeft: 1 }}>
            <NoMaxWidthTooltip title={assetTitle}>
              <Typography variant="body1" noWrap={true} sx={{ paddingLeft: 1 }}>
                {assetName}
              </Typography>
            </NoMaxWidthTooltip>
          </Box>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

BlobResult.propTypes = {
  item: PropTypes.object,
};
