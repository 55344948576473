import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import ResendIcon from "@mui/icons-material/SendOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

import {
  clearSelectedRows,
  NoMaxWidthTooltip,
  setPreferencesPage,
  setPreferencesPageSize,
  selectSelectedRows,
  setSelected,
  StyledCard,
  StyledDataGrid,
  ViewAsMenu,
} from "../../components";

import {
  findMemberRole,
  formatTimestampFromNow,
  isEditableRole,
  isNil,
  isOwnerRole,
  memberRoles,
} from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import {
  useGetMyOrganizationQuery,
  useNotifyMembers,
  useSaveMembers,
  useDeleteMembers,
} from ".";

export const MemberListingGrid = () => {
  const { tabId } = useParams();

  const theme = useTheme();
  const dispatch = useDispatch();

  const selectedRows = useSelector(selectSelectedRows);
  const preferences = useSelector((state) => state.preferences);
  const initialSortModel = [
    {
      field: "name",
      sort: "asc",
    },
  ];
  const [sortModel, setSortModel] = useState(initialSortModel);

  const { isLoading: isLoadingAccount, data: account } = useGetMyAccountQuery();

  const {
    isLoading: isLoadingOrganization,
    data: organization,
    refetch,
  } = useGetMyOrganizationQuery();

  const notifyMembers = useNotifyMembers();
  const saveMembers = useSaveMembers();
  const deleteMembers = useDeleteMembers();

  // eslint-disable-next-line no-unused-vars
  const { filteredMembers, isOwner, isEditable, title } = useMemo(() => {
    const isOwner = isOwnerRole(account, organization, null);
    const isEditable = isEditableRole(account, organization, null);

    const filteredMembers = organization.membership.members.map((x) => {
      // Note: if our member is only invited, we do not have an id
      const updated = {
        ...x,
        id: isNil(x.id) ? x.invitationId : x.id,
        name: x.profile.name,
      };
      return updated;
    });

    const title =
      organization.membership.members.length + " Organization Members";

    return { filteredMembers, isOwner, isEditable, title };
  }, [account, organization]);

  const onRefresh = () => {
    refetch();
    dispatch(clearSelectedRows());
  };

  if (tabId === undefined || tabId !== "members") {
    return <></>;
  }

  const renderMoreMenu = (row) => {
    let moreMenu = [];

    if (row.status !== "active" && isOwner) {
      // Only if an open invitation
      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip
              title={<div>Resend organization invitation to member</div>}
            >
              <div>{<ResendIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-resend"
          label="Resend"
          onClick={notifyMembers(organization.id, [row])}
          showInMenu
        />
      );
    }

    const ownerCount = organization.membership.members.filter(
      (x) => !isNil(x.id) && x.role === "owner"
    ).length;

    if (
      isOwner &&
      (row.id !== account.id || (row.id === account.id && ownerCount > 1))
    ) {
      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Delete member from organization</div>}>
              <div>{<DeleteIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-delete"
          label="Delete"
          onClick={deleteMembers(organization.id, [row])}
          showInMenu
        />
      );
    }

    if (isOwner && memberRoles && memberRoles.length > 0) {
      memberRoles.forEach((memberRole) => {
        if (row.role !== memberRole.value) {
          moreMenu.push(
            <GridActionsCellItem
              icon={
                <Tooltip title={memberRole.description}>
                  <Box sx={{ color: "icon" }}>{memberRole.icon}</Box>
                </Tooltip>
              }
              label={`Set role: ${memberRole.label}`}
              id={"more-menu-" + memberRole.value}
              key={"more-menu-" + memberRole.value}
              onClick={saveMembers(organization.id, [
                {
                  ...row,
                  role: memberRole.value,
                },
              ])}
              showInMenu
            />
          );
        }
      });
    }

    return moreMenu;
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      description: "Organization member name and email",
      width: 500,
      minWidth: 300,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const rowAvatar =
          (params.row.profile && params.row.profile.avatar) || "";
        const rowName =
          ((params.row.profile && params.row.profile.name) || "") +
          (account.id == params.row.id ? " (me)" : "");
        const rowEmail = (params.row.profile && params.row.profile.email) || "";

        return (
          <NoMaxWidthTooltip
            title={
              <div>
                <div>{rowName}</div>
                <div>{rowEmail}</div>
              </div>
            }
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Avatar
                  alt="Person"
                  src={rowAvatar}
                  sx={{
                    marginRight: 1,
                    marginTop: 1,
                    height: 32,
                    width: 32,
                    color: theme.palette.icon,
                    float: "right",
                  }}
                />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <div>
                  <Typography
                    color="textSecondary"
                    scope="row"
                    variant="body1"
                    noWrap
                    sx={{
                      display: "inline-block",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      minWidth: "0",
                    }}
                  >
                    {" "}
                    {rowName}
                  </Typography>
                </div>
                <div>
                  <Typography
                    color="textSecondary"
                    scope="row"
                    variant="caption"
                    noWrap
                    sx={{
                      display: "inline-block",
                      fontWeight: 500,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                      minWidth: "0",
                    }}
                  >
                    {" "}
                    {rowEmail}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </NoMaxWidthTooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      description: "Organization membership status",
      flex: 0.5,
      minWidth: 200,
      editable: false,
      renderCell: (params) => {
        const rowStatus = params.row.status || "";
        const rowStatusDate =
          (params.row.status !== "active"
            ? " (" + formatTimestampFromNow(params.row.ts * 1000) + ")"
            : "") || "";

        return (
          <div>
            <NoMaxWidthTooltip
              title={
                <div>
                  <Box
                    sx={{
                      display: "inline-block",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                      minWidth: "0",
                    }}
                  >
                    {rowStatus}
                  </Box>
                  <div>{rowStatusDate}</div>
                </div>
              }
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <div>
                    <Typography
                      color="textSecondary"
                      scope="row"
                      variant="body1"
                      noWrap
                      sx={{
                        display: "inline-block",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                        minWidth: "0",
                      }}
                    >
                      {rowStatus}
                    </Typography>
                    <div hidden={rowStatusDate.length === 0}>
                      <Typography
                        color="textSecondary"
                        scope="row"
                        variant="caption"
                        noWrap
                        sx={{
                          display: "inline-block",
                          fontWeight: 500,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                          minWidth: "0",
                        }}
                      >
                        {" "}
                        {rowStatusDate}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </NoMaxWidthTooltip>
          </div>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      description: "Organization membership role",
      flex: 0.5,
      minWidth: 100,
      editable: false,
      renderCell: (params) => {
        const rowRole = findMemberRole(params.row.role);
        let roleName = "";
        let roleDescription = "";
        let roleIcon = <div />;
        if (rowRole) {
          roleName = rowRole.label;
          roleDescription = rowRole.description;
          roleIcon = (
            <Box
              sx={{
                marginRight: 1,
                marginTop: 1,
                height: 32,
                width: 32,
                color: theme.palette.icon,
                float: "right",
              }}
            >
              {rowRole.icon}
            </Box>
          );
        }

        return (
          <NoMaxWidthTooltip title={roleDescription}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>{roleIcon}</Grid>
              <Grid item>
                <Typography scope="row" variant="body1" noWrap={true}>
                  {roleName}
                </Typography>
              </Grid>
            </Grid>
          </NoMaxWidthTooltip>
        );
      },
    },
    {
      field: "moreMenu",
      type: "actions",
      headerName: "",
      flex: 0.2,
      midWidth: 50,
      editable: false,
      getActions: (params) => renderMoreMenu(params.row),
      renderHeader: () => <ViewAsMenu />,
    },
  ];

  return (
    <StyledCard>
      <CardHeader
        title={title}
        action={
          <IconButton size="small" id="refreshIcon" onClick={onRefresh}>
            <RefreshIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent
        sx={{
          alignItems: "center",
          padding: 0,
          marginBottom: "-25px",
        }}
      >
        <StyledDataGrid
          autoHeight
          checkboxSelection
          columns={columns}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: preferences.pageSize,
                page: preferences.page,
              },
            },
            sorting: {
              sortModel: [{ ...sortModel }],
            },
          }}
          loading={isLoadingAccount || isLoadingOrganization}
          onPaginationModelChange={(paginationModel) => {
            dispatch(setPreferencesPage(paginationModel.page));
            dispatch(setPreferencesPageSize(paginationModel.pageSize));
          }}
          onRowSelectionModelChange={(selected) => {
            dispatch(setSelected(selected));
          }}
          onSortModelChange={(m) => {
            setSortModel(m);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={preferences}
          rows={filteredMembers}
          rowSelectionModel={selectedRows}
          sortModel={sortModel}
        />
      </CardContent>
    </StyledCard>
  );
};
