import { useDispatch } from "react-redux";
import { clearSelectedRows } from "../../components";

import { useInvitationMutation, useRejectShareInvitationMutation } from ".";

export const formatRejectInvitationMessage = (invitation, err) => {
  if (invitation) {
    return (
      `share invitation to '${invitation.asset.name}' ` +
      `by ${invitation.recipient.name} ` +
      `(${invitation.recipient.email}) ` +
      (invitation.organizationName && invitation.organizationName.length > 0
        ? `from ${invitation.organizationName} `
        : "") +
      (err === undefined ? `rejected` : `reject failed ${err}`)
    );
  }
};

export const useRejectInvitations = () => {
  const dispatch = useDispatch();
  const rejectInvitations = useInvitationMutation(
    useRejectShareInvitationMutation,

    (invitation) => {
      return formatRejectInvitationMessage(invitation);
    },

    (invitation, err) => {
      return formatRejectInvitationMessage(invitation, err);
    }
  );

  return (invitations) => () => {
    rejectInvitations(invitations);
    dispatch(clearSelectedRows());
  };
};
