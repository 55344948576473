import { api, providesList } from "../api/apiSlice";

export const containerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getContainersUsage: build.query({
      query({ top }) {
        const params = {};

        if (top) {
          params["top"] = top;
        }
        return {
          url: "container/usage/",
          method: "GET",
          params,
        };
      },
      providesTags: (result) =>
        providesList(result && result.items, "ContainerUsage"),
    }),
    getContainerUsage: build.query({
      query: (containerId) => `container/${containerId}/usage/`,
      providesTags: (_result, _error, { id }) => [
        { type: "ContainerUsage", id },
      ],
    }),
  }),
});

export const { useGetContainersUsageQuery, useGetContainerUsageQuery } =
  containerApi;
