/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import { Box, CardContent, CardHeader, Chip, Typography } from "@mui/material";
import AssetsIcon from "@mui/icons-material/PagesOutlined";
import SharedAssetsIcon from "@mui/icons-material/ShareOutlined";
import OpenIcon from "@mui/icons-material/OpenInBrowserOutlined";

import { NoMaxWidthTooltip, StyledCard } from "../../components";
import {
  formatTimestampFromNow,
  formatTimestampAsDateTime,
} from "../../helpers";
import { useGetMyOrganizationQuery } from "../organization";
import { useOpenShareAsset } from "../asset";

import { SearchActionMenu } from ".";

export const AssetResult = ({ item }) => {
  const theme = useTheme();

  const { data: organization } = useGetMyOrganizationQuery();
  const openAsset = useOpenShareAsset();

  const handleClick = () => {
    openAsset(item);
  };

  const options = useMemo(() => {
    var result = [
      {
        id: "open-asset",
        component: (
          <Box sx={{ display: "flex", flex: 1 }}>
            <NoMaxWidthTooltip
              title={<div>`Open built asset &quot;${item.name}&quot;`</div>}
              sx={{ paddingRight: 1 }}
            >
              <div>{<OpenIcon />}</div>
            </NoMaxWidthTooltip>
            <Typography variant="body1">Open</Typography>
          </Box>
        ),
        action: () => openAsset(item),
      },
    ];
    return result;
  }, [item, openAsset]);

  const { icon, title, organizationName, tags } = useMemo(() => {
    const isOrganizationAsset = item.organizationId === organization.id;
    const organizationName = isOrganizationAsset
      ? organization.hidden
        ? "" // `orgid-${organization.id}`
        : organization.name
      : "";
    const icon = isOrganizationAsset ? <AssetsIcon /> : <SharedAssetsIcon />;
    const title = item.name + (isOrganizationAsset ? "" : " (shared with me)");
    const tags =
      item.tags &&
      item.tags.map((x) => (
        <Box key={x.id} sx={{ paddingRight: 1 }}>
          <NoMaxWidthTooltip key={x.id} title={`tag:${x.name}`}>
            <Chip
              key={x.id}
              variant="outlined"
              margin="dense"
              size="small"
              label={`tag:${x.name}`}
              sx={{ paddingRight: 1 }}
            />
          </NoMaxWidthTooltip>
        </Box>
      ));
    return {
      icon,
      title,
      organizationName,
      tags,
    };
  }, [item, organization]);

  return (
    <StyledCard
      sx={{
        height: "100%",
        width: "100%",
        display: "block",
        padding: 1,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <CardHeader
        sx={{
          margin: 0,
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
        title={
          <Box
            sx={{ display: "flex", flex: 1, padding: 1 }}
            onClick={handleClick}
          >
            <Box sx={{ height: 24, width: 24 }}>{icon}</Box>
            <Box sx={{ paddingLeft: 1 }}>
              <NoMaxWidthTooltip title={title}>
                <Typography sx={{ paddingLeft: 1 }} variant="h5" noWrap={true}>
                  {item.name}
                </Typography>
              </NoMaxWidthTooltip>
            </Box>
            <Box sx={{ paddingLeft: 1 }}>
              <NoMaxWidthTooltip
                title={
                  "Last modified " + formatTimestampAsDateTime(item.ts * 1000)
                }
              >
                <Typography variant="body1" noWrap={true}>
                  {formatTimestampFromNow(item.ts * 1000)}
                </Typography>
              </NoMaxWidthTooltip>
            </Box>
            <Box sx={{ paddingLeft: 1 }}>
              <NoMaxWidthTooltip title={organizationName}>
                <Typography variant="body1" noWrap={true}>
                  {organizationName}
                </Typography>
              </NoMaxWidthTooltip>
            </Box>
          </Box>
        }
        action={<SearchActionMenu options={options} />}
      />
      <CardContent
        sx={{
          margin: 0,
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
        onClick={handleClick}
      >
        <div hidden={!(item.description && item.description.length > 0)}>
          <Box sx={{ display: "flex", flex: 1, padding: 1 }}>
            <Box sx={{ marginTop: 0 }}>
              <NoMaxWidthTooltip title={<>{item.description}</>}>
                <Typography variant="body1" noWrap={true}>
                  {item.description}
                </Typography>
              </NoMaxWidthTooltip>
            </Box>
          </Box>
        </div>
        <div hidden={!(tags && tags.length > 0)}>
          <Box sx={{ display: "flex", flex: 1, padding: 1 }}>
            <Box sx={{ display: "flex", flex: 1 }}>{tags}</Box>
          </Box>
        </div>
      </CardContent>
    </StyledCard>
  );
};

AssetResult.propTypes = {
  item: PropTypes.object,
};
