import { createSelector, createSlice } from "@reduxjs/toolkit";

const selectionModelSlice = createSlice({
  name: "selectionModel",
  initialState: { rows: [] },
  reducers: {
    setSelected(state, action) {
      state.rows = action.payload;
    },
    clearSelectedRows(state) {
      state.rows = [];
    },
    selectSingleRow(state, action) {
      if (action.payload) {
        state.rows = [action.payload.id];
      } else {
        state.rows = [state.rows[0]];
      }
    },
  },
});

export const { setSelected, clearSelectedRows, selectSingleRow } =
  selectionModelSlice.actions;
export const selectionModel = selectionModelSlice.reducer;

export const selectSelectedRows = (state) => state.selectionModel.rows;
export const selectHasSingleRowSelected = createSelector(
  selectSelectedRows,
  (rows) => rows.length === 1
);
export const selectHasSelectedRows = createSelector(
  selectSelectedRows,
  (rows) => rows.length != 0
);
