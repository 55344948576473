import { configureStore } from "@reduxjs/toolkit";
// import { setupListeners } from "@reduxjs/toolkit/query";
// import { createLogger } from "redux-logger";
// import { logLevel } from "../config.json";

import rootReducer from "./reducers";

import { api } from "../features/api/apiSlice";

export default function configureAppStore(initialState) {
  const middlewares = [];

  // if (process.env.NODE_ENV !== "production") {
  //   const level = logLevel.toLowerCase();
  //   var middlewareLogLevel = "log";
  //   if (level == "trace") middlewareLogLevel = "info";
  //   else if (level == "debug") middlewareLogLevel = "info";
  //   else if (level == "info") middlewareLogLevel = "info";
  //   else if (level == "warning") middlewareLogLevel = "warn";
  //   else if (level == "error") middlewareLogLevel = "error";

  //   const middlewareLogger = createLogger({
  //     level: middlewareLogLevel,
  //   });

  //   middlewares.push(middlewareLogger);
  // }
  middlewares.push(api.middleware);

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewares),
    devTools: process.env.NODE_ENV !== "production",
    initialState,
    enhancers: [],
  });

  // if (process.env.NODE_ENV !== "production" && module.hot) {
  //   module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  // }

  // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
  // see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
  // setupListeners(store.dispatch);

  return store;
}
