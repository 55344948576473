import React from "react";
import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { environmentName } from "../../config.json";
import { ActionBarCard, StyledButton } from "../../components";

import { useInviteShare } from "../share";
import {
  useOpenDeleteBlobDialog,
  useOpenNewFolderDialog,
  useOpenCopyBlobDialog,
  useOpenRenameBlobDialog,
  useOpenBlob,
  useOpenUploadBlobsDialog,
  useDownloadBlob,
  usePreviewBlob,
  isPointCloud,
  useGenerateBim,
  useGenerateFloorplan,
  useSelectedBlobs,
} from ".";

export const ActionBar = ({ asset, blobs, share }) => {
  const {
    selectedBlobs,
    hasEditableRole,
    hasViewableRole,
    hasAnyFolderSelected,
    isSameOrganization,
    selectedBlob,
  } = useSelectedBlobs(share, blobs);

  const openNewFolderDialog = useOpenNewFolderDialog(asset);
  const openCopyBlobDialog = useOpenCopyBlobDialog(asset);
  const openRenameBlobDialog = useOpenRenameBlobDialog(asset);
  const openDeleteBlobDialog = useOpenDeleteBlobDialog();
  const openUploadBlobsDialog = useOpenUploadBlobsDialog();
  const openBlob = useOpenBlob();
  const downloadBlob = useDownloadBlob();
  const generateBim = useGenerateBim();
  const generateFloorplan = useGenerateFloorplan();
  const previewBlob = usePreviewBlob();

  const inviteShare = useInviteShare();

  if (!asset || !share) {
    return <></>;
  }

  const renderShare = () =>
    asset &&
    share &&
    isSameOrganization &&
    hasEditableRole && (
      <StyledButton
        id="actionbar-share"
        variant="contained"
        onClick={inviteShare([share.data])}
      >
        Share
      </StyledButton>
    );

  const renderOpenBlob = () =>
    selectedBlob &&
    !hasAnyFolderSelected && (
      <StyledButton
        id="actionbar-open"
        variant="outlined"
        onClick={openBlob(selectedBlob)}
      >
        Open
      </StyledButton>
    );

  const renderBimBlob = () =>
    selectedBlob &&
    hasEditableRole &&
    isPointCloud(selectedBlob) &&
    environmentName != "production" && (
      <StyledButton
        id="actionbar-bim"
        variant="outlined"
        onClick={generateBim(selectedBlob)}
      >
        BIM (alpha)
      </StyledButton>
    );

  const renderGenerateFloorplan = () =>
    selectedBlob &&
    hasEditableRole &&
    isPointCloud(selectedBlob) &&
    environmentName != "production" && (
      <StyledButton
        id="actionbar-floorplan"
        variant="outlined"
        onClick={generateFloorplan(selectedBlob)}
      >
        floorplan (alpha)
      </StyledButton>
    );

  const renderPreview = () =>
    selectedBlob &&
    hasEditableRole &&
    isPointCloud(selectedBlob) && (
      <StyledButton
        id="actionbar-preview"
        variant="outlined"
        onClick={previewBlob(selectedBlob)}
      >
        Preview
      </StyledButton>
    );

  const renderUpload = () =>
    // (selectedBlobs.length == 0) &&
    hasEditableRole && (
      <StyledButton
        id="upload-dialog-button"
        variant="outlined"
        onClick={openUploadBlobsDialog(asset)}
      >
        Upload
      </StyledButton>
    );

  const renderDownload = () =>
    selectedBlobs.length > 0 &&
    !hasAnyFolderSelected &&
    hasViewableRole && (
      <StyledButton
        id="actionbar-download"
        variant="outlined"
        onClick={downloadBlob(selectedBlobs)}
      >
        Download
      </StyledButton>
    );

  const renderNewFolder = () =>
    hasEditableRole && (
      <StyledButton
        id="actionbar-new"
        variant="outlined"
        onClick={openNewFolderDialog()}
      >
        New Folder
      </StyledButton>
    );

  const renderCopy = () =>
    hasEditableRole &&
    selectedBlob &&
    !hasAnyFolderSelected && (
      <StyledButton
        id="actionbar-copy"
        variant="outlined"
        onClick={openCopyBlobDialog(selectedBlob)}
      >
        Copy
      </StyledButton>
    );

  const renderRename = () =>
    hasEditableRole &&
    selectedBlob &&
    !hasAnyFolderSelected && (
      <StyledButton
        id="actionbar-rename"
        variant="outlined"
        onClick={openRenameBlobDialog(selectedBlob)}
      >
        Rename / Move
      </StyledButton>
    );

  const renderDelete = () =>
    hasEditableRole &&
    selectedBlobs.length > 0 && (
      <StyledButton
        id="actionbar-delete"
        variant="outlined"
        onClick={openDeleteBlobDialog(selectedBlobs)}
      >
        Delete
      </StyledButton>
    );

  return (
    <Box sx={{ backgroundColor: "background.default" }}>
      <ActionBarCard
        buttons={[
          renderShare,
          renderOpenBlob,
          renderPreview,
          renderGenerateFloorplan,
          renderBimBlob,
          renderUpload,
          renderDownload,
          renderCopy,
          renderRename,
          renderDelete,
          renderNewFolder,
        ]}
      />
    </Box>
  );
};

ActionBar.propTypes = {
  share: PropTypes.object,
  blobs: PropTypes.object,
  asset: PropTypes.object,
};
