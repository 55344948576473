import { useOpenBlobQuery, useBlobQuery } from ".";

export const useOpenBlob = () =>
  useBlobQuery(
    useOpenBlobQuery,
    (result) => {
      window.open(result.sasToken, "_blank");
    },
    () => {}
  );
