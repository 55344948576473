import React from "react";

import { Box, Grid } from "@mui/material";

import { UploadBlobs } from "../blobs";
import { ShareEmailDialog } from "../share";
import {
  AssetListingGrid,
  CopyShareAsset,
  DeleteShareAsset,
  ActionBar,
  UpsertShareAsset,
  useShareAssets,
} from ".";

export const AssetList = () => {
  const shareAssets = useShareAssets();

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item lg={10} sm={12} xl={10} xs={12}>
          <AssetListingGrid shareAssets={shareAssets} />
        </Grid>
        <Grid item lg={2} sm={12} xl={2} xs={12}>
          <ActionBar shareAssets={shareAssets} />
        </Grid>
      </Grid>

      <CopyShareAsset shareAssets={shareAssets} />
      <DeleteShareAsset shareAssets={shareAssets} />
      <ShareEmailDialog shareAssets={shareAssets} />
      <UpsertShareAsset />
      <UploadBlobs />
    </Box>
  );
};
