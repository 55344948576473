/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import LogoIcon from "../../assets/images/builtstream_logo.png";

export const StyledCompanyLogo = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isInSidebar",
})(({ theme, isInSidebar }) => ({
  display: "flex",
  flexDirection: "row",
  ...(isInSidebar && {
    padding: "16px 26px 16px 26px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  }),
}));

export const StyledCompanyLogoIcon = styled(Box)(({ theme }) => ({
  width: "19px", // 155 / 8
  height: "25px", // 205 / 8
  marginBottom: theme.spacing(1),
}));

export const StyledCompanyName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isInSidebar",
})(({ theme, isInSidebar }) => ({
  fontWeight: 500,
  fontSize: "16px",
  letterSpacing: "-0.05px",
  lineHeight: "20px",
  fontFamily: "bsiKontora",
  marginLeft: theme.spacing(0.5),
  marginTop: theme.spacing(0.75),
  ...(isInSidebar && {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  }),
}));

export const CompanyLogo = (props) => {
  const { isInSidebar } = props;

  return (
    <RouterLink to="/" style={{ textDecoration: "none" }}>
      <StyledCompanyLogo isInSidebar={isInSidebar}>
        <StyledCompanyLogoIcon component="img" alt="Logo" src={LogoIcon} />
        <StyledCompanyName isInSidebar={isInSidebar}>
          builtstream
        </StyledCompanyName>
      </StyledCompanyLogo>
    </RouterLink>
  );
};

CompanyLogo.propTypes = {
  isInSidebar: PropTypes.bool.isRequired,
};
