import React, { useContext, useMemo } from "react";

import { useTheme } from "@mui/material/styles";
import { IconButton, Tooltip } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import { ColorModeContext } from "..";

export const ThemeButton = () => {
  const theme = useTheme();

  const colorMode = useContext(ColorModeContext);

  const themeIconButton = useMemo(() => {
    var icon = <Brightness4Icon />;
    var title = "Change to dark mode";
    if (theme.palette.mode === "dark") {
      icon = <Brightness7Icon />;
      title = "Change to light mode";
    }
    return (
      <Tooltip title={title}>
        <IconButton
          sx={{
            ml: 1,
            mr: 1,
            color: theme.palette.icon,
          }}
          onClick={colorMode.toggleColorMode}
          color="inherit"
          edge="end"
          aria-label="color theme"
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  }, [colorMode, theme]);

  return themeIconButton;
};
