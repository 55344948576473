import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectSelectedRows } from "../../components";
import { isEditableRole, isOwnerRole } from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";

export const useSelectedShares = (shares) => {
  const { data: account } = useGetMyAccountQuery();
  const { data: organization } = useGetMyOrganizationQuery();
  const selectedRows = useSelector(selectSelectedRows);

  return useMemo(() => {
    let selectedShares = [];
    let hasOwnerRole = false;
    let hasEditableRole = false;
    let hasAnyExternalShares = false;

    if (shares && shares.data) {
      selectedShares = shares.data.filter((x) => selectedRows.includes(x.id));

      if (selectedShares) {
        const selectedOwnerRoles = selectedShares.filter((x) =>
          isOwnerRole(account, organization, x)
        );
        hasOwnerRole = selectedOwnerRoles.length === selectedShares.length;

        const selectedEditableRoles = selectedShares.filter((x) =>
          isEditableRole(account, organization, x)
        );
        hasEditableRole =
          selectedEditableRoles.length === selectedShares.length;

        const selectedNotInMyOrganization = selectedShares.filter(
          (x) => x.organizationId !== organization.id
        );
        hasAnyExternalShares = selectedNotInMyOrganization.length > 0;
      }
    }

    const selectedShare = selectedShares.length == 1 && selectedShares[0];
    const hasSelectedShares = selectedShares.length > 0;

    return {
      selectedShares,
      hasEditableRole,
      hasOwnerRole,
      selectedShare,
      hasSelectedShares,
      hasAnyExternalShares,
    };
  }, [account, organization, selectedRows, shares]);
};
