import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { Box, Grid, Tab, Tabs } from "@mui/material";

import {
  ActionBar,
  Members,
  Overview,
  Pending,
  Subscription,
  useGetOrganizationInvitationsQuery,
} from ".";
import { TabPanel } from "../../components";

function a11yProps(index) {
  return {
    id: `organization-tab-${index}`,
    "aria-controls": `organization-tabpanel-${index}`,
  };
}

export const Organization = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const history = useHistory();
  const location = useLocation();
  const { tabId } = useParams();

  const invitations = useGetOrganizationInvitationsQuery();

  const panels = useMemo(() => {
    var panels = [];

    if (invitations && invitations.isLoading) {
      return panels;
    }

    if (invitations && invitations.data && invitations.data.length > 0) {
      panels.push({
        id: "pending",
        component: <Pending invitations={invitations} />,
      });
    }

    panels.push({
      id: "overview",
      component: <Overview />,
    });

    panels.push({
      id: "members",
      component: <Members />,
    });

    panels.push({
      id: "subscription",
      component: <Subscription />,
    });

    return panels;
  }, [invitations]);

  const handleTabIndexChange = useCallback(
    (newValue) => {
      if (panels.length === 0) {
        return;
      }

      if (newValue === undefined) {
        newValue = tabIndex;
      } else if (newValue > panels.length) {
        newValue = 0;
      }

      const newPath = "/organization/" + panels[newValue].id;
      if (location.pathname !== newPath) {
        history.push(newPath);
      }
    },
    [history, location.pathname, panels, tabIndex]
  );

  useEffect(() => {
    if (panels.length === 0) {
      return;
    }

    if (tabId === undefined) {
      handleTabIndexChange(0);
      return;
    }

    const index = panels.findIndex((x) => x.id === tabId);
    var newTabIndex = 0;
    if (index !== -1) {
      newTabIndex = index;
    }
    setTabIndex(newTabIndex);

    if (tabId !== panels[newTabIndex].id) {
      handleTabIndexChange(newTabIndex);
    }
  }, [handleTabIndexChange, panels, tabId, tabIndex]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        backgroundColor: "background.default",
        padding: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={10} sm={12} xl={10} xs={12}>
          <Box
            position="static"
            color="transparent"
            sx={{
              boxShadow: "none",
            }}
          >
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={tabIndex}
              onChange={(e, v) => handleTabIndexChange(v)}
              onClick={(e, v) => handleTabIndexChange(v)}
              variant="scrollable"
              scrollButtons={true}
              aria-label="org tabs"
            >
              {panels.map((x) => {
                return (
                  <Tab
                    label={x.id}
                    key={`organization-tab-key-${x.id}`}
                    {...a11yProps(x.id)}
                  />
                );
              })}
            </Tabs>
          </Box>
          {panels.map((x, idx) => {
            return (
              <TabPanel
                key={`organization-tabpanel-key-${x.id}`}
                index={idx}
                name="organization"
                value={tabIndex}
              >
                {x.component}
              </TabPanel>
            );
          })}
        </Grid>
        <Grid item lg={2} sm={12} xl={2} xs={12}>
          <ActionBar invitations={invitations} />
        </Grid>
      </Grid>
    </Box>
  );
};
