import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Logger from "js-logger";

import { clearSelectedRows, InputDialog, logToastDebug, logToastError } from "../../components";

import { useCreateFolderBlobMutation, genPath, creatingFolder } from ".";
import { close } from "./newFolderSlice";

const logger = Logger.get("NewFolder");

export const NewFolder = ({ blobs }) => {
  const dispatch = useDispatch();
  const { open, row, containerId, target } = useSelector(
    (state) => state.newFolder
  );
  const [createFolder, { error, isError, isSuccess }] =
    useCreateFolderBlobMutation();

  // The folder has been successfully created
  useEffect(() => {
    if (isSuccess && open && target) {
      const msg = `folder "${target}" created`;
      logToastDebug(logger, msg);

      blobs.refetch();

      dispatch(close());
      dispatch(clearSelectedRows());
    }
  }, [blobs, dispatch, isSuccess, open, target]);

  // The folder failed to create
  useEffect(() => {
    if (isError && open && target) {
      
      const msg = `folder "${target}" create failed`;
      logToastError(logger, msg, error);

      dispatch(close());
    }
  }, [dispatch, error, isError, open, target]);

  const isFolder = row && row.metadata.type == "folder";
  const item = isFolder && row;

  let newFolderValue = genPath(blobs.data || {}, item);

  if (newFolderValue.length > 0) {
    newFolderValue += "/";
  }

  newFolderValue += "New Folder";

  const handleClose = useCallback(
    (target) => {
      if (!target) {
        dispatch(close());

        return;
      }

      dispatch(creatingFolder(target));
      createFolder({ containerId: containerId, path: target });
    },
    [dispatch, containerId, createFolder]
  );

  return (
    <InputDialog
      open={open}
      cancelTitle="Cancel"
      inputLabel="Name"
      inputValue={newFolderValue}
      okTitle="Create"
      title="Create new folder?"
      onClose={handleClose}
    />
  );
};

NewFolder.propTypes = {
  blobs: PropTypes.object.isRequired,
};
