/* eslint-disable react/display-name */
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Logger from "js-logger";

import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import OpenIcon from "@mui/icons-material/OpenInBrowserOutlined";
import AccountIcon from "@mui/icons-material/AccountCircle";

import { NoMaxWidthTooltip, StyledCard } from "../../components";
import { useGetMyOrganizationQuery } from "../organization";

import { SearchActionMenu } from ".";

const logger = Logger.get("AccountResult");

export const AccountResult = ({ item }) => {
  const theme = useTheme();

  const { data: organization } = useGetMyOrganizationQuery();

  const handleClick = useCallback(() => {
    logger.debug("open account:", item);
  }, [item]);

  const options = useMemo(() => {
    var result = [
      {
        id: "open-account",
        component: (
          <Box sx={{ display: "flex", flex: 1 }}>
            <NoMaxWidthTooltip
              title={<div>`Open account &quot;${item.name}&quot;`</div>}
              sx={{ paddingRight: 1 }}
            >
              <div>{<OpenIcon />}</div>
            </NoMaxWidthTooltip>
            <Typography variant="body1">Open</Typography>
          </Box>
        ),
        action: () => handleClick(item),
      },
    ];
    return result;
  }, [item, handleClick]);

  const { avatar, email, name, title, organizationName } = useMemo(() => {
    const organizationName = organization.hidden
      ? "" // `orgid-${organization.id}`
      : organization.name;
    var avatar = <AccountIcon />;
    var email = "";
    var name = "";

    if (item && item.profile) {
      if (item.profile.avatar) {
        avatar = (
          <Avatar
            alt="Person"
            src={item.profile.avatar}
            sx={{
              width: 56,
              height: 56,
              // color: theme.palette.icon,
              color: "icon",
            }}
          />
        );
      }
      if (item.profile.email) {
        email = item.profile.email;
      }

      if (item.profile.name) {
        name = item.profile.name;
      }
    }

    const title = name;
    return {
      avatar,
      email,
      name,
      title,
      organizationName,
    };
  }, [item, organization]);

  return (
    <StyledCard
      sx={{
        height: "100%",
        width: "100%",
        display: "block",
        padding: 1,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <CardHeader
        sx={{
          margin: 0,
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
        title={
          <Box
            sx={{ display: "flex", flex: 1, padding: 1 }}
            onClick={handleClick}
          >
            <Box
              sx={{
                width: 56,
                height: 56,
                // color: theme.palette.icon,
                color: "icon",
              }}
            >
              {avatar}
            </Box>
            <div>
              <Box sx={{ paddingLeft: 1 }}>
                <NoMaxWidthTooltip title={title}>
                  <Typography variant="h5" noWrap={true}>
                    {name}
                  </Typography>
                </NoMaxWidthTooltip>
              </Box>
              <div hidden={!(email && email.length > 0)}>
                <Box sx={{ paddingLeft: 1 }}>
                  <NoMaxWidthTooltip title={<>{email}</>}>
                    <Typography variant="body1" noWrap={true}>
                      {email}
                    </Typography>
                  </NoMaxWidthTooltip>
                </Box>
              </div>
            </div>
          </Box>
        }
        action={
          <div hidden={true}>
            <SearchActionMenu options={options} />
          </div>
        }
      />
      <CardContent
        sx={{
          margin: 0,
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
        onClick={handleClick}
      >
        <div hidden={!(organizationName && organizationName.length > 0)}>
          <Box sx={{ display: "flex", flex: 1, padding: 1 }}>
            <Box sx={{ paddingLeft: 1 }}>
              <NoMaxWidthTooltip title={organizationName}>
                <Typography variant="body1" noWrap={true}>
                  {organizationName}
                </Typography>
              </NoMaxWidthTooltip>
            </Box>
          </Box>
        </div>
      </CardContent>
    </StyledCard>
  );
};

AccountResult.propTypes = {
  item: PropTypes.object,
};
