import React, { useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import Logger from "js-logger";

import { Box, Typography } from "@mui/material";

import { isNil } from "../../helpers";
import { AuthContext, LoadingCircularProgress } from "../../components";
import { useGetMyAccountQuery } from "../account/accountApiSlice";
import { useGetMyOrganizationQuery } from "../organization/organizationApiSlice";

import { PlanSelection } from ".";

const REDIRECT_TO_HOME = "/home";

const logger = Logger.get("Pricing");

export const Pricing = () => {
  const auth = useContext(AuthContext);

  const { isAuthenticated, signup } = auth;
  logger.debug("isAuthenticated:", isAuthenticated());

  const history = useHistory();

  const { priceId } = useParams();
  useEffect(() => {
    logger.debug("priceId:", priceId);
    if (!isAuthenticated() && priceId !== undefined) {
      signup();
    }
  }, [isAuthenticated, signup, priceId]);

  const { isLoading: myAccountIsLoading, data: myAccount } =
    useGetMyAccountQuery(null);

  const { isLoading: myOrganizationIsLoading, data: myOrganization } =
    useGetMyOrganizationQuery(null);

  const { accountEmail, accountStatus, orgStatus, redirect } = useMemo(() => {
    logger.debug("myAccount:", myAccount);
    logger.debug("myOrganization:", myOrganization);

    var redirect = null;
    var accountStatus = "unauthorized";
    var orgStatus = "unauthorized";
    var accountEmail = "unknown email";

    if (myAccount && myAccount.status) {
      accountStatus = myAccount.status;
      accountEmail = myAccount.profile && myAccount.profile.email;
    }

    if (myOrganization && myOrganization.billingId) {
      orgStatus =
        myOrganization.billingId !== undefined &&
        !isNil(myOrganization.billingId)
          ? "signupCompleted"
          : "signupSelection";
    }

    if (!myAccountIsLoading && !myOrganizationIsLoading) {
      logger.debug(
        "myAccountIsLoading, myOrganizationIsLoading, setting redirect:",
        myAccountIsLoading,
        myOrganizationIsLoading,
        redirect
      );
      redirect = "";
    }

    logger.debug("accountStatus:", accountStatus);
    logger.debug("orgStatus:", orgStatus);

    if (accountStatus === "signupCompleted" || accountStatus === "active") {
      redirect = REDIRECT_TO_HOME;
      logger.debug("accountStatus setting redirect:", accountStatus, redirect);
    } else if (orgStatus === "signupCompleted" || orgStatus === "active") {
      redirect = REDIRECT_TO_HOME;
      logger.debug("orgStatus setting redirect:", orgStatus, redirect);
    } else if (
      accountStatus === "authorized" ||
      orgStatus === "signupSelection"
    ) {
      if (priceId !== undefined && priceId !== "") {
        redirect = `/checkout/${priceId}`;
        logger.debug("priceId, setting redirect:", priceId, redirect);
      }
    }

    return { accountEmail, accountStatus, orgStatus, redirect };
  }, [
    myAccount,
    myOrganization,
    myAccountIsLoading,
    myOrganizationIsLoading,
    priceId,
  ]);

  useEffect(() => {
    if (redirect) {
      logger.debug("useEffect, redirecting to: ", redirect);
      history.push(redirect);
    }
  }, [history, redirect]);

  if (redirect === null) {
    logger.debug("checking subscriptiong status...");
    return <LoadingCircularProgress />;
  } else if (redirect !== "") {
    if (priceId !== undefined && priceId !== "") {
      logger.debug(
        "subscription selection detected, redirecting to checkout:",
        redirect
      );
      return (
        <div>
          <Typography>
            Subscription selection detected, redirecting to checkout...
          </Typography>

          <LoadingCircularProgress />
        </div>
      );
    } else {
      logger.debug("subscription already detected, redirecting to:", redirect);
      return (
        <div>
          <Typography>Subscription already detected, redirecting...</Typography>

          <LoadingCircularProgress />
        </div>
      );
    }
  } else if (
    accountStatus === "authorized" ||
    orgStatus === "signupSelection"
  ) {
    logger.debug("authorized, need subscription");
    return (
      <Box sx={{ padding: 2 }}>
        <Box sx={{ padding: 2, display: "flex" }}>
          <Typography variant="h5">
            Choose a subscription plan to complete your sign up using{" "}
            {accountEmail}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <PlanSelection />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      <PlanSelection />
    </Box>
  );
};
