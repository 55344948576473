import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Typography } from "@mui/material";

import { LoadingCircularProgress } from "../../components";
import { formatTimestampAsDateTime } from "../../helpers";

import { MemberGroup, useGetOrganizationQuery } from "../organization";
import { useGetMyAccountQuery } from "../account";

export const Summary = (props) => {
  const { asset, share } = props;

  const organizationId = asset && asset.organizationId;

  const { isLoading: isLoadingAccount, data: account } = useGetMyAccountQuery();

  const { isLoading: isLoadingOrganization, data: organization } =
    useGetOrganizationQuery(organizationId, { skip: !organizationId });

  const orgName = useMemo(
    () =>
      organization && organization.name && organization.name.length > 0
        ? organization.name
        : "n/a",
    [organization]
  );

  const isOrgHidden = useMemo(
    () => !asset || !orgName || orgName.length === 0,
    [orgName, asset]
  );

  const { orgMembersLink } = useMemo(() => {
    let orgMembersLink = undefined;

    if (asset && account && asset.organizationId === account.organizationId) {
      orgMembersLink = "/organization/members";
    }

    return { orgMembersLink };
  }, [account, asset]);

  const { shareMemberCount, shareMembersLink } = useMemo(() => {
    let count = 0;
    let link = undefined;

    if (share && share.data) {
      if (share.data.membership && share.data.membership.members) {
        count = share.data.membership.members.length;
      }
      link = `/share/asset/${share.data.id}`;
    }

    return { shareMemberCount: count, shareMembersLink: link };
  }, [share]);

  if (isLoadingAccount || isLoadingOrganization) {
    return <LoadingCircularProgress />;
  }

  return (
    <>
      {asset && (
        <div>
          <Typography variant="body2">Last Modified</Typography>
          <Typography variant="body1">
            {formatTimestampAsDateTime(
              asset.ts * 1000,
              account.preferences.dateFormat
            )}
          </Typography>
          <br />
        </div>
      )}

      {!isOrgHidden && (
        <div>
          <Typography variant="body2">Organization</Typography>
          <Typography variant="body1">{orgName}</Typography>
          <br />
          <Typography variant="body2">Members</Typography>
          <MemberGroup
            link={orgMembersLink}
            members={
              organization &&
              organization.membership &&
              organization.membership.members
            }
          />
          <br />
        </div>
      )}

      <div hidden={shareMemberCount !== 0 || shareMembersLink === ""}>
        <Typography variant="body2">Shared with no one</Typography>
      </div>
      <div hidden={shareMemberCount === 0 || shareMembersLink === ""}>
        <Typography variant="body2">Shared with</Typography>
        <MemberGroup
          link={shareMembersLink}
          members={
            share &&
            share.data &&
            share.data.membership &&
            share.data.membership.members
          }
        />
      </div>
    </>
  );
};

Summary.propTypes = {
  organizations: PropTypes.array,
  asset: PropTypes.object,
  share: PropTypes.object,
};
