import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import RestoreIcon from "@mui/icons-material/RestoreOutlined";

import { mapToQuery, searchInputSlice, useSearchHistories } from ".";

const OnDeleteAll = (props) => {
  const { onDeleteAll } = props;

  return (
    <Box className="OnDeleteAll" sx={{ visibility: "hidden" }}>
      <Tooltip title="Clear all search histories">
        <CloseIcon
          size="small"
          onClick={() => onDeleteAll()}
          sx={{ marginRight: 1 }}
        />
      </Tooltip>
    </Box>
  );
};

OnDeleteAll.propTypes = {
  onDeleteAll: PropTypes.func.isRequired,
};

const OnDeleteItem = (props) => {
  const { item, onDeleteItem } = props;

  return (
    <Box className="OnDeleteItem" sx={{ visibility: "hidden" }}>
      <Tooltip title="Clear search history">
        <CloseIcon
          size="small"
          onClick={() => onDeleteItem(item)}
          sx={{ marginRight: 1 }}
        />
      </Tooltip>
    </Box>
  );
};

OnDeleteItem.propTypes = {
  item: PropTypes.object.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
};

export const SearchHistories = () => {
  const dispatch = useDispatch();
  const { fields, metadata, open, query } = useSelector(
    (state) => state.searchInput
  );
  const { execSearchInput, updateSearchInput } = searchInputSlice.actions;

  const { deleteById, deleteAll, isLoading, filtered, refetch } =
    useSearchHistories({ fields, metadata, open, query }, { skip: !open });

  const onDeleteAll = useCallback(async () => {
    await deleteAll();
  }, [deleteAll]);

  const onDelete = useCallback(
    async (result) => {
      await deleteById({ id: result.id });
    },
    [deleteById]
  );

  const onSelect = useCallback(
    (result) => {
      const data = {
        input: mapToQuery(result.query, result.fields),
        query: result.query,
        fields: result.fields,
      };

      dispatch(updateSearchInput(data));
      dispatch(execSearchInput());
      refetch();
    },
    [dispatch, execSearchInput, refetch, updateSearchInput]
  );

  return (
    <Box sx={{ paddingRight: 1, paddingLeft: 1 }}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          paddingLeft: 1,
          height: "48px",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "background.paper",
            "& .OnDeleteAll": {
              visibility: "visible",
              marginLeft: "auto",
              marginRight: 0,
            },
          },
        }}
      >
        <Typography>Recent searches</Typography>
        <OnDeleteAll onDeleteAll={onDeleteAll} />
      </Box>
      <List>
        {!isLoading &&
          filtered &&
          filtered.map((d) => (
            <ListItem
              button
              sx={{
                paddingRight: 1,
                paddingLeft: 1,
                "&:hover": {
                  backgroundColor: "background.paper",
                  "& .OnDeleteItem": {
                    visibility: "visible",
                    marginLeft: "auto",
                    marginRight: 0,
                  },
                },
              }}
              key={d.id}
            >
              <RestoreIcon size="small" sx={{ marginRight: 1 }} />
              <ListItemText
                onClick={() => onSelect(d)}
                primary={
                  <>
                    {d.fields &&
                      d.fields.map(([k, v]) => (
                        <Chip
                          key={`${k}:${v}`}
                          variant="outlined"
                          margin="dense"
                          size="small"
                          label={`${k}: ${v}`}
                          sx={{ marginRight: 1 }}
                        />
                      ))}{" "}
                    {d.query && d.query.join(" ")}
                  </>
                }
              />
              <OnDeleteItem item={d} onDeleteItem={onDelete} />
            </ListItem>
          ))}
      </List>
    </Box>
  );
};
