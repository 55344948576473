import React from "react";
import Logger from "js-logger";

const logger = Logger.get("renderers");

export const renderError = (msg, err) => {
  logger.info("error: " + msg + " " + err.message);

  return (
    <div>
      {msg} {err.message}
    </div>
  );
};

export const renderInfo = (msg) => {
  logger.debug(msg);

  return <div>{msg}</div>;
};
