import React from "react";

import { Box, Typography } from "@mui/material";

import localConfig from "../../config.json";

const versionConfig = localConfig.version;

export const Version = () => {
  return (
    <Box>
      <Typography variant="h6">
        Version {versionConfig.short}{" "}
        <Typography variant="body2">{versionConfig.long}</Typography>
      </Typography>
    </Box>
  );
};
