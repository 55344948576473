import { useDispatch } from "react-redux";
import { clearSelectedRows } from "../../components";

import { useMemberMutation, useSaveOrganizationMemberMutation } from ".";

const formatSaveMemberMessage = (id, member, err) => {
  if (id && member) {
    return (
      `organization member ${member.profile.name} (${id}) ` +
      (err === undefined ? "updated" : `update failed: ${err}`)
    );
  }
};

export const useSaveMembers = () => {
  const dispatch = useDispatch();
  const saveMember = useMemberMutation(
    useSaveOrganizationMemberMutation,

    (id, member) => {
      if (member) {
        return formatSaveMemberMessage(id, member);
      }
    },

    (id, member, err) => {
      if (member && err) {
        return formatSaveMemberMessage(id, member, err);
      }
    }
  );

  return (id, members) => () => {
    saveMember({ id, items: members });
    dispatch(clearSelectedRows());
  };
};
