import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { UploadDialog } from ".";
import { close } from "./uploadBlobsSlice";

export const UploadBlobs = ({ blobs }) => {
  const dispatch = useDispatch();
  const { open, asset } = useSelector((state) => state.uploadBlobs);

  const handleClose = useCallback(
    (target) => {
      if (!target) {
        dispatch(close());

        return;
      }
    },
    [dispatch]
  );

  return (
    <UploadDialog
      open={open}
      asset={asset}
      onRedirect={blobs !== undefined ? blobs.refetch : null}
      currentPath={blobs !== undefined ? blobs.currentPath : ""}
      onClose={handleClose}
    />
  );
};

UploadBlobs.propTypes = {
  blobs: PropTypes.object,
};
