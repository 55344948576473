import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

const ReadOnlyBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

export const StyledReadOnly = (props) => {
  const { label, value } = props;
  return (
    <ReadOnlyBox key={label} hidden={!value || value.length === 0}>
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body1">{value}</Typography>
    </ReadOnlyBox>
  );
};

StyledReadOnly.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};
