import { useCallback } from "react";
import { useDispatch } from "react-redux";
import Logger from "js-logger";

import { setSelected } from "../../components";

const logger = Logger.get("useDeleteShare");

import { open } from "./deleteShareSlice";

export const useDeleteShare = () => {
  const dispatch = useDispatch();

  return useCallback(
    (items) => () => {
      logger.debug("useDeleteShare:", items);
      const ids = items.map((item) => item.id);

      dispatch(setSelected(ids));
      dispatch(open(items));
    },
    [dispatch]
  );
};
