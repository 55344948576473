import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { selectSingleRow } from "../../components";

import { open } from "./newFolderSlice";

export const useOpenNewFolderDialog = (asset) => {
  const dispatch = useDispatch();

  const containerId = asset && asset.container && asset.container.id;

  return useCallback(
    (row) => () => {
      row && dispatch(selectSingleRow(row));
      dispatch(open({ row, containerId }));
    },
    [dispatch, containerId]
  );
};
