import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import Logger from "js-logger";

import { Box, Button, Grid, Typography } from "@mui/material";

import { AuthContext } from "../../components";

const logger = Logger.get("BillingPortal");

export const BillingPortal = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  const { isAuthenticated, login } = auth;
  logger.debug("isAuthenticated, auth:", isAuthenticated(), auth);

  const handleAuth = () => {
    logger.debug("handleAuth");
    login();
  };

  if (isAuthenticated()) {
    history.push("/organization/subscription");
    return <></>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            alignContent: "space-evenly",
            display: "flex",
          }}
        >
          <Typography variant="body1" sx={{ paddingBottom: 1 }}>
            Please login to continue to billing portal.
          </Typography>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            alignContent: "space-evenly",
            display: "flex",
          }}
        >
          <Button
            aria-controls="continue-to-sign-in-button"
            variant="contained"
            color="primary"
            onClick={handleAuth}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
