import React from "react";
import PropTypes from "prop-types";

import { Card, CardContent, Grid } from "@mui/material";

export const ActionBarCard = ({ buttons }) => {
  const renderedButtons = buttons
    .map((btn) => btn())
    .filter((btn) => !!btn)
    .map((btn, idx) => (
      <Grid item lg={12} sm={12} xl={12} xs={12} key={idx}>
        {btn}
      </Grid>
    ));

  return (
    <Card
      sx={{
        height: "100%",
        backgroundColor: "background.default",
        boxShadow: "none",
        padding: "0px",
        margin: "0px",
      }}
    >
      <CardContent
        sx={{
          alignItems: "center",
          display: "flex",
          padding: "0px",
          backgroundColor: "background.default",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: "background.default",
          }}
        >
          {renderedButtons}
        </Grid>
      </CardContent>
    </Card>
  );
};

ActionBarCard.propTypes = {
  buttons: PropTypes.array.isRequired,
};
