import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { CardContent, CardHeader, Divider, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";

import { clearSelectedRows, StyledCard } from "../../components";

import { BlobPathBreadcrumbs, BlobTreeDataGrid } from ".";

const useTitle = (blobs) =>
  useMemo(() => {
    if (!blobs || !blobs.data) {
      return "";
    }

    return (
      blobs.data.numFolders +
      " Folder" +
      (blobs.data.numFolders == 1 ? ", " : "s, ") +
      blobs.data.numFiles +
      " File" +
      (blobs.data.numFiles == 1 ? "" : "s")
    );
  }, [blobs]);

export const BlobTree = ({ asset, share, blobs }) => {
  const dispatch = useDispatch();

  const title = useTitle(blobs);

  const onRefresh = () => {
    blobs.refetch();
    dispatch(clearSelectedRows());
  };

  if (blobs.isLoading) {
    return <div>Loading</div>;
  }

  return (
    <StyledCard>
      <CardHeader
        title={title}
        action={
          <IconButton size="small" id="refreshIcon" onClick={onRefresh}>
            <RefreshIcon />
          </IconButton>
        }
      />
      <BlobPathBreadcrumbs asset={asset} blobs={blobs} />
      <Divider />
      <CardContent>
        <BlobTreeDataGrid asset={asset} blobs={blobs} share={share} />
      </CardContent>
    </StyledCard>
  );
};

BlobTree.propTypes = {
  asset: PropTypes.object,
  share: PropTypes.object,
  blobs: PropTypes.object,
};
