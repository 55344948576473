import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color: theme.palette.text.primary,
  letterSpacing: "normal",
  "& .MuiCardHeader-title": {
    // h5
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: "20px",
    // backgroundColor: theme.palette.error.light,
  },
  "& .MuiCardHeader-action": {
    // body2
    color: theme.palette.text.secondary,
    fontSize: "12px",
    letterSpacing: "-0.04px",
    lineHeight: "18px",
  },
  "& .MuiDataGrid-root": {
    color: theme.palette.text.primary,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "20px",
  },
  "& .MuiIconButton-root": {
    color: theme.palette.text.primary,
  },
  "& .MuiTablePagination": {
    color: theme.palette.icon,
  },
  "& .MuiTablePagination-selectIcon": {
    color: theme.palette.icon,
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiCheckbox-root svg": {
    width: 16,
    height: 16,
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.icon}`,
    borderRadius: 2,
  },
  "& .MuiCheckbox-root svg path": {
    display: "none",
  },
  "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
    backgroundColor: "#1890ff",
    borderColor: "#1890ff",
  },
  "& .MuiTablePagination-root ": {
    color: theme.palette.text.primary,
  },
  "& .MuiTablePagination-actions ": {
    // backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  "& .MuiDataGrid-actionsCell ": {
    // backgroundColor: theme.palette.error.main,
  },
  // "& .MuiDataGrid-columnHeader": {
  //   // color: theme.palette.mode === "light" ? "#d9d9d9" : "#ff9800",
  //   color: theme.palette.mode === "light" ? "success.main" : "error.main",
  //   backgroundColor:
  //     theme.palette.mode === "light" ? "#ff9800" : "#4caf50",
  //   // color: theme.palette.mode === "light" ? "black" : "white",
  // },
}));
