import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Logger from "js-logger";

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { PlanFeatures, StripeMode, useGetPriceTableQuery } from ".";

const logger = Logger.get("PlanSelection");

export const PlanSelection = () => {
  const [cycle, setCycle] = useState("month");

  const history = useHistory();

  const { isLoading, data } = useGetPriceTableQuery();

  useEffect(() => {
    logger.debug("pricetable: ", data);
  }, [data]);

  const handleCycleChange = (event) => {
    setCycle(event.target.value);
  };

  const handlePlanSelection = (event) => {
    logger.debug("handlePlanSelection: ", event.currentTarget.id);
    const price = data.prices.find((x) => x.id === event.currentTarget.id);
    if (price) {
      history.push(`/pricing/${price.id}`);
    }
  };

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid container spacing={2} sx={{ height: "100%", padding: 2 }}>
      <Grid
        item
        key={`plan-cycle`}
        sx={{
          width: "inherit",
          alignContent: "flex-start",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="radio-billing-cycle"
            name="cycle"
            value={cycle}
            onChange={handleCycleChange}
            sx={{
              width: "inherit",
              alignContent: "flex-start",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <FormControlLabel
              value="month"
              control={<Radio />}
              label="Billed monthly"
            />
            <FormControlLabel
              value="year"
              control={<Radio />}
              label="Billed yearly"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {data &&
        data.prices &&
        data.prices
          .filter((x) => x.recurring.interval === cycle)
          .sort(
            (a, b) =>
              parseInt(a.product.metadata.sortIndex) -
              parseInt(b.product.metadata.sortIndex)
          )
          .map((row, index) => {
            var buttonActionLabel = "Subscribe";
            var hidePricing = false;
            if (
              row.recurring.trial_period_days &&
              row.recurring.trial_period_days > 0
            ) {
              buttonActionLabel = "Start Trial";
            } else if (row.unit_amount === 0) {
              buttonActionLabel = "Contact us";
              hidePricing = true;
            }
            const price = hidePricing
              ? ""
              : `${row.currency}$${row.unit_amount / 100}`;
            const interval = hidePricing ? "" : ` / ${row.recurring.interval}`;
            const features =
              (row.product.metadata &&
                row.product.metadata.features &&
                row.product.metadata.features.split(",")) ||
              [];

            const highlight =
              (row.product.metadata &&
                row.product.metadata.highlight &&
                row.product.metadata.highlight.trim()) ||
              "";

            return (
              <Grid
                item
                key={`plan-option-${index}`}
                sx={{
                  padding: 0,
                  alignContent: "space-evenly",
                  display: "flex",
                  maxWidth: "300px",
                }}
              >
                <Card id={row.id} sx={{ width: "100%", height: "100%" }}>
                  <CardContent>
                    <StripeMode highlight={highlight} liveMode={row.livemode} />
                    <Box sx={{ minHeight: "225px" }}>
                      <Box
                        component="img"
                        alt={row.product.name}
                        src={row.product.images[0]}
                        borderRadius={4}
                        sx={{
                          marginTop: 2,
                          maxHeight: "100px",
                          maxWidth: "200px",
                          display: "flex",
                        }}
                      />
                      <Typography
                        variant="h5"
                        sx={{
                          paddingTop: 2,
                          alignContent: "flex-start",
                          display: "flex",
                        }}
                      >
                        {row.product.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          paddingTop: 1,
                          alignContent: "flex-start",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        {row.product.description}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        paddingTop: 2,
                        paddingBottom: 2,
                        alignContent: "flex-start",
                        display: "flex",
                        minHeight: "75px",
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{ textTransform: "uppercase" }}
                      >
                        {price}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ paddingLeft: "10px", justifyContent: "center" }}
                      >
                        {interval}
                      </Typography>
                    </Box>
                    {hidePricing ? (
                      <Button
                        variant="contained"
                        color="primary"
                        target="_top"
                        rel="noopener noreferrer"
                        href={`mailto:sales@builtstream.com`}
                        // classes={{
                        //   label: classes.button,
                        // }}
                        sx={{
                          width: "100%",
                          paddingLeft: 2,
                          paddingRight: 2,
                        }}
                      >
                        {buttonActionLabel}
                      </Button>
                    ) : (
                      <Button
                        aria-controls="plan-select-button"
                        variant="contained"
                        color="primary"
                        id={row.id}
                        onClick={handlePlanSelection}
                        sx={{
                          width: "100%",
                          paddingLeft: 2,
                          paddingRight: 2,
                        }}
                      >
                        {buttonActionLabel}
                      </Button>
                    )}
                    <PlanFeatures features={features} />
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
    </Grid>
  );
};
