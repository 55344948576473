/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Logger from "js-logger";

import { useTheme } from "@mui/material/styles";
import { Box, CardHeader, Tooltip, Typography } from "@mui/material";
import GenericIcon from "@mui/icons-material/DescriptionOutlined";
import OpenIcon from "@mui/icons-material/OpenInBrowserOutlined";

import { NoMaxWidthTooltip, StyledCard } from "../../components";
import {
  formatTimestampAsDate,
  formatTimestampAsDateTime,
} from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import { SearchActionMenu } from ".";

// eslint-disable-next-line no-unused-vars
const logger = Logger.get("GenericResult");

export const GenericResult = ({ item }) => {
  const theme = useTheme();

  const { data: account } = useGetMyAccountQuery();

  const options = useMemo(() => {
    var result = [];

    result.push({
      id: "open",
      component: (
        <Box sx={{ display: "flex", flex: 1 }}>
          <Tooltip
            title={<div>Open &quot;{item.name}&quot;</div>}
            sx={{ paddingRight: 1 }}
          >
            <div>{<OpenIcon />}</div>
          </Tooltip>
          <Typography variant="body1">Open</Typography>
        </Box>
      ),
      action: () => logger.debug("open item: ", item),
    });

    return result;
  }, [item]);

  const { icon, title } = useMemo(() => {
    const icon = <GenericIcon />;
    const title = item.name;

    return {
      icon,
      title,
    };
  }, [item]);

  return (
    <StyledCard
      sx={{
        height: "100%",
        width: "100%",
        display: "block",
        padding: 1,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <CardHeader
        sx={{
          margin: 0,
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
        title={
          <Box sx={{ display: "flex", flex: 1 }}>
            <Box sx={{ height: 24, width: 24 }}>{icon}</Box>
            <Box sx={{ paddingLeft: 1 }}>
              <NoMaxWidthTooltip title={title}>
                <Typography sx={{ paddingLeft: 1 }} variant="h5" noWrap={true}>
                  {item.name}
                </Typography>
              </NoMaxWidthTooltip>
            </Box>
            <Box sx={{ paddingLeft: 1 }}>
              <NoMaxWidthTooltip
                title={
                  "Last modified " +
                  formatTimestampAsDateTime(
                    item.ts * 1000,
                    account.preferences.dateFormat
                  )
                }
              >
                <Typography variant="body1" noWrap={true}>
                  {formatTimestampAsDate(
                    item.ts * 1000,
                    account.preferences.dateFormat
                  )}
                </Typography>
              </NoMaxWidthTooltip>
            </Box>
          </Box>
        }
        action={<SearchActionMenu options={options} />}
      />
    </StyledCard>
  );
};

GenericResult.propTypes = {
  item: PropTypes.object,
};
