/* eslint-disable no-unused-vars */
import React, { useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Logger from "js-logger";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import OpenIcon from "@mui/icons-material/OpenInBrowserOutlined";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";
import FileIcon from "@mui/icons-material/DescriptionOutlined";
import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import FloorplanIcon from "@mui/icons-material/Filter2Outlined";
import BIMIcon from "@mui/icons-material/Filter3Outlined";
import NewFolderIcon from "@mui/icons-material/CreateNewFolderOutlined";
import PreviewIcon from "@mui/icons-material/PreviewOutlined";
import RenameIcon from "@mui/icons-material/EditOutlined";
import ErrorIcon from "@mui/icons-material/ErrorOutlined";
import WaitingIcon from "@mui/icons-material/HourglassEmpty";
import RunningIcon from "@mui/icons-material/HourglassBottom";

import { environmentName } from "../../config.json";
import {
  selectSelectedRows,
  selectSingleRow,
  setSelected,
  StyledDataGrid,
  ViewAsMenu,
} from "../../components";
import {
  convertToByteUnit,
  convertByteNumberByUnit,
  formatTimestampAsDateTime,
  isEditableRole,
} from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";
import {
  isFolder,
  getBlobName,
  isPointCloud,
  useOpenDeleteBlobDialog,
  useOpenCopyBlobDialog,
  useOpenNewFolderDialog,
  useOpenRenameBlobDialog,
  useOpenBlob,
  useOpenUploadBlobsDialog,
  useDownloadBlob,
  usePreviewBlob,
  useGenerateFloorplan,
  useGenerateBim,
} from ".";

const showPointCloud = (row) => !isFolder(row) && isPointCloud(row);

const isProduction = environmentName === "production";

const logger = Logger.get("BlobTreeDataGrid");

export const BlobTreeDataGrid = ({ asset, blobs, share }) => {
  const theme = useTheme();

  const { data: account } = useGetMyAccountQuery();
  const { data: organization } = useGetMyOrganizationQuery();

  const [sortModel, setSortModel] = useState([]);
  const openBlob = useOpenBlob();
  const previewBlob = usePreviewBlob();
  const generateFloorplan = useGenerateFloorplan();
  const generateBim = useGenerateBim();
  const downloadBlob = useDownloadBlob();

  const openNewFolderDialog = useOpenNewFolderDialog(asset);
  const openCopyBlobDialog = useOpenCopyBlobDialog(asset);
  const openRenameBlobDialog = useOpenRenameBlobDialog(asset);
  const openDeleteBlobDialog = useOpenDeleteBlobDialog();
  const openUploadBlobsDialog = useOpenUploadBlobsDialog();

  const dispatch = useDispatch();
  const selectedRows = useSelector(selectSelectedRows);
  const preferences = useSelector((state) => state.preferences);

  const rows = useMemo(() => {
    if (!blobs.data) {
      return [];
    }

    if (blobs.data.currentPath && blobs.data.currentPath.length > 0) {
      const parentId =
        blobs.data.currentPath.length > 1
          ? blobs.data.currentPath[blobs.data.currentPath.length - 2].id
          : null;
      return [
        {
          id: "",
          name: "..",
          parentId: parentId,
          metadata: { type: "folder" },
          properties: {},
        },
      ].concat(blobs.data.data);
    }

    return blobs.data.data;
  }, [blobs]);

  const { hasEditableRole } = useMemo(() => {
    let hasEditableRole = false;

    if (account && organization && share && share.data) {
      hasEditableRole = isEditableRole(account, organization, share.data);
    }

    return { hasEditableRole };
  }, [account, organization, share]);

  const handleSelectRow = useCallback(
    (row) => {
      if (isFolder(row)) {
        const newParentId = row.name === ".." ? row.parentId : row.id;

        // Ugh... https://github.com/mui/mui-x/issues/2714
        setTimeout(() => {
          blobs.setParentId(newParentId);
        });
      }
    },
    [blobs]
  );

  const onCellClick = (params, event) => {
    event.stopPropagation();
    event.defaultMuiPrevented = true;

    if (params.field !== "__check__" && params.field !== "moreMenu") {
      handleSelectRow(params.row);
    }
  };

  const renderMoreMenu = (row) => {
    const moreMenu = [];

    if (!isFolder(row)) {
      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Open file</div>}>
              <div>{<OpenIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-open"
          label="Open"
          onClick={openBlob(row)}
          showInMenu
        />
      );

      if (showPointCloud(row)) {
        if (row.metadata && row.metadata.potreeFormatState) {
          if (row.metadata.potreeFormatState === "completed") {
            moreMenu.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title={<div>preview generated</div>}>
                    <div>{<PreviewIcon sx={{ color: "icon" }} />}</div>
                  </Tooltip>
                }
                key="more-menu-preview"
                label="Preview"
                onClick={previewBlob(row)}
              />
            );
          } else if (
            row.metadata &&
            (row.metadata.potreeFormatState === "waiting" ||
              row.metadata.potreeFormatState === "running")
          ) {
            moreMenu.push(
              <GridActionsCellItem
                icon={
                  <Tooltip
                    title={
                      <div>
                        preview generation {row.metadata.potreeFormatState}
                      </div>
                    }
                  >
                    <div>
                      {row.metadata.potreeFormatState === "waiting" ? (
                        <WaitingIcon sx={{ color: "icon" }} />
                      ) : (
                        <RunningIcon sx={{ color: "icon" }} />
                      )}
                    </div>
                  </Tooltip>
                }
                key="more-menu-preview"
                label="Preview"
                onClick={previewBlob(row)}
              />
            );
          } else if (row.metadata && row.metadata.potreeFormatError != "") {
            moreMenu.push(
              <GridActionsCellItem
                icon={
                  <Tooltip
                    title={
                      <div>
                        preview generation {row.metadata.potreeFormatState}:{" "}
                        {row.metadata.potreeFormatError}
                      </div>
                    }
                  >
                    <div>{<ErrorIcon sx={{ color: "icon" }} />}</div>
                  </Tooltip>
                }
                key="more-menu-preview"
                label="Preview"
                onClick={previewBlob(row)}
              />
            );
          }
        }

        moreMenu.push(
          <GridActionsCellItem
            icon={
              <Tooltip
                title={<div>Generate point cloud preview and render</div>}
              >
                <div>{<PreviewIcon sx={{ color: "icon" }} />}</div>
              </Tooltip>
            }
            key="more-menu-preview"
            label="Preview"
            onClick={previewBlob(row)}
            showInMenu
          />
        );
      }

      if (!isProduction && hasEditableRole) {
        moreMenu.push(
          <GridActionsCellItem
            icon={
              <Tooltip title={<div>Generate 2D floorplan from file</div>}>
                <div>{<FloorplanIcon sx={{ color: "icon" }} />}</div>
              </Tooltip>
            }
            key="more-menu-floorplan"
            label="Floorplan (alpha)"
            onClick={generateFloorplan(row)}
            showInMenu
          />
        );
        moreMenu.push(
          <GridActionsCellItem
            icon={
              <Tooltip title={<div>Generate 3D BIM from file</div>}>
                <div>{<BIMIcon sx={{ color: "icon" }} />}</div>
              </Tooltip>
            }
            key="more-menu-bim"
            label="BIM (alpha)"
            onClick={generateBim(row)}
            showInMenu
          />
        );
      }

      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Download file</div>}>
              <div>{<DownloadIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-download"
          label="Download"
          onClick={downloadBlob([row])}
          showInMenu
        />
      );
    }

    if (row && row.name !== ".." && hasEditableRole) {
      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Copy {row.nodeType}</div>}>
              <div>{<CopyIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-copy"
          label="Copy"
          onClick={openCopyBlobDialog(row)}
          showInMenu
        />
      );

      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Rename {row.nodeType}</div>}>
              <div>{<RenameIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-rename"
          label="Rename / Move"
          onClick={openRenameBlobDialog(row)}
          showInMenu
        />
      );

      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Delete {row.nodeType}</div>}>
              <div>{<DeleteIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-delete"
          label="Delete"
          onClick={openDeleteBlobDialog([row])}
          showInMenu
        />
      );

      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Create new folder</div>}>
              <div>{<NewFolderIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-new"
          label="New Folder"
          onClick={openNewFolderDialog(row)}
          showInMenu
        />
      );
    }

    return moreMenu;
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      description: "Folder / File name",
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={getBlobName(params.row)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              {params.row.metadata &&
              params.row.metadata.type &&
              params.row.metadata.type === "folder" ? (
                <FolderIcon sx={{ color: theme.palette.icon }} />
              ) : (
                <FileIcon sx={{ color: theme.palette.icon }} />
              )}
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Typography scope="row" variant="body1" noWrap={true}>
                {getBlobName(params.row)}
              </Typography>
            </Grid>
          </Grid>
        </Tooltip>
      ),
    },
    {
      field: "lastModified",
      headerName: "Modified",
      description: "Last modified",
      flex: 0.6,
      minWidth: 175,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const lastModified = params.row.properties.lastModified;

        if (!lastModified) {
          return <></>;
        }

        return (
          <Tooltip title={"Last modified " + lastModified}>
            <Typography scope="row" variant="body1" noWrap={true}>
              {formatTimestampAsDateTime(
                lastModified,
                account.preferences.dateFormat
              )}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "contentLength",
      headerName: "Size",
      description: "File size",
      flex: 0.6,
      minWidth: 175,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (
          !params.row.properties ||
          !params.row.properties.etag ||
          (params.row.metadata &&
            params.row.metadata.type &&
            params.row.metadata.type === "folder")
        ) {
          return <></>;
        }

        const units = convertToByteUnit(params.row.properties.contentLength);
        const size = convertByteNumberByUnit(
          params.row.properties.contentLength,
          units,
          1
        );

        return (
          <Tooltip
            title={
              <div>
                Size {size} {units}
              </div>
            }
          >
            <Typography scope="row" variant="body1" noWrap={true}>
              {size} {units}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "moreMenu",
      type: "actions",
      headerName: "",
      midWidth: 50,
      editable: false,
      sortable: false,
      align: "right",
      getActions: (params) => renderMoreMenu(params.row),
      renderHeader: () => <ViewAsMenu />,
    },
  ];

  const rowCount = (blobs.data && blobs.data.count) || 0;

  const handleUploadDialogOpen = () => {
    logger.debug("handleUploadDialogOpen");
    dispatch(openUploadBlobsDialog(asset));
  };

  const NoRowsOverlay = () => {
    return (
      <Box
        sx={{
          display: "flex",
          marginTop: "80px",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No files found.{" "}
        <Button color="primary" variant="text" onClick={handleUploadDialogOpen}>
          Upload
        </Button>{" "}
        files to get started.
      </Box>
    );
  };

  // Note: Sorting of paginated with hierarchy not currently supported
  return (
    <StyledDataGrid
      autoHeight
      checkboxSelection
      columns={columns}
      slots={{
        loadingOverlay: LinearProgress,
        noRowsOverlay: NoRowsOverlay,
      }}
      disableSelectionOnClick
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: blobs.pageSize,
            page: blobs.page,
          },
        },
        // sorting: {
        //   sortModel: [{ ...blobs.sortModel }],
        // },
      }}
      loading={blobs.isLoading}
      onCellClick={onCellClick}
      onPaginationModelChange={(paginationModel) => {
        blobs.setPage(paginationModel.page);
        blobs.setPageSize(paginationModel.pageSize);
      }}
      onRowSelectionModelChange={(selected) => {
        dispatch(setSelected(selected));
      }}
      // onSortModelChange={(m) => {
      //   if (m.length > 0) {
      //     blobs.setSortModel(m[0]);
      //   } else {
      //     blobs.resetSortModel();
      //   }
      // }}
      pageSizeOptions={[1, 2, 3, 10, 25, 50, 100]}
      paginationMode="server"
      paginationModel={preferences}
      rowCount={rowCount}
      rows={rows}
      rowSelectionModel={selectedRows}
      // sortingMode="server"
    />
  );
};

BlobTreeDataGrid.propTypes = {
  asset: PropTypes.object,
  blobs: PropTypes.object,
  share: PropTypes.object,
};

export default BlobTreeDataGrid;
