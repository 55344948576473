import React from "react";
import Logger from "js-logger";

import { CardHeader, Switch, Tooltip } from "@mui/material";

import { StyledCard } from "../../components";

const logger = Logger.get("TwoFactorAuthentication");

export const TwoFactorAuthentication = () => {
  const [enabled, setEnabled] = React.useState(false);

  const handleChange = (event) => {
    logger.debug(
      "handleChange: (not implemented at this time)",
      event.target.checked
    );
    setEnabled(event.target.checked);
  };

  const title = "Two-Factor Authentication";
  const description =
    "Two-factor authentication (2FA) adds an extra layer of security" +
    "to your account beyond your username and password. When you sign in with 2FA enabled," +
    "you will be prompted to use a security key, enter a verfication code or approve the " +
    "sign in from your mobile device.";

  return (
    <StyledCard>
      <CardHeader
        title={title}
        subheader={description}
        action={
          <div>
            <Tooltip title="coming soon">
              <Switch
                checked={enabled}
                onChange={handleChange}
                color="primary"
                name="enable2FA"
                inputProps={{ "aria-label": "primary checkbox" }}
                // TODO: Revisit: handle 2FA
                disabled={true}
              />
            </Tooltip>
          </div>
        }
      />
    </StyledCard>
  );
};
