import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { open } from "./upsertShareAssetSlice";

export const useUpsertShareAsset = () => {
  const dispatch = useDispatch();

  return useCallback(
    (item) => () => {
      dispatch(open(item));
    },
    [dispatch]
  );
};
