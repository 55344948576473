import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { default as AuthContext } from "./AuthContext";
import { LoadingCircularProgress } from "..";

export const Callback = (props) => {
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (/access_token|id_token|error/.test(props.location.hash)) {
      auth.handleAuthentication();
    } else {
      throw new Error("Invalid callback url");
    }
  });

  return <LoadingCircularProgress />;
};

Callback.propTypes = {
  location: PropTypes.object,
};
