// Reference: https://en.wikipedia.org/wiki/Mebibyte
const sizeNames = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
const sizeMetricNames = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export function convertToByteUnitString(sizeBytes, decimals = 2) {
  if (sizeBytes == 0) return "0 B";

  const exponent = Math.trunc(Math.floor(Math.log(sizeBytes) / Math.log(1024)));
  const perUnit = Math.pow(1024, exponent);
  const factor = Math.pow(10, Math.max(1, decimals));
  return (
    (Math.round((sizeBytes / perUnit) * factor) / factor).toFixed(decimals) +
    " " +
    sizeNames[exponent]
  );
}

export function convertToByteUnitNumber(sizeBytes, decimals = 2) {
  if (sizeBytes == 0) return 0;

  const exponent = Math.trunc(Math.floor(Math.log(sizeBytes) / Math.log(1024)));
  const perUnit = Math.pow(1024, exponent);
  const factor = Math.pow(10, Math.max(1, decimals));
  return Math.round((sizeBytes / perUnit) * factor) / factor;
}

export function convertToByteUnit(sizeBytes) {
  if (sizeBytes == 0) return sizeNames[0];

  const exponent = Math.trunc(Math.floor(Math.log(sizeBytes) / Math.log(1024)));
  return sizeNames[exponent];
}

export function convertByteNumberByUnit(sizeBytes, units, decimals = 2) {
  if (sizeBytes == 0) return 0;

  let exponent = sizeNames.findIndex((sizeName) => sizeName === units);
  if (exponent === -1) {
    exponent = sizeMetricNames.findIndex((sizeName) => sizeName === units);
  }
  if (exponent === -1) {
    return 0;
  }

  const perUnit = Math.pow(1024, exponent);
  const factor = Math.pow(10, Math.max(1, decimals));
  return Math.round((sizeBytes / perUnit) * factor) / factor;
}

export function convertUnitsToBytes(sizeUnits, units) {
  if (sizeUnits == 0) return 0;

  let exponent = sizeNames.findIndex((sizeName) => sizeName === units);
  if (exponent === -1) {
    exponent = sizeMetricNames.findIndex((sizeName) => sizeName === units);
  }
  if (exponent === -1) {
    return 0;
  }

  const perUnit = Math.pow(1024, exponent);
  return sizeUnits * perUnit;
}
