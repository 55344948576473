import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectSelectedRows } from "../../components";
import { isEditableRole, isOwnerRole } from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";

export const useSelectedMembers = (share) => {
  const { data: account } = useGetMyAccountQuery();
  const { data: organization } = useGetMyOrganizationQuery();
  const selectedRows = useSelector(selectSelectedRows);

  return useMemo(() => {
    let selectedMembers = [];
    let inactiveMembers = [];
    let hasOwnerRole = isOwnerRole(account, organization, share);
    let hasEditableRole = isEditableRole(account, organization, share);
    let isMe = false;

    if (share && share.membership && share.membership.members) {
      selectedMembers = share.membership.members.filter(
        (member) =>
          selectedRows.includes(member.id) ||
          selectedRows.includes(member.invitationId)
      );

      inactiveMembers = selectedMembers.filter((x) => x.status !== "active");
    }

    const selectedMember = selectedMembers.length == 1 && selectedMembers[0];
    const hasSelectedMembers = selectedMembers.length > 0;
    const hasInactiveMembers = inactiveMembers.length > 0;

    isMe = selectedMember && selectedMember.id === account.id;

    return {
      hasEditableRole,
      hasInactiveMembers,
      hasSelectedMembers,
      hasOwnerRole,
      inactiveMembers,
      isMe,
      selectedMember,
      selectedMembers,
    };
  }, [account, organization, selectedRows, share]);
};
