import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import config from "../../config.json";

const baseQuery = fetchBaseQuery({
  baseUrl: config.http.apiUrl + "/",
  prepareHeaders: (headers) => {
    // Todo: Should support multiple storage locations.  Desktop vs web.
    const token = localStorage.getItem("access_token");

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

// eslint-disable-next-line no-unused-vars
const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });

export const api = createApi({
  baseQuery: baseQuery,
  tagTypes: [
    "Accounts",
    "Assets",
    "Billings",
    "Blobs",
    "ContainerUsage",
    "Invitations",
    "OrganizationFeatures",
    "Organizations",
    "Prices",
    "PriceTableItems",
    "RecentAssets",
    "Search",
    "SearchHistory",
    "SearchParameters",
    "SearchSuggestions",
    "Shares",
    "TagNames",
    "Tags",
    "Tasks",
    "Version",
    "UNAUTHORIZED",
    "UNKNOWN_ERROR",
  ],
  endpoints: () => ({}),
});

export const providesList = (resultsWithIds, tagType) => {
  return resultsWithIds
    ? [
        { type: tagType, id: "LIST" },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: "LIST" }];
};
