import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectPageSize } from "../../components";
import {
  useDeleteAllSearchHistoryMutation,
  useDeleteSearchHistoryMutation,
  useGetSearchHistoryQuery,
  parseParameters,
} from ".";

export const useSearchHistories = ({ open, fields, metadata, query }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(useSelector(selectPageSize));
  const [sortModel, setSortModel] = useState({
    field: "ts",
    sort: "desc",
  });
  const [filters, setFilters] = useState(null);

  const {
    isLoading,
    isUninitialized,
    data: unfiltered,
    refetch,
  } = useGetSearchHistoryQuery(
    {
      query: query,
      page: page,
      pageSize: pageSize,
      sortModel: sortModel,
      fields: fields,
      metadata: metadata,
    },
    { skip: !open }
  );
  const [deleteAllSearchHistory] = useDeleteAllSearchHistoryMutation();
  const [deleteSearchHistory] = useDeleteSearchHistoryMutation();

  const filtered = useMemo(() => {
    var filtered = [];

    if (!isLoading && unfiltered && unfiltered.items) {
      unfiltered.items.forEach((x) => {
        const { query, fields } = parseParameters(x.parameters);
        var result = {
          ...x,
          query,
          fields,
        };

        if (filtered.find((existing) => existing.id === result.id)) {
          return;
        }

        filtered.push(result);
      });

      if (filters) {
        // TODO: revisit filtering search history results
        return filtered;
      }

      return filtered;
    }

    return filtered;
  }, [filters, isLoading, unfiltered]);

  useEffect(() => {
    if (query && query !== "" && !isUninitialized) {
      refetch();
    }
  }, [isUninitialized, query, refetch]);

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    isLoading,
    isUninitialized,
    filtered,
    unfiltered,
    refetch,
    sortModel,
    setSortModel,
    fields,
    metadata,
    query,
    filters,
    setFilters,
    deleteAll: deleteAllSearchHistory,
    deleteById: deleteSearchHistory,
  };
};
