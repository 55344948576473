import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Logger from "js-logger";
import { v4 as uuidv4 } from "uuid";

import { Box, Typography } from "@mui/material";

import {
  convertToByteUnit,
  convertByteNumberByUnit,
  isUUID,
} from "../../helpers";
import { AuthContext } from "../../components";
import { TasksContext } from "../../workers";
import { UploadProgress } from ".";

const logger = Logger.get("UploadBlob");

export const UploadBlob = (props) => {
  const {
    assetId,
    assetName,
    cancelling,
    containerId,
    file,
    folderPath,
    onComplete,
    onRegister,
    onUnregister,
    organizationId,
    overwrite,
    shareId,
    uploading,
  } = props;
  const [uploadState, setUploadState] = useState("");
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadSize, setUploadSize] = useState(0);
  const [units] = useState(convertToByteUnit(file.size));

  const auth = useContext(AuthContext);
  const tasksContext = useContext(TasksContext);

  const workerHandler = (response) => {
    if (file.requestId === response.request.id) {
      logger.debug("workerHandler:", response);

      if (response.task && isUUID(response.task.id)) {
        logger.debug(
          "workerHandler.setting upload state:",
          response.task.action
        );
        setUploadState(response.task.action);
      }

      if (
        response.task &&
        response.task.progress &&
        Object.keys(response.task.progress).length > 0
      ) {
        if (!response.task.progress.metadata) {
          // Only update on overall progress
          var percentComplete = Math.max(
            uploadPercentage,
            parseInt(response.task.progress.value, 10)
          );
          var totalFileSize = Math.max(
            uploadSize,
            parseInt(response.request.size, 10)
          );
          setUploadPercentage(percentComplete);
          setUploadSize((percentComplete * totalFileSize) / 100);
        }
      }

      if (response.task && response.task.result && response.task.result.state) {
        if (response.task.result.state === "completed") {
          setUploadPercentage(100);
          setUploadSize(parseInt(response.request.size, 10));
          setUploadState("completed");
          setUploadMessage("completed");

          onComplete(file.requestId, "completed");
        } else if (
          response.task.result.state === "cancelled" ||
          response.task.result.state === "faulted"
        ) {
          setUploadState("error");
          const errMsg =
            (response.error && response.error.message) || "unknown error";
          setUploadMessage(errMsg);
          onComplete(file.requestId, "error");
        }
      }
    }
  };

  useEffect(() => {
    if (cancelling) {
      handleCancel();
    }

    return function cleanup() {
      logger.debug("useEffect1 cleanup");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelling]);

  useEffect(() => {
    if (uploading) {
      handleUpload();
    }

    return function cleanup() {
      logger.debug("useEffect0 cleanup");
      tasksContext.removeListener(file.requestId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploading]);

  const init = () => {
    setUploadState("");
    setUploadMessage("");
    setUploadPercentage(0);
    setUploadSize(0);
  };

  const handleUpload = () => {
    init();

    file.requestId = uuidv4();
    logger.debug("handleUpload.uploading: file:", file);

    const registration = {
      task: {
        action: "register",
        type: "UploadBlob",
        token: auth.getAccessToken(),
      },
      request: {
        assetId: assetId,
        assetName: assetName,
        containerId: containerId,
        folderPath: folderPath,
        id: file.requestId,
        name: file.name,
        organizationId: organizationId,
        overwrite: overwrite,
        shareId: shareId,
        size: file.size,
        sourceAgent: window.navigator.userAgent,

        // Note:
        // https://developer.mozilla.org/en-US/docs/Web/API/FileReader
        // FileReader is used to read file content from the user's (remote) system in secure ways only.
        // It cannot be used to simply read a file by pathname from a file system.
        // To read files by pathname in JavaScript, standard Ajax solutions should be used to do
        // server-side file reading, with CORS permission if reading cross-domain.
        // Therefore, we need to pass the entire File object to our web worker threads.
        file: file,
      },
    };
    setUploadState("queued");

    tasksContext.addListener(file.requestId, workerHandler);
    tasksContext.register(registration);

    onRegister(file.requestId);
  };

  const handleCancel = () => {
    logger.debug("handleCancel: file:", file);
    const registration = {
      task: {
        action: "cancel",
        type: "UploadBlob",
        token: auth.getAccessToken(),
      },
      request: {
        id: file.requestId,
      },
    };
    tasksContext.cancel(registration);
  };

  const handlePause = () => {
    logger.debug("handlePause: file:", file);
  };

  const handleResume = () => {
    logger.debug("handleResume: file:", file);
  };

  const handleRetry = () => {
    logger.debug("handleRetry: file:", file);

    // Clean up previous attempt
    tasksContext.removeListener(file.requestId);
    onUnregister(file.requestId);

    // Start new attempt
    handleUpload();
  };

  return (
    <>
      <Box
        sx={{
          flexBasis: "100%",
          margin: 0,
          padding: 0,
          width: "100%",
          alignItems: "flex-start",
          justifyItems: "flex-start",
          overflowYy: "auto",
          borderBottom: "none",
        }}
      >
        <Typography variant="body1" noWrap={true}>
          {file.name}
        </Typography>
      </Box>
      <Box sx={{ margin: 0, width: "100%" }}>
        {uploading || uploadState ? (
          <UploadProgress
            denominator={convertByteNumberByUnit(file.size, units, 1)}
            message={uploadMessage}
            numerator={convertByteNumberByUnit(uploadSize, units, 1)}
            onCancel={handleCancel}
            onPause={handlePause}
            onResume={handleResume}
            onRetry={handleRetry}
            state={uploadState}
            units={units}
            value={uploadPercentage}
          />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

UploadBlob.propTypes = {
  assetId: PropTypes.string.isRequired,
  assetName: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  cancelling: PropTypes.bool.isRequired,
  file: PropTypes.object.isRequired,
  folderPath: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onRegister: PropTypes.func.isRequired,
  onUnregister: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  overwrite: PropTypes.bool.isRequired,
  shareId: PropTypes.string.isRequired,
  uploading: PropTypes.bool.isRequired,
};
