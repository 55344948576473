import { colors } from "@mui/material";

const white = "#FFFFFF";
const black = "#000000";
const builtstreamPrimary = {
  50: "#E1F5FF",
  100: "#B2E5FF",
  200: "#7DD5FF",
  300: "#44C4FF",
  400: "#01B7FF",
  500: "#00AAFE",
  600: "#009CEF",
  700: "#0089DA",
  800: "#0078C6",
  900: "#0057A3",
  A100: "#82B1FF",
  A200: "#448AFF",
  A400: "#2979FF",
  A700: "#2962FF",
};
const builtstreamSecondary = {
  50: "#C8FFF4",
  100: "#70EFDE",
  200: "#03DAC5",
  300: "#00C4B4",
  400: "#00B3A6",
  500: "#01A299",
  600: "#019592",
  700: "#018786",
  800: "#017374",
  900: "#005457",
  A100: "#A7FFEB",
  A200: "#64FFDA",
  A400: "#1DE9B6",
  A700: "#00BFA5",
};

export default {
  mode: "light",
  black,
  white,
  primary: {
    contrastText: white,
    dark: builtstreamPrimary[900],
    main: builtstreamPrimary[700], // [500]
    light: builtstreamPrimary[100],
  },
  secondary: {
    contrastText: white,
    dark: builtstreamSecondary[800], // [900]
    main: builtstreamSecondary[500], // ['A400']
    light: builtstreamSecondary[100], // ['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
};
