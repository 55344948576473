import React from "react";
import PropTypes from "prop-types";

import { Box, Icon, Typography } from "@mui/material";

export const PlanFeatures = (props) => {
  const { features } = props;

  if (features === undefined || features.length === 0) {
    return <></>;
  }

  return (
    <div>
      <Typography
        variant="body2"
        sx={{
          paddingTop: 2,
          alignContent: "left",
          display: "flex",
        }}
      >
        This includes:
      </Typography>
      <Box sx={{ alignContent: "left", display: "block" }}>
        {features.map((f, i) => {
          const feature = f.trim();
          return (
            <div key={`feature-${i}`}>
              <Typography
                key={`feature-${i}`}
                variant="body2"
                sx={{
                  paddingTop: 1,
                  alignContent: "flex-start",
                  display: "flex",
                }}
              >
                <Icon
                  fontSize="inherit"
                  sx={{
                    fontSize: "inherit",
                    verticalAlign: "text-top",
                    paddingRight: 2,
                    paddingTop: "1px",
                  }}
                >
                  check_circle
                </Icon>
                {feature}
              </Typography>
            </div>
          );
        })}
      </Box>
    </div>
  );
};

PlanFeatures.propTypes = {
  features: PropTypes.array,
};
