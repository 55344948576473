import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { Box, Grid, Tab, Tabs } from "@mui/material";

import { TabPanel } from "../../components";
import { useGetMyOrganizationQuery, Subscription } from "../organization";
import { Profile, Security, Preferences, Notifications } from ".";

function a11yProps(index) {
  return {
    id: `settings-tab-${index}`,
    "aria-controls": `settings-tabpanel-${index}`,
  };
}

export const Settings = () => {
  const history = useHistory();
  const { tabId } = useParams();

  const theme = useTheme();

  const [tabIndex, setTabIndex] = useState(0);

  const { data: organization } = useGetMyOrganizationQuery();

  const panels = useMemo(() => {
    var panels = [
      {
        id: "profile",
        component: <Profile />,
      },
      {
        id: "security",
        component: <Security />,
      },
      {
        id: "preferences",
        component: <Preferences />,
      },
      {
        id: "notifications",
        component: <Notifications />,
      },
    ];
    if (organization.hidden) {
      panels.push({
        id: "subscription",
        componenet: <Subscription />,
      });
    }
    return panels;
  }, [organization]);

  const handleTabIndexChange = useCallback(
    (newValue) => {
      if (newValue > panels.length) {
        newValue = 0;
      }

      history.push("/settings/" + panels[newValue].id);
    },
    [history, panels]
  );

  useEffect(() => {
    if (tabId === undefined) {
      handleTabIndexChange(0);
      return;
    }

    const index = panels.findIndex((x) => x.id === tabId);
    if (index === -1) {
      setTabIndex(0);
    } else {
      return setTabIndex(index);
    }
  }, [handleTabIndexChange, panels, tabId]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.default,
        padding: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={10} sm={12} xl={10} xs={12}>
          <Box
            position="static"
            color="transparent"
            sx={{
              boxShadow: "none",
            }}
          >
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={tabIndex}
              onChange={(e, v) => handleTabIndexChange(v)}
              variant="scrollable"
              scrollButtons={true}
              aria-label="settings tabs"
            >
              {panels.map((x, idx) => {
                return (
                  <Tab
                    label={x.id}
                    key={`settings-tab-key-${idx}`}
                    {...a11yProps({ idx })}
                  />
                );
              })}
            </Tabs>
          </Box>
          {panels.map((x, idx) => {
            return (
              <TabPanel
                key={`settings-tabpanel-key-${idx}`}
                index={idx}
                name="settings"
                value={tabIndex}
              >
                {x.component}
              </TabPanel>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};
