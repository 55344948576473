import React from "react";

import { useTheme } from "@mui/material/styles";
import { Box, Button, Link, Typography } from "@mui/material";
import ReportIcon from "@mui/icons-material/ReportProblem";

export const NotFound = () => {
  const theme = useTheme();

  return (
    // <div className={classes.root}>
    <Box
      sx={{
        padding: theme.spacing(2),
        width: "100%",
      }}
    >
      {/* <div className={classes.content}> */}
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
        }}
      >
        {/* <div className={classes.reportIcon}> */}
        <Box
          sx={{
            color: theme.palette.error.main,
            align: "center",
            zoom: 2,
          }}
        >
          <ReportIcon />
        </Box>
        {/* </div> */}
        <Typography variant="h3">Oops!</Typography>
        <br />
        <Typography variant="body1">
          The page you are looking for was not found.
        </Typography>
        <br />
        <Link
          href="/"
          onClick={() => {
            window.open("#", "_self");
          }}
          variant="body1"
        >
          return to application
        </Link>
        <br />
        <br />
        <Typography variant="body1">
          If you continue to have problems, please contact{" "}
          <Button
            variant="text"
            color="primary"
            target="_top"
            rel="noopener noreferrer"
            href={`mailto:support@builtstream.com`}
            // classes={{
            //   label: classes.supportButton,
            // }}
            // className={classes.supportButton}
            sx={{
              textTransform: "none",
              padding: 0,
              margin: 0,
            }}
          >
            support@builtstream.com
          </Button>{" "}
          to troubleshoot.
        </Typography>
      </Box>
      {/* </div> */}
    </Box>
    // </div>
  );
};
