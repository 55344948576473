import { createSlice } from "@reduxjs/toolkit";

export const uploadBlobsSlice = createSlice({
  name: "uploadBlobs",
  initialState: {
    open: false,
    asset: undefined,
  },
  reducers: {
    open(state, action) {
      state.open = true;
      state.asset = action.payload;
    },
    close(state) {
      state.open = false;
      state.asset = undefined;
    },
  },
});

export const { open, close } = uploadBlobsSlice.actions;
export const uploadBlobs = uploadBlobsSlice.reducer;
