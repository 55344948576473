import React from "react";

import OwnerIcon from "@mui/icons-material/FaceOutlined";
import CanEditIcon from "@mui/icons-material/CreateOutlined";
import CanViewIcon from "@mui/icons-material/VisibilityOutlined";

export const memberRoles = [
  {
    value: "owner",
    label: "Owner",
    description: "Can create, update and delete",
    icon: <OwnerIcon />,
  },
  {
    value: "can edit",
    label: "Can Edit",
    description: "Can create and update",
    icon: <CanEditIcon />,
  },
  {
    value: "can view",
    label: "Can View",
    description: "Can only view (read-only)",
    icon: <CanViewIcon />,
  },
];

export function findMemberRole(roleName) {
  if (roleName) {
    const m = memberRoles.find((x) => x.value === roleName);
    if (m) {
      return m;
    }
  }
  return null;
}

export function findMyRole(acct, obj) {
  if (obj && obj.membership && obj.membership.members) {
    const m = obj.membership.members.find((x) => x.id === acct.id);
    if (m) {
      return m.role;
    }
  }
  return "";
}

function getAnyRole(account, accountOrganization, assetShare) {
  if (
    !account ||
    account === undefined ||
    account === null ||
    !accountOrganization ||
    accountOrganization === undefined ||
    accountOrganization.id === null
  ) {
    return "";
  }

  let m = null;
  if (
    assetShare &&
    Object.keys(assetShare).length > 0 &&
    assetShare.membership &&
    assetShare.membership.members &&
    account.organizationId !== assetShare.organizationId
  ) {
    // We've specified a share, and account does not belong to
    // same organization, check for share permissions
    m = assetShare.membership.members.find((x) => x.id === account.id);
  } else if (
    accountOrganization.id === account.organizationId &&
    (assetShare === null ||
      (assetShare &&
        Object.keys(assetShare).length > 0 &&
        account.organizationId === assetShare.organizationId))
  ) {
    // We are within the same organization,
    // whether we specified a share or not
    if (accountOrganization.membership) {
      m = accountOrganization.membership.members.find(
        (x) => x.id === account.id
      );
    }
  }
  if (m) {
    return m.role;
  }

  return "";
}

export function isOwnerRole(account, accountOrganization, assetShare) {
  const r = getAnyRole(account, accountOrganization, assetShare);
  const result = r === "owner";

  return result;
}

export function isEditableRole(account, accountOrganization, assetShare) {
  const r = getAnyRole(account, accountOrganization, assetShare);
  const result = r === "owner" || r === "can edit";

  return result;
}

export function isViewableRole(account, accountOrganization, assetShare) {
  const r = getAnyRole(account, accountOrganization, assetShare);
  const result = r === "owner" || r === "can edit" || r === "can view";

  return result;
}
