import { useState, useEffect } from "react";
import Logger from "js-logger";

import { logToastDebug, logToastError } from "../../components";

const logger = Logger.get("useInvitationMutation");

export const useInvitationMutation = (mutation, onSuccess, onFailure) => {
  // Note: data = invitations
  const [data, setData] = useState();
  const [success, setSuccess] = useState([]);
  const [errors, setErrors] = useState([]);
  const [runMutation] = mutation();
  useEffect(() => {
    if (data) {
      if (data.length === 0) {
        return;
      }
      if (success.length + errors.length < data.length) {
        return;
      }

      let msg = success.join("; ");
      if (errors.length > 0) {
        msg += errors.join("; ");
        logToastError(logger, msg);
      } else {
        logToastDebug(logger, msg);
      }

      setSuccess([]);
      setErrors([]);
      setData();
    }
  }, [data, success, errors]);

  useEffect(() => {
    const doRun = async (obj) => {
      const result = await runMutation(obj.id, {
        refetchOnMountOrArgChange: true,
        // keepUnusedDataFor: 5,
      }).unwrap();
      return result;
    };

    if (data) {
      setSuccess([]);
      setErrors([]);

      // logger.debug("useInvitationMutation running:", data);
      for (var idx = 0; idx < data.length; idx++) {
        const item = data[idx];
        doRun(item)
          .then(() => {
            setSuccess((prev) => [...prev, onSuccess(item)]);
          })
          .catch((err) => {
            logger.error("item, err:", item, err);
            const msg = `${err.status} ${err.data}`;
            setErrors((prev) => [...prev, onFailure(item, msg)]);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, runMutation]);

  return setData;
};
