import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { InputDialog, clearSelectedRows } from "../../components";
import { addSuffixBeforeExtension, runTask } from "../../helpers";

import { genPath, useCopyBlobMutation } from ".";
import { close } from "./copyBlobSlice";

export const CopyBlob = ({ blobs }) => {
  const dispatch = useDispatch();
  const [copyBlob] = useCopyBlobMutation();
  const { open, row } = useSelector((state) => state.copyBlob);

  const type = row && row.metadata.type === "folder" ? "folder" : "file";
  const path = genPath(blobs.data, row);

  const title = row ? `Copy ${type} "${path}"?` : "";
  const value = row ? `${addSuffixBeforeExtension(path, " (copy)")}` : "";

  const handleClose = useCallback(
    async (target) => {
      dispatch(close());

      if (!target) {
        return;
      }

      dispatch(clearSelectedRows());

      const msg = `${path} to ${target}`;
      await runTask(
        "Copy",
        msg,
        async () => await copyBlob({ blob: row, target }).unwrap()
      ).then(blobs.refetch);
    },
    [blobs, copyBlob, dispatch, path, row]
  );

  return (
    <InputDialog
      open={open}
      cancelTitle="Cancel"
      inputLabel="To"
      inputValue={value}
      okTitle="Copy"
      title={title}
      onClose={handleClose}
    />
  );
};

CopyBlob.propTypes = {
  blobs: PropTypes.object.isRequired,
};
