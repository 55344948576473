import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

import { ActionBarCard, StyledButton } from "../../components";
import { memberRoles } from "../../helpers";
import { useGetMyOrganizationQuery } from "../organization";

import {
  useAcceptInvitations,
  useDeleteShareMembers,
  useGetShareQuery,
  useOpenAsset,
  useOpenShare,
  useDeleteShare,
  useInviteShare,
  useNotifyShareMembers,
  useOpenInvitation,
  useRejectInvitations,
  useSaveShareMembers,
  useSelectedInvitations,
  useSelectedMembers,
  useSelectedShares,
} from ".";

export const ActionBar = (props) => {
  const { shareAssets, invitations } = props;
  const { shareId } = useParams();

  const { data: organization } = useGetMyOrganizationQuery();

  const { data: share } = useGetShareQuery(shareId, {
    skip: shareId === undefined,
  });

  const openInvitation = useOpenInvitation();
  const acceptInvitations = useAcceptInvitations();
  const rejectInvitations = useRejectInvitations();

  const openShare = useOpenShare();
  const openAsset = useOpenAsset();
  const deleteShare = useDeleteShare();
  const inviteShare = useInviteShare();

  const notifyShareMembers = useNotifyShareMembers();
  const saveShareMembers = useSaveShareMembers();
  const deleteShareMembers = useDeleteShareMembers();

  const {
    hasEditableRole,
    hasInactiveMembers,
    hasOwnerRole,
    hasSelectedMembers,
    inactiveMembers,
    isMe,
    selectedMembers,
    selectedMember,
  } = useSelectedMembers(share);

  const {
    selectedShare,
    selectedShares,
    hasEditableRole: hasSharesEditableRole,
    hasSelectedShares,
    hasAnyExternalShares,
  } = useSelectedShares(shareAssets && shareAssets.data);

  const { selectedInvitation, selectedInvitations, hasSelectedInvitations } =
    useSelectedInvitations(invitations);

  const isMyOrganization = useMemo(() => {
    var isMyOrganization = false;

    if (organization && share) {
      isMyOrganization = share.organizationId === organization.id;
    }

    return isMyOrganization;
  }, [organization, share]);

  const renderOpenInvitation = useCallback(() => {
    if (hasSelectedInvitations && selectedInvitation) {
      return (
        <StyledButton
          id="actionbar-open-invitation"
          variant="outlined"
          onClick={() => openInvitation(selectedInvitation)}
        >
          Open
        </StyledButton>
      );
    }
    return false;
  }, [hasSelectedInvitations, selectedInvitation, openInvitation]);

  const renderAcceptInvitations = useCallback(() => {
    if (hasSelectedInvitations && selectedInvitations) {
      return (
        <StyledButton
          id="actionbar-accept-invitations"
          variant="outlined"
          onClick={acceptInvitations(selectedInvitations)}
        >
          Accept
        </StyledButton>
      );
    }
    return false;
  }, [hasSelectedInvitations, selectedInvitations, acceptInvitations]);

  const renderRejectInvitations = useCallback(() => {
    if (hasSelectedInvitations && selectedInvitations) {
      return (
        <StyledButton
          id="actionbar-reject-invitations"
          variant="outlined"
          onClick={rejectInvitations(selectedInvitations)}
        >
          Reject
        </StyledButton>
      );
    }
    return false;
  }, [hasSelectedInvitations, selectedInvitations, rejectInvitations]);

  const renderOpenShare = useCallback(() => {
    // Warning: Cannot update a component (`ActionBar`) while rendering a
    //          different component(`ForwardRef`).To locate the bad
    //          setState() call inside`ForwardRef`, follow the stack
    //          trace as described in https://fb.me/setstate-in-render

    if (selectedShare && shareId === undefined) {
      return (
        <StyledButton
          id="actionbar-open-share"
          variant="outlined"
          onClick={() => openShare(selectedShare)}
        >
          Open Members
        </StyledButton>
      );
    }
    return false;
  }, [openShare, selectedShare, shareId]);

  const renderOpenAsset = useCallback(() => {
    if (selectedShare && shareId === undefined) {
      return (
        <StyledButton
          id="actionbar-open-share"
          variant="outlined"
          onClick={() => openAsset(selectedShare)}
        >
          Open Built Asset
        </StyledButton>
      );
    }
    return false;
  }, [openAsset, selectedShare, shareId]);

  const renderDeleteShare = useCallback(() => {
    if (
      hasSharesEditableRole &&
      hasSelectedShares &&
      shareId === undefined &&
      !hasAnyExternalShares
    ) {
      return (
        <StyledButton
          id="actionbar-delete-share"
          variant="outlined"
          onClick={deleteShare(selectedShares)}
        >
          Delete
        </StyledButton>
      );
    }
    return false;
  }, [
    hasAnyExternalShares,
    hasSharesEditableRole,
    hasSelectedShares,
    selectedShares,
    shareId,
    deleteShare,
  ]);

  const renderInviteShare = useCallback(() => {
    if (hasSharesEditableRole && hasSelectedShares && !hasAnyExternalShares) {
      return (
        <StyledButton
          id="actionbar-invite-share"
          variant="outlined"
          onClick={inviteShare(selectedShares)}
        >
          Invite
        </StyledButton>
      );
    } else if (
      hasEditableRole &&
      isMyOrganization &&
      share &&
      selectedMembers.length === 0
    ) {
      return (
        <StyledButton
          id="actionbar-invite-share"
          variant="outlined"
          onClick={inviteShare([share])}
        >
          Invite
        </StyledButton>
      );
    }
    return false;
  }, [
    hasAnyExternalShares,
    hasSharesEditableRole,
    selectedShares,
    hasEditableRole,
    hasSelectedShares,
    isMyOrganization,
    selectedMembers,
    share,
    inviteShare,
  ]);

  const renderNotifyMembers = useCallback(() => {
    if (hasEditableRole && hasInactiveMembers && isMyOrganization) {
      return (
        <StyledButton
          id="actionbar-notify-members"
          variant="outlined"
          onClick={notifyShareMembers(share.id, inactiveMembers)}
        >
          Resend
        </StyledButton>
      );
    }
    return false;
  }, [
    hasEditableRole,
    hasInactiveMembers,
    isMyOrganization,
    inactiveMembers,
    notifyShareMembers,
    share,
  ]);

  const renderChangeMemberRoles = useCallback(
    (value) => {
      if (hasEditableRole && hasSelectedMembers && isMyOrganization) {
        let allowedRoles = new Map();
        allowedRoles.set(value, 0);

        for (var idx = 0; idx < selectedMembers.length; idx++) {
          const o = selectedMembers[idx];
          if (o && o.role && value !== o.role) {
            allowedRoles.set(value, allowedRoles.get(value) + 1);
          }
        }
        if (allowedRoles.get(value) > 0) {
          const memberRole = memberRoles.find((x) => x.value === value);
          let updatedMembers = [];
          for (idx = 0; idx < selectedMembers.length; idx++) {
            updatedMembers.push({
              ...selectedMembers[idx],
              role: memberRole.value,
            });
          }
          const key = `actionbar-change-role-${value.replace(" ", "-")}`;
          const label = `Set role: ${memberRole.label}`;
          return (
            <StyledButton
              id={key}
              key={key}
              variant="outlined"
              onClick={saveShareMembers(share.id, updatedMembers)}
            >
              {label}
            </StyledButton>
          );
        }
      }
      return false;
    },
    [
      hasEditableRole,
      hasSelectedMembers,
      isMyOrganization,
      selectedMembers,
      saveShareMembers,
      share,
    ]
  );

  const renderChangeMemberRolesToOwner = useCallback(() => {
    return renderChangeMemberRoles("owner");
  }, [renderChangeMemberRoles]);

  const renderChangeMemberRolesToCanEdit = useCallback(() => {
    return renderChangeMemberRoles("can edit");
  }, [renderChangeMemberRoles]);

  const renderChangeMemberRolesToCanView = useCallback(() => {
    return renderChangeMemberRoles("can view");
  }, [renderChangeMemberRoles]);

  const renderDeleteMembers = useCallback(() => {
    if ((hasOwnerRole && hasSelectedMembers) || (isMe && selectedMember)) {
      return (
        <StyledButton
          id="actionbar-delete-members"
          variant="outlined"
          onClick={deleteShareMembers(share.id, selectedMembers)}
        >
          Delete
        </StyledButton>
      );
    }
    return false;
  }, [
    hasOwnerRole,
    hasSelectedMembers,
    isMe,
    selectedMember,
    selectedMembers,
    deleteShareMembers,
    share,
  ]);

  return (
    <Box sx={{ backgroundColor: "background.default" }}>
      <ActionBarCard
        buttons={[
          renderOpenInvitation,
          renderAcceptInvitations,
          renderRejectInvitations,
          renderOpenShare,
          renderOpenAsset,
          renderDeleteShare,
          renderInviteShare,
          renderNotifyMembers,
          renderChangeMemberRolesToOwner,
          renderChangeMemberRolesToCanEdit,
          renderChangeMemberRolesToCanView,
          renderDeleteMembers,
        ]}
      />
    </Box>
  );
};

ActionBar.propTypes = {
  shareAssets: PropTypes.object,
  invitations: PropTypes.object,
};
