import React, { useContext } from "react";
import Logger from "js-logger";

import { Box } from "@mui/material";

import { AuthContext } from "../../components";

const logger = Logger.get("Signin");

export const Signin = () => {
  const auth = useContext(AuthContext);
  const { isAuthenticated } = auth;
  logger.debug("Signin isAuthenticated, auth:", isAuthenticated(), auth);

  return (
    <Box sx={{ padding: 2, display: "flex" }}>{/* <PlanSelection /> */}</Box>
  );
};
