import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { Box, Grid } from "@mui/material";

import { LoadingCircularProgress } from "../../components";
import { ShareEmailDialog, useGetShareAssetQuery } from "../share";
import {
  ActionBar,
  BlobTree,
  CopyBlob,
  DeleteBlob,
  NewFolder,
  RenameBlob,
  UploadBlobs,
  useBlobs,
} from "../blobs";
import { UpsertShareAsset, AssetForm, Summary } from ".";

export const ManageAsset = () => {
  const { assetId } = useParams();

  const shareAsset = useGetShareAssetQuery(assetId, {
    skip: !assetId,
  });

  const { asset, containerIds } = useMemo(() => {
    let asset = undefined;
    let containerIds = [];
    if (!shareAsset.isLoading) {
      asset =
        shareAsset && shareAsset.data && shareAsset.data.asset
          ? shareAsset.data.asset
          : undefined;
      containerIds = asset && asset.container ? [asset.container.id] : [];
    }

    return { asset, containerIds };
  }, [shareAsset]);

  const blobs = useBlobs(containerIds);

  if (shareAsset.isLoading || blobs.isLoading) {
    return <LoadingCircularProgress />;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item lg={10} sm={12} xl={10} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <AssetForm shareAsset={shareAsset} />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <BlobTree asset={asset} share={shareAsset} blobs={blobs} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} sm={12} xl={2} xs={12}>
          <Grid container spacing={0}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <ActionBar asset={asset} share={shareAsset} blobs={blobs} />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Summary asset={asset} share={shareAsset} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DeleteBlob blobs={blobs} />
      <CopyBlob blobs={blobs} />
      <NewFolder blobs={blobs} />
      <RenameBlob blobs={blobs} />
      <UploadBlobs blobs={blobs} />
      <ShareEmailDialog shareAssets={shareAsset} />
      <UpsertShareAsset />
    </Box>
  );
};
