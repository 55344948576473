import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { selectSingleRow } from "../../components";

import { open } from "./renameBlobSlice";

export const useOpenRenameBlobDialog = () => {
  const dispatch = useDispatch();

  return useCallback(
    (row) => () => {
      row && dispatch(selectSingleRow(row));
      dispatch(open({ row }));
    },
    [dispatch]
  );
};
