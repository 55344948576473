import React from "react";
import PropTypes from "prop-types";

import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVertOutlined";

export const SearchActionMenu = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    setAnchorEl(null);

    if (option.action) {
      option.action();
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="search-action-menu-button"
        aria-controls={open ? "search-action-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="search-action-menu"
        MenuListProps={{
          "aria-labelledby": "search-action-menu",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options &&
          options.map((option) => (
            <MenuItem key={option.id} onClick={() => handleClose(option)}>
              {option.component}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

SearchActionMenu.propTypes = {
  options: PropTypes.array,
};
