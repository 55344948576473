import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import SharedAssetsIcon from "@mui/icons-material/ShareOutlined";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import OpenIcon from "@mui/icons-material/OpenInBrowserOutlined";
import AcceptIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RejectIcon from "@mui/icons-material/BlockOutlined";

import {
  clearSelectedRows,
  LoadingCircularProgress,
  NoMaxWidthTooltip,
  selectSelectedRows,
  setSelected,
  StyledCard,
  StyledDataGrid,
  ViewAsMenu,
} from "../../components";
import { findMemberRole, formatTimestampAsDateTime } from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import {
  useOpenInvitation,
  useAcceptInvitations,
  useRejectInvitations,
} from ".";

export const InvitationListingGrid = (props) => {
  const { invitations } = props;
  const dispatch = useDispatch();

  const { isLoading: isLoadingAccount, data: account } = useGetMyAccountQuery();

  const selectedRows = useSelector(selectSelectedRows);

  const openInvitation = useOpenInvitation();
  const acceptInvitations = useAcceptInvitations();
  const rejectInvitations = useRejectInvitations();

  const title = useMemo(() => {
    var title = "Pending Share Invitations";

    if (invitations.isLoading) {
      return title;
    }

    const length = invitations.data ? invitations.data.length : "No";
    title = `${length} ${title}`;

    return title;
  }, [invitations]);

  const onRefresh = () => {
    invitations.refetch();
    dispatch(clearSelectedRows());
  };

  if (invitations.isLoading) {
    return <LoadingCircularProgress />;
  }

  const renderMoreMenu = (row) => {
    let moreMenu = [
      <GridActionsCellItem
        icon={
          <Tooltip title={<div>Open share invitation</div>}>
            {<OpenIcon sx={{ color: "icon" }} />}
          </Tooltip>
        }
        key="more-menu-open"
        label="Open"
        onClick={() => openInvitation(row)}
        showInMenu
      />,
    ];

    moreMenu.push(
      <GridActionsCellItem
        icon={
          <Tooltip title={<div>Accept share invitation</div>}>
            <div>{<AcceptIcon sx={{ color: "icon" }} />}</div>
          </Tooltip>
        }
        key="more-menu-accept"
        label="Accept"
        onClick={acceptInvitations([row])}
        showInMenu
      />
    );

    moreMenu.push(
      <GridActionsCellItem
        icon={
          <Tooltip title={<div>Reject share invitation</div>}>
            <div>{<RejectIcon sx={{ color: "icon" }} />}</div>
          </Tooltip>
        }
        key="more-menu-reject"
        label="Reject"
        onClick={rejectInvitations([row])}
        showInMenu
      />
    );

    return moreMenu;
  };

  const onCellClick = (params, event) => {
    event.defaultMuiPrevented = true;

    if (params.field !== "__check__" && params.field !== "moreMenu") {
      openInvitation(params.row);
    }
  };

  const columns = [
    {
      field: "from",
      headerName: "From",
      description: "Invited from",
      width: 200,
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const title = `${params.row.owner.name} (${params.row.owner.email})`;
        return (
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <NoMaxWidthTooltip
                title={title}
                enterDelay={500}
                leaveDelay={200}
              >
                <Typography scope="row" variant="body1" noWrap={true}>
                  {" "}
                  {params.row.owner.name}
                </Typography>
              </NoMaxWidthTooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      description: "Shared built asset name",
      flex: 1,
      width: 300,
      minWidth: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const orgName =
          params.row.organizationName && params.row.organizationName.length > 0
            ? params.row.organizationName
            : "";
        const title =
          params.row.asset.name +
          (orgName.length > 0 ? ` (from ${orgName})` : "");
        return (
          <Grid container alignItems="center" spacing={2}>
            <Grid item>{<SharedAssetsIcon />}</Grid>
            <Grid item>
              <NoMaxWidthTooltip
                title={title}
                enterDelay={500}
                leaveDelay={200}
              >
                <Typography scope="row" variant="body1" noWrap={true}>
                  {" "}
                  {params.row.asset.name}
                </Typography>
              </NoMaxWidthTooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      description: "Shared membership role",
      flex: 0.5,
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const rowRole = findMemberRole(params.row.role);
        let roleName = "";
        let roleDescription = "";
        let roleIcon = <div />;
        if (rowRole) {
          roleName = rowRole.label;
          roleDescription = rowRole.description;
          roleIcon = rowRole.icon;
        }

        return (
          <NoMaxWidthTooltip title={roleDescription}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>{roleIcon}</Grid>
              <Grid item>
                <Typography scope="row" variant="body1" noWrap={true}>
                  {roleName}
                </Typography>
              </Grid>
            </Grid>
          </NoMaxWidthTooltip>
        );
      },
    },
    {
      field: "ts",
      headerName: "Invited",
      description: "Last invited",
      flex: 0.6,
      minWidth: 175,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <NoMaxWidthTooltip
          title={
            "Last invited " +
            formatTimestampAsDateTime(
              params.row.ts * 1000,
              account.preferences.dateFormat
            )
          }
        >
          <Typography scope="row" variant="body1" noWrap={true}>
            {formatTimestampAsDateTime(
              params.row.ts * 1000,
              account.preferences.dateFormat
            )}
          </Typography>
        </NoMaxWidthTooltip>
      ),
    },
    {
      field: "moreMenu",
      type: "actions",
      headerName: "",
      flex: 0.2,
      midWidth: 50,
      editable: false,
      sortable: false,
      getActions: (params) => renderMoreMenu(params.row),
      renderHeader: () => <ViewAsMenu />,
    },
  ];

  const rowCount = (invitations.data && invitations.data.count) || 0;

  const NoRowsOverlay = () => {
    return (
      <Box
        sx={{
          display: "flex",
          marginTop: "80px",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No share invitations found.
      </Box>
    );
  };

  return (
    <StyledCard>
      <CardHeader
        title={title}
        action={
          <IconButton
            size="small"
            id="refreshIcon"
            onClick={onRefresh}
            sx={{ justifyContent: "flex-end" }}
          >
            <RefreshIcon />
          </IconButton>
        }
      />
      <CardContent
        sx={{
          alignItems: "center",
          display: "flex",
          padding: 0,
          marginBottom: "-25px",
        }}
      >
        <StyledDataGrid
          autoHeight
          checkboxSelection
          columns={columns}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableSelectionOnClick
          hideFooterPagination
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
                page: 0,
              },
            },
          }}
          loading={invitations.isLoading || isLoadingAccount}
          onCellClick={onCellClick}
          onRowSelectionModelChange={(selected) => {
            dispatch(setSelected(selected));
          }}
          rowCount={rowCount}
          rows={invitations.data || []}
          rowSelectionModel={selectedRows}
        />
      </CardContent>
    </StyledCard>
  );
};

InvitationListingGrid.propTypes = {
  invitations: PropTypes.object,
};
