import { createSlice } from "@reduxjs/toolkit";

export const newFolderSlice = createSlice({
  name: "newFolder",
  initialState: {
    open: false,
    row: undefined,
    containerId: undefined,
    target: undefined,
  },
  reducers: {
    open(state, action) {
      state.open = true;
      state.row = action.payload.row;
      state.containerId = action.payload.containerId;
      state.target = undefined;
    },
    creatingFolder(state, action) {
      state.target = action.payload;
    },
    close(state) {
      state.open = false;
      state.row = undefined;
      state.containerId = undefined;
      state.target = undefined;
    },
  },
});

export const { open, close, creatingFolder } = newFolderSlice.actions;
export const newFolder = newFolderSlice.reducer;
