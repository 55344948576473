import { api, providesList } from "../api/apiSlice";
import { isNil } from "../../helpers/uuid";

export const organizationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyOrganization: build.query({
      query: () => `organization/me`,
      // eslint-disable-next-line no-unused-vars
      providesTags: (result, error, _arg) =>
        result
          ? [{ type: "Organizations", id: result.id }]
          : error?.status === 401 || error?.status === 403
          ? ["UNAUTHORIZED"]
          : ["UNKNOWN_ERROR"],
    }),
    getMyOrganizationFeatures: build.query({
      query: () => `organization/me/features`,
      // eslint-disable-next-line no-unused-vars
      providesTags: (result, error, _arg) =>
        result
          ? [{ type: "OrganizationFeatures", id: result.id }]
          : error?.status === 401 || error?.status === 403
          ? ["UNAUTHORIZED"]
          : ["UNKNOWN_ERROR"],
    }),
    getOrganization: build.query({
      query: (id) => `organization/${id}`,
      providesTags: (_result, _error, id) => [{ type: "Organizations", id }],
    }),
    getOrganizationInvitations: build.query({
      query: () => `organization/invitation/`,
      providesTags: (result) => providesList(result, "Invitations"),
    }),
    getOrganizationJoinInvitation: build.query({
      query: (invitationId) => `organization/join/${invitationId}`,
      providesTags: (_result, _error, { invitationId }) => [
        { type: "Invitations", invitationId },
      ],
    }),
    getOrganizationInvitation: build.query({
      query: (id, invitationId) =>
        `organization/${id}/invitation/${invitationId}`,
      providesTags: (_result, _error, { invitationId }) => [
        { type: "Invitations", invitationId },
      ],
    }),
    saveOrganization: build.mutation({
      query(org) {
        return {
          url: `organization/${org.id}`,
          method: "PUT",
          body: org,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Organizations", id },
      ],
    }),
    validateOrganization: build.mutation({
      query(org) {
        return {
          url: `organization/validate`,
          method: "POST",
          body: org,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Organizations", id },
      ],
    }),
    acceptOrganizationInvitation: build.mutation({
      query(invitationId) {
        return {
          url: `organization/invitation/${invitationId}/accept`,
          method: "PUT",
        };
      },
      // eslint-disable-next-line no-unused-vars
      invalidatesTags: (result, error, _arg) =>
        result
          ? [
              { type: "Accounts" },
              { type: "Organizations" },
              { type: "Invitations" },
              { type: "Assets" },
              { type: "Shares" },
              { type: "Tasks" },
            ]
          : error?.status === 401 || error?.status === 403
          ? ["UNAUTHORIZED"]
          : ["UNKNOWN_ERROR"],
    }),
    rejectOrganizationInvitation: build.mutation({
      query(invitationId) {
        return {
          url: `organization/invitation/${invitationId}/reject`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, { invitationId }) =>
        result
          ? [
              { type: "Organizations", id: result.id },
              { type: "Invitations", invitationId },
              { type: "Invitations", id: "LIST" },
              { type: "Assets", id: "LIST" },
              { type: "Shares", id: "LIST" },
              { type: "Tasks", id: "LIST" },
            ]
          : error?.status === 401 || error?.status === 403
          ? ["UNAUTHORIZED"]
          : ["UNKNOWN_ERROR"],
    }),
    inviteOrganizationMember: build.mutation({
      query({ id, invitation }) {
        return {
          url: `organization/${id}/invitation`,
          method: "POST",
          body: invitation,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Organizations", id },
      ],
    }),
    notifyOrganizationMember: build.mutation({
      query({ id, member }) {
        const memberId = isNil(member.id) ? member.invitationId : member.id;
        return {
          url: `organization/${id}/member/${memberId}/notify`,
          method: "PUT",
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Organizations", id },
      ],
    }),
    saveOrganizationMember: build.mutation({
      query({ id, member }) {
        const memberId = isNil(member.id) ? member.invitationId : member.id;
        return {
          url: `organization/${id}/member/${memberId}`,
          method: "PUT",
          body: member,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Organizations", id },
      ],
    }),
    deleteOrganizationMember: build.mutation({
      query({ id, member }) {
        const memberId = isNil(member.id) ? member.invitationId : member.id;
        return {
          url: `organization/${id}/member/${memberId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Organizations", id },
        { type: "Shares", id: "LIST" },
        { type: "Invitations", id: "LIST" },
        { type: "Assets", id: "LIST" },
        { type: "Tasks", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetOrganizationQuery,
  useGetMyOrganizationQuery,
  useGetMyOrganizationFeaturesQuery,
  useGetOrganizationInvitationsQuery,
  useGetOrganizationInvitationQuery,
  useGetOrganizationJoinInvitationQuery,
  useAcceptOrganizationInvitationMutation,
  useDeleteOrganizationMemberMutation,
  useInviteOrganizationMemberMutation,
  useNotifyOrganizationMemberMutation,
  useRejectOrganizationInvitationMutation,
  useSaveOrganizationMutation,
  useSaveOrganizationMemberMutation,
  useValidateOrganizationMutation,
} = organizationApi;
