import moment from "moment";

const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss a";
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_TIME_FORMAT = "HH:mm:ss a";
const ISO8601_DATE_TIME_FORMAT = "YYYYMMDDTHHmmss.fffZ";

export function formatTimestampAsDateTime(
  tsInMs,
  format = DEFAULT_DATE_TIME_FORMAT
) {
  if (format != DEFAULT_DATE_TIME_FORMAT && !format.includes("HH")) {
    format = format + " " + DEFAULT_TIME_FORMAT;
  }
  return moment(tsInMs).format(format);
}

export function formatTimestampAsDate(tsInMs, format = DEFAULT_DATE_FORMAT) {
  return moment(tsInMs).format(format);
}

export function formatTimestampAsTime(tsInMs, format = DEFAULT_TIME_FORMAT) {
  return moment(tsInMs).format(format);
}

export function formatTimestampFromNow(tsInMs) {
  return moment(tsInMs).fromNow();
}

export function formatISO8601AsDateTime(
  iso8601,
  format = DEFAULT_DATE_TIME_FORMAT
) {
  return moment(iso8601, ISO8601_DATE_TIME_FORMAT).format(format);
}

export function formatISO8601AsDate(iso8601, format = DEFAULT_DATE_FORMAT) {
  return moment(iso8601, ISO8601_DATE_TIME_FORMAT).format(format);
}

export function formatISO8601AsTime(iso8601, format = DEFAULT_TIME_FORMAT) {
  return moment(iso8601, ISO8601_DATE_TIME_FORMAT).format(format);
}

export function formatISO8601FromNow(iso8601) {
  return moment(iso8601, ISO8601_DATE_TIME_FORMAT).fromNow();
}
