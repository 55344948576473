import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import Logger from "js-logger";

import { CardHeader, FormControl, MenuItem, Select } from "@mui/material";
import timezones from "timezones.json";

import { StyledCard } from "../../components";
import { useGetMyAccountQuery } from "../account";
import { useSaveMyAccountPreferences } from ".";

const logger = Logger.get("TimeZone");

export const TimeZone = () => {
  const dispatch = useDispatch();

  const { isLoading, data: account } = useGetMyAccountQuery();

  const saveMyAccountPreferences = useSaveMyAccountPreferences();

  const tz = useMemo(() => {
    if (isLoading) {
      return timezones[0];
    }

    return account.preferences.timeZone;
  }, [account, isLoading]);

  function handleChange(event) {
    logger.debug("handleChange:", event.target.value);
    if (isLoading) {
      return;
    }

    if (event.target.value !== tz) {
      const preferences = {
        ...account.preferences,
        timeZone: event.target.value,
      };
      dispatch(saveMyAccountPreferences(account.id, preferences));
      logger.debug("handleChange saved:", preferences);
    }
  }

  const title = "Time Zone";
  const description = "Set your time zone.";

  return (
    <StyledCard sx={{ padding: 0 }}>
      <CardHeader
        title={title}
        subheader={description}
        action={
          <FormControl variant="outlined">
            <Select
              id="select-timezone-outlined"
              value={tz}
              onChange={handleChange}
              margin="dense"
              size="small"
            >
              {timezones.map((row, index) => {
                const rowId = `timezone-menu-item-${index}`;
                return (
                  <MenuItem key={rowId} value={row.value}>
                    {row.text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        }
      />
    </StyledCard>
  );
};
