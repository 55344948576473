import Logger from "js-logger";

import { logToastDebug, logToastError } from "../../components";
import { useSaveMyAccountPreferencesMutation } from "../account";

import { useSettingsMutation } from ".";

const logger = Logger.get("useSaveMyAccountPreferences");

export const useSaveMyAccountPreferences = () => {
  const saveMyAccountPreferences = useSettingsMutation(
    useSaveMyAccountPreferencesMutation,
    (accountData, data) => {
      logger.debug("result:", accountData, data);

      if (data) {
        const msg = "preferences updated";
        logToastDebug(logger, msg);
        return;
      }
    },
    (accountData, error) => {
      const msg = "preferences update failed";
      logToastError(logger, msg, error);
    }
  );

  return (id, preferences) => () => {
    saveMyAccountPreferences({ id, preferences });
  };
};
