import React from "react";
import { useParams } from "react-router-dom";

import { Box, Grid } from "@mui/material";

import { StorageChart } from "../blobs";

import { CurrentSubscription } from ".";

export const Subscription = () => {
  const { tabId } = useParams();

  if (tabId === undefined || tabId !== "subscription") {
    return <></>;
  }

  return (
    <Box sx={{ height: "100%", paddingTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <CurrentSubscription />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <StorageChart />
        </Grid>
      </Grid>
    </Box>
  );
};
