import React from "react";

import { Box, Grid } from "@mui/material";

import { TaskView, ActionBar, useTasks } from ".";

export const ManageTask = () => {
  const tasks = useTasks();

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item lg={10} sm={12} xl={10} xs={12}>
          <TaskView />
        </Grid>
        <Grid item lg={2} sm={12} xl={1} xs={12}>
          <ActionBar tasks={tasks} />
        </Grid>
      </Grid>
    </Box>
  );
};
