import { useDispatch } from "react-redux";
import { clearSelectedRows } from "../../components";

import { useNotifyOrganizationMemberMutation, useMemberMutation } from ".";

export const formatNotifyMemberMessage = (id, member, err) => {
  if (id && member) {
    return (
      `organization member ${member.profile.name} (${id}) ` +
      (err === undefined ? "notified" : `notification failed: ${err}`)
    );
  }
};

export const useNotifyMembers = () => {
  const dispatch = useDispatch();
  const notifyMember = useMemberMutation(
    useNotifyOrganizationMemberMutation,

    (id, member) => {
      return formatNotifyMemberMessage(id, member);
    },

    (id, member, err) => {
      return formatNotifyMemberMessage(id, member, err);
    }
  );

  return (id, members) => () => {
    notifyMember({ id, items: members });
    dispatch(clearSelectedRows());
  };
};
