import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectPageSize } from "../../components";
import { useGetSearchSuggestionsQuery } from ".";

export const useSearchSuggestions = ({ open, fields, metadata, query }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(useSelector(selectPageSize));
  const [sortModel, setSortModel] = useState({
    field: "ts",
    sort: "desc",
  });
  const [filters, setFilters] = useState(null);

  const {
    isLoading,
    isUninitialized,
    data: unfiltered,
    refetch,
  } = useGetSearchSuggestionsQuery(
    {
      query: query,
      page: page,
      pageSize: pageSize,
      sortModel: sortModel,
      fields: fields,
      metadata: metadata,
    },
    { skip: !open }
  );

  const filtered = useMemo(() => {
    var filtered = [];

    if (!isLoading && unfiltered && unfiltered.items) {
      unfiltered.items.forEach((x) => {
        var result = {
          ...x,
        };

        if (filtered.find((existing) => existing.id === result.id)) {
          return;
        }

        filtered.push(result);
      });

      if (filters) {
        // TODO: revisit filtering search suggestions results
        return filtered;
      }

      return filtered;
    }

    return filtered;
  }, [filters, isLoading, unfiltered]);

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    isLoading,
    isUninitialized,
    filtered,
    unfiltered,
    refetch,
    sortModel,
    setSortModel,
    fields,
    metadata,
    query,
    filters,
    setFilters,
  };
};
