/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { AssetAutocomplete } from ".";

export const CopyShareAssetDialog = ({
  shareAssets,
  onClose,
  open,
  source,
  title,
}) => {
  const [shareAsset, setShareAsset] = useState({});
  const [assetName, setAssetName] = useState("");
  const [folder, setFolder] = useState("");

  useEffect(() => {
    if (open) {
      setShareAsset(null);
      setAssetName("");
      setFolder("");
    } else {
      setShareAsset(null);
      setAssetName("");
      setFolder("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareAssets, source, open]);

  const handleAssetChanged = (changed) => {
    setShareAsset(changed);
  };

  const handleNewAssetNameChange = (event) => {
    const newName = event.target.value
      .replace(/^\//, "")
      .replace(/\/\//, "/")
      .replace(/[\/|\\:;*?"<>]/g, "");
    setAssetName(newName);
  };

  const handleFolderChange = (event) => {
    const newFolder = event.target.value
      .replace(/^\//, "")
      .replace(/\/\//, "/")
      .replace(/[|\\:;*?"<>]/g, "");
    setFolder(newFolder);
  };

  const getResult = () => {
    let dest = shareAsset
      ? {
          id: shareAsset.asset.id,
          container: {
            id: shareAsset.asset.containerId,
          },
        }
      : {
          name: assetName,
        };

    const result = {
      src: {
        asset: source,
        folder: "",
      },
      dest: {
        asset: dest,
        folder: folder,
      },
    };

    return result;
  };

  const handleCancel = () => {
    if (onClose != null) {
      onClose(false, getResult());
    }
  };

  const handleOk = () => {
    if (onClose != null) {
      onClose(true, getResult());
    }
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      onClose={handleCancel}
      open={open}
      aria-labelledby="asset-dialog-title"
      aria-describedby="asset-dialog-description"
      sx={{ flex: 1, paddingTop: 2 }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h5">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 1,
            top: 1,
            // color: theme.palette.grey[500],
            // color: "grey[500]",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          fullWidth
          helperText="Enter a new destination built asset name"
          label="New Built Asset"
          margin="dense"
          size="small"
          variant="outlined"
          onChange={handleNewAssetNameChange}
          value={assetName}
          disabled={shareAsset != null}
        />
        <AssetAutocomplete
          onChange={handleAssetChanged}
          disabled={assetName.length > 0}
        />
        <TextField
          fullWidth
          helperText="Enter a destination folder path (empty for root folder, '/' as path separator)"
          label="Folder"
          margin="dense"
          size="small"
          variant="outlined"
          onChange={handleFolderChange}
          value={folder}
          disabled={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          color="primary"
          autoFocus
          disabled={shareAsset === null && assetName.length === 0}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CopyShareAssetDialog.propTypes = {
  shareAssets: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  source: PropTypes.object,
  title: PropTypes.string.isRequired,
};
