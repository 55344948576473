import { QueryableWorker } from ".";
import TaskWorker from "./task.worker.js";

import Logger from "js-logger";

const getMaxWorkerCount = function () {
  // Start up workers for each core
  return 4; // navigator.hardwareConcurrency || 4;
};

const maxRetries = 3;

export default class TaskManager {
  constructor(store, auth) {
    this.logger = Logger.get("TaskManager");

    this.listeners = new Map();

    this.pending = new Map();
    this.active = new Map();
    this.completed = new Map();

    // Initialize web workers
    this.workers = [];
    this.logger.debug(
      "detected hardwareConcurrency:",
      navigator.hardwareConcurrency
    );
    for (let i = 0; i < getMaxWorkerCount(); i++) {
      const worker = new QueryableWorker(TaskWorker);
      this.addListeners(worker);
      this.workers.push(worker);

      // this.logger.debug("initialized worker " + i + ":", worker);
    }
    this.logger.debug("initialized " + this.workers.length + " workers");

    this.store = store;
    this.auth = auth;

    // TODO Load up active registrations from DB on start
  }

  addListener = function (name, listener) {
    this.listeners.set(name, listener);
  };

  removeListener = function (name) {
    if (this.listeners.has(name)) {
      this.listeners.delete(name);
    }
    // if (Object.keys(this.listeners).includes(name)) {
    //   delete this.listeners[name];
    // }
  };

  addListeners = (worker) => {
    worker.addListener("responseCompleted", this.completedResponse);
    worker.addListener("response", this.genericResponse);
    worker.addListener("responseError", this.genericResponseError);
  };

  notifyListeners = (data) => {
    for (let value of this.listeners.values()) {
      value.call(this, data);
    }
  };

  getWorker = function (requestId) {
    this.logger.debug("getWorker:", requestId);

    for (let i = 0; i < getMaxWorkerCount(); i++) {
      const w = this.workers[i];
      if (w.requestId === requestId) {
        this.logger.debug("getWorker: found:", w);
        return w;
      }
    }

    this.logger.debug("getWorker: none available");
    return null;
  };

  getNextAvailableWorker = function () {
    this.logger.debug("getNextAvailableWorker...");

    for (let i = 0; i < getMaxWorkerCount(); i++) {
      const w = this.workers[i];
      if (w.status == "available") {
        this.logger.debug("getNextAvailableWorker: found:", w);
        return w;
      }
    }

    this.logger.debug("getNextAvailableWorker: none available");
    return null;
  };

  getActiveSize = function (taskAction) {
    var count = 0;
    for (let value of this.active.values()) {
      if (value.taskAction === taskAction || !taskAction) {
        count++;
      }
    }
    return count;
  };

  getPendingSize = function (taskAction) {
    var count = 0;
    for (let value of this.pending.values()) {
      if (value.taskAction === taskAction || !taskAction) {
        count++;
      }
    }
    return count;
  };

  getNextPending = function (taskAction) {
    for (let value of this.pending.values()) {
      if (value.taskAction === taskAction || !taskAction) {
        return value;
      }
    }
    return null;
  };

  handleNext = function () {
    this.logger.debug(
      "handleNext enter:",
      this.getPendingSize("register"),
      this.getPendingSize(),
      this.getActiveSize(),
      this.pending,
      this.active,
      this.workers
    );

    var nextPending = null;
    if (this.getPendingSize("register") > 0) {
      // Priority to registrations over longer running tasks
      nextPending = this.getNextPending("register");
    } else if (this.getPendingSize() > 0) {
      nextPending = this.getNextPending();
    }

    if (!nextPending) {
      this.logger.debug("handleNext no queued tasks");
      return;
    }

    const worker = this.getNextAvailableWorker();
    if (!worker) {
      this.logger.debug("handleNext could not find a worker");
      return;
    }

    // Refresh the auth token
    nextPending.task.token = this.auth.getAccessToken();

    if (this.pending.has(nextPending.request.id)) {
      this.pending.delete(nextPending.request.id);
    }
    this.active.set(nextPending.request.id, nextPending);

    const queryMethod = nextPending.task.action + nextPending.task.type;
    worker.sendQuery(queryMethod, nextPending);

    this.logger.debug(
      "handleNext started:",
      queryMethod,
      nextPending.request.id,
      this.pending,
      this.active,
      this.workers
    );
  };

  getTaskActions = () => {
    return ["register", "start", "put", "cancel"];
  };

  getTaskTypes = () => {
    return ["UploadBlob", "DownloadBlob"];
  };

  register = (data) => {
    this.logger.debug("register enter:", data);
    if (
      this.pending.has(data.request.id) ||
      this.active.has(data.request.id) ||
      this.completed.has(data.request.id)
    ) {
      this.logger.debug("already registered:", data);
      return false;
    }

    this.pending.set(data.request.id, data);
    this.logger.debug("task registration queued as pending:", data);

    this.handleNext();

    return true;
  };

  cancel = (data) => {
    this.logger.debug("cancel:", data);
    const id = data.request.id;
    data.error = {
      message: "cancelled",
    };

    if (this.completed.has(id)) {
      this.logger.debug("task request already completed:", data);
      return false;
    } else if (this.pending.has(id)) {
      let value = this.pending.get(id);
      value.task.action = "cancel";
      this.pending.set(id, value);
      this.logger.debug("task request pending, action now cancel:", data);
      this.handleNext();
      return false;
    }

    const worker = this.getWorker(id);
    if (!worker) {
      this.logger.debug(
        "cancel: could not find active worker for task request",
        id
      );
      this.uploadBlobResponseError(data);
      return false;
    }
    const queryMethod = "cancel" + data.task.type;
    worker.sendQuery(queryMethod, data);

    return true;
  };

  completedResponse = (response) => {
    response.error = {};

    this.logger.debug("completedResponse:", response);

    // this.notifyListeners(response);

    this.handleNext();
  };

  genericResponse = (response) => {
    this.logger.debug("genericResponse:", response);
    const id = response.request.id;

    if (
      !this.pending.has(id) &&
      !this.active.has(id) &&
      !this.completed.has(id)
    ) {
      this.logger.debug(
        "genericResponse: could not find task registration",
        id
      );
      this.handleNext();
      return;
    }

    if (response.task.action === "register") {
      // Task is now registered, insert task into pending to start uploading
      response.task.action = "start";
      if (this.active.has(id)) {
        this.active.delete(id);
      }
      this.pending.set(id, response);
    } else if (response.task.action === "start") {
      this.logger.debug(
        "genericResponse.task.action(start):",
        response.task.action,
        response.task.chunkId,
        response.task.numChunks,
        response.task.inprogress
      );
      if (response.task.type === "UploadBlob") {
        if (!response.task.inprogress) {
          // Task is now start, insert task into pending to start put block loop
          response.task.action = "put";

          if (this.active.has(id)) {
            this.active.delete(id);
          }

          this.pending.set(id, response);
        }
      }
    } else if (response.task.action === "put") {
      this.logger.debug(
        "genericResponse.task.action(put):",
        response.task.action,
        response.task.chunkId,
        response.task.numChunks,
        response.task.inprogress
      );
      if (!response.task.inprogress) {
        // Re-inserting ourselves into the worker queue for the next putblock
        if (this.active.has(id)) {
          this.active.delete(id);
        }

        this.pending.set(id, response);
      }
    } else if (response.task.action === "complete") {
      this.logger.debug(
        "genericResponse.task.action(complete):",
        response.task.action,
        response.task.chunkId,
        response.task.numChunks
      );

      // Task fully complete
      this.completed.set(id, response);

      if (this.active.has(id)) {
        this.active.delete(id);
      }

      if (this.pending.has(id)) {
        this.pending.delete(id);
      }
    }

    this.notifyListeners(response);
    this.handleNext();
  };

  genericResponseError = (response) => {
    this.logger.debug("genericResponseError:", response);
    const id = response.request.id;

    if (
      response.error &&
      response.error.message != "cancelled" &&
      response.error.status != 409 &&
      response.error.count &&
      response.error.count < maxRetries
    ) {
      // retry the action

      if (this.active.has(id)) {
        this.active.delete(id);
      }

      if (this.completed.has(id)) {
        this.completed.delete(id);
      }

      this.pending.set(id, response);
    } else {
      this.completed.set(id, response);

      if (this.pending.has(id)) {
        this.pending.delete(id);
      }

      if (this.active.has(id)) {
        this.active.delete(id);
      }
    }

    this.notifyListeners(response);
    this.handleNext();
  };
}
