import { api } from "../api/apiSlice";

export const tagApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTags: build.query({
      query: () => `tag`,
      providesTags: "Tags",
    }),
    getTagNames: build.query({
      query: () => `tag?names`,
      providesTags: "TagNames",
    }),
  }),
});

export const { useGetTagsQuery, useGetTagNamesQuery } = tagApi;
