import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

import { Topbar } from ".";

const Public = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { children, staticContext, title, ...rest } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        paddingTop: 64,
        height: "100%",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Topbar {...rest} />
      <main
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          textAlign: "center",
        }}
      >
        {children}
      </main>
    </Box>
  );
};

Public.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  staticContext: PropTypes.object,
};

export const PublicWithRouter = withRouter(Public);
