import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { Box, Grid, Tab, Tabs } from "@mui/material";

import { TabPanel } from "../../components";
import {
  ActionBar,
  Assets,
  DeleteShare,
  Pending,
  Recent,
  ShareEmailDialog,
  useInvitations,
  useShares,
} from ".";

function a11yProps(index) {
  return {
    id: `shares-tab-${index}`,
    "aria-controls": `shares-tabpanel-${index}`,
  };
}

export const Shares = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const history = useHistory();
  const location = useLocation();
  const { tabId } = useParams();

  const theme = useTheme();

  const shareAssets = useShares();

  const invitations = useInvitations();

  const panels = useMemo(() => {
    var panels = [];
    if (invitations && invitations.data && invitations.data.length > 0) {
      panels.push({
        id: "pending",
        component: <Pending invitations={invitations} />,
        label: "Pending",
      });
    }
    panels.push({
      id: "recent",
      component: <Recent />,
      label: "Recent",
    });

    panels.push({
      id: "asset",
      component: <Assets shareAssets={shareAssets} />,
      label: "Built Assets",
    });

    return panels;
  }, [invitations, shareAssets]);

  const handleTabIndexChange = useCallback(
    (newValue) => {
      if (panels.length === 0) {
        return;
      }

      if (newValue === undefined) {
        newValue = tabIndex;
      } else if (newValue > panels.length) {
        newValue = 0;
      }

      const newPath = "/shares/" + panels[newValue].id;
      if (location.pathname !== newPath) {
        history.push(newPath);
      }
    },
    [history, location.pathname, panels, tabIndex]
  );

  useEffect(() => {
    if (tabId === undefined) {
      handleTabIndexChange(0);
      return;
    }

    const index = panels.findIndex((x) => x.id === tabId);
    if (index === -1) {
      setTabIndex(0);
    } else {
      setTabIndex(index);
    }
  }, [handleTabIndexChange, panels, tabId]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.default,
        padding: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={10} sm={12} xl={10} xs={12}>
          <Box position="static" color="transparent">
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={tabIndex}
              onChange={(e, v) => handleTabIndexChange(v)}
              onClick={(e, v) => handleTabIndexChange(v)}
              variant="scrollable"
              scrollButtons={true}
              aria-label="shares tabs"
            >
              {panels.map((x) => {
                // log.debug("tabs entry: key, x:", idx, x);
                return (
                  <Tab
                    label={x.label}
                    key={`shares-tab-key-${x.id}`}
                    {...a11yProps(x.id)}
                  />
                );
              })}
            </Tabs>
          </Box>
          {panels.map((x, idx) => {
            // logger.debug("tabpanel entry: key, x:", idx, x);
            return (
              <TabPanel
                key={`shares-tabpanel-key-${x.id}`}
                index={idx}
                name="shares"
                value={tabIndex}
              >
                {x.component}
              </TabPanel>
            );
          })}
        </Grid>
        <Grid item lg={2} sm={12} xl={2} xs={12}>
          <ActionBar shareAssets={shareAssets} invitations={invitations} />
        </Grid>
      </Grid>

      <ShareEmailDialog shareAssets={shareAssets} />
      <DeleteShare shareAssets={shareAssets} />
    </Box>
  );
};
