import { useHistory } from "react-router-dom";

export const useOpenRecentShare = () => {
  const history = useHistory();

  return (share) => {
    if (share && share.id) {
      history.push(`/share/recent/${share.id}`);
    }
  };
};
