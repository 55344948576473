import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectSelectedRows } from "../../components";

export const useSelectedInvitations = (invitations) => {
  const selectedRows = useSelector(selectSelectedRows);

  return useMemo(() => {
    let selectedInvitations = [];

    if (invitations && invitations.data) {
      selectedInvitations = invitations.data.filter((x) =>
        selectedRows.includes(x.id)
      );
    }

    const selectedInvitation =
      selectedInvitations.length == 1 && selectedInvitations[0];
    const hasSelectedInvitations = selectedInvitations.length > 0;

    return {
      selectedInvitations,
      selectedInvitation,
      hasSelectedInvitations,
    };
  }, [selectedRows, invitations]);
};
