import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { setSelected } from "../../components";

import { open } from "./copyShareAssetSlice";

export const useCopyShareAsset = () => {
  const dispatch = useDispatch();

  return useCallback(
    (item) => () => {
      dispatch(setSelected([item.asset.id]));
      dispatch(open(item));
    },
    [dispatch]
  );
};
