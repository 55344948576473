import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import AssetsIcon from "@mui/icons-material/PagesOutlined";
import SharedAssetsIcon from "@mui/icons-material/ShareOutlined";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import OpenIcon from "@mui/icons-material/OpenInBrowserOutlined";

import {
  clearSelectedRows,
  NoMaxWidthTooltip,
  StyledCard,
  StyledDataGrid,
  ViewAsMenu,
} from "../../components";
import { formatTimestampFromNow } from "../../helpers";
import { MemberGroup } from "../organization";
import { useGetMyAccountQuery } from "../account";
import {
  useGetRecentShareAssetsQuery,
  useOpenAsset,
  useOpenRecentShare,
} from ".";

export const RecentListingGrid = () => {
  const dispatch = useDispatch();

  const [pageSize] = useState(10);

  const { data: account } = useGetMyAccountQuery();
  const openRecentShareAsset = useOpenRecentShare();
  const openAsset = useOpenAsset();

  const { isLoading, data, refetch } = useGetRecentShareAssetsQuery(pageSize);

  const title = "Recent Shared Activity";

  const renderMoreMenu = (row) => {
    let moreMenu = [
      <GridActionsCellItem
        icon={
          <Tooltip title={<div>Open shared built asset members</div>}>
            <div>{<OpenIcon sx={{ color: "icon" }} />}</div>
          </Tooltip>
        }
        key="more-menu-open-members"
        label="Open Members"
        onClick={() => openRecentShareAsset(row)}
        showInMenu
      />,
      <GridActionsCellItem
        icon={
          <Tooltip title={<div>Open shared built asset</div>}>
            <div>{<OpenIcon sx={{ color: "icon" }} />}</div>
          </Tooltip>
        }
        key="more-menu-open-built-asset"
        label="Open Built Asset"
        onClick={() => openAsset(row)}
        showInMenu
      />,
    ];

    return moreMenu;
  };

  const onRefresh = () => {
    refetch();
    dispatch(clearSelectedRows());
  };

  const onCellClick = (params, event) => {
    event.defaultMuiPrevented = true;

    if (params.field !== "moreMenu") {
      openRecentShareAsset(params.row);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      description: "Shared built asset name",
      flex: 1,
      width: 400,
      minWidth: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const isOrgAsset = account.organizationId === params.row.organizationId;
        const title =
          params.row.asset.name + (isOrgAsset ? "" : " (shared with me)");
        return (
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              {isOrgAsset ? <AssetsIcon /> : <SharedAssetsIcon />}
            </Grid>
            <Grid item>
              {/* 
            Note: enter/leave delay experiment with workaround following error:
            Warning: Failed prop type:
            Material-UI: The `anchorEl` prop provided to the component is
            invalid. The anchor element should be part of the document layout.
            Make sure the element is present in the document or that it's not
            display none. 
            */}
              <NoMaxWidthTooltip
                title={title}
                enterDelay={500}
                leaveDelay={200}
              >
                <Typography scope="row" variant="body1" noWrap={true}>
                  {" "}
                  {params.row.asset.name}
                </Typography>
              </NoMaxWidthTooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "ts",
      headerName: "Modified",
      description: "Last modified",
      flex: 0.25,
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <NoMaxWidthTooltip
          title={
            "Last modified " + formatTimestampFromNow(params.row.ts * 1000)
          }
        >
          <Typography scope="row" variant="body1" noWrap={true}>
            {formatTimestampFromNow(params.row.ts * 1000)}
          </Typography>
        </NoMaxWidthTooltip>
      ),
    },
    {
      field: "members",
      headerName: "Members",
      description: "Shared members",
      flex: 0.25,
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <MemberGroup members={params.row.membership.members} />
      ),
    },
    {
      field: "moreMenu",
      type: "actions",
      headerName: "",
      flex: 0.1,
      midWidth: 50,
      editable: false,
      sortable: false,
      getActions: (params) => renderMoreMenu(params.row),
      renderHeader: () => <ViewAsMenu />,
    },
  ];

  const NoRowsOverlay = () => {
    return (
      <Box
        sx={{
          display: "flex",
          marginTop: "80px",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No share activity found.
      </Box>
    );
  };

  return (
    <StyledCard>
      <CardHeader
        title={title}
        action={
          <IconButton
            size="small"
            id="refreshIcon"
            onClick={onRefresh}
            sx={{ justifyContent: "flex-end" }}
          >
            <RefreshIcon />
          </IconButton>
        }
      />
      <CardContent
        sx={{
          padding: "0px !important",
          margin: "0px !important",
          alignItems: "center",
          marginBottom: "-25px",
        }}
      >
        <StyledDataGrid
          autoHeight
          columns={columns}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableSelectionOnClick
          hideFooter
          hideFooterPagination
          hideFooterRowCount
          hideFooterSelectedRowCount
          loading={isLoading}
          onCellClick={onCellClick}
          rows={data && data.data ? data.data : []}
        />
      </CardContent>
    </StyledCard>
  );
};
