import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import TaskIcon from "@mui/icons-material/QueueOutlined";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import ConvertFormatIcon from "@mui/icons-material/LayersOutlined";
import DeleteFormatIcon from "@mui/icons-material/LayersClearOutlined";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import OpenIcon from "@mui/icons-material/OpenInBrowserOutlined";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PauseIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import ResumeIcon from "@mui/icons-material/PlayCircleOutlineOutlined";

import {
  selectSelectedRows,
  setSelected,
  selectPreferences,
  StyledCard,
  StyledDataGrid,
  ViewAsMenu,
  clearSelectedRows,
} from "../../components";

import { formatTimestampAsDateTime } from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import {
  useCancelTask,
  useDeleteTask,
  useOpenTask,
  useResumeTask,
  usePauseTask,
} from ".";

export const TaskListingGrid = (props) => {
  const { tasks } = props;
  const dispatch = useDispatch();
  const preferences = useSelector(selectPreferences);
  const selectedRows = useSelector(selectSelectedRows);

  const cancelTask = useCancelTask();
  const deleteTask = useDeleteTask();
  const openTask = useOpenTask();
  const pauseTask = usePauseTask();
  const resumeTask = useResumeTask();

  const notImplemented = true;

  const { data: account } = useGetMyAccountQuery();

  const title = useMemo(() => {
    if (tasks.isLoading) {
      return "Tasks";
    }
    return `${tasks.data.count} Tasks`;
  }, [tasks]);

  const getTaskIcon = (taskName) => {
    if (taskName === "uploadBlob") return <UploadIcon />;
    else if (taskName === "downloadBlob") return <DownloadIcon />;
    else if (taskName === "convertFormat") return <ConvertFormatIcon />;
    else if (taskName === "deleteFormat") return <DeleteFormatIcon />;
    else return <TaskIcon />;
  };

  const onRefresh = () => {
    tasks.refetch();
    dispatch(clearSelectedRows());
  };

  const onCellClick = (params, event) => {
    event.defaultMuiPrevented = true;

    if (params.field !== "__check__" && params.field !== "moreMenu") {
      openTask(params.row);
    }
  };

  const renderMoreMenu = (row) => {
    let moreMenu = [];

    moreMenu.push(
      <GridActionsCellItem
        icon={
          <Tooltip title={<div>Open task details</div>}>
            <div>{<OpenIcon sx={{ color: "icon" }} />}</div>
          </Tooltip>
        }
        key="more-menu-open"
        label="Open"
        onClick={() => openTask(row)}
        showInMenu
      />
    );

    if (!notImplemented) {
      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Pause task</div>}>
              <div>{<PauseIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-pause"
          label="Pause"
          onClick={pauseTask([row])}
          disabled={true}
          showInMenu
        />
      );
      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Resume task</div>}>
              <div>{<ResumeIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-resume"
          label="Resume"
          onClick={resumeTask([row])}
          disabled={true}
          showInMenu
        />
      );
      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Cancel task</div>}>
              <div>{<CancelIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-cancel"
          label="Cancel"
          onClick={cancelTask([row])}
          disabled={true}
          showInMenu
        />
      );
    }

    moreMenu.push(
      <GridActionsCellItem
        icon={
          <Tooltip title={<div>Delete task</div>}>
            <div>{<DeleteIcon sx={{ color: "icon" }} />}</div>
          </Tooltip>
        }
        key="more-menu-delete"
        label="Delete"
        onClick={deleteTask([row])}
        disabled={false}
        showInMenu
      />
    );

    return moreMenu;
  };

  const columns = [
    {
      field: "name",
      headerName: "Action",
      description: "Task action",
      width: 175,
      editable: false,
      renderCell: (params) => {
        if (!params || !params.row || !params.row.name) {
          return <></>;
        }

        const name = params.row.name;

        return (
          <Grid container alignItems="center" spacing={2}>
            <Grid item>{getTaskIcon(name)}</Grid>
            <Grid item>
              <Tooltip title={name}>
                <Typography scope="row" variant="body1" noWrap={true}>
                  {" "}
                  {name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      description: "Task description",
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (params) => {
        if (!params || !params.row) {
          return <></>;
        }

        let assetTitle = "";
        if (params.row.assetName && params.row.assetName !== "N/A") {
          assetTitle = params.row.assetName + "/";
        }

        let description = "";
        if (params.row.description) {
          description = params.row.description;
        }

        return (
          <Tooltip
            title={
              <>
                <div>
                  {assetTitle}
                  {description}
                </div>
              </>
            }
          >
            <Typography scope="row" variant="body1" noWrap={true}>
              {description}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "assetName",
      headerName: "Name",
      description: "Built asset name",
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (params) => {
        if (!params || !params.row) {
          return <></>;
        }

        let assetTitle = "";
        if (params.row.assetName) {
          assetTitle = params.row.assetName;
        }

        return (
          <Tooltip
            title={
              <>
                <div>{assetTitle}</div>
              </>
            }
          >
            <Typography scope="row" variant="body1" noWrap={true}>
              {assetTitle}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "ts",
      headerName: "Modified",
      description: "Last modified",
      flex: 0.9,
      minWidth: 175,
      editable: false,
      renderCell: (params) => {
        if (!params || !params.row) {
          return <></>;
        }

        return (
          <Tooltip
            title={
              "Last modified " +
              formatTimestampAsDateTime(
                params.row.ts * 1000,
                account.preferences.dateFormat
              )
            }
          >
            <Typography scope="row" variant="body1" noWrap={true}>
              {formatTimestampAsDateTime(
                params.row.ts * 1000,
                account.preferences.dateFormat
              )}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "state",
      headerName: "State",
      description: "Last known task state",
      flex: 0.5,
      minWidth: 100,
      editable: false,
      renderCell: (params) => {
        if (!params || !params.row) {
          return <></>;
        }

        let state = "";
        if (params.row.state) {
          state = params.row.state;
        }

        return (
          <Tooltip title={state}>
            <Typography scope="row" variant="body1" noWrap={true}>
              {state}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "moreMenu",
      type: "actions",
      headerName: "",
      flex: 0.2,
      midWidth: 50,
      editable: false,
      getActions: (params) => renderMoreMenu(params.row),
      renderHeader: () => <ViewAsMenu />,
    },
  ];

  const rowCount = (tasks.data && tasks.data.count) || 0;

  const NoRowsOverlay = () => {
    return (
      <Box
        sx={{
          display: "flex",
          marginTop: "80px",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No tasks found.
      </Box>
    );
  };

  return (
    <StyledCard>
      <CardHeader
        title={title}
        action={
          <IconButton
            size="small"
            id="refreshIcon"
            onClick={onRefresh}
            sx={{ justifyContent: "flex-end" }}
          >
            <RefreshIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent
        sx={{
          padding: "0px !important",
          margin: "0px !important",
          alignItems: "center",
          marginBottom: "-25px",
        }}
      >
        <StyledDataGrid
          autoHeight
          checkboxSelection
          columns={columns}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: tasks.pageSize,
                page: tasks.page,
              },
            },
            sorting: {
              sortModel: [{ ...tasks.sortModel }],
            },
          }}
          loading={tasks.isLoading}
          onCellClick={onCellClick}
          onPaginationModelChange={(paginationModel) => {
            tasks.setPage(paginationModel.page);
            tasks.setPageSize(paginationModel.pageSize);
          }}
          onRowSelectionModelChange={(selected) => {
            dispatch(setSelected(selected));
          }}
          onSortModelChange={(m) => {
            if (m.length > 0) {
              tasks.setSortModel(m[0]);
            } else {
              tasks.resetSortModel();
            }
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          paginationModel={preferences}
          rowCount={rowCount}
          rows={tasks.data && tasks.data.data ? tasks.data.data : []}
          rowSelectionModel={selectedRows}
          tasks="server"
        />
      </CardContent>
    </StyledCard>
  );
};

TaskListingGrid.propTypes = {
  tasks: PropTypes.object.isRequired,
};
