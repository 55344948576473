import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import AssetsIcon from "@mui/icons-material/PagesOutlined";
import SharedAssetsIcon from "@mui/icons-material/ShareOutlined";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import OpenIcon from "@mui/icons-material/OpenInBrowserOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import {
  clearSelectedRows,
  LoadingCircularProgress,
  NoMaxWidthTooltip,
  selectSelectedRows,
  setSelected,
  StyledCard,
  StyledDataGrid,
  ViewAsMenu,
} from "../../components";
import { isEditableRole, formatTimestampAsDateTime } from "../../helpers";
import { MemberGroup, useGetMyOrganizationQuery } from "../organization";
import { useGetMyAccountQuery } from "../account";
import { useDeleteShare, useOpenAsset, useOpenShare } from ".";

export const AssetListingGrid = (props) => {
  const { shareAssets } = props;
  const dispatch = useDispatch();

  const { isLoading: isLoadingAccount, data: account } = useGetMyAccountQuery();
  const { isLoading: isLoadingOrganization, data: organization } =
    useGetMyOrganizationQuery();

  const preferences = useSelector((state) => state.preferences);
  const selectedRows = useSelector(selectSelectedRows);

  const openShare = useOpenShare();
  const openAsset = useOpenAsset();
  const deleteShare = useDeleteShare();

  const title = useMemo(() => {
    var title = "Built Assets Shared";

    if (
      shareAssets.isLoading ||
      shareAssets.data === undefined ||
      shareAssets.data.data === undefined
    ) {
      return title;
    }
    const length = shareAssets.data.data && shareAssets.data.data.length;
    title = `${length} Built Assets Shared`;

    return title;
  }, [shareAssets]);

  const onRefresh = () => {
    shareAssets.refetch();
    dispatch(clearSelectedRows());
  };

  if (shareAssets.isLoading) {
    return <LoadingCircularProgress />;
  }

  const renderMoreMenu = (row) => {
    let moreMenu = [
      <GridActionsCellItem
        icon={
          <Tooltip title={<div>Open shared built asset members</div>}>
            <div>{<OpenIcon sx={{ color: "icon" }} />}</div>
          </Tooltip>
        }
        key="more-menu-open-members"
        label="Open Members"
        onClick={() => openShare(row)}
        showInMenu
      />,
      <GridActionsCellItem
        icon={
          <Tooltip title={<div>Open shared built asset</div>}>
            <div>{<OpenIcon sx={{ color: "icon" }} />}</div>
          </Tooltip>
        }
        key="more-menu-open-built-asset"
        label="Open Built Asset"
        onClick={() => openAsset(row)}
        showInMenu
      />,
    ];

    if (
      isEditableRole(account, organization, null) &&
      organization.id === row.organizationId
    ) {
      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Delete share to built asset</div>}>
              <div>{<DeleteIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-delete"
          label="Delete"
          onClick={deleteShare([row])}
          showInMenu
        />
      );
    }

    return moreMenu;
  };

  const onCellClick = (params, event) => {
    event.defaultMuiPrevented = true;

    if (params.field !== "__check__" && params.field !== "moreMenu") {
      openShare(params.row);
    }
  };

  const columns = [
    {
      field: "asset.name",
      headerName: "Name",
      description: "Shared built asset name",
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (params) => {
        const isOrgAsset = account.organizationId === params.row.organizationId;
        const title =
          params.row.asset.name + (isOrgAsset ? "" : " (shared with me)");
        return (
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              {isOrgAsset ? <AssetsIcon /> : <SharedAssetsIcon />}
            </Grid>
            <Grid item>
              {/* 
            Note: enter/leave delay experiment with workaround following error:
            Warning: Failed prop type:
            Material-UI: The `anchorEl` prop provided to the component is
            invalid. The anchor element should be part of the document layout.
            Make sure the element is present in the document or that it's not
            display none. 
            */}
              <NoMaxWidthTooltip
                title={title}
                enterDelay={500}
                leaveDelay={200}
              >
                <Typography scope="row" variant="body1" noWrap={true}>
                  {" "}
                  {params.row.asset.name}
                </Typography>
              </NoMaxWidthTooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "ts",
      headerName: "Modified",
      description: "Last modified",
      flex: 0.6,
      minWidth: 175,
      editable: false,
      renderCell: (params) => (
        <NoMaxWidthTooltip
          title={
            "Last modified " +
            formatTimestampAsDateTime(
              params.row.ts * 1000,
              account.preferences.dateFormat
            )
          }
        >
          <Typography scope="row" variant="body1" noWrap={true}>
            {formatTimestampAsDateTime(
              params.row.ts * 1000,
              account.preferences.dateFormat
            )}
          </Typography>
        </NoMaxWidthTooltip>
      ),
    },
    {
      field: "members",
      headerName: "Members",
      description: "Shared members",
      flex: 0.6,
      minWidth: 175,
      editable: false,
      renderCell: (params) => (
        <MemberGroup
          link={`/share/asset/${params.row.id}`}
          members={params.row.membership.members}
        />
      ),
    },
    {
      field: "moreMenu",
      type: "actions",
      headerName: "",
      flex: 0.2,
      midWidth: 50,
      editable: false,
      getActions: (params) => renderMoreMenu(params.row),
      renderHeader: () => <ViewAsMenu />,
    },
  ];

  const rowCount = (shareAssets.data && shareAssets.data.count) || 0;

  const NoRowsOverlay = () => {
    return (
      <Box
        sx={{
          display: "flex",
          marginTop: "80px",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No shared built assets found.
      </Box>
    );
  };

  return (
    <StyledCard>
      <CardHeader
        title={title}
        action={
          <IconButton
            size="small"
            id="refreshIcon"
            onClick={onRefresh}
            sx={{ justifyContent: "flex-end" }}
          >
            <RefreshIcon />
          </IconButton>
        }
      />
      <CardContent
        sx={{
          alignItems: "center",
          display: "flex",
          padding: 0,
          marginBottom: "-25px",
        }}
      >
        <StyledDataGrid
          autoHeight
          checkboxSelection
          columns={columns}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: shareAssets.pageSize,
                page: shareAssets.page,
              },
            },
            sorting: {
              sortModel: [{ ...shareAssets.sortModel }],
            },
          }}
          loading={
            shareAssets.isLoading || isLoadingAccount || isLoadingOrganization
          }
          onCellClick={onCellClick}
          onPaginationModelChange={(paginationModel) => {
            shareAssets.setPage(paginationModel.page);
            shareAssets.setPageSize(paginationModel.pageSize);
          }}
          onRowSelectionModelChange={(selected) => {
            dispatch(setSelected(selected));
          }}
          onSortModelChange={(m) => {
            if (m.length > 0) {
              shareAssets.setSortModel(m[0]);
            } else {
              shareAssets.resetSortModel();
            }
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          paginationModel={preferences}
          rowCount={rowCount}
          rows={(shareAssets.data && shareAssets.data.data) || []}
          rowSelectionModel={selectedRows}
          sortingMode="server"
        />
      </CardContent>
    </StyledCard>
  );
};

AssetListingGrid.propTypes = {
  shareAssets: PropTypes.object,
};
