import React, { useMemo, useRef, useState } from "react";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";

import { useTheme } from "@mui/material/styles";
import {
  Alert,
  Badge,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  IconButton,
  Popover,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import MarkChatReadIcon from "@mui/icons-material/ChatBubbleOutline";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";

import { formatTimestampFromNow } from "../../helpers";

export const NotificationCenter = () => {
  const {
    notifications,
    clear: handleClearAll,
    markAllAsRead: handleMarkAllRead,
    markAsRead,
    unreadCount,
  } = useNotificationCenter();
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const theme = useTheme();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleFilter = () => {
    setShowUnreadOnly(!showUnreadOnly);
  };

  const { title } = useMemo(() => {
    var title = "Notifications";
    if (unreadCount > 0) {
      title = `${unreadCount} New Notifications`;
    }
    return { title };
  }, [unreadCount]);

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          size="small"
          color="inherit"
          onClick={handleOpen}
          ref={ref}
          sx={{ ml: 1 }}
        >
          <Badge badgeContent={unreadCount} color="primary">
            <NotificationsIcon sx={{ color: theme.palette.icon }} />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleClose}
        open={isOpen}
      >
        <Card
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <CardHeader
            action={
              <FormGroup sx={{ color: "#fff" }}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      onChange={toggleFilter}
                      checked={showUnreadOnly}
                      size="small"
                    />
                  }
                  label="Unread"
                />
              </FormGroup>
            }
            title={title}
            titleTypographyProps={{
              color: theme.palette.text.primary,
              variant: "body1",
            }}
          ></CardHeader>
          <CardContent>
            <Stack
              sx={{
                height: "400px",
                width: "min(60ch, 100ch)",
                padding: "8px",
                overflowY: "auto",
              }}
              spacing={2}
            >
              {(!notifications.length ||
                (unreadCount === 0 && showUnreadOnly)) && (
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignContent: "space-around",
                    justifyContent: "center",
                  }}
                >
                  No notifications
                </Typography>
              )}
              {(showUnreadOnly
                ? notifications.filter((v) => !v.read)
                : notifications
              ).map((notification) => {
                return (
                  <Alert
                    severity={notification.type || "info"}
                    key={notification.id}
                    variant="outlined"
                    action={
                      notification.read ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <IconButton
                          color="primary"
                          aria-label="mark-as-read"
                          component="span"
                          onClick={() => markAsRead(notification.id)}
                        >
                          <MarkChatReadIcon color="primary" />
                        </IconButton>
                      )
                    }
                    sx={{
                      "& .MuiAlert-message": {
                        color:
                          theme.palette.mode === "dark"
                            ? theme.palette.primary.contrastText
                            : theme.palette.text.primary,
                      },
                    }}
                  >
                    {notification.content}
                    <Typography variant="body2">
                      {formatTimestampFromNow(notification.createdAt)}
                    </Typography>
                  </Alert>
                );
              })}
            </Stack>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="outlined" size="small" onClick={handleClearAll}>
              Clear All
            </Button>

            <Button variant="outlined" size="small" onClick={handleMarkAllRead}>
              Mark All Read
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};
