import { useState, useEffect } from "react";

export const useBlobMutation = (mutation, onSuccess, onFailure) => {
  const [blob, setBlob] = useState();
  const [runMutation, { data, error, isError, isSuccess }] = mutation();

  useEffect(() => {
    if (blob) {
      if (isSuccess) {
        onSuccess(blob.blob, data);
        setBlob();
      }
    }
  }, [blob, data, isSuccess, onSuccess]);

  useEffect(() => {
    if (blob) {
      if (isError) {
        onFailure(blob.blob, error);
        setBlob();
      }
    }
  }, [blob, error, isError, onFailure]);

  useEffect(() => {
    if (blob) {
      runMutation(blob, {
        refetchOnMountOrArgChange: true,
        keepUnusedDataFor: 5,
      });
    }
  }, [blob, runMutation]);

  return setBlob;
};
