import Logger from "js-logger";

import { logToastDebug, logToastError } from "../../components";
import { useSaveMyAccountStatusMutation } from "../account";

import { useSettingsMutation } from ".";

const logger = Logger.get("useSaveMyAccountStatus");

export const useSaveMyAccountStatus = () => {
  const saveMyAccountStatus = useSettingsMutation(
    useSaveMyAccountStatusMutation,
    (accountData, data) => {
      logger.debug("result:", accountData, data);

      if (data) {
        const msg = "account status updated";
        logToastDebug(logger, msg);
        return;
      }
    },
    (accountData, error) => {
      const msg = "account state update failed";
      logToastError(logger, msg, error);
    }
  );

  return (id, status) => () => {
    saveMyAccountStatus({ id, status });
  };
};
