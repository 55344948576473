import { styled } from "@mui/material/styles";
import { MenuItem } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  root: {
    marginRight: theme.spacing(2),
    width: "100%",
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}));
