import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

export function InputDialog(props) {
  const {
    inputLabel,
    inputValue,
    onClose,
    cancelTitle,
    okTitle,
    title,
    description,
    open,
    ...other
  } = props;

  const [value, setValue] = useState(inputValue);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const handleChange = (event) => {
    // logger.debug("handleChange:", event.target.value);
    setValue(event.target.value);
  };

  const handleCancel = () => {
    onClose(null);
  };

  const handleOk = () => {
    onClose(value);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...other}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" hidden={!description}>
          {description}
        </DialogContentText>
        <TextField
          fullWidth
          label={inputLabel}
          margin="dense"
          size="small"
          name="value"
          onChange={handleChange}
          value={value}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancelTitle}
        </Button>
        <Button onClick={handleOk} color="primary" autoFocus>
          {okTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

InputDialog.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  cancelTitle: PropTypes.string.isRequired,
  okTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};
