import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

import { AuthContext } from "../../../components";
import { Topbar } from ".";

const Inline = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { children, staticContext, ...rest } = props;

  const { isAuthenticated } = useContext(AuthContext);

  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: theme.palette.background.default,
      }}
    >
      {isAuthenticated() ? <Topbar /> : <></>}
      <main
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          textAlign: "center",
        }}
      >
        {children}
      </main>
    </Box>
  );
};

Inline.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  staticContext: PropTypes.object,
};

export const InlineWithRouter = withRouter(Inline);
