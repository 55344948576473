import { api } from "../api/apiSlice";

export const versionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVersion: build.query({
      query: () => `version`,
      providesTags: (_result, _error, arg) => [{ type: "Version", arg }],
    }),
  }),
});

export const { useGetVersionQuery } = versionApi;
