import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import AssetsIcon from "@mui/icons-material/PagesOutlined";
import SharedAssetsIcon from "@mui/icons-material/ShareOutlined";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import OpenIcon from "@mui/icons-material/OpenInBrowserOutlined";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import {
  clearSelectedRows,
  LoadingCircularProgress,
  NoMaxWidthTooltip,
  selectSelectedRows,
  setSelected,
  StyledCard,
  StyledDataGrid,
  ViewAsMenu,
} from "../../components";

import {
  formatTimestampAsDateTime,
  isEditableRole,
  isOwnerRole,
} from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";
import {
  useDeleteShareAsset,
  useOpenShareAsset,
  useCopyShareAsset,
  useUpsertShareAsset,
} from ".";

export const AssetListingGrid = ({ shareAssets }) => {
  const dispatch = useDispatch();

  const selectedRows = useSelector(selectSelectedRows);
  const preferences = useSelector((state) => state.preferences);

  const { isLoading: isLoadingAccount, data: account } = useGetMyAccountQuery();
  const { isLoading: isLoadingOrganization, data: organization } =
    useGetMyOrganizationQuery();

  const openShareAsset = useOpenShareAsset();
  const copyShareAsset = useCopyShareAsset();
  const deleteShareAsset = useDeleteShareAsset();
  const upsertShareAsset = useUpsertShareAsset();

  if (shareAssets.isLoading || isLoadingAccount || isLoadingOrganization) {
    return <LoadingCircularProgress />;
  }

  const title = `${
    (shareAssets.data && shareAssets.data.count) || 0
  } Built Assets`;

  const onRefresh = () => {
    shareAssets.refetch();
    dispatch(clearSelectedRows());
  };

  const renderMoreMenu = (row) => {
    let moreMenu = [];

    moreMenu.push(
      <GridActionsCellItem
        icon={
          <Tooltip title={<div>Open built asset</div>}>
            <div>{<OpenIcon sx={{ color: "icon" }} />}</div>
          </Tooltip>
        }
        key="more-menu-open"
        label="Open"
        onClick={() => openShareAsset(row)}
        showInMenu
      />
    );

    if (
      isEditableRole(account, organization, null) &&
      account.organizationId === row.organizationId
    ) {
      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Copy built asset</div>}>
              <div>{<CopyIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-copy"
          label="Copy"
          onClick={copyShareAsset(row)}
          showInMenu
        />
      );
    }

    if (
      isOwnerRole(account, organization, row) &&
      account.organizationId === row.organizationId
    ) {
      moreMenu.push(
        <GridActionsCellItem
          icon={
            <Tooltip title={<div>Delete built asset</div>}>
              <div>{<DeleteIcon sx={{ color: "icon" }} />}</div>
            </Tooltip>
          }
          key="more-menu-delete"
          label="Delete"
          onClick={deleteShareAsset([row])}
          showInMenu
        />
      );
    }

    return moreMenu;
  };

  const onCellClick = (params, event) => {
    event.defaultMuiPrevented = true;

    if (params.field !== "__check__" && params.field !== "moreMenu") {
      openShareAsset(params.row);
    }
  };

  const columns = [
    {
      field: "asset.name",
      headerName: "Name",
      description: "Built asset name",
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (params) => {
        if (!params.row || !params.row.asset) {
          return <></>;
        }
        const isOrgAsset = account.organizationId === params.row.organizationId;
        const assetName = params.row.asset.name;
        const title = assetName + (isOrgAsset ? "" : " (shared with me)");
        return (
          <NoMaxWidthTooltip title={title}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                {isOrgAsset ? (
                  <AssetsIcon
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                ) : (
                  <SharedAssetsIcon
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                )}
              </Grid>

              <Grid item>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                    minWidth: "200px",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" noWrap={true}>
                    {assetName}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </NoMaxWidthTooltip>
        );
      },
    },
    {
      field: "asset.ts",
      headerName: "Modified",
      description: "Last modified",
      flex: 0.3,
      minWidth: 100,
      editable: false,
      renderCell: (params) => {
        if (!params.row || !params.row.asset || !params.row.asset.ts) {
          return <></>;
        }

        const ts = formatTimestampAsDateTime(
          params.row.asset.ts * 1000,
          account.preferences.dateFormat
        );
        const title = "Last modifed " + ts;

        return (
          <NoMaxWidthTooltip title={title}>
            <Typography scope="row" variant="body1" noWrap={true}>
              {ts}
            </Typography>
          </NoMaxWidthTooltip>
        );
      },
    },
    {
      field: "moreMenu",
      type: "actions",
      headerName: "",
      flex: 0.1,
      midWidth: 50,
      editable: false,
      getActions: (params) => renderMoreMenu(params.row),
      renderHeader: () => <ViewAsMenu />,
    },
  ];

  const rowCount = (shareAssets.data && shareAssets.data.count) || 0;

  const handleCreateNewAsset = () => {
    dispatch(upsertShareAsset());
  };

  const NoRowsOverlay = () => {
    return (
      <Box
        sx={{
          display: "flex",
          marginTop: "80px",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No built assets found.{" "}
        <Button color="primary" variant="text" onClick={handleCreateNewAsset}>
          Create
        </Button>{" "}
        a built asset to to get started.
      </Box>
    );
  };

  return (
    <StyledCard>
      <CardHeader
        title={title}
        action={
          <IconButton
            size="small"
            id="refreshIcon"
            onClick={onRefresh}
            sx={{
              justifyContent: "flex-end",
            }}
          >
            <RefreshIcon />
          </IconButton>
        }
      />
      <CardContent
        sx={{
          padding: "0px !important",
          margin: "0px !important",
          alignItems: "center",
          marginBottom: "-25px",
        }}
      >
        <StyledDataGrid
          autoHeight
          checkboxSelection
          columns={columns}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: shareAssets.pageSize,
                page: shareAssets.page,
              },
            },
            sorting: {
              sortModel: [{ ...shareAssets.sortModel }],
            },
          }}
          loading={shareAssets.isLoading}
          onCellClick={onCellClick}
          onPaginationModelChange={(paginationModel) => {
            shareAssets.setPage(paginationModel.page);
            shareAssets.setPageSize(paginationModel.pageSize);
          }}
          onRowSelectionModelChange={(selected) => {
            dispatch(setSelected(selected));
          }}
          onSortModelChange={(m) => {
            if (m.length > 0) {
              shareAssets.setSortModel(m[0]);
            } else {
              shareAssets.resetSortModel();
            }
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          paginationModel={preferences}
          rowCount={rowCount}
          rows={
            shareAssets.data && shareAssets.data.data
              ? shareAssets.data.data
              : []
          }
          rowSelectionModel={selectedRows}
          sortingMode="server"
        />
      </CardContent>
    </StyledCard>
  );
};

AssetListingGrid.propTypes = {
  shareAssets: PropTypes.object.isRequired,
};
