import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Divider,
  Hidden,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import DesktopIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import SignoutIcon from "@mui/icons-material/ExitToAppOutlined";
import LogoIcon from "../../../assets/images/builtstream_logo.png";

import {
  ThemeButton,
  AuthContext,
  CustomRouterLink,
  NotificationCenter,
  StyledCompanyLogoIcon,
} from "../../../components";

import {
  http,
  version as configVersion,
  environmentName,
} from "../../../config.json";
import { AccountAvatar } from "../../../features/account";
import { SearchBar } from "../../../features/search";

const drawerWidth = 240;

const TopbarMenuItem = styled(MenuItem)(() => ({
  display: "flex",
  paddingTop: 0,
  paddingBottom: 0,
}));

const TopbarListItem = styled(ListItem)(() => ({
  display: "flex",
  paddingTop: 0,
  paddingBottom: 0,
  height: "26px",
}));

// const logger = Logger.get("Private.Topbar");

export const Topbar = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onSidebarOpen, title, isSignup, ...rest } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const [downloadAgentUrl] = useState(() => {
    const suffix =
      environmentName === "production"
        ? ""
        : environmentName === "local" || environmentName === "pr"
        ? "-dev"
        : "-" + environmentName;

    const url =
      http.downloadAgentUrl +
      "bsi-agent-" +
      configVersion.short +
      suffix +
      "-setup.exe";

    return url;
  });

  const auth = useContext(AuthContext);
  const { logout } = auth;

  const theme = useTheme();

  const menuId = "primary-search-account-menu";

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { renderMenu, renderToolbar } = useMemo(() => {
    const renderMenu = (
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem>
          <AccountAvatar />
        </MenuItem>
        <Divider />
        <div hidden={isSignup}>
          <TopbarMenuItem disableGutters onClick={handleMenuClose}>
            <TopbarListItem
              component={CustomRouterLink}
              href="/settings"
              to="/settings"
              button
            >
              <ListItemIcon>
                <SettingsIcon
                  fontSize="small"
                  sx={{ color: theme.palette.icon }}
                />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </TopbarListItem>
          </TopbarMenuItem>
          <TopbarMenuItem disableGutters onClick={handleMenuClose}>
            <TopbarListItem
              component={Link}
              href={downloadAgentUrl}
              to={downloadAgentUrl}
              target="_blank"
              rel="noopener"
              button
            >
              <ListItemIcon>
                <DesktopIcon
                  fontSize="small"
                  sx={{ color: theme.palette.icon }}
                />
              </ListItemIcon>
              <ListItemText primary="Download Agent" />
            </TopbarListItem>
          </TopbarMenuItem>
          <TopbarMenuItem disableGutters onClick={handleMenuClose}>
            <TopbarListItem
              component={CustomRouterLink}
              href="/about"
              to="/about"
              button
            >
              <ListItemIcon>
                <StyledCompanyLogoIcon
                  component="img"
                  alt="Logo"
                  src={LogoIcon}
                />
              </ListItemIcon>
              <ListItemText primary="About" />
            </TopbarListItem>
          </TopbarMenuItem>
        </div>
        <TopbarMenuItem disableGutters onClick={handleMenuClose}>
          <TopbarListItem onClick={logout} button>
            <ListItemIcon>
              <SignoutIcon
                fontSize="small"
                sx={{ color: theme.palette.icon }}
              />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </TopbarListItem>
        </TopbarMenuItem>
      </Menu>
    );

    const renderToolbar = (
      <Toolbar
        sx={{
          boxShadow: "none",
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Typography component="h1" variant="h6" color="inherit" noWrap>
          {title}
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
          }}
        />
        <div hidden={isSignup}>
          <SearchBar />
        </div>
        <div hidden={isSignup}>
          <NotificationCenter />
        </div>
        <ThemeButton />
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="dialog"
          onClick={handleMenuOpen}
          color="inherit"
          sx={{ color: theme.palette.icon }}
        >
          <AccountCircle />
        </IconButton>
        <Hidden lgUp>
          <IconButton
            sx={{ color: theme.palette.icon }}
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    );

    return { renderMenu, renderToolbar };
  }, [
    anchorEl,
    downloadAgentUrl,
    isMenuOpen,
    isSignup,
    logout,
    onSidebarOpen,
    theme,
    title,
  ]);

  return (
    <div>
      <Hidden mdDown>
        <AppBar
          {...rest}
          position="absolute"
          sx={{
            boxShadow: "none",
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
            marginLeft: 0,
            width: `calc(100% - 0px)`,
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(["width", "margin"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            ...(open && {
              marginLeft: drawerWidth,
              width: `calc(100% - ${drawerWidth}px)`,
              transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }),
          }}
        >
          {renderToolbar}
          {renderMenu}
        </AppBar>
      </Hidden>
      <Hidden lgUp>
        <AppBar
          {...rest}
          position="absolute"
          sx={{
            boxShadow: "none",
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
          }}
        >
          {renderToolbar}
          {renderMenu}
        </AppBar>
      </Hidden>
    </div>
  );
};

Topbar.propTypes = {
  title: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  isSignup: PropTypes.bool.isRequired,
};
