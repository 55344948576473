import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Redirect, useLocation } from "react-router-dom";
import Logger from "js-logger";

import { styled, useTheme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import AssetsIcon from "@mui/icons-material/Pages";
import SharesIcon from "@mui/icons-material/ShareOutlined";
// Deprecated: use Search and/or Asset->BlobTree instead
// import FilesIcon from "@mui/icons-material/FileCopyOutlined";
import TasksIcon from "@mui/icons-material/QueueOutlined";
import OrganizationIcon from "@mui/icons-material/SupervisedUserCircle";
import AdminIcon from "@mui/icons-material/RoomServiceOutlined";

import {
  AuthContext,
  LoadingCircularProgress,
  logToastDebug,
} from "../../../components";
import { useGetMyAccountQuery } from "../../../features/account";
import {
  useGetMyOrganizationQuery,
  useGetOrganizationInvitationsQuery,
} from "../../../features/organization";
import { useInvitations } from "../../../features/share";
import { Sidebar, Topbar } from ".";

const drawerWidth = 240;

const AuthorizedUserContent = styled(Box)(({ theme }) => ({
  paddingTop: 56,
  height: "100%",
  [theme.breakpoints.up("sm")]: {
    paddingTop: 64,
  },
  [theme.breakpoints.up("lg")]: {
    paddingLeft: drawerWidth,
  },
}));

const logger = Logger.get("Private");

const homePage = "home";
const assetsPage = "assets";
const sharesPage = "shares";
// const filesPage = "files";
const tasksPage = "tasks";
const organizationPage = "organization";
const adminPage = "admin";

const configurePages = (isGlobalAdmin, isOrgHidden, counts) => {
  return [
    {
      title: "Home",
      href: `/${homePage}`,
      icon: <HomeIcon />,
      hidden: false,
      count: counts[homePage] || 0,
    },
    {
      title: "Built Assets",
      href: `/${assetsPage}`,
      icon: <AssetsIcon />,
      hidden: false,
      count: counts[assetsPage] || 0,
    },
    {
      title: "Shares",
      href: `/${sharesPage}`,
      icon: <SharesIcon />,
      hidden: false,
      count: counts[sharesPage] || 0,
    },
    // Deprecated: use Search and/or Asset->BlobTree instead
    // {
    //   title: "Files",
    //   href: `/${filesPage}`,
    //   icon: <FilesIcon />,
    //   hidden: false,
    //   count: counts[filesPage] || 0,
    // },
    {
      title: "Tasks",
      href: `/${tasksPage}`,
      icon: <TasksIcon />,
      hidden: false,
      count: counts[tasksPage] || 0,
    },
    {
      title: "Organization",
      href: `/${organizationPage}`,
      icon: <OrganizationIcon />,
      hidden: isOrgHidden,
      count: counts[organizationPage] || 0,
    },
    {
      title: "Admin",
      href: `/${adminPage}`,
      icon: <AdminIcon />,
      hidden: !isGlobalAdmin,
      count: counts[adminPage] || 0,
    },
  ];
};

const Private = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { children, staticContext, title, ...rest } = props;
  // logger.debug("props:", props);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [orgInvitationsNotified, setOrgInvitationsNotified] = useState(false);
  const [shareInvitationsNotified, setShareInvitationsNotified] =
    useState(false);

  const theme = useTheme();

  const auth = useContext(AuthContext);

  const location = useLocation();

  const { isLoading: isLoadingAccount, data: account } = useGetMyAccountQuery();

  const { isLoading: isLoadingOrganization, data: organization } =
    useGetMyOrganizationQuery();

  const isActive = useMemo(() => {
    return (
      account !== undefined &&
      (account.status === "signupCompleted" || account.status === "active")
    );
  }, [account]);

  const { isLoading: isLoadingInvitations, data: orgInvitations } =
    useGetOrganizationInvitationsQuery(null, {
      skip: isLoadingAccount || isLoadingOrganization,
    });

  const shareInvitations = useInvitations();

  useEffect(() => {
    if (orgInvitations === undefined || isLoadingInvitations) {
      return;
    }

    if (!orgInvitationsNotified) {
      setOrgInvitationsNotified(true);
      if (orgInvitations.length > 0) {
        const msg =
          orgInvitations.length + " pending organization invitations detected";

        // Note: ensure notification center captures the toast
        setTimeout(() => {
          logToastDebug(logger, msg, "/organization/pending");
        }, 1000);
      }
    }
  }, [isLoadingInvitations, orgInvitationsNotified, orgInvitations]);

  useEffect(() => {
    if (
      shareInvitations === undefined ||
      shareInvitations.isLoading ||
      shareInvitations.data === undefined
    ) {
      return;
    }

    if (!shareInvitationsNotified) {
      setShareInvitationsNotified(true);
      if (shareInvitations.data.length > 0) {
        const msg =
          shareInvitations.data.length +
          " pending sharing invitations detected";

        // Note: ensure notification center captures the toast
        setTimeout(() => {
          logToastDebug(logger, msg, "/shares/pending");
        }, 1000);
      }
    }
  }, [shareInvitationsNotified, shareInvitations]);

  const pages = useMemo(() => {
    var isGlobalAdmin = false;
    var isOrgHidden = false;
    var counts = {};

    if (account && account.roles && account.roles.length > 0) {
      isGlobalAdmin = account.roles.find((x) => x === "admin");
    }

    if (organization !== undefined) {
      isOrgHidden = organization.hidden;
    }

    if (orgInvitations !== undefined && orgInvitations.length > 0) {
      counts[organizationPage] = orgInvitations.length;
    }

    if (
      shareInvitations !== undefined &&
      shareInvitations.data !== undefined &&
      shareInvitations.data.length > 0
    ) {
      counts[sharesPage] = shareInvitations.data.length;
    }

    return configurePages(isGlobalAdmin, isOrgHidden, counts);
  }, [account, organization, orgInvitations, shareInvitations]);

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  if (!auth.isAuthenticated()) {
    return (
      <>
        <Redirect to="/signin" />
      </>
    );
  }

  if (isLoadingAccount || isLoadingOrganization || isLoadingInvitations) {
    return <LoadingCircularProgress />;
  }

  if (auth.isAuthenticated() && account && Object.keys(account).length > 0) {
    if (isActive) {
      // logger.debug("authenticated, completely signed up");

      // We are authenticated, standard full layout
      return (
        <AuthorizedUserContent>
          <Topbar
            onSidebarOpen={handleSidebarOpen}
            isSignup={false}
            {...rest}
          />
          <Sidebar
            onClose={handleSidebarClose}
            open={shouldOpenSidebar}
            variant={isDesktop ? "persistent" : "temporary"}
            isSignup={false}
            pages={pages}
          />
          <main sx={{ height: "100%", boxShadow: "none" }}>{children}</main>
        </AuthorizedUserContent>
      );
    }

    logger.debug(
      "authenticated, but not completely signed up:",
      account.status
    );

    // Handle authenticated but not completely signed up
    // Options are:
    // * proceed with joining an organization
    // * proceed with pricing table selection (to Stripe checkout)
    // * proceed with returning from Stripe checkout
    // * proceed with Stripe's billing portal (update subscription)
    if (
      location.pathname.startsWith("/organization/invitation/") ||
      location.pathname.startsWith("/organization/pending/") ||
      location.pathname.startsWith("/pricing") ||
      location.pathname.startsWith("/checkout") ||
      location.pathname.startsWith("/billing/portal/")
    ) {
      logger.debug(
        "authenticated, must complete sign up or billing:",
        location.pathname
      );
      return (
        <AuthorizedUserContent>
          <Topbar onSidebarOpen={handleSidebarOpen} isSignup={true} {...rest} />
          <Sidebar
            onClose={handleSidebarClose}
            open={shouldOpenSidebar}
            variant={isDesktop ? "persistent" : "temporary"}
            isSignup={true}
            pages={pages}
          />
          <main
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              textAlign: "center",
            }}
          >
            {children}
          </main>
        </AuthorizedUserContent>
      );
    }

    // Handle authenticated with any outstanding organization invitations,
    // use the first one (must accept/reject), ignore any others
    if (orgInvitations && orgInvitations.length > 0) {
      const invitation = orgInvitations[0];
      var redirect = undefined;

      if (invitation.asset.type === "organization") {
        redirect = `/organization/invitation/${invitation.id}`;

        logger.debug(
          "authenticated with pending organization invitation:",
          redirect,
          orgInvitations
        );

        return <Redirect to={redirect} />;
      } else {
        // Note: Must be authenticated AND subscribed for
        //       any other invitation types
        logger.debug(
          "authenticated with pending invitation but need subscription:",
          invitation
        );
      }
    }

    // Handle authenticated, not joining an existing organization,
    // but still need to choose subscription plan
    logger.debug("authenticated, need subscription");
    return <Redirect to="/pricing" />;
  }

  return <></>;
};

Private.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  staticContext: PropTypes.object,
};

export const PrivateWithRouter = withRouter(Private);
