export const getBlobName = (blob) => {
  if (blob.name == "..") {
    return blob.name;
  }

  const isFolder = blob.name.includes("/.bsikeep");
  const idx = isFolder ? 2 : 1;
  const parts = blob.name.split("/");
  return parts[parts.length - idx];
};

export const isFolder = (blob) =>
  blob.metadata && blob.metadata.type === "folder";

const pointCloudExtensions = [
  ".e57",
  ".las",
  ".laz",
  ".npy",
  ".obj",
  ".pcd",
  ".ply",
  ".pts",
  ".ptx",
];
export const isPointCloud = (blob) => {
  const ext =
    blob.name.substring(blob.name.lastIndexOf("."), blob.name.length) || "";

  // TODO: Revisit: Change to API call and cache?
  const found = pointCloudExtensions.find((x) => x === ext);

  return found ? true : false;
};

export const genPath = (data, row) => {
  let parts = data && data.currentPath ? data.currentPath : [];
  parts = row ? parts.concat(row) : parts;

  return parts.map(getBlobName).join("/");
};
