import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { clearSelectedRows, ConfirmationDialog } from "../../components";
import { runTask } from "../../helpers";

import { getBlobName, genPath, useDeleteBlobMutation } from ".";
import { close } from "./deleteBlobSlice";

export const DeleteBlob = ({ blobs }) => {
  const dispatch = useDispatch();
  const { open, rows } = useSelector((state) => state.deleteBlob);
  const [deleteBlob] = useDeleteBlobMutation();

  const { title, confirmation } = useMemo(() => {
    var title = "";
    var confirmation = "";
    var description = "";

    if (rows) {
      description =
        rows.length > 1 ? `${rows.length} items` : `"${getBlobName(rows[0])}"`;

      title = `Delete ${description}?`;

      confirmation = `Are you sure you want to delete ${description}?`;
    }

    return { title, confirmation };
  }, [rows]);

  const onClose = useCallback(
    async (target) => {
      dispatch(close());

      if (!target) {
        return;
      }

      dispatch(clearSelectedRows());

      for (var i = 0; i < rows.length; i++) {
        const blob = rows[i];

        await runTask(
          "Delete",
          genPath(blobs.data, blob),
          async () => await deleteBlob(blob).unwrap()
        );
      }
      blobs.refetch();
    },
    [blobs, deleteBlob, dispatch, rows]
  );

  return (
    <ConfirmationDialog
      open={open}
      cancelTitle="Cancel"
      okTitle="Delete"
      title={title}
      description={confirmation}
      onClose={onClose}
    />
  );
};

DeleteBlob.propTypes = {
  blobs: PropTypes.object.isRequired,
};
