import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import { CardContent, Grid, Switch, Typography } from "@mui/material";

import { StyledCard } from "../../components";
import { useGetMyAccountQuery } from "../account";
import { useSaveMyAccountNotifications } from ".";

export const Alerts = () => {
  const dispatch = useDispatch();

  const { isLoading, data: account } = useGetMyAccountQuery();

  const saveMyAccountNotifications = useSaveMyAccountNotifications();

  const alerts = useMemo(() => {
    if (isLoading) {
      return {};
    }

    return account.notifications.alerts;
  }, [account, isLoading]);

  function handleChange(event) {
    if (isLoading) {
      return;
    }

    const { name, checked } = event.target;
    const newNotifications = {
      ...account.notifications,
      alerts: { ...account.notifications.alerts, [name]: checked },
    };
    dispatch(saveMyAccountNotifications(account.id, newNotifications));
  }

  const title = "Alerts";

  return (
    <StyledCard sx={{ padding: 0 }}>
      <CardContent>
        <Typography variant="h5" sx={{ paddingBottom: 1 }}>
          {title}
        </Typography>
        <Grid container spacing={0} sx={{ padding: 0 }}>
          <Grid item xs={10} md={10} lg={10} xl={10}>
            <Typography sx={{ paddingTop: 1 }}>On account changes</Typography>
          </Grid>
          <Grid item xs={2} md={2} lg={2} xl={2}>
            <Switch
              checked={alerts.onAccountChanges}
              onChange={handleChange}
              color="primary"
              name="onAccountChanges"
              inputProps={{ "aria-label": "primary checkbox" }}
              sx={{ float: "right", margin: 0 }}
            />
          </Grid>

          <Grid item xs={11} md={11} lg={11} xl={11}>
            <Typography sx={{ paddingTop: 1 }}>On shared changes</Typography>
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <Switch
              checked={alerts.onSharedChanges}
              onChange={handleChange}
              color="primary"
              name="onSharedChanges"
              inputProps={{ "aria-label": "primary checkbox" }}
              sx={{ float: "right", margin: 0 }}
            />
          </Grid>

          <Grid item xs={11} md={11} lg={11} xl={11}>
            <Typography sx={{ paddingTop: 1 }}>
              On requests from others
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <Switch
              checked={alerts.onRequestsFromOthers}
              onChange={handleChange}
              color="primary"
              name="onRequestsFromOthers"
              inputProps={{ "aria-label": "primary checkbox" }}
              sx={{ float: "right", margin: 0 }}
            />
          </Grid>

          <Grid item xs={11} md={11} lg={11} xl={11}>
            <Typography sx={{ paddingTop: 1 }}>
              On access from new devices or clients
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <Switch
              checked={alerts.onAccessFromNewClients}
              onChange={handleChange}
              color="primary"
              name="onAccessFromNewClients"
              inputProps={{ "aria-label": "primary checkbox" }}
              sx={{ float: "right", margin: 0 }}
            />
          </Grid>

          <Grid item xs={11} md={11} lg={11} xl={11}>
            <Typography sx={{ paddingTop: 1 }}>
              On recommended actions
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <Switch
              checked={alerts.onRecommendedActions}
              onChange={handleChange}
              color="primary"
              name="onRecommendedActions"
              inputProps={{ "aria-label": "primary checkbox" }}
              sx={{ float: "right", margin: 0 }}
            />
          </Grid>

          <Grid item xs={11} md={11} lg={11} xl={11}>
            <Typography sx={{ paddingTop: 1 }}>On new features</Typography>
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <Switch
              checked={alerts.onNewFeatures}
              onChange={handleChange}
              color="primary"
              name="onNewFeatures"
              inputProps={{ "aria-label": "primary checkbox" }}
              sx={{ float: "right", margin: 0 }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};
