/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

export const CustomRouterLink = forwardRef((props, ref) => {
  // Note: Some non-DOM props are passed in and not valid to children elements
  const { activeClassName, ...rest } = props;
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...rest} />
    </div>
  );
});
