import { api, providesList } from "../api/apiSlice";
import { isNil } from "../../helpers/uuid";

export const shareApi = api.injectEndpoints({
  endpoints: (build) => ({
    getShares: build.query({
      query: () => `share`,
      providesTags: (result) => providesList(result && result.data, "Shares"),
    }),
    getShareAssets: build.query({
      query({ all, include, page, pageSize, sortModel }) {
        var params = {
          p: page,
          rpp: pageSize,
        };

        if (sortModel) {
          params.sortField = sortModel.field;
          params.sortDir = sortModel.sort;
        }

        if (all) {
          params.all = all;
        }

        if (include) {
          params.include = include;
        }

        return {
          url: "share/asset",
          params,
        };
      },
      providesTags: (result) => providesList(result && result.data, "Shares"),
    }),
    getRecentShareAssets: build.query({
      query(totalResults) {
        return {
          url: "share/asset",
          params: {
            p: 0,
            rpp: totalResults,
            sortField: "ts",
            sortDirection: "desc",
          },
        };
      },
      providesTags: (result) => providesList(result && result.data, "Shares"),
    }),
    getShareAsset: build.query({
      query: (assetId) => `share/asset/${assetId}`,
      // eslint-disable-next-line no-unused-vars
      providesTags: ({ id }, _error, _arg) => [{ type: "Shares", id: id }],
    }),
    getShare: build.query({
      query: (id) => `share/${id}`,
      providesTags: (_result, _error, arg) => [{ type: "Shares", id: arg }],
    }),
    getInvitations: build.query({
      query: () => `share/invitation/`,
      providesTags: (result) => providesList(result, "Invitations"),
    }),
    getShareInvitations: build.query({
      query: (id) => `share/${id}/invitation/`,
      providesTags: (result) => providesList(result, "Invitations"),
    }),
    getShareJoinInvitation: build.query({
      query: (invitationId) => `share/join/${invitationId}`,
      providesTags: (_result, _error, { invitationId }) => [
        { type: "Invitations", invitationId },
      ],
    }),
    getShareInvitation: build.query({
      query: ({ id, invitationId }) => `share/${id}/invitation/${invitationId}`,
      providesTags: (_result, _error, { invitationId }) => [
        { type: "Invitations", invitationId },
      ],
    }),
    saveShare: build.mutation({
      query(share) {
        return {
          url: `share/${share.id}`,
          method: "PUT",
          body: share,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Shares", id }],
    }),
    deleteShare: build.mutation({
      query(id) {
        return {
          url: `share/${id}/member`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Shares", id },
        { type: "Shares", id: "LIST" },
      ],
    }),
    acceptShareInvitation: build.mutation({
      query(invitationId) {
        return {
          url: `share/invitation/${invitationId}/accept`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, { invitationId }) =>
        result
          ? [
              { type: "Shares", id: result.id },
              { type: "Invitations", invitationId },
              { type: "Shares", id: "LIST" },
              { type: "Assets", id: "LIST" },
            ]
          : error?.status === 401 || error?.status === 403
          ? ["UNAUTHORIZED"]
          : ["UNKNOWN_ERROR"],
    }),
    rejectShareInvitation: build.mutation({
      query(invitationId) {
        return {
          url: `share/invitation/${invitationId}/reject`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, { invitationId }) =>
        result
          ? [
              { type: "Shares", id: result.id },
              { type: "Invitations", invitationId },
              { type: "Shares", id: "LIST" },
              { type: "Assets", id: "LIST" },
            ]
          : error?.status === 401 || error?.status === 403
          ? ["UNAUTHORIZED"]
          : ["UNKNOWN_ERROR"],
    }),
    inviteShareMember: build.mutation({
      query({ id, invitation }) {
        return {
          url: `share/${id}/invitation`,
          method: "POST",
          body: invitation,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Shares", id },
        { type: "Shares", id: "LIST" },
        { type: "Assets", id: "LIST" },
      ],
    }),
    notifyShareMember: build.mutation({
      query({ id, member }) {
        const memberId = isNil(member.id) ? member.invitationId : member.id;
        return {
          url: `share/${id}/member/${memberId}/notify`,
          method: "PUT",
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Shares", id }],
    }),
    saveShareMember: build.mutation({
      query({ id, member }) {
        const memberId = isNil(member.id) ? member.invitationId : member.id;
        return {
          url: `share/${id}/member/${memberId}`,
          method: "PUT",
          body: member,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Shares", id }],
    }),
    deleteShareMember: build.mutation({
      query({ id, member }) {
        const memberId = isNil(member.id) ? member.invitationId : member.id;
        return {
          url: `share/${id}/member/${memberId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Shares", id },
        { type: "Shares", id: "LIST" },
        { type: "Invitations", id: "LIST" },
        { type: "Assets", id: "LIST" },
        { type: "Tasks", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetShareQuery,
  useGetSharesQuery,
  useGetShareAssetQuery,
  useGetShareAssetsQuery,
  useGetRecentShareAssetsQuery,
  useGetInvitationsQuery,
  useGetShareInvitationsQuery,
  useGetShareInvitationQuery,
  useGetShareJoinInvitationQuery,
  useAcceptShareInvitationMutation,
  useDeleteShareMutation,
  useDeleteShareMemberMutation,
  useInviteShareMemberMutation,
  useNotifyShareMemberMutation,
  useRejectShareInvitationMutation,
  useSaveShareMutation,
  useSaveShareMemberMutation,
} = shareApi;
