import Logger from "js-logger";
import { logToastDebug, logToastError } from "../../components";

import { useGenerateBimMutation, useBlobMutation } from ".";

const logger = Logger.get("useGenerateBim");

export const formatGenerateBimMessage = (blob, data, err) => {
  if ((blob, data)) {
    return (
      `BIM ${blob.name} ` +
      (err === undefined
        ? `generated: ${data.blob.metadata.bimResultLocation}) `
        : `generation failed: ${err}`)
    );
  }
};

export const useGenerateBim = () => {
  const configuration = {
    metadata: {},
  };

  const generateBim = useBlobMutation(
    useGenerateBimMutation,
    (blob, data) => {
      if (data.blob && data.blob.metadata) {
        if (data.blob.metadata.bimResultLocation) {
          logToastDebug(logger, formatGenerateBimMessage(blob, data));
          return;
        } else if (data.blob.metadata.bimError) {
          if (!data.blob.metadata.bimError.includes("in progress")) {
            const err = new Error(data.blob.metadata.bimError);
            logToastError(logger, formatGenerateBimMessage(blob, data, err));
            return;
          }
        }
      }
      const msg = `BIM '${blob.name}' generation in progress`;
      logToastDebug(logger, msg);
    },
    (blob, error) => {
      logToastError(logger, formatGenerateBimMessage(blob, {}, error));
    }
  );

  return (blob) => () => {
    generateBim({ blob, configuration });
  };
};
