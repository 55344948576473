import { createSlice } from "@reduxjs/toolkit";

export const inviteShareSlice = createSlice({
  name: "inviteShare",
  initialState: {
    open: false,
    rows: undefined,
  },
  reducers: {
    open(state, action) {
      state.open = true;
      state.rows = action.payload;
    },
    close(state) {
      state.open = false;
      state.rows = undefined;
    },
  },
});

export const { open, close } = inviteShareSlice.actions;
export const inviteShare = inviteShareSlice.reducer;
