import { api, providesList } from "../api/apiSlice";
import { pollForTasks } from "../task";

export const blobApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBlobs: build.query({
      query({ containerIds, page, pageSize, parentId }) {
        return {
          url: "container/blobs",
          method: "POST",
          body: {
            containerIds,
            parentId,
            p: page,
            rpp: pageSize,
          },
        };
      },
      providesTags: (result) => providesList(result && result.items, "Blobs"),
    }),
    openBlob: build.query({
      query: (blob) => `container/${blob.containerId}/blob/${blob.id}/open/`,
    }),
    previewBlob: build.query({
      query: (blob) => `container/${blob.containerId}/blob/${blob.id}/preview/`,
    }),
    downloadBlob: build.query({
      async queryFn(blobs, _api, _extraOptions, baseQuery) {
        const requests = blobs.map((blob) =>
          baseQuery({
            url: `container/${blob.containerId}/blob/${blob.id}/download/`,
          })
        );

        const responses = await Promise.all(requests);

        const data = responses.map((item) => {
          return item.data;
        });

        return { data };
      },
    }),
    generateFloorplan: build.mutation({
      query: ({ blob, configuration }) => ({
        url: `container/${blob.containerId}/blob/${blob.id}/floorplan/`,
        method: "PUT",
        body: JSON.stringify(configuration),
      }),
    }),
    generateBim: build.mutation({
      query: ({ blob, configuration }) => ({
        url: `container/${blob.containerId}/blob/${blob.id}/bim/`,
        method: "PUT",
        body: JSON.stringify(configuration),
      }),
    }),
    createFolderBlob: build.mutation({
      query: ({ containerId, path }) => ({
        url: `container/${containerId}/folder/`,
        method: "POST",
        body: JSON.stringify({
          fileName: path,
        }),
      }),
    }),
    deleteBlob: build.mutation({
      async queryFn({ id, containerId }, _api, _extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `container/${containerId}/blob/${id}`,
          method: "DELETE",
        });

        const registrationIds = response.data.registrationIds;
        const data = await pollForTasks(registrationIds, baseQuery);

        return { data };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Blobs", id }],
    }),
    copyBlob: build.mutation({
      async queryFn({ blob, target }, _api, _extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `container/${blob.containerId}/blob/${blob.id}/copy/`,
          method: "PUT",
          body: {
            name: target,
            containerId: blob.containerId,
            organizationId: blob.organizationId,
            shareId: blob.shareId,
            parentId: blob.parentId,
          },
        });

        const registrationIds = response.data.registrationIds;
        const data = await pollForTasks(registrationIds, baseQuery);

        return { data };
      },
      invalidatesTags: ["Blobs"],
    }),
    renameBlob: build.mutation({
      async queryFn({ blob, target }, _api, _extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `container/${blob.containerId}/blob/${blob.id}/rename/`,
          method: "PUT",
          body: {
            id: blob.id,
            name: target,
            containerId: blob.containerId,
            organizationId: blob.organizationId,
            shareId: blob.shareId,
          },
        });

        const registrationIds = response.data.registrationIds;
        const data = await pollForTasks(registrationIds, baseQuery);

        return { data };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Blobs", id }],
    }),
  }),
});

export const {
  useGetBlobsQuery,
  useOpenBlobQuery,
  usePreviewBlobQuery,
  useDownloadBlobQuery,
  useGenerateFloorplanMutation,
  useGenerateBimMutation,
  useCreateFolderBlobMutation,
  useDeleteBlobMutation,
  useCopyBlobMutation,
  useRenameBlobMutation,
} = blobApi;
