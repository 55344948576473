import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectSelectedRows } from "../../components";
import { isEditableRole } from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";

export const useSelectedTasks = (tasks) => {
  const { data: account } = useGetMyAccountQuery();
  const { data: organization } = useGetMyOrganizationQuery();
  const selectedRows = useSelector(selectSelectedRows);

  return useMemo(() => {
    let selectedTasks = [];
    let hasEditableRole = isEditableRole(account, organization, null);
    let isSameOrganization = false;

    if (tasks.data) {
      selectedTasks = tasks.data.data.filter((task) =>
        selectedRows.includes(task.id)
      );
      const selectedOtherOrganization = selectedTasks.filter(
        (x) => x.organizationId !== organization.id
      );
      isSameOrganization = selectedOtherOrganization.length === 0;
    }

    const selectedTask = selectedTasks.length == 1 && selectedTasks[0];
    const hasSelectedTasks = selectedTasks.length > 0;

    return {
      selectedTasks,
      hasEditableRole,
      isSameOrganization,
      selectedTask,
      hasSelectedTasks,
    };
  }, [account, organization, selectedRows, tasks.data]);
};
