/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined";

import { StyledCard } from "../../components";

import {
  AccountResult,
  AssetResult,
  BlobResult,
  GenericResult,
  SearchResultNav,
  useSearches,
} from ".";

export const SearchResultListing = () => {
  const history = useHistory();

  const {
    pageSize,
    setPageSize,
    query,
    fields,
    setFilters,
    isFetching,
    isLoading,
    filtered,
    unfiltered,
  } = useSearches();

  const { showing, title, total } = useMemo(() => {
    const title = (
      <Box sx={{ fontWeight: 700, display: "flex", flex: 1 }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, display: "flex", flex: 1 }}
        >
          Results for:&nbsp;
          {fields &&
            fields.map(([k, v]) => (
              <Box
                key={`${k}:${v}`}
                sx={{ marginTop: "-4px", paddingRight: 1 }}
              >
                <Chip
                  key={`${k}:${v}`}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  label={`${k}:${v.replace(/['"]/g, "")}`}
                />
              </Box>
            ))}
          <Box>{query && query.trim()}</Box>
        </Typography>
      </Box>
    );

    const showing = filtered.items.reduce((acc, cur) => {
      return acc + ((cur.data && cur.data.length) || 0);
    }, 0);

    const total = filtered.items.reduce((acc, cur) => {
      return acc + (cur.count || 0);
    }, 0);

    return {
      showing,
      title,
      total,
    };
  }, [filtered, query, fields]);

  const handleClose = () => {
    history.push("/home");
  };

  const handleShowMore = () => {
    setPageSize(pageSize + 10);
  };

  const renderItem = (item, itemType) => {
    if (itemType === "account") {
      return <AccountResult item={item} />;
    } else if (itemType === "asset") {
      return <AssetResult item={item} />;
    } else if (itemType === "blob") {
      return <BlobResult item={item} />;
    } else {
      return <GenericResult item={item} />;
    }
  };

  return (
    <>
      <StyledCard>
        <CardHeader
          title={title}
          subheader={
            <SearchResultNav filtered={unfiltered} setFilters={setFilters} />
          }
          action={
            <Tooltip title="Close search">
              <IconButton size="small" onClick={handleClose} disabled={false}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <Divider />
      </StyledCard>

      <Box hidden={!isLoading && !isFetching}>
        <LinearProgress />
      </Box>
      <Box hidden={isLoading}>
        <List>
          {filtered &&
            filtered.items &&
            filtered.items.map(
              (item) =>
                item &&
                item.data &&
                item.data.map((d) => (
                  <ListItem
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "block",
                      margin: 0,
                      backgroundColor: "background.default",
                      paddingTop: 1,
                      paddingBottom: 1,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    key={d.id}
                  >
                    {renderItem(d, item.type)}
                  </ListItem>
                ))
            )}
        </List>
      </Box>
      <Box hidden={isLoading || showing >= total}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Button
            disabled={isFetching || isLoading}
            onClick={handleShowMore}
            size="small"
            variant="text"
            sx={{ alignContent: "center" }}
          >
            show more results
          </Button>
        </Box>
      </Box>
      <Box hidden={isFetching || isLoading || showing > 0 || total > 0}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Typography variant="h5">no results found</Typography>
        </Box>
      </Box>
    </>
  );
};
