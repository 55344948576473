import Logger from "js-logger";

const pollingInterval = 1000;
const maxPollingAttempts = 60;
const logger = Logger.get("pollForTasks");

const wait = (ms = pollingInterval) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const loadTaskByRegistrationId = async (baseQuery, registrationId) => {
  const response = await baseQuery({
    url: `task/registration/${registrationId}`,
  });

  return response.data;
};

export const pollForTasks = async (registrationIds, baseQuery) => {
  let errorCount = 0;
  let attempt = 0;
  let ids = registrationIds;

  logger.debug("polling for tasks using registrationIDs", registrationIds);

  while (attempt <= maxPollingAttempts) {
    attempt += 1;

    await wait();

    const id = ids[0];
    logger.debug(`looking up task with registrationID ${id}`);

    try {
      const data = await loadTaskByRegistrationId(baseQuery, id);

      if (data.state === "completed" || data.state === "faulted") {
        if (data.state === "faulted") {
          errorCount += 1;
        }

        if (registrationIds.length > 1) {
          ids.shift();
          logger.debug("there are more registration ids");
          continue;
        }

        logger.debug("no more tasks to poll for");

        return {
          errorCount,
        };
      }
    } catch (error) {
      logger.debug("pollForTasks.loadTask error:", error);
      logger.debug("pollForTasks.loadTask polling...");
    }
  }

  throw Error("timed out");
};
