import React from "react";
import { render } from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import App from "./App"; // eslint-disable-line import/no-named-as-default
import configureAppStore from "./app/store";
import "./index.css";

const store = configureAppStore();

render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>,
  document.getElementById("app")
);
