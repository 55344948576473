import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import {
  CardHeader,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";

import { StyledCard } from "../../components";
import { useGetMyAccountQuery } from "../account";
import { useSaveMyAccountPreferences } from ".";

const dateFormats = [
  "YYYY-MM-DD",
  "YYYY-DD-MM",
  "DD-MM-YYYY",
  "YY-MM-DD",
  "YY-DD-MM",
  "DD-MM-YY",
  "DD-MMM-YYYY",
  "DD-MMM",
  "YYYY/MM/DD",
  "YYYY/DD/MM",
  "DD/MM/YYYY",
  "YY/MM/DD",
  "YY/DD/MM",
  "DD/MM/YY",
  "DD/MMM/YYYY",
  "DD/MMM",
  "MMM DD, YYYY",
  "MMM DD",
  "MMMM DD, YYYY",
  "MMMM DD",
];

export const DateFormat = () => {
  const dateFormatList = dateFormats.map((row) => {
    return {
      value: row,
      text: row + " (" + moment().format(row) + ")",
    };
  });

  const dispatch = useDispatch();

  const { isLoading, data: account } = useGetMyAccountQuery();

  const saveMyAccountPreferences = useSaveMyAccountPreferences();

  const dateFormat = useMemo(() => {
    if (isLoading) {
      return dateFormats[0];
    }

    return account.preferences.dateFormat;
  }, [account, isLoading]);

  function handleChange(event) {
    if (isLoading) {
      return;
    }

    if (event.target.value !== dateFormat) {
      const preferences = {
        ...account.preferences,
        dateFormat: event.target.value,
      };
      dispatch(saveMyAccountPreferences(account.id, preferences));
    }
  }

  const title = "Date Format";
  const description = "Set your date format.";

  if (isLoading) {
    return (
      <>
        <CircularProgress />
      </>
    );
  }

  return (
    <StyledCard sx={{ padding: 0 }}>
      <CardHeader
        title={title}
        subheader={description}
        action={
          <FormControl variant="outlined">
            <Select
              id="select-dateformat-outlined"
              value={dateFormat}
              margin="dense"
              size="small"
              onChange={handleChange}
            >
              {dateFormatList.map((row, index) => {
                const rowId = `dateformat-menu-item-${index}`;
                return (
                  <MenuItem key={rowId} value={row.value}>
                    {row.text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        }
      />
    </StyledCard>
  );
};
