import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UpsertShareAssetDialog } from ".";

import { close } from "./upsertShareAssetSlice";

export const UpsertShareAsset = () => {
  const dispatch = useDispatch();
  const { open, row } = useSelector((state) => state.upsertShareAsset);

  const onClose = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  return <UpsertShareAssetDialog onClose={onClose} open={open} source={row} />;
};
