/* eslint-disable no-unused-vars */
import { api, providesList } from "../api/apiSlice";
import Logger from "js-logger";

var logger = Logger.get("search");

export const parseIntoParameters = (
  query,
  fields,
  metadata,
  page,
  pageSize,
  sortModel
) => {
  var params = {
    q: query,
    p: page,
    rpp: pageSize,
  };

  if (sortModel) {
    params.sortField = sortModel.field;
    params.sortDir = sortModel.sort;
  }

  if (fields) {
    for (const [k, v] of fields) {
      if (!params[k]) {
        params[k] = [];
      }
      params[k].push(v);
    }
  }

  if (metadata) {
    params.metadata = metadata;
  }

  // logger.debug("params:", params);
  return params;
};

export const searchApi = api.injectEndpoints({
  endpoints: (build) => ({
    search: build.query({
      query({ fields, metadata, page, pageSize, query, sortModel }) {
        const params = parseIntoParameters(
          query,
          fields,
          metadata,
          page,
          pageSize,
          sortModel
        );
        return {
          url: "search/",
          method: "GET",
          params,
        };
      },
      providesTags: (result) => providesList(result && result.items, "Search"),
    }),
    getSearchHistory: build.query({
      query({ fields, metadata, page, pageSize, query, sortModel }) {
        const params = parseIntoParameters(
          query,
          fields,
          metadata,
          page,
          pageSize,
          sortModel
        );
        return {
          url: "search/history/",
          method: "GET",
          params,
        };
      },
      providesTags: (result) =>
        providesList(result && result.items, "SearchHistory"),
    }),
    deleteAllSearchHistory: build.mutation({
      query() {
        return {
          url: `search/history/`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "SearchHistory" }],
    }),
    deleteSearchHistory: build.mutation({
      query({ id }) {
        return {
          url: `search/history/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "SearchHistory", id },
        { type: "SearchHistory", id: "LIST" },
      ],
    }),
    getSearchParameters: build.query({
      query: () => `search/parameter`,
      providesTags: "SearchParameters",
    }),
    getSearchSuggestions: build.query({
      query({ fields, metadata, page, pageSize, query, sortModel }) {
        const params = parseIntoParameters(
          query,
          fields,
          metadata,
          page,
          pageSize,
          sortModel
        );
        return {
          url: "search/suggestion",
          method: "GET",
          params,
        };
      },
      providesTags: (result, error, arg) => [
        { type: "SearchSuggestions", id: arg },
      ],
    }),
  }),
});

export const {
  useDeleteAllSearchHistoryMutation,
  useDeleteSearchHistoryMutation,
  useGetSearchHistoryQuery,
  useGetSearchParametersQuery,
  useGetSearchSuggestionsQuery,
  useSearchQuery,
} = searchApi;
