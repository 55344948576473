/* eslint-disable react/display-name */
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import AssetsIcon from "@mui/icons-material/PagesOutlined";
import SharedAssetsIcon from "@mui/icons-material/ShareOutlined";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import OpenIcon from "@mui/icons-material/OpenInBrowserOutlined";

import { useGetMyAccountQuery } from "../account";
import {
  clearSelectedRows,
  NoMaxWidthTooltip,
  StyledCard,
  StyledDataGrid,
  ViewAsMenu,
} from "../../components";
import { formatTimestampFromNow } from "../../helpers";
import {
  useGetRecentAssetsQuery,
  useOpenShareAsset,
  useUpsertShareAsset,
} from ".";

export const RecentAssets = () => {
  const dispatch = useDispatch();

  const [pageSize] = useState(5);

  const { data: account } = useGetMyAccountQuery();
  const openAsset = useOpenShareAsset();
  const upsertShareAsset = useUpsertShareAsset();

  const { isLoading, data, refetch } = useGetRecentAssetsQuery(pageSize);

  const title = "Recent Built Asset Activity";

  const onRefresh = () => {
    refetch();
    dispatch(clearSelectedRows());
  };

  const renderMoreMenu = (row) => {
    let moreMenu = [
      <GridActionsCellItem
        icon={
          <Tooltip title={<div>Open built asset</div>}>
            <div>{<OpenIcon sx={{ color: "icon" }} />}</div>
          </Tooltip>
        }
        key="more-menu-open"
        label="Open"
        onClick={() => openAsset(row)}
        showInMenu
      />,
    ];

    return moreMenu;
  };

  const onCellClick = (params, event) => {
    event.defaultMuiPrevented = true;

    if (params.field !== "moreMenu") {
      openAsset(params.row);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      description: "Built asset name",
      flex: 1,
      width: 500,
      minWidth: 200,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        const isOrgAsset = account.organizationId === params.row.organizationId;
        const title = params.row.name + (isOrgAsset ? "" : " (shared with me)");
        return (
          <NoMaxWidthTooltip title={title}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                {isOrgAsset ? (
                  <AssetsIcon
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                ) : (
                  <SharedAssetsIcon
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                )}
              </Grid>

              <Grid item>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                    minWidth: "200px",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" noWrap={true}>
                    {params.row.name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </NoMaxWidthTooltip>
        );
      },
    },
    {
      field: "ts",
      headerName: "Modified",
      description: "Last modified",
      flex: 0.25,
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Tooltip
          title={
            "Last modified " +
            formatTimestampFromNow(
              params.row.ts * 1000,
              account.preferences.dateFormat
            )
          }
        >
          <Typography scope="row" variant="body1" noWrap={true}>
            {formatTimestampFromNow(
              params.row.ts * 1000,
              account.preferences.dateFormat
            )}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "moreMenu",
      type: "actions",
      headerName: "",
      flex: 0.1,
      midWidth: 50,
      editable: false,
      getActions: (params) => renderMoreMenu(params.row),
      renderHeader: () => <ViewAsMenu />,
    },
  ];

  const handleCreateNewAsset = () => {
    dispatch(upsertShareAsset());
  };

  const NoRowsOverlay = () => {
    return (
      <Box
        sx={{
          display: "flex",
          marginTop: "80px",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No built assets found.{" "}
        <Button color="primary" variant="text" onClick={handleCreateNewAsset}>
          Create
        </Button>{" "}
        a built asset to to get started.
      </Box>
    );
  };

  return (
    <StyledCard>
      <CardHeader
        title={title}
        action={
          <IconButton
            size="small"
            id="refreshIcon"
            onClick={onRefresh}
            sx={{ justifyContent: "flex-end" }}
          >
            <RefreshIcon />
          </IconButton>
        }
      />
      <CardContent
        sx={{
          padding: "0px !important",
          margin: "0px !important",
          alignItems: "center",
          marginBottom: "-25px",
        }}
      >
        <StyledDataGrid
          autoHeight
          columns={columns}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableSelectionOnClick
          hideFooter
          hideFooterPagination
          hideFooterRowCount
          hideFooterSelectedRowCount
          loading={isLoading}
          onCellClick={onCellClick}
          rows={data && data.data ? data.data : []}
        />
      </CardContent>
    </StyledCard>
  );
};
