import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStore } from "react-redux";

import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Box, Button, Link, Typography } from "@mui/material";
import ReportIcon from "@mui/icons-material/ReportProblem";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ErrorBoundary } from "react-error-boundary";

import Logger from "js-logger";

import "react-perfect-scrollbar/dist/css/styles.css";

const THEME_CURRENT = "theme";
const THEME_LIGHT = "light";
const THEME_DARK = "dark";

import { darkTheme, lightTheme } from "./theme";

import { Auth, AuthContext, ColorModeContext } from "./components";
import { auth0 as auth0Config, version, logLevel } from "./config.json";
import { TaskManager, TasksContext } from "./workers";

import Routes from "./app/Routes";

const browserHistory = createBrowserHistory();

const MainApp = () => {
  var loggerLevel = Logger.OFF;
  const level = logLevel.toLowerCase();
  if (level == "trace") loggerLevel = Logger.TRACE;
  else if (level == "debug") loggerLevel = Logger.DEBUG;
  else if (level == "info") loggerLevel = Logger.INFO;
  else if (level == "warning") loggerLevel = Logger.WARN;
  else if (level == "error") loggerLevel = Logger.ERROR;
  Logger.useDefaults({
    defaultLevel: loggerLevel,
    formatter: function (messages, context) {
      if (context.name) {
        messages.unshift(`(${context.name})`);
      }
      messages.unshift(`[${context.level.name}]`);
      messages.unshift(new Date().toISOString());
    },
  });
  var logger = Logger.get("App");
  logger.info("Version:", version.long);

  const [auth] = useState(
    new Auth(
      browserHistory,
      auth0Config.domain,
      auth0Config.clientId,
      auth0Config.callbackUrl,
      auth0Config.audienceUrl
    )
  );

  const [taskManager] = useState(new TaskManager(useStore(), auth));

  const theme = useTheme();

  function fallbackRender({ error }) {
    return (
      <Box sx={{ padding: 2, width: "100%" }}>
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Box sx={{ color: "red", align: "center", zoom: 2 }}>
            <ReportIcon />
          </Box>
          <Typography variant="h3">Oops! Something went wrong.</Typography>
          <br />
          <Typography variant="body1" sx={{ color: "red" }}>
            <strong>Error:</strong> {error.message}
          </Typography>
          <br />
          <Link
            href="/"
            onClick={() => {
              window.open("#", "_self");
            }}
            variant="body1"
            sx={{ textTransform: "uppercase" }}
          >
            return to application
          </Link>
          <br />
          <br />
          <Typography variant="body1">
            If you continue to have problems, please contact{" "}
            <Button
              variant="text"
              color="primary"
              target="_top"
              rel="noopener noreferrer"
              href={`mailto:support@builtstream.com`}
              // classes={{
              //   label: classes.supportButton,
              // }}
              sx={{ textTransform: "none", padding: 0, margin: 0 }}
            >
              support@builtstream.com
            </Button>{" "}
            to troubleshoot.
          </Typography>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    if (!auth.isAuthenticated()) {
      logger.debug("useEffect.!isAuthenticated, clearing session");
      auth.clearSession();
      return;
    }

    auth.renewToken(() => {
      logger.debug("useEffect.renewToken");
    });
  }, [auth, auth.isAuthenticated, logger]);

  useEffect(() => {
    logger.debug("theme:", theme);
    logger.debug("theme.palette.mode:", theme.palette.mode);
  }, [theme, logger]);

  return (
    <div>
      <TasksContext.Provider value={taskManager}>
        <AuthContext.Provider value={auth}>
          <Router history={browserHistory}>
            <ErrorBoundary fallbackRender={fallbackRender}>
              <Routes />
            </ErrorBoundary>
            <ToastContainer
              pauseOnHover
              position="bottom-right"
              autoClose={3000}
              hideProgressBar
              limit={3}
              style={{ width: "40%" }}
              theme={
                theme && theme.palette && theme.palette.mode
                  ? theme.palette.mode
                  : "light"
              }
            />
          </Router>
        </AuthContext.Provider>
      </TasksContext.Provider>
    </div>
  );
};

MainApp.propTypes = {
  history: PropTypes.object,
};

const App = () => {
  const prevMode = JSON.parse(localStorage.getItem(THEME_CURRENT));
  const [mode, setMode] = useState(prevMode ? prevMode : THEME_LIGHT);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) =>
          prevMode === THEME_LIGHT ? THEME_DARK : THEME_LIGHT
        );
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const theme = useMemo(() => {
    return mode === THEME_DARK ? darkTheme : lightTheme;
  }, [mode]);

  useEffect(() => {
    localStorage.setItem(THEME_CURRENT, JSON.stringify(mode));
  }, [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Helmet
          bodyAttributes={{
            style: "background-color:" + theme.palette.background.default,
          }}
        />
        <MainApp />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
