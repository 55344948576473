import React, {
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Logger from "js-logger";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PagesIcon from "@mui/icons-material/PagesOutlined";

import { isEditableRole, isOwnerRole } from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";
import { useGetShareAssetQuery } from "../share";
import { logToastDebug, logToastError, useInput } from "../../components";
import { useSaveAssetMutation, TagForm } from ".";

const shouldSave = (originalAsset, newAsset) => {
  if (
    !originalAsset &&
    newAsset &&
    newAsset.asset &&
    newAsset.asset.name !== ""
  ) {
    return true;
  }

  if (
    originalAsset &&
    originalAsset.asset &&
    newAsset &&
    newAsset.asset &&
    (originalAsset.asset.name !== newAsset.asset.name ||
      originalAsset.asset.key !== newAsset.asset.key ||
      originalAsset.asset.description !== newAsset.asset.description ||
      (originalAsset.asset.addresses &&
        newAsset.asset.addresses &&
        originalAsset.asset.addresses.length > 0 &&
        newAsset.asset.addresses.length > 0 &&
        originalAsset.asset.addresses[0].address !==
          newAsset.asset.addresses[0].address))
  ) {
    return true;
  }

  return false;
};

const logger = Logger.get("UpsertShareAssetDialog");

export const UpsertShareAssetDialog = (props) => {
  const { onClose, open, source } = props;
  const history = useHistory();

  const nameFieldRef = useRef(null);
  const keyFieldRef = useRef(null);

  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");

  const { data: account } = useGetMyAccountQuery();
  const { data: organization } = useGetMyOrganizationQuery();

  const { data: existing } = useGetShareAssetQuery(source && source.assetId, {
    skip: source === undefined || source.assetId === undefined,
  });

  const [saveAsset] = useSaveAssetMutation();

  const name = useInput(existing && existing.asset ? existing.asset.name : "");
  const key = useInput(existing && existing.asset ? existing.asset.key : "");
  const description = useInput(
    existing && existing.asset ? existing.asset.description : ""
  );
  const address = useInput(
    existing &&
      existing.asset &&
      existing.asset.addresses &&
      existing.asset.addresses.length > 0
      ? existing.asset.addresses[0].address
      : ""
  );

  const onSave = useCallback(
    async (changed) => {
      var link = undefined;
      if (changed.asset.id) {
        link = `/asset/${changed.asset.id}`;
      }
      try {
        setSaving(true);

        const updatedAsset = await saveAsset(changed.asset).unwrap();

        if (!existing) {
          link = `/asset/${updatedAsset.id}`;

          history.push(link);
          return;
        }
        const msg = "updated built asset '" + changed.asset.name + "'";
        logToastDebug(logger, msg, link);
      } catch (err) {
        const msg = "update built asset '" + changed.asset.name + "' failed";
        logToastError(logger, msg, err, link);
      } finally {
        setSaving(false);
      }
    },
    [existing, history, saveAsset]
  );

  const created = useMemo(
    () =>
      existing &&
      existing.id !== null &&
      existing.asset !== undefined &&
      existing.asset.container !== null,
    [existing]
  );

  const title = useMemo(() => {
    return (created ? "Edit" : "Create") + " Built Asset";
  }, [created]);

  const { canUpsert } = useMemo(() => {
    let canUpsert = true;

    if (existing) {
      const canEdit =
        organization &&
        (existing === undefined ||
          (existing !== undefined &&
            organization.id === existing.organizationId)) &&
        isEditableRole(account, organization, existing);
      const owner = isOwnerRole(account, organization, existing);
      canUpsert = canEdit || owner;
    }

    return { canUpsert };
  }, [account, organization, existing]);

  const updated = useMemo(() => {
    let newAddress = {};
    let existingAsset = {};

    if (existing && existing.asset) {
      existingAsset = {
        ...existing.asset,
      };
      if (existing.asset.addresses.length > 0) {
        newAddress = { ...existing.asset.addresses[0] };
      }
    }
    newAddress.address = address.value;

    const newData = {
      asset: {
        ...existingAsset,
        name: name.value,
        key: key.value,
        description: description.value,
        addresses: [newAddress],
      },
    };

    const updated = {
      ...existing,
      ...newData,
    };

    return updated;
  }, [existing, name, key, description, address]);

  const handleAssetChanged = useCallback(() => {
    setDirty(shouldSave(existing, updated));
  }, [existing, updated]);

  const handleSave = useCallback(() => {
    if (dirty) {
      onSave(updated);

      if (onClose) {
        onClose(true);
      }
    }
  }, [dirty, onClose, onSave, updated]);

  const handleOpen = () => {
    setDirty(false);
    setSaving(false);
    setErrorMessage("");
    setInfoMessage("");

    if (existing && existing.asset) {
      name.setValue(existing.asset.name);
      description.setValue(existing.asset.description);
      key.setValue(existing.asset.key);
      address.setValue(
        existing.asset.addresses && existing.asset.addresses.length > 0
          ? existing.asset.addresses[0].address
          : ""
      );
    }
  };

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose(true);
    }
  }, [onClose]);

  useEffect(() => {
    if (!created && nameFieldRef.current) {
      nameFieldRef.current.focus();
    }
  }, [existing, created, nameFieldRef, keyFieldRef]);

  return (
    <Dialog
      open={open}
      TransitionProps={{
        onEnter: handleOpen,
      }}
      maxWidth="xl"
      fullWidth={true}
      sx={{
        flex: 1,
        paddingTop: 2,
      }}
    >
      <DialogTitle>
        <PagesIcon sx={{ float: "left" }} />
        <Box sx={{ marginLeft: "30px", marginTop: "5px" }}>{title}</Box>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 1,
          top: 1,
          // color: theme.palette.grey[500],
          color: "grey[500]",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <TextField
          fullWidth
          label="Name"
          margin="dense"
          size="small"
          name="name"
          disabled={!canUpsert || saving}
          onBlur={handleAssetChanged}
          required={true}
          variant="outlined"
          inputRef={nameFieldRef}
          value={name.value}
          onChange={name.onChange}
          sx={{ width: "100%" }}
        />

        <TextField
          fullWidth
          label="Built Asset ID"
          margin="dense"
          size="small"
          name="key"
          disabled={!canUpsert || saving}
          onBlur={handleAssetChanged}
          required={false}
          variant="outlined"
          inputRef={keyFieldRef}
          value={key.value}
          onChange={key.onChange}
          sx={{ width: "100%" }}
        />

        <TextField
          fullWidth
          label="Description"
          margin="dense"
          size="small"
          name="description"
          disabled={!canUpsert || saving}
          onBlur={handleAssetChanged}
          required={false}
          variant="outlined"
          value={description.value}
          onChange={description.onChange}
          sx={{ width: "100%" }}
        />

        {/* TODO: Revisit: array of addresses */}
        {/*
                TODO: Validate address and geocoding with Azure Maps Search services
                https://docs.microsoft.com/en-us/azure/azure-maps/how-to-search-for-address
              */}

        <TextField
          fullWidth
          label="Address"
          margin="dense"
          size="small"
          name="address"
          disabled={!canUpsert || saving}
          onBlur={handleAssetChanged}
          required={false}
          variant="outlined"
          value={address.value}
          onChange={address.onChange}
          sx={{ width: "100%" }}
        />

        <div hidden={!created}>
          <TagForm shareAsset={source} />
        </div>
        <Box sx={{ display: "flex", paddingTop: 2 }}>
          <Typography
            hidden={!errorMessage}
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              margin: 0,
              padding: 2,
              width: "100%",
              // color: theme.palette.error.main,
              color: "error.main",
            }}
          >
            {errorMessage}
          </Typography>
          <Typography hidden={!infoMessage}>{infoMessage}</Typography>
          <Button
            variant="outlined"
            autoFocus
            onClick={handleClose}
            sx={{ marginRight: 1 }}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="outlined"
            autoFocus
            onClick={handleSave}
            disabled={saving || name.length == 0 || !dirty}
            sx={{ marginRight: 0 }}
          >
            {saving ? "Saving..." : "Save"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

UpsertShareAssetDialog.propTypes = {
  source: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
