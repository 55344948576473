import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { LoadingCircularProgress, StyledCard } from "../../components";
import { useGetMyAccountQuery } from "../account";
import { useSaveMyAccountNotifications } from ".";

const deliveryActiveList = [
  { value: "always", text: "Always" },
  { value: "off", text: "Off" },
];

const deliveryMobileList = [
  { value: "immediately", text: "Immediately" },
  {
    value: "inactive",
    text: "When inactive on desktop and web",
  },
  {
    value: "inactive 1 min",
    text: "After inactive for 1 minute",
  },
  {
    value: "inactive 5 min",
    text: "After inactive for 5 minutes",
  },
  {
    value: "inactive 10 min",
    text: "After inactive for 10 minutes",
  },
  {
    value: "inactive 15 min",
    text: "After inactive for 15 minutes",
  },
  {
    value: "inactive 20 min",
    text: "After inactive for 20 minutes",
  },
  {
    value: "inactive 30 min",
    text: "After inactive for 30 minutes",
  },
  {
    value: "off",
    text: "Off",
  },
];

const deliveryEmailList = [
  { value: "always", text: "Always" },
  {
    value: "every 15 min",
    text: "Once every 15 minutes",
  },
  {
    value: "every hour",
    text: "Once every hour",
  },
  {
    value: "every day",
    text: "Once every day",
  },
  {
    value: "off",
    text: "Off",
  },
];

export const Delivery = () => {
  const dispatch = useDispatch();

  const { isLoading, data: account } = useGetMyAccountQuery();

  const saveMyAccountNotifications = useSaveMyAccountNotifications();

  const delivery = useMemo(() => {
    if (isLoading) {
      return {};
    }

    return account.notifications.delivery;
  }, [account, isLoading]);

  function handleChange(event) {
    if (isLoading) {
      return;
    }

    const { name, value } = event.target;
    // logger.debug("handleChange(): name, value: ", name, value);
    const newNotifications = {
      ...account.notifications,
      delivery: { ...delivery, [name]: value },
    };
    dispatch(saveMyAccountNotifications(account.id, newNotifications));
  }

  if (isLoading) {
    return <LoadingCircularProgress />;
  }

  return (
    <StyledCard sx={{ padding: 0 }}>
      <CardContent>
        <Grid container spacing={1} sx={{ padding: 0 }}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Typography variant="h5" sx={{ paddingTop: 1 }}>
              Delivery
            </Typography>
          </Grid>

          <Grid item xs={6} md={8} lg={8} xl={9}>
            <Typography sx={{ paddingTop: 1 }}>
              Send notifications to my active web and desktop clients
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} lg={4} xl={3}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <Select
                id="select-active-outlined"
                value={delivery.sendActiveClient}
                name="sendActiveClient"
                margin="dense"
                size="small"
                onChange={handleChange}
              >
                {deliveryActiveList.map((row, index) => {
                  const rowId = `active-menu-item-${index}`;
                  return (
                    <MenuItem key={rowId} value={row.value}>
                      {row.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} md={8} lg={8} xl={9}>
            <Typography sx={{ paddingTop: 1 }}>
              Send notifications to my mobile devices
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} lg={4} xl={3}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <Select
                id="select-mobile-outlined"
                value={delivery.sendMobileDevices}
                name="sendMobileDevices"
                margin="dense"
                size="small"
                onChange={handleChange}
              >
                {deliveryMobileList.map((row, index) => {
                  const rowId = `mobile-menu-item-${index}`;
                  return (
                    <MenuItem key={rowId} value={row.value}>
                      {row.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} md={8} lg={8} xl={9}>
            <Typography sx={{ paddingTop: 1 }}>
              Send notifications to my email accounts
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} lg={4} xl={3}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <Select
                id="select-email-outlined"
                value={delivery.sendEmail}
                name="sendEmail"
                margin="dense"
                size="small"
                onChange={handleChange}
              >
                {deliveryEmailList.map((row, index) => {
                  const rowId = `email-menu-item-${index}`;
                  return (
                    <MenuItem key={rowId} value={row.value}>
                      {row.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};
