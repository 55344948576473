import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import { CardHeader, FormControl, MenuItem, Select } from "@mui/material";

import { StyledCard } from "../../components";
import { useGetMyAccountQuery } from "../account";
import { useSaveMyAccountPreferences } from ".";

// TODO: Revisit: adding other languages
const languageList = ["English (United States)"];

export const Language = () => {
  const dispatch = useDispatch();
  const { isLoading, data: account } = useGetMyAccountQuery();

  const saveMyAccountPreferences = useSaveMyAccountPreferences();

  const lang = useMemo(() => {
    if (isLoading) {
      return languageList[0];
    }

    return account.preferences.language;
  }, [account, isLoading]);

  function handleChange(event) {
    if (isLoading) {
      return;
    }

    if (event.target.value !== lang) {
      const preferences = {
        ...account.preferences,
        language: event.target.value,
      };
      dispatch(saveMyAccountPreferences(account.id, preferences));
    }
  }

  const title = "Language";
  const description = "Set your default language.";

  return (
    <StyledCard sx={{ padding: 0 }}>
      <CardHeader
        title={title}
        subheader={description}
        action={
          <FormControl variant="outlined">
            <Select
              id="select-language-outlined"
              value={lang}
              margin="dense"
              size="small"
              onChange={handleChange}
            >
              {languageList.map((row, index) => {
                const rowId = `language-menu-item-${index}`;
                return (
                  <MenuItem key={rowId} value={row}>
                    {row}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        }
      />
    </StyledCard>
  );
};
