/* eslint-disable no-unused-vars */
import { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Logger from "js-logger";
import { useSelector } from "react-redux";

import { selectPageSize } from "../../components";
import { parseQuery, useSearchQuery } from ".";

const logger = Logger.get("searches");

export const useSearches = () => {
  const { query: paramQuery } = useParams();
  // logger.debug("useSearches query:", paramQuery);

  const location = useLocation();
  // logger.debug("useSearches location:", location);

  const [previous, setPrevious] = useState("");

  const [defaultPage] = useState(0);
  const [defaultPageSize] = useState(useSelector(selectPageSize));
  const [defaultSortModel] = useState({
    field: "ts",
    sort: "desc",
  });

  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [sortModel, setSortModel] = useState(defaultSortModel);

  const [filters, setFilters] = useState(null);

  const { fields, metadata, query } = useMemo(() => {
    const { query: parsedQuery, fields: parsedFields } = parseQuery(paramQuery);

    // const searchParams = new URLSearchParams(paramQuery);
    // logger.debug("useSearches searchParams:", searchParams);

    const urlSearchParams = new URLSearchParams(location.search);
    // logger.debug("useSearches urlSearchParams:", urlSearchParams);

    var params = [];
    for (const [k, v] of urlSearchParams.entries()) {
      params.push([k, v]);
    }

    if (parsedFields && parsedFields.length > 0) {
      parsedFields.forEach((x) => params.push(x));
    }
    // logger.debug("useSearches params:", params);

    setPage(defaultPage);
    setPageSize(defaultPageSize);
    setSortModel(defaultSortModel);

    var metadata = null;
    var fields = [];
    for (const [k, v] of params) {
      if (k === "p") {
        setPage(v);
      } else if (k === "rpp") {
        setPageSize(v);
      } else if (k === "sortField") {
        setSortModel((prev) => ({
          ...prev,
          field: v,
        }));
      } else if (k === "sortDir") {
        setSortModel((prev) => ({
          ...prev,
          sort: v,
        }));
      } else if (k === "metadata") {
        metadata = v;
      } else {
        fields.push([k, v]);
      }
    }

    return {
      fields,
      metadata,
      query: parsedQuery,
    };
  }, [defaultPage, defaultPageSize, defaultSortModel, paramQuery, location]);

  const {
    isFetching,
    isLoading,
    data: unfiltered,
  } = useSearchQuery({
    query,
    page,
    pageSize,
    sortModel,
    fields,
    metadata,
  });

  const { isNewSearch } = useMemo(() => {
    const isNewSearch = previous !== paramQuery;

    if (isNewSearch && !isFetching && !isLoading) {
      setPrevious(paramQuery);
    }

    return {
      isNewSearch,
    };
  }, [isFetching, isLoading, paramQuery, previous]);

  const filtered = useMemo(() => {
    var filtered = {
      count: 0,
      items: [],
    };
    if (isNewSearch && isFetching) {
      return filtered;
    } else if (unfiltered) {
      if (filters) {
        // logger.debug("using filters:", filters);

        for (const [k, v] of Object.entries(filters)) {
          if (k === "type") {
            const items = unfiltered.items.filter((x) => x.type === v);
            items.forEach((x) => {
              filtered["count"] += x.count || 0;
              filtered["items"].push(x);
            });
          }
        }
        return filtered;
      }

      // logger.debug("using no filters:", unfiltered);
      return unfiltered;
    }
    return filtered;
  }, [filters, isFetching, isNewSearch, unfiltered]);

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    sortModel,
    setSortModel,
    filters,
    setFilters,
    query,
    fields,
    metadata,
    isFetching,
    isLoading,
    filtered,
    unfiltered,
  };
};
