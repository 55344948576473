import { createSlice } from "@reduxjs/toolkit";

export const copyBlobSlice = createSlice({
  name: "copyBlob",
  initialState: {
    open: false,
    row: undefined,
  },
  reducers: {
    open(state, action) {
      state.open = true;
      state.row = action.payload.row;
    },
    close(state) {
      state.open = false;
      state.row = undefined;
    },
  },
});

export const { open, close } = copyBlobSlice.actions;
export const copyBlob = copyBlobSlice.reducer;
