import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Chart } from "react-google-charts";
import { colors, CardContent, CardHeader, Typography } from "@mui/material";

import { LoadingCircularProgress, StyledCard } from "../../components";
import { convertByteNumberByUnit } from "../../helpers";

import { useGetContainersUsageQuery } from "../blobs";

export const AssetChart = (props) => {
  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("BarChart");
  const [units, setUnits] = useState("GiB");
  const [used, setUsed] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [title, setTitle] = useState("Top Built Assets");
  // eslint-disable-next-line no-unused-vars
  const [topN, setTopN] = useState(5);

  const { isLoading, data } = useGetContainersUsageQuery(
    { top: topN },
    { skip: !topN }
  );

  const options = {
    chartArea: { width: "100%", height: "75%" },
    isStacked: true,
    hAxis: {
      title: "",
      minValue: 0,
      maxValue: maxValue,
      viewWindowMode: "maximized",
    },
    vAxis: {
      title: "",
      ticks: "",
    },
    colors: [
      colors.blue[400],
      colors.orange[400],
      colors.green[400],
      colors.blueGrey[100],
      colors.red[500],
    ],
    legend: { position: "bottom" },
    ticks: "",
  };

  const formatLabel = (asset, units) => {
    const filesUsed = asset.quota.filesUsed || 0.0;
    const dataUsed = asset.quota.dataUsed || 0.0;
    const sharedUsed = asset.quota.sharedUsed || 0.0;
    const total = convertByteNumberByUnit(
      filesUsed + dataUsed + sharedUsed,
      units
    );
    var label = "'" + asset.name + "' " + total + " " + units;
    var details = [];
    if (filesUsed > 0) {
      details.push("files " + convertByteNumberByUnit(filesUsed, units));
    }
    if (dataUsed > 0) {
      details.push("data " + convertByteNumberByUnit(dataUsed, units));
    }
    if (sharedUsed > 0) {
      details.push("shared " + convertByteNumberByUnit(sharedUsed, units));
    }
    if (details.length > 0) {
      label += " (" + details.join(",") + ")";
    }
    return label;
  };

  const calculateValue = (asset, units) => {
    const filesUsed = asset.quota.filesUsed || 0.0;
    const dataUsed = asset.quota.dataUsed || 0.0;
    const sharedUsed = asset.quota.sharedUsed || 0.0;
    const value = convertByteNumberByUnit(
      filesUsed + dataUsed + sharedUsed,
      units
    );
    return value;
  };

  const add = (accumulator, value) => {
    if (value) {
      return accumulator + value;
    }
    return accumulator;
  };

  const compareAssetQuota = (a, b) => {
    const qa =
      (a.quota.filesUsed === undefined ? 0 : a.quota.filesUsed) +
      (a.quota.dataUsed === undefined ? 0 : a.quota.dataUsed) +
      (a.quota.sharedUsed === undefined ? 0 : a.quota.sharedUsed);
    const qb =
      (b.quota.filesUsed === undefined ? 0 : b.quota.filesUsed) +
      (b.quota.dataUsed === undefined ? 0 : b.quota.dataUsed) +
      (b.quota.sharedUsed === undefined ? 0 : b.quota.sharedUsed);

    if (qa > qb) {
      return -1;
    }
    return 1;
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setTitle("Top " + topN + " Built Assets");

    const u = units;
    const largest =
      data && data.data
        ? data.data.slice().sort(compareAssetQuota).slice(0, topN)
        : [];

    const filesUsed = largest.reduce((a, x) => add(a, x.quota.filesUsed), 0);
    const sharedUsed = largest.reduce((a, x) => add(a, x.quota.sharedUsed), 0);
    const dataUsed = largest.reduce((a, x) => add(a, x.quota.dataUsed), 0);

    const filesAll =
      data && data.data
        ? data.data.reduce((a, x) => add(a, x.quota.filesUsed), 0)
        : 0;
    const sharedAll =
      data && data.data
        ? data.data.reduce((a, x) => add(a, x.quota.sharedUsed), 0)
        : 0;
    const dataAll =
      data && data.data
        ? data.data.reduce((a, x) => add(a, x.quota.dataUsed), 0)
        : 0;

    const filesOther = filesAll - filesUsed;
    const sharedOther = sharedAll - sharedUsed;
    const dataOther = dataAll - dataUsed;

    var labels = largest.map((x) => formatLabel(x, u)) || [];
    var values = largest.map((x) => calculateValue(x, u)) || [];

    var assetOther = {
      name: "Others",
      quota: {
        filesUsed: filesOther,
        sharedUsed: sharedOther,
        dataUsed: dataOther,
      },
    };
    labels.push(formatLabel(assetOther, u));
    values.push(calculateValue(assetOther, u));

    var value = [labels, values];
    if (
      typeof props.chartType === "undefined" ||
      props.chartType === null ||
      props.chartType === "BarChart"
    ) {
      setChartType("BarChart");
    } else {
      setChartType(props.chartType);
      value = [
        ["Built Asset", "Storage"],
        ...labels.map((x, i) => {
          return [x, values[i]];
        }),
      ];
    }

    let used = convertByteNumberByUnit(filesUsed + sharedUsed + dataUsed, u);
    let total = convertByteNumberByUnit(filesAll + sharedAll + dataAll, u);

    setUnits(u);
    setChartData(value);
    setMaxValue(total);
    setUsed(used);
  }, [props, isLoading, data, topN, units]);

  if (isLoading) {
    return <LoadingCircularProgress />;
  }

  return (
    <StyledCard sx={{ padding: 0 }}>
      <CardHeader
        title={title}
        action={
          <Typography
            variant="body2"
            sx={{
              paddingRight: 1,
              paddingTop: 1,
            }}
          >
            {used} {units} Total
          </Typography>
        }
      />
      <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
        {data === undefined || data.data === undefined ? (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              flexWrap: "wrap",
              alignContent: "space-around",
              justifyContent: "center",
              width: "100%",
              height: "250px",
            }}
          >
            No organization built assets found
          </Typography>
        ) : maxValue === 0.0 ? (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              flexWrap: "wrap",
              alignContent: "space-around",
              justifyContent: "center",
              width: "100%",
              height: "250px",
            }}
          >
            No data found for organization built assets
          </Typography>
        ) : (
          <Chart
            width={"100%"}
            height={"250px"}
            chartType={chartType}
            loader={<LoadingCircularProgress />}
            data={chartData}
            options={options}
          />
        )}
      </CardContent>
    </StyledCard>
  );
};

AssetChart.propTypes = {
  chartType: PropTypes.string,
};
