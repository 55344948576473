import { combineReducers } from "redux";

import { api } from "../features/api/apiSlice";
import {
  deleteShareAsset,
  copyShareAsset,
  upsertShareAsset,
} from "../features/asset";
import {
  newFolder,
  copyBlob,
  renameBlob,
  deleteBlob,
  uploadBlobs,
} from "../features/blobs";
import { searchInput } from "../features/search";
import { deleteShare, inviteShare } from "../features/share";

import { selectionModel, preferences } from "../components/state";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,

  selectionModel,
  newFolder,
  copyBlob,
  renameBlob,
  deleteBlob,
  uploadBlobs,
  preferences,
  deleteShareAsset,
  copyShareAsset,
  upsertShareAsset,
  searchInput,
  deleteShare,
  inviteShare,
});

export default rootReducer;
