import React from "react";
import { useParams } from "react-router-dom";

import { Box, Grid } from "@mui/material";

import { ProfileImage, ProfileForm } from ".";

export const Profile = () => {
  const { tabId } = useParams();

  if (tabId !== undefined && tabId !== "profile") {
    return <></>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item lg={3} md={3} xl={2} xs={12}>
          <ProfileImage />
        </Grid>
        <Grid item lg={9} md={9} xl={10} xs={12}>
          <ProfileForm />
        </Grid>
      </Grid>
    </Box>
  );
};
