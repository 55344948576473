import React from "react";
import { useParams } from "react-router-dom";

import { Box, Grid } from "@mui/material";

import { DateFormat, Language, TimeZone, MeasurementsForm } from ".";

export const Preferences = () => {
  const { tabId } = useParams();

  if (tabId === undefined || tabId !== "preferences") {
    return <></>;
  }

  return (
    <Box sx={{ height: "100%", padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Language />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <TimeZone />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <DateFormat />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <MeasurementsForm />
        </Grid>
      </Grid>
    </Box>
  );
};
