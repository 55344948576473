import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Breadcrumbs, Link, Typography } from "@mui/material";

import { getBlobName } from ".";

export const BlobPathBreadcrumbs = ({ asset, blobs }) => {
  const assetName = asset ? asset.name : "";

  const handleBreadcrumbClick = (event, data) => {
    event.preventDefault();
    blobs.setParentId(data.id);
  };

  const breadcrumbs = useMemo(() => {
    const data = blobs.data;
    const currentPath = data && data.currentPath ? data.currentPath : [];
    const crumbs = [];

    crumbs.push({
      name: assetName,
      disabled: !currentPath || currentPath.length === 0,
    });

    if (currentPath) {
      crumbs.push(
        ...currentPath.map((p, idx) => {
          return {
            id: p.id,
            name: getBlobName(p),
            disabled: idx == currentPath.length - 1,
          };
        })
      );
    }

    return crumbs;
  }, [assetName, blobs]);

  return (
    <Breadcrumbs sx={{ paddingLeft: 2 }}>
      {breadcrumbs.map((crumb, idx) => {
        return crumb.disabled ? (
          <Typography key={idx}>{crumb.name}</Typography>
        ) : (
          <Link
            key={idx}
            component="button"
            onClick={(event) => handleBreadcrumbClick(event, crumb)}
          >
            {crumb.name}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

BlobPathBreadcrumbs.propTypes = {
  asset: PropTypes.object,
  blobs: PropTypes.object,
};
