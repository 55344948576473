import Logger from "js-logger";
import { logToastDebug, logToastError } from "../../components";

import { http as httpConfig } from "../../config.json";
import { usePreviewBlobQuery, useBlobQuery } from ".";

const logger = Logger.get("usePreviewBlob");

export const formatGeneratePreviewMessage = (blob, err) => {
  if (blob) {
    return (
      `preview ${blob.name} ` +
      (err === undefined
        ? `generated: ${blob.metadata.potreeFormatError}) `
        : `generation failed: ${err}`)
    );
  }
};

export const usePreviewBlob = () =>
  useBlobQuery(
    usePreviewBlobQuery,
    (data) => {
      logger.debug("data:", data);
      const blob = { ...data.blobItem };
      if (data.blobItem && data.blobItem.metadata) {
        logger.debug("data.blobItem.metadata:", data.blobItem.metadata);

        if (data.blobItem.metadata.potreeFormatState == "completed") {
          logger.debug("data.sasToken:", data.sasToken);
          window.open(`${httpConfig.pcviewerUrl}?${data.sasToken}`, "_blank");
          return;
        } else if (
          data.blobItem.metadata.potreeFormatError &&
          !data.blobItem.metadata.potreeFormatError.includes("in progress")
        ) {
          const err = new Error(data.blobItem.metadata.potreeFormatError);
          logToastError(logger, formatGeneratePreviewMessage(blob, err));
          return;
        }
      }
      const msg = `preview ${blob.name} generation in progress`;
      logToastDebug(logger, msg);
      return;
    },
    (blob, err) => {
      logToastError(logger, formatGeneratePreviewMessage(blob, err));
    }
  );
