import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Chart } from "react-google-charts";
import { colors, CardContent, CardHeader, Typography } from "@mui/material";

import { LoadingCircularProgress, StyledCard } from "../../components";
import { useGetMyOrganizationFeaturesQuery } from "../organization";
import { convertByteNumberByUnit, convertUnitsToBytes } from "../../helpers";
import { useGetContainersUsageQuery } from ".";

export const StorageChart = (props) => {
  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("BarChart");
  const [units, setUnits] = useState("GiB");
  const [used, setUsed] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const { isLoading: isLoadingFeatures, data: features } =
    useGetMyOrganizationFeaturesQuery();

  const { isLoading, data } = useGetContainersUsageQuery({ top: 0 });

  const title = "Storage";

  const options = {
    chartArea: { width: "100%", height: "75%" },
    isStacked: true,
    hAxis: {
      title: "",
      minValue: 0,
      maxValue: maxValue,
      viewWindowMode: "maximized",
    },
    vAxis: {
      title: "",
      ticks: "",
    },
    colors: [
      colors.blue[400],
      colors.orange[400],
      colors.green[400],
      colors.blueGrey[100],
      colors.red[500],
    ],
    legend: { position: "bottom" },
    ticks: "",
  };

  useEffect(() => {
    if (isLoading || isLoadingFeatures) {
      return;
    }

    let u = units;
    let filesUsed = 0.0;
    let sharedUsed = 0.0;
    let dataUsed = 0.0;

    if (data) {
      if (data.filesUsed) {
        filesUsed = convertByteNumberByUnit(data.filesUsed, u, 2);
      }
      if (data.sharedUsed) {
        sharedUsed = convertByteNumberByUnit(data.sharedUsed, u, 2);
      }
      if (data.dataUsed) {
        dataUsed = convertByteNumberByUnit(data.dataUsed, u, 2);
      }
    }
    let used = Math.round((filesUsed + sharedUsed + dataUsed) * 100) / 100;
    let allocated = features
      ? convertByteNumberByUnit(
          convertUnitsToBytes(
            features.storage.quota.amount,
            features.storage.quota.units
          ),
          u,
          2
        )
      : used;
    let unused = Math.round(Math.max(allocated - used, 0) * 100) / 100;
    let over = Math.round(Math.max(used - allocated, 0) * 100) / 100;

    const filesLabel = "Files (" + u + ")";
    const sharedLabel = "Shared (" + u + ")";
    const dataLabel = "Data (" + u + ")";
    const unusedLabel = "Unused (" + u + ")";
    const overQuotaLabel = "Over Quota (" + u + ")";
    var value = [
      ["", filesLabel, sharedLabel, dataLabel, unusedLabel, overQuotaLabel],
      ["", filesUsed, sharedUsed, dataUsed, unused, over],
    ];
    if (
      typeof props.chartType === "undefined" ||
      props.chartType === null ||
      props.chartType === "BarChart"
    ) {
      setChartType("BarChart");
    } else {
      setChartType(props.chartType);
      value = [
        ["Type", "Storage"],
        [filesLabel, filesUsed],
        [sharedLabel, sharedUsed],
        [dataLabel, dataUsed],
        [unusedLabel, unused],
        [overQuotaLabel, over],
      ];
    }

    setUnits(u);
    setChartData(value);
    setMaxValue(Math.max(allocated, used));
    setUsed(used);
  }, [props, data, features, isLoading, isLoadingFeatures, units]);

  if (isLoading || isLoadingFeatures) {
    return <LoadingCircularProgress />;
  }

  return (
    <StyledCard sx={{ padding: 0 }}>
      <CardHeader
        title={title}
        action={
          <Typography
            variant="body2"
            sx={{
              paddingRight: 1,
              paddingTop: 1,
            }}
          >
            {used} {units} of {maxValue} {units} Used
          </Typography>
        }
      />
      <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
        <Chart
          width={"100%"}
          height={"250px"}
          chartType={chartType}
          loader={<LoadingCircularProgress />}
          data={chartData}
          options={options}
        />
      </CardContent>
    </StyledCard>
  );
};

StorageChart.propTypes = {
  chartType: PropTypes.string,
};
