import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setPreferencesPage,
  setPreferencesPageSize,
  setPreferencesSortModel,
  resetPreferencesPage,
  resetPreferencesPageSize,
  resetPreferencesSortModel,
} from "../../components";
import { useGetBlobsQuery, getBlobName } from ".";

export const useBlobs = (containerIds) => {
  const [parentId, setParentId] = useState();
  const preferences = useSelector((state) => state.preferences);
  const dispatch = useDispatch();

  const { page, pageSize, sortModel } = useMemo(() => {
    return {
      page: preferences.page,
      pageSize: preferences.pageSize,
      sortModel: preferences.sortModel,
    };
  }, [preferences]);

  const setPage = (newPage) => {
    dispatch(setPreferencesPage(newPage));
  };
  const setPageSize = (newPageSize) => {
    dispatch(setPreferencesPageSize(newPageSize));
  };
  const setSortModel = (newSortModel) => {
    dispatch(setPreferencesSortModel(newSortModel));
  };

  const resetPage = () => {
    dispatch(resetPreferencesPage());
  };
  const resetPageSize = () => {
    dispatch(resetPreferencesPageSize());
  };
  const resetSortModel = () => {
    dispatch(resetPreferencesSortModel());
  };

  const { isLoading, data, refetch } = useGetBlobsQuery(
    { containerIds, page, pageSize, parentId },
    { skip: containerIds.length == 0 }
  );

  const currentPath = useMemo(() => {
    let parts = data && data.currentPath ? data.currentPath : [];

    return parts.map(getBlobName).join("/");
  }, [data]);

  return {
    page,
    resetPage,
    setPage,
    parentId,
    setParentId,
    pageSize,
    resetPageSize,
    setPageSize,
    sortModel,
    resetSortModel,
    setSortModel,
    isLoading,
    data,
    refetch,
    currentPath,
  };
};
