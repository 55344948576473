/* eslint-disable no-prototype-builtins */
import { http as httpConfig } from "../config.json";
import Logger from "js-logger";

const conatinerApiBaseUrl = httpConfig.apiUrl + "/container/";

export function getUploadUrl(containerId) {
  return conatinerApiBaseUrl + containerId + "/blob/";
}
export function getUploadFinalizeUrl(containerId, blobId) {
  return conatinerApiBaseUrl + containerId + "/blob/" + blobId + "/finalize/";
}

const taskApiBaseUrl = httpConfig.apiUrl + "/task/";

export function getTaskUrl() {
  return taskApiBaseUrl;
}

export class QueryableWorker {
  constructor(workerScript, defaultListener, onError) {
    this.logger = Logger.get("QueryableWorker");
    var instance = this;
    var worker = new workerScript();
    var listeners = {};
    this.status = "available";

    this.defaultListener = defaultListener;

    if (onError) {
      worker.onerror = onError;
    }

    this.postMessage = function (message) {
      worker.postMessage(message);
    };

    this.terminate = function () {
      worker.terminate();
    };

    this.addListener = function (name, listener) {
      listeners[name] = listener;
    };

    this.removeListener = function (name) {
      delete listeners[name];
    };

    // This functions takes at least one argument, the method name we want to query.
    // Then we can pass in the arguments that the method needs.
    this.sendQuery = function () {
      if (arguments.length < 1) {
        // this.logger.debug("sendQuery takes at least one argument");
        throw new TypeError(
          "QueryableWorker.sendQuery takes at least one argument"
        );
      }
      this.status = "busy";
      this.requestId = arguments[1].request.id;
      this.logger.debug(
        "sendQuery:",
        arguments,
        this.status,
        this.inProgress,
        this.requestId
      );
      worker.postMessage({
        queryMethod: arguments[0],
        queryMethodArguments: Array.prototype.slice.call(arguments, 1),
      });
    };

    worker.onmessage = function (event) {
      if (
        event.data instanceof Object &&
        event.data.hasOwnProperty("queryMethodListener") &&
        event.data.hasOwnProperty("queryMethodArguments")
      ) {
        if (
          event.data.queryMethodListener == "responseCompleted" ||
          event.data.queryMethodListener == "responseError"
        ) {
          instance.status = "available";
          instance.requestId = null;
        }
        // instance.logger.debug(
        //   "QueryableWorker.onmessage0b:",
        //   instance,
        //   event.data.queryMethodArguments
        // );
        listeners[event.data.queryMethodListener].apply(
          instance,
          event.data.queryMethodArguments
        );
      } else {
        instance.status = "available";
        instance.requestId = null;
        // instance.logger.debug(
        //   "QueryableWorker.onmessage1:",
        //   instance,
        //   event.data
        // );
        if (this.defaultListener) {
          this.defaultListener.call(instance, event.data);
        }
      }
    };
  }
}
