/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { alpha, styled, useTheme } from "@mui/material/styles";
import {
  Dialog,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import ClearAllIcon from "@mui/icons-material/ClearAllOutlined";

import { api } from "../api/apiSlice";

import {
  SearchHistories,
  SearchSuggestions,
  closeSearchInput,
  execSearchInput,
  openSearchInput,
  updateSearchInput,
  parseQuery,
} from ".";

const SearchField = styled(TextField)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  borderColor: theme.palette.divider,
  borderWidth: "2px",
  borderStyle: "solid",
  backgroundColor: alpha(theme.palette.background.paper, 0.25),
  "&:hover": {
    backgroundColor: alpha(theme.palette.background.paper, 1),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

// search bar
// see: https://m3.material.io/components/search/specs
//
export const SearchBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const { fields, input, metadata, open, query, searching } = useSelector(
    (state) => state.searchInput
  );

  const theme = useTheme();

  const handleCancel = (event) => {
    event.stopPropagation();
    dispatch(closeSearchInput());
  };

  const handleChange = (event) => {
    const { query, fields } = parseQuery(event.target.value);
    const data = {
      input: event.target.value,
      query: query,
      fields: fields,
    };
    dispatch(updateSearchInput(data));
  };

  const handleClear = () => {
    dispatch(updateSearchInput(""));
  };

  const handleClick = () => {
    if (!open) {
      dispatch(openSearchInput());
      dispatch(api.util.invalidateTags(["SearchHistory"]));
    }
  };

  useEffect(() => {
    if (input === undefined) {
      return;
    }

    const newQuery = input.trim();
    if (newQuery === "") {
      return;
    }

    if (searching) {
      dispatch(execSearchInput());
      history.push(`/search/${newQuery}`);
    }
  }, [dispatch, history, input, searching]);

  return (
    <div>
      <SearchField
        placeholder="Search..."
        margin="dense"
        size="small"
        variant="outlined"
        onClick={handleClick}
        InputProps={{
          "aria-label": "search",
          startAdornment: (
            <InputAdornment position="start">
              <IconButton size="small">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Dialog
        sx={{
          color: theme.palette.primary.main,
          paddingTop: theme.spacing(2),
          position: "absolute",
          display: "inline",
          width: "100%",
          height: "100%",
          "& .MuiDrawer-scrollPaper": {
            alignItems: "self-start",
            display: "block",
            marginTop: -1 * theme.spacing(4),
          },
        }}
        open={open}
        maxWidth="xl"
        fullWidth={true}
      >
        <div>
          <TextField
            sx={{
              paddingRight: theme.spacing(2),
              paddingLeft: theme.spacing(2),
              width: "100%",
            }}
            value={input}
            fullWidth
            placeholder="Search..."
            margin="dense"
            size="small"
            variant="outlined"
            onChange={handleChange}
            onClick={handleClick}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(execSearchInput());
              }
            }}
            InputProps={{
              "aria-label": "search",
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    size="small"
                    onClick={() => dispatch(execSearchInput())}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <div hidden={input === ""}>
                    <Tooltip title="Clear search input">
                      <IconButton
                        size="small"
                        onClick={handleClear}
                        disabled={false}
                      >
                        <ClearAllIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Tooltip title="Close search">
                    <IconButton
                      size="small"
                      onClick={handleCancel}
                      disabled={false}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            inputRef={(inputref) => {
              if (inputref != null) {
                inputref.focus();
              }
            }}
          />
          <SearchSuggestions />
          <SearchHistories />
        </div>
      </Dialog>
    </div>
  );
};
