import {WebAuth} from "auth0-js";

import Logger from "js-logger";
import { toast } from "react-toastify";

const REDIRECT_ON_LOGIN = "redirect_on_login";

// Note: We use local storage to keep token session between
//       restarts/reloads/etc., NOT in-memory(at this time).

export default class Auth {
  constructor(history, domain, clientId, redirectUri, audience) {
    this.logger = Logger.get("Auth");
    this.history = history;
    this.userProfile = null;
    this.userFromToken = null;
    this.requestedScopes = "openid profile email";
    this.auth0 = new WebAuth({
      domain: domain,
      clientID: clientId,
      redirectUri: redirectUri,
      audience: audience,
      responseType: "token id_token",
      scope: this.requestedScopes,
      leeway: 300,
    });
  }

  _login = (authorizeOptions) => {
    localStorage.setItem(
      REDIRECT_ON_LOGIN,
      JSON.stringify(this.history.location)
    );
    this.auth0.authorize(authorizeOptions);
  };

  login = () => {
    const authorizeOptions = {
      mode: "login",
    };
    this._login(authorizeOptions);
  };

  signup = () => {
    const authorizeOptions = {
      mode: "signUp",
      screen_hint: "signup",
    };
    this._login(authorizeOptions);
  };

  clearSession = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("issued_at");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("scopes");
  };

  logout = () => {
    // LocalStorage
    this.clearSession();
    this.userFromToken = null;
    this.auth0.logout({
      clientID: this.auth0.baseOptions.clientID,
      returnTo: this.auth0.baseOptions.redirectUri.replace("/callback", ""),
    });
  };

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      this.logger.debug("handleAuthentication: authResult:", authResult);

      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);

        let redirectLocation = "/";

        const item = localStorage.getItem(REDIRECT_ON_LOGIN);
        if (item) {
          const redirect_on_login = JSON.parse(item);
          if (
            redirect_on_login.pathname !== "undefined" &&
            redirect_on_login.pathname !== "/signin"
          ) {
            redirectLocation = redirect_on_login.pathname;
          }
        }

        this.history.push(redirectLocation);
      } else if (err) {
        this.history.push("/");
        if (err.error != "invalid_token") {
          this.logger.error(err);
          toast.error(
            `Error: ${err.error}: ${err.errorDescription}. ` +
              "\nCheck the console for further details.",
            { autoClose: false }
          );
          new Promise((x) => setTimeout(x, 3000)).then(() => this.logout());
        }
      }
      localStorage.removeItem(REDIRECT_ON_LOGIN);
    });
  };

  setSession = (authResult) => {
    this.userFromToken = {
      name: authResult.idTokenPayload.name,
      email: authResult.idTokenPayload.email,
    };

    // LocalStorage
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    const scopes = authResult.scope || this.requestedScopes || "";
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("issued_at", authResult.idTokenPayload.iat * 1000);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("scopes", JSON.stringify(scopes));

    const issuedAtDiff =
      new Date().getTime() - authResult.idTokenPayload.iat * 1000;
    if (issuedAtDiff < 0) {
      this.logger.debug("setSession: issuedAt time drift (ms):", issuedAtDiff);
      this.logger.debug(
        "setSession: expiresAt time drift (ms):",
        expiresAt - authResult.idTokenPayload.exp * 1000
      );
    }

    this.scheduleTokenRenewal();
  };

  isReady() {
    // LocalStorage
    const issuedAt = JSON.parse(localStorage.getItem("issued_at"));
    const issuedAtDiff = new Date().getTime() - issuedAt;
    const isAuth = this.isAuthenticated();

    if (issuedAtDiff < 0) {
      this.logger.debug(
        "isReady: " +
          isAuth +
          ", issuedAt: " +
          issuedAt +
          ", time drift (ms): " +
          issuedAtDiff
      );
    }

    return isAuth;
  }

  isAuthenticated() {
    // LocalStorage
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  }

  getAccessToken = () => {
    // LocalStorage
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      // throw new Error("no access token found.");
      this.logger.debug("getAccessToken: no access token found.");
      return null;
    }
    return accessToken;
  };

  getProfile = (callback) => {
    if (this.userProfile) {
      return callback(this.userProfile);
    }
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      callback(null);
    } else {
      this.auth0.client.userInfo(accessToken, (err, profile) => {
        if (profile) {
          this.userProfile = profile;
          // this.logger.debugger("getProfile: ", profile);
        }
        callback(profile, err);
      });
    }
  };

  userHasScopes(scopes) {
    // LocalStorage
    const grantedScopes = (
      JSON.parse(localStorage.getItem("scopes")) || ""
    ).split(" ");
    return scopes.every((scope) => grantedScopes.includes(scope));
  }

  renewToken(callback) {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        this.logger.error(`Error: ${err.error} - ${err.error_description}.`);

        if (err.error === "login_required") {
          this.clearSession();
        }
      } else {
        this.setSession(result);
      }

      if (callback) {
        callback(err, result);
      }
    });
  }

  scheduleTokenRenewal() {
    // LocalStorage
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    const bufferInMilliseconds = 10 * 60 * 1000;
    const delay = expiresAt - Date.now() - bufferInMilliseconds;
    if (delay > 0) {
      setTimeout(() => this.renewToken(), delay);
    }
  }
}
