import { api, providesList } from "../api/apiSlice";

export const billingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBilling: build.query({
      query: (id) => `billing/${id}`,
      providesTags: (result, error, arg) => [{ type: "Billings", id: arg }],
    }),
    getPriceTable: build.query({
      query: () => `billing/price/table`,
      providesTags: (result) => providesList(result.prices, "PriceTableItems"),
    }),
    createCheckoutSession: build.mutation({
      query(data) {
        return {
          url: `billing/checkout/session`,
          method: "POST",
          body: data,
        };
      },
    }),
    createPortalSession: build.mutation({
      query(data) {
        return {
          url: `billing/portal/session`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetBillingQuery,
  useGetPriceTableQuery,
  useCreateCheckoutSessionMutation,
  useCreatePortalSessionMutation,
} = billingApi;
