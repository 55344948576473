import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

import { InvitationListingGrid, OrganizationJoin } from ".";

export const Pending = (props) => {
  const { invitations } = props;

  const { tabId, invitationId } = useParams();

  const isViewingInvitation = useMemo(() => {
    return invitationId !== undefined;
  }, [invitationId]);

  if (tabId !== "pending") {
    return <></>;
  }

  if (isViewingInvitation) {
    return (
      <Box sx={{ paddingTop: 2 }}>
        <OrganizationJoin />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ paddingTop: 2 }}>
        <InvitationListingGrid invitations={invitations} />
      </Box>
    </>
  );
};

Pending.propTypes = {
  invitations: PropTypes.object,
};
