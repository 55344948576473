import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

import { AssetListingGrid, MemberListingGrid } from ".";

export const Assets = (props) => {
  const { shareAssets } = props;

  const { tabId, shareId } = useParams();

  const isViewingAsset = useMemo(() => {
    return shareId !== undefined;
  }, [shareId]);

  if (tabId !== "asset") {
    return <></>;
  }

  if (isViewingAsset) {
    return (
      <Box sx={{ paddingTop: 2 }}>
        <MemberListingGrid />
      </Box>
    );
  }

  return (
    <Box sx={{ paddingTop: 2 }}>
      <AssetListingGrid shareAssets={shareAssets} />
    </Box>
  );
};

Assets.propTypes = {
  shareAssets: PropTypes.object,
};
