import { createSlice } from "@reduxjs/toolkit";

export const deleteBlobSlice = createSlice({
  name: "deleteBlob",
  initialState: {
    open: false,
    rows: undefined,
  },
  reducers: {
    open(state, action) {
      state.open = true;
      state.rows = action.payload;
    },
    close(state) {
      state.open = false;
      state.rows = undefined;
    },
  },
});

export const { open, close } = deleteBlobSlice.actions;
export const deleteBlob = deleteBlobSlice.reducer;
