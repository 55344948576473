import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { clearSelectedRows, InputDialog } from "../../components";
import { runTask } from "../../helpers";

import { genPath, useRenameBlobMutation } from ".";
import { close } from "./renameBlobSlice";

export const RenameBlob = ({ blobs }) => {
  const dispatch = useDispatch();
  const [renameBlob] = useRenameBlobMutation();
  const { open, row } = useSelector((state) => state.renameBlob);

  const type = row && row.metadata.type === "folder" ? "folder" : "file";
  const path = genPath(blobs.data, row);

  const title = row ? `Rename / Move ${type} "${path}"?` : "";
  const value = path;

  const handleClose = useCallback(
    async (target) => {
      dispatch(close());

      if (!target) {
        return;
      }

      dispatch(clearSelectedRows());

      const msg = `${path} to ${target}`;
      await runTask(
        "Rename",
        msg,
        async () => await renameBlob({ blob: row, target }).unwrap()
      ).then(blobs.refetch);
    },
    [blobs, dispatch, path, renameBlob, row]
  );

  return (
    <InputDialog
      open={open}
      cancelTitle="Cancel"
      inputLabel="To"
      inputValue={value}
      okTitle="Rename / Move"
      title={title}
      onClose={handleClose}
    />
  );
};

RenameBlob.propTypes = {
  blobs: PropTypes.object.isRequired,
};
