import React from "react";

import { Box, Typography, Link } from "@mui/material";

import localConfig from "../../config.json";
const httpConfig = localConfig.http;

export const Footer = () => {
  return (
    <Box>
      <Typography variant="body2" color="textSecondary">
        &copy; {new Date().getFullYear()}{" "}
        <Link
          color="inherit"
          component="a"
          href="https://builtstream.com/"
          target="_blank"
        >
          builtstream Inc.
        </Link>{" "}
        All rights reserved.
      </Typography>
      <br />
      <Typography variant="body2" color="textPrimary">
        <Link
          variant="body2"
          component="a"
          // TODO: revisit when we have proper Terms & Conditions page
          // href="https://builtstream.com/terms"
          href="https://builtstream.com/privacy"
          target="_blank"
          sx={{ paddingRight: 1 }}
        >
          Terms of Service
        </Link>
        <Link
          variant="body2"
          component="a"
          // TODO: revisit when we have proper Privacy page
          href="https://builtstream.com/privacy"
          target="_blank"
          sx={{ paddingRight: 1 }}
        >
          Privacy Policy
        </Link>
        <Link
          variant="body2"
          component="a"
          href={httpConfig.releasenotesUrl}
          target="_blank"
          sx={{ paddingRight: 1 }}
        >
          Release Notes
        </Link>
      </Typography>
    </Box>
  );
};
