import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { ActionBarCard, StyledButton } from "../../components";

import { memberRoles } from "../../helpers";

import {
  InviteDialog,
  useAcceptInvitations,
  useDeleteMembers,
  useGetMyOrganizationQuery,
  useNotifyMembers,
  useOpenInvitation,
  useRejectInvitations,
  useSaveMembers,
  useSelectedInvitations,
  useSelectedMembers,
} from ".";

export const ActionBar = (props) => {
  const { invitations } = props;
  const { data: organization } = useGetMyOrganizationQuery();

  const openInvitation = useOpenInvitation();
  const acceptInvitations = useAcceptInvitations();
  const rejectInvitations = useRejectInvitations();

  const notifyMembers = useNotifyMembers();
  const saveMembers = useSaveMembers();
  const deleteMembers = useDeleteMembers();

  const {
    hasOwnerRole,
    hasInactiveMembers,
    hasSelectedMembers,
    inactiveMembers,
    selectedMembers,
  } = useSelectedMembers();

  const { selectedInvitations, selectedInvitation, hasSelectedInvitations } =
    useSelectedInvitations(invitations);

  const renderOpenInvitation = useCallback(() => {
    if (hasSelectedInvitations && selectedInvitation) {
      return (
        <StyledButton
          id="actionbar-open-invitation"
          variant="outlined"
          onClick={() => openInvitation(selectedInvitation)}
        >
          Open
        </StyledButton>
      );
    }
    return false;
  }, [hasSelectedInvitations, selectedInvitation, openInvitation]);

  const renderAcceptInvitations = useCallback(() => {
    if (hasSelectedInvitations && selectedInvitation) {
      return (
        <StyledButton
          id="actionbar-accept-invitations"
          variant="outlined"
          onClick={acceptInvitations([selectedInvitation])}
        >
          Accept
        </StyledButton>
      );
    }
    return false;
  }, [hasSelectedInvitations, selectedInvitation, acceptInvitations]);

  const renderRejectInvitations = useCallback(() => {
    if (hasSelectedInvitations && selectedInvitations) {
      return (
        <StyledButton
          id="actionbar-reject-invitations"
          variant="outlined"
          onClick={rejectInvitations(selectedInvitations)}
        >
          Reject
        </StyledButton>
      );
    }
    return false;
  }, [hasSelectedInvitations, selectedInvitations, rejectInvitations]);

  const renderInviteMembers = useCallback(() => {
    if (hasOwnerRole) {
      return <InviteDialog />;
    }
    return false;
  }, [hasOwnerRole]);

  const renderNotifyMembers = useCallback(() => {
    if (hasOwnerRole && hasInactiveMembers) {
      return (
        <StyledButton
          id="actionbar-notify-members"
          variant="outlined"
          onClick={notifyMembers(organization.id, inactiveMembers)}
        >
          Resend
        </StyledButton>
      );
    }
    return false;
  }, [
    hasOwnerRole,
    hasInactiveMembers,
    inactiveMembers,
    notifyMembers,
    organization,
  ]);

  const renderChangeMemberRoles = useCallback(
    (value) => {
      if (hasOwnerRole && hasSelectedMembers) {
        let allowedRoles = new Map();
        allowedRoles.set(value, 0);

        for (var idx = 0; idx < selectedMembers.length; idx++) {
          const o = selectedMembers[idx];
          if (o && o.role && value !== o.role) {
            allowedRoles.set(value, allowedRoles.get(value) + 1);
          }
        }
        if (allowedRoles.get(value) > 0) {
          const memberRole = memberRoles.find((x) => x.value === value);
          let updatedMembers = [];
          for (idx = 0; idx < selectedMembers.length; idx++) {
            updatedMembers.push({
              ...selectedMembers[idx],
              role: memberRole.value,
            });
          }
          const key = `actionbar-change-role-${value.replace(" ", "-")}`;
          const label = `Set role: ${memberRole.label}`;
          return (
            <StyledButton
              id={key}
              key={key}
              variant="outlined"
              onClick={saveMembers(organization.id, updatedMembers)}
            >
              {label}
            </StyledButton>
          );
        }
      }
      return false;
    },
    [
      hasOwnerRole,
      hasSelectedMembers,
      selectedMembers,
      saveMembers,
      organization,
    ]
  );

  const renderChangeMemberRolesToOwner = useCallback(() => {
    return renderChangeMemberRoles("owner");
  }, [renderChangeMemberRoles]);

  const renderChangeMemberRolesToCanEdit = useCallback(() => {
    return renderChangeMemberRoles("can edit");
  }, [renderChangeMemberRoles]);

  const renderChangeMemberRolesToCanView = useCallback(() => {
    return renderChangeMemberRoles("can view");
  }, [renderChangeMemberRoles]);

  const renderDeleteMembers = useCallback(() => {
    if (hasOwnerRole && hasSelectedMembers) {
      return (
        <StyledButton
          id="actionbar-delete-members"
          variant="outlined"
          onClick={deleteMembers(organization.id, selectedMembers)}
        >
          Delete
        </StyledButton>
      );
    }
    return false;
  }, [
    hasOwnerRole,
    hasSelectedMembers,
    selectedMembers,
    deleteMembers,
    organization,
  ]);

  return (
    <Box sx={{ backgroundColor: "background.default" }}>
      <ActionBarCard
        buttons={[
          renderOpenInvitation,
          renderAcceptInvitations,
          renderRejectInvitations,
          renderInviteMembers,
          renderNotifyMembers,
          renderChangeMemberRolesToOwner,
          renderChangeMemberRolesToCanEdit,
          renderChangeMemberRolesToCanView,
          renderDeleteMembers,
        ]}
      />
    </Box>
  );
};

ActionBar.propTypes = {
  invitations: PropTypes.object,
};
