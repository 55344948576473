import React from "react";

import { Box, Grid } from "@mui/material";

import {
  ActionBar,
  AssetChart,
  CopyShareAsset,
  DeleteShareAsset,
  RecentAssets,
  UpsertShareAsset,
  useShareAssets,
} from "../asset";
import { StorageChart } from "../blobs";
import { MemberChart } from "../organization";

export const Home = () => {
  const shareAssets = useShareAssets();

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item lg={10} sm={12} xl={10} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <AssetChart chartType="PieChart" />
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <StorageChart chartType="PieChart" />
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <MemberChart chartType="PieChart" />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <RecentAssets />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} sm={12} xl={2} xs={12}>
          <ActionBar shareAssets={shareAssets} />
        </Grid>
      </Grid>

      <DeleteShareAsset shareAssets={shareAssets} />
      <CopyShareAsset shareAssets={shareAssets} />
      <UpsertShareAsset />
    </Box>
  );
};
