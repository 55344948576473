import { useHistory } from "react-router-dom";

export const useOpenInvitation = () => {
  const history = useHistory();

  return (invitation) => {
    if (invitation && invitation.id) {
      history.push(`/organization/pending/${invitation.id}`);
    }
  };
};
