import { createSlice } from "@reduxjs/toolkit";

export const searchInputSlice = createSlice({
  name: "searchInput",
  initialState: {
    fields: null,
    input: "",
    // TODO revisit metadata redundant with fields?
    metadata: null,
    open: false,
    query: "",
    searching: false,
  },
  reducers: {
    closeSearchInput(state) {
      state.fields = null;
      state.input = "";
      // TODO revisit metadata redundant with fields?
      // state.metadata = null;
      state.open = false;
      state.query = "";
      state.searching = false;
    },
    execSearchInput(state) {
      state.searching = true;
      state.open = false;
    },
    openSearchInput(state) {
      state.fields = null;
      state.input = "";
      // TODO revisit metadata redundant with fields?
      // state.metadata = null;
      state.open = true;
      state.query = "";
      state.searching = false;
    },
    updateSearchInput(state, action) {
      state.input = action.payload.input;
      state.fields = action.payload.fields;
      // TODO revisit metadata redundant with fields?
      // state.metadata = action.payload.metadata;
      state.query = action.payload.query;
    },
  },
});

export const {
  closeSearchInput,
  execSearchInput,
  openSearchInput,
  updateSearchInput,
} = searchInputSlice.actions;

export const searchInput = searchInputSlice.reducer;
