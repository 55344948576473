import React from "react";
import PropTypes from "prop-types";

import { Box, Drawer } from "@mui/material";

import { styled, useTheme } from "@mui/material/styles";

import { CompanyLogo } from "../../../components";
import { SidebarNav } from ".";

const drawerWidth = 240;

const SidebarDrawerContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: theme.spacing(2),
}));

export const Sidebar = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { open, variant, onClose, isSignup, pages, ...rest } = props;

  const theme = useTheme();

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      variant={variant}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          backgroundColor: theme.palette.secondary.dark,
          [theme.breakpoints.up("lg")]: {
            marginTop: 0, // 64px
            height: "calc(100% - 0px)", // "calc(100% - 64px)"
          },
        },
      }}
    >
      <CompanyLogo isInSidebar={true} />
      <SidebarDrawerContent>
        <SidebarNav hidden={isSignup} pages={pages} />
      </SidebarDrawerContent>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  isSignup: PropTypes.bool.isRequired,
  pages: PropTypes.array.isRequired,
};
