import { createSlice } from "@reduxjs/toolkit";

export const copyShareAssetSlice = createSlice({
  name: "copyShareAsset",
  initialState: {
    open: false,
    row: undefined,
  },
  reducers: {
    open(state, action) {
      state.open = true;
      state.row = action.payload;
    },
    close(state) {
      state.open = false;
      state.row = undefined;
    },
  },
});

export const { open, close } = copyShareAssetSlice.actions;
export const copyShareAsset = copyShareAssetSlice.reducer;
