import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Avatar, CircularProgress, Grid, Typography } from "@mui/material";

import { useGetMyAccountQuery } from "./accountApiSlice";

export const AccountAvatar = () => {
  const { isLoading, data } = useGetMyAccountQuery();

  const { avatar, fullname, email } = useMemo(() => {
    var avatar = "";
    var fullname = "";
    var email = "";

    if (isLoading || data === undefined || Object.keys(data).length === 0) {
      return { avatar, fullname, email };
    }

    if (data && data.profile && data.profile.avatar) {
      avatar = data.profile.avatar;
    }
    if (data && data.profile && data.profile.avatar) {
      fullname = data.profile.name;
    }
    if (data && data.profile && data.profile.avatar) {
      email = data.profile.email;
    }

    return { avatar, fullname, email };
  }, [isLoading, data]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={1}>
      <Grid item lg={3} md={3} sm={3} xl={3} xs={3}>
        <Avatar
          alt="Person"
          sx={{
            width: 56,
            height: 56,
          }}
          component={RouterLink}
          src={avatar}
          to="/settings"
        />
      </Grid>
      <Grid item lg={9} md={9} sm={9} xl={9} xs={9}>
        <Typography
          variant="body1"
          sx={{
            marginTop: 1,
            marginLeft: 1,
          }}
        >
          {fullname}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginTop: 1,
            marginLeft: 1,
          }}
        >
          {email}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AccountAvatar;
