import React, { useContext } from "react";

import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  ListItemButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  AuthContext,
  CompanyLogo,
  CustomRouterLink,
  ThemeButton,
} from "../../../components";

export const Topbar = () => {
  const auth = useContext(AuthContext);
  const { login, signup } = auth;

  const theme = useTheme();

  return (
    <AppBar
      position="absolute"
      sx={{
        marginLeft: 0,
        width: `calc(100% - 0px)`,
        boxShadow: "none",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Toolbar>
        <CompanyLogo isInSidebar={false} />
        <Box
          sx={{
            width: `calc(100%)`,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ListItemButton
              sx={{
                marginRight: theme.spacing(1),
                width: "85px",
                justifyContent: "center",
              }}
              component={CustomRouterLink}
              onClick={login}
              href="/signin"
              to="/signin"
            >
              <Tooltip title="Log in">
                <Typography color="textPrimary" variant="body1">
                  Log in
                </Typography>
              </Tooltip>
            </ListItemButton>
            <ListItemButton
              sx={{
                marginRight: theme.spacing(1),
                width: "85px",
                justifyContent: "center",
              }}
              component={CustomRouterLink}
              onClick={signup}
              href="/signin"
              to="/signin"
            >
              <Tooltip title="Sign up">
                <Typography color="textPrimary" variant="body1">
                  Sign up
                </Typography>
              </Tooltip>
            </ListItemButton>
          </Box>
        </Box>
        <ThemeButton />
      </Toolbar>
    </AppBar>
  );
};
