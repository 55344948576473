import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Logger from "js-logger";
import { v4 as uuidv4 } from "uuid";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PagesIcon from "@mui/icons-material/PagesOutlined";

import {
  logToastInfo,
  logToastError,
  clearSelectedRows,
} from "../../components";
import { emailRegex, memberRoles } from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";
import { useInviteShareMemberMutation } from ".";
import { close } from "./inviteShareSlice";

const emailTestPattern = new RegExp(emailRegex);

const logger = Logger.get("ShareEmailDialog");

export const ShareEmailDialog = (props) => {
  const { shareAssets } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const { isLoading, data: account } = useGetMyAccountQuery();

  const { isLoading: isLoadingOrganization, data: organization } =
    useGetMyOrganizationQuery();

  const { open, rows: shares } = useSelector((state) => state.inviteShare);

  const [inviteShareMember] = useInviteShareMemberMutation();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [role, setRole] = useState("can edit");
  const [invitations, setInvitations] = useState([]);
  const [inviting, setInviting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const nameTextFieldRef = useRef(null);

  const clearDialog = () => {
    setMessage("");
    setEmail("");
    setName("");
    setInvitations([]);
    setInviting(false);
    setErrorMessage("");
    setInfoMessage("");
  };

  const title = useMemo(() => {
    let title = "No built asset selected";
    if (shares && shares.length > 0) {
      title = `${shares[0].asset.name}`;
      if (shares.length > 1) {
        title += ` and ${shares.length - 1} others`;
      }
    }
    return title;
  }, [shares]);

  const handleDialogOpen = () => {
    clearDialog();
    if (nameTextFieldRef && nameTextFieldRef.current) {
      nameTextFieldRef.current.focus();
    }
  };

  const handleDialogClose = () => {
    dispatch(clearSelectedRows());
    dispatch(close());
    clearDialog();
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleNameChange = (event) => {
    event.preventDefault();
    const newName = event.target.value;
    // logger.debug("handleNameChange:", event.key, newName);
    setErrorMessage("");
    setInfoMessage("");
    setName(newName);
  };

  const handleEmailChange = (event) => {
    event.preventDefault();
    const newEmail = event.target.value;
    // logger.debug("handleEmailChange:", event.key, newEmail);
    setErrorMessage("");
    setEmail(newEmail);
  };

  const handleEmailSubmit = (event) => {
    // logger.debug("handleEmailSubmit:", event.key, newEmail);
    if (event.key === "Enter") {
      if (event.target) {
        event.preventDefault();
      }
      if (!emailTestPattern.test(email)) {
        setErrorMessage("Please enter valid email address.");
      } else {
        setErrorMessage("");
        setInfoMessage("");
        setInvitations((prev) => [
          // avoid duplicates
          ...prev.filter((chip) => chip.label !== email),
          {
            key: uuidv4(),
            email: email,
            name: name,
            role: role,
            label: email + " (" + role + ")",
          },
        ]);
        setEmail("");
        setName("");
        nameTextFieldRef.current.focus();
      }
    }
  };

  const handleEmailDelete = (event, chipToDelete) => {
    // logger.debug("handleEmailDelete:", event, chipToDelete);
    setInvitations((prev) =>
      prev.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleMessageChange = (event) => {
    event.preventDefault();
    const newMessage = event.target.value;
    // logger.debug("handleMessageChange:", event.key, newMessage);
    setErrorMessage("");
    setInfoMessage("");
    setMessage(newMessage);
  };

  const handleCancel = (event) => {
    handleDialogClose(event);
  };

  const handleInvite = async () => {
    setInviting(true);
    let invitationMessages = [];
    let errorMessages = [];

    for (var shareIdx = 0; shareIdx < shares.length; shareIdx++) {
      const share = shares[shareIdx];

      for (var i = 0; i < invitations.length; i++) {
        let invitation = {
          id: uuidv4(),
          organizationId: account.organizationId,
          organizationName: organization.name,
          asset: {
            id: share.id,
            type: "asset",
            name: share.asset.name,
          },
          role: invitations[i].role,
          status: "invited",
          owner: {
            id: account.id,
            name: account.profile.name,
            email: account.profile.email,
          },
          recipient: {
            name: invitations[i].name,
            email: invitations[i].email,
          },
          message: message,
        };
        let msg =
          `${invitation.recipient.email} invited to ` +
          `${invitation.asset.name} (${invitation.role})`;
        try {
          const result = await inviteShareMember({
            id: share.id,
            invitation: invitation,
          }).unwrap();

          logger.debug(msg, result);
          invitationMessages.push(msg);
        } catch (err) {
          msg += ` failed: ${err.message}`;
          logger.debug(msg);
          errorMessages.push(msg);
        }
      }
    }
    setInviting(false);
    clearDialog();

    let msg = `${invitationMessages.length} invitation${
      invitationMessages.length > 1 ? "s" : ""
    } sent`;

    if (errorMessages.length > 0) {
      msg += " with errors";
      setErrorMessage(msg);

      msg += ". " + invitationMessages.join("; ");
      logToastError(logger, msg + "; " + errorMessages.join("; "));
    } else {
      setInfoMessage(msg);

      msg += ". " + invitationMessages.join("; ");
      logToastInfo(logger, msg);
    }

    if (invitationMessages.length > 0 || errorMessages.length > 0) {
      shareAssets.refetch();
    }
  };

  if (isLoading || isLoadingOrganization) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        TransitionProps={{
          onEnter: handleDialogOpen,
        }}
        onClose={handleDialogClose}
        aria-labelledby="share-dialog-title"
        open={open}
        sx={{ flex: 1, paddingTop: 2 }}
      >
        <DialogTitle>
          <PagesIcon sx={{ float: "left" }} />
          <Box sx={{ marginLeft: "30px", marginTop: "5px" }}>{title}</Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 1,
            top: 1,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item lg={4} sm={4} md={4} xl={4} xs={3}>
              <TextField
                id="invite-dialog-name"
                // InputProps={{ classes: { input: classes.inputFields } }}
                InputProps={{ height: 27 }}
                fullWidth
                label="Name"
                placeholder="Enter recipient's name (optional)"
                required={true}
                variant="outlined"
                value={name}
                onChange={handleNameChange}
                inputRef={(input) => (nameTextFieldRef.current = input)}
              />
            </Grid>
            <Grid item lg={5} sm={5} md={5} xl={5} xs={4}>
              <TextField
                id="invite-dialog-email-address"
                // InputProps={{ classes: { input: classes.inputFields } }}
                InputProps={{ height: 27 }}
                fullWidth
                label="Email"
                required={true}
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                onKeyDown={handleEmailSubmit}
              />
            </Grid>
            <Grid item lg={2} sm={2} md={2} xl={2} xs={4}>
              <TextField
                id="invite-dialog-email-select-role"
                select
                fullWidth
                size="small"
                label="Role"
                variant="outlined"
                required={true}
                value={role}
                onChange={handleRoleChange}
                sx={{ paddingTop: "2px" }}
              >
                {memberRoles.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Tooltip title={option.description}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>{option.icon}</Grid>
                        <Grid item>
                          <Typography scope="row" variant="body1" noWrap={true}>
                            {option.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Tooltip>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={1} sm={1} md={1} xl={1} xs={1}>
              <Button
                variant="text"
                color="primary"
                component="span"
                onClick={() => handleEmailSubmit({ key: "Enter" })}
                sx={{
                  marginTop: "10px",
                }}
              >
                Add
              </Button>
            </Grid>
            <Grid item lg={10} sm={9} md={10} xl={10} xs={8}>
              {invitations.map((option) => (
                <Chip
                  key={option.key}
                  label={option.label}
                  variant="outlined"
                  onDelete={(event) => handleEmailDelete(event, option)}
                  color="primary"
                />
              ))}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
              <TextField
                id="invite-dialog-email-message"
                label="Message"
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                placeholder="Add a message (optional)"
                value={message}
                disabled={!inviting && invitations.length == 0}
                onChange={handleMessageChange}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", paddingTop: 2 }}>
            <Typography
              hidden={!errorMessage}
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                margin: 0,
                padding: 1,
                width: "100%",
                color: "error.main",
              }}
            >
              {errorMessage}
            </Typography>
            <Typography
              hidden={!infoMessage}
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                margin: 0,
                padding: 1,
                width: "100%",
                color: "info.main",
              }}
            >
              {infoMessage}
            </Typography>
            <Button
              variant="outlined"
              autoFocus
              onClick={handleCancel}
              sx={{ marginRight: 1, float: "left" }}
            >
              {!infoMessage ? "Cancel" : "Close"}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              autoFocus
              onClick={handleInvite}
              sx={{ marginRight: 0, float: "left" }}
              disabled={inviting || invitations.length == 0}
            >
              {inviting ? "Inviting..." : "Invite"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

ShareEmailDialog.propTypes = {
  shareAssets: PropTypes.object.isRequired,
};
