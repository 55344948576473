import React from "react";

import { Box, Card, CardContent, Grid } from "@mui/material";

import { CompanyLogo } from "../../components";
import { ApiVersion, Footer, Version } from ".";

export const About = () => {
  return (
    <Box sx={{ padding: 2, height: "100%", width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item lg={5} sm={8} md={6} xl={4} xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <CompanyLogo isInSidebar={false} />
                </Grid>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <Version />
                </Grid>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <ApiVersion />
                </Grid>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <Footer />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
