import { useMemo } from "react";
import { useGetInvitationsQuery } from "./shareApiSlice";
import { useDispatch, useSelector } from "react-redux";

import {
  setPreferencesPage,
  setPreferencesPageSize,
  setPreferencesSortModel,
} from "../../components";

export const useInvitations = () => {
  const preferences = useSelector((state) => state.preferences);
  const dispatch = useDispatch();

  const { page, pageSize, sortModel } = useMemo(() => {
    return {
      page: preferences.page,
      pageSize: preferences.pageSize,
      sortModel: preferences.sortModel,
    };
  }, [preferences]);

  const setPage = (newPage) => {
    dispatch(setPreferencesPage(newPage));
  };
  const setPageSize = (newPageSize) => {
    dispatch(setPreferencesPageSize(newPageSize));
  };
  const setSortModel = (newSortModel) => {
    dispatch(setPreferencesSortModel(newSortModel));
  };

  const { isLoading, data, refetch } = useGetInvitationsQuery();

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    isLoading,
    data,
    refetch,
    sortModel,
    setSortModel,
  };
};
