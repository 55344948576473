/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";

import Logger from "js-logger";

import { Button } from "@mui/material";
import LaunchIcon from "@mui/icons-material/LaunchOutlined";

import { logToastError } from "../../components";
import { isOwnerRole } from "../../helpers";

import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";
import { useCreatePortalSessionMutation } from "../billing";

const logger = Logger.get("BillingPortalButton");

export const BillingPortalButton = () => {
  const [isOwner, setIsOwner] = useState(false);

  const { data: account } = useGetMyAccountQuery();
  const { data: organization } = useGetMyOrganizationQuery();

  const [createPortalSession] = useCreatePortalSessionMutation();

  useEffect(() => {
    setIsOwner(isOwnerRole(account, organization, null));
  }, [account, organization]);

  const handleCreatePortalSession = useCallback(() => {
    try {
      createPortalSession()
        .then((result) => {
          window.open(result.data.url, "_self");
        })
        .catch((err) => {
          const msg = "billing portal session failed";
          logToastError(logger, msg, err);
        });
    } catch (err) {
      const msg = "billing portal session failed";
      logToastError(logger, msg, err);
    }
  }, [createPortalSession]);

  return (
    <Button
      aria-controls="continue-to-portal-session-button"
      variant="outlined"
      color="primary"
      onClick={() => handleCreatePortalSession()}
      disabled={!isOwner}
      endIcon={<LaunchIcon />}
      sx={{ marginTop: 1, float: "right" }}
    >
      Billing Portal
    </Button>
  );
};
