import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@mui/material";

import { formatTimestampAsDate, formatTimestampAsTime } from "../../helpers";

export const InvitationFooter = (props) => {
  const { invitation, invitationId } = props;

  var invitationIdReference = <></>;
  var invitationReference = <></>;

  if (invitationId !== undefined) {
    invitationIdReference = (
      <Typography variant="caption">
        Invitation code reference <strong>{invitationId}</strong>
      </Typography>
    );
  }

  if (invitation !== undefined) {
    invitationReference = (
      <Typography
        variant="caption"
        sx={{
          paddingTop: 1,
        }}
      >
        Invitation created by <strong>{invitation.owner.email}</strong> for{" "}
        <strong>{invitation.recipient.email}</strong> on{" "}
        {formatTimestampAsDate(invitation.ts * 1000)} at{" "}
        {formatTimestampAsTime(invitation.ts * 1000)}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        paddingTop: 4,
      }}
    >
      <Box>{invitationIdReference}</Box>
      <Box>{invitationReference}</Box>
    </Box>
  );
};

InvitationFooter.propTypes = {
  invitation: PropTypes.object,
  invitationId: PropTypes.string,
};
