import { useDispatch } from "react-redux";
import { clearSelectedRows } from "../../components";

import { useDeleteShareMemberMutation, useMemberMutation } from ".";

export const formatDeleteShareMemberMessage = (id, member, err) => {
  if (id && member) {
    return (
      `share member ${member.profile.name} (${id}) ` +
      (err === undefined ? "deleted" : `delete failed: ${err}`)
    );
  }
};

export const useDeleteShareMembers = () => {
  const dispatch = useDispatch();
  const deleteShareMember = useMemberMutation(
    useDeleteShareMemberMutation,
    (id, member) => {
      return formatDeleteShareMemberMessage(id, member);
    },
    (id, member, err) => {
      return formatDeleteShareMemberMessage(id, member, err);
    }
  );

  return (id, members) => () => {
    deleteShareMember({ id, items: members });
    dispatch(clearSelectedRows());
  };
};
