import React from "react";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

import { MemberListingGrid } from ".";

export const Members = () => {
  const { tabId } = useParams();

  if (tabId !== undefined && tabId !== "members") {
    return <></>;
  }

  return (
    <Box sx={{ paddingTop: 2 }}>
      <MemberListingGrid />
    </Box>
  );
};
