import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Logger from "js-logger";
import { v4 as uuidv4 } from "uuid";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OrganizationIcon from "@mui/icons-material/SupervisedUserCircle";

import {
  clearSelectedRows,
  logToastInfo,
  logToastError,
  StyledButton,
} from "../../components";

import { emailRegex, memberRoles } from "../../helpers";
import {
  useGetMyOrganizationQuery,
  useInviteOrganizationMemberMutation,
} from ".";
import { useGetMyAccountQuery } from "../account";

const emailTestPattern = new RegExp(emailRegex);

const logger = Logger.get("InviteDialog");

export const InviteDialog = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { isLoading: isLoadingAccount, data: account } = useGetMyAccountQuery();
  const {
    isLoading: isLoadingOrganization,
    data: organization,
    refetch,
  } = useGetMyOrganizationQuery();

  const [inviteMember] = useInviteOrganizationMemberMutation();

  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [role, setRole] = useState("can edit");
  const [invitations, setInvitations] = useState([]);
  const [inviting, setInviting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [isQuotaAvailable, setIsQuotaAvailable] = useState(false);
  const nameTextFieldRef = useRef(null);

  const quota_when_unspecified = 10000;

  useEffect(() => {
    handleQuota();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitations]);

  const handleQuota = () => {
    const prev = isQuotaAvailable;
    const quota =
      organization.membership.quota == 0
        ? quota_when_unspecified
        : organization.membership.quota;
    if (organization && organization.membership) {
      let available =
        quota - organization.membership.members.length - invitations.length;

      setIsQuotaAvailable(available > 0);
      if (available > 0 != prev) {
        setInfoMessage("");
      }
      if (available == 0) {
        setInfoMessage("User quota (" + quota + ") reached");
      }
    }
  };

  const clearDialog = () => {
    setMessage("");
    setEmail("");
    setName("");
    setInvitations([]);
    setInviting(false);
    setErrorMessage("");
    setInfoMessage("");
  };

  const handleDialogOpen = () => {
    setIsInviteDialogOpen(true);
    clearDialog();
    if (nameTextFieldRef && nameTextFieldRef.current) {
      nameTextFieldRef.current.focus();
    }
    handleQuota();
  };

  const handleDialogClose = () => {
    dispatch(clearSelectedRows());
    setIsInviteDialogOpen(false);
    clearDialog();
    setIsQuotaAvailable(false);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleNameChange = (event) => {
    event.preventDefault();
    const newName = event.target.value;
    setErrorMessage("");
    setInfoMessage("");
    setName(newName);
  };

  const handleEmailChange = (event) => {
    event.preventDefault();
    const newEmail = event.target.value;
    setErrorMessage("");
    setInfoMessage("");
    setEmail(newEmail);
  };

  const handleEmailSubmit = (event) => {
    if (event.key === "Enter") {
      if (event.target) {
        event.preventDefault();
      }
      if (!emailTestPattern.test(email)) {
        setErrorMessage("Please enter valid email address.");
      } else {
        setErrorMessage("");
        setInfoMessage("");
        logger.debug("handleEmailSubmit.valid email:", email);
        setInvitations((prev) => [
          // avoid duplicates
          ...prev.filter((chip) => chip.label !== email),
          {
            key: uuidv4(),
            email: email,
            name: name,
            role: role,
            label: email + " (" + role + ")",
          },
        ]);
        setEmail("");
        setName("");
        nameTextFieldRef.current.focus();
      }
    }
  };

  const handleEmailDelete = (event, chipToDelete) => {
    setInvitations((prev) =>
      prev.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleMessageChange = (event) => {
    event.preventDefault();
    const newMessage = event.target.value;
    setErrorMessage("");
    setInfoMessage("");
    setMessage(newMessage);
  };

  const handleCancel = (event) => {
    handleDialogClose(event);
  };

  const handleInvite = async () => {
    setInviting(true);
    let invitationMessages = [];
    let errorMessages = [];

    for (var i = 0; i < invitations.length; i++) {
      let invitation = {
        id: uuidv4(),
        organizationId: organization.id,
        organizationName: organization.name,
        asset: {
          id: organization.id,
          type: "organization",
          name: organization.name,
        },
        role: invitations[i].role,
        status: "invited",
        owner: {
          id: account.id,
          name: account.profile.name,
          email: account.profile.email,
        },
        recipient: {
          name: invitations[i].name,
          email: invitations[i].email,
        },
        message: message,
      };
      let msg =
        `${invitation.recipient.email} invited to ` +
        `${invitation.asset.name} (${invitation.role})`;
      try {
        const result = await inviteMember({
          id: organization.id,
          invitation: invitation,
        }).unwrap();

        logger.debug(msg, result);
        invitationMessages.push(msg);
      } catch (err) {
        msg += ` failed: ${err.message}`;
        logger.debug(msg);
        errorMessages.push(msg);
      }
    }
    setInviting(false);
    clearDialog();

    let msg = `${invitationMessages.length} invitation${
      invitationMessages.length > 1 ? "s" : ""
    } sent`;

    if (errorMessages.length > 0) {
      msg += " with errors";
      setErrorMessage(msg);

      msg += ". " + invitationMessages.join("; ");
      logToastError(logger, msg + "; " + errorMessages.join("; "));
    } else {
      setInfoMessage(msg);

      msg += ". " + invitationMessages.join("; ");
      logToastInfo(logger, msg);
    }

    if (invitationMessages.length > 0 || errorMessages.length > 0) {
      refetch();
    }
  };

  if (isLoadingAccount || isLoadingOrganization) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <StyledButton
        id="invite-dialog-button"
        variant="outlined"
        onClick={() => setIsInviteDialogOpen(true)}
      >
        Invite
      </StyledButton>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        TransitionProps={{
          onEnter: handleDialogOpen,
        }}
        onClose={handleDialogClose}
        aria-labelledby="invite-dialog-title"
        open={isInviteDialogOpen}
        sx={{
          flex: 1,
          paddingTop: 2,
        }}
      >
        <DialogTitle>
          <OrganizationIcon sx={{ float: "left" }} />
          <Box sx={{ marginLeft: "30px", marginTop: "5px" }}>
            Invite New Members
          </Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item lg={4} sm={4} md={4} xl={4} xs={3}>
              <TextField
                id="invite-dialog-name"
                // InputProps={{ classes: { input: classes.inputFields } }}
                InputProps={{ height: 27 }}
                fullWidth
                label="Name"
                placeholder="Enter recipient's name"
                variant="outlined"
                required={true}
                value={name}
                disabled={!isQuotaAvailable}
                onChange={handleNameChange}
                inputRef={(input) => (nameTextFieldRef.current = input)}
              />
            </Grid>
            <Grid item lg={5} sm={5} md={5} xl={5} xs={4}>
              <TextField
                id="invite-dialog-email-address"
                // InputProps={{ classes: { input: classes.inputFields } }}
                InputProps={{ height: 27 }}
                fullWidth
                label="Email"
                variant="outlined"
                required={true}
                value={email}
                disabled={!isQuotaAvailable}
                onChange={handleEmailChange}
                onKeyPress={handleEmailSubmit}
              />
            </Grid>
            <Grid item lg={2} sm={2} md={2} xl={2} xs={4}>
              <TextField
                id="invite-dialog-email-select-role"
                select
                fullWidth
                size="small"
                label="Role"
                variant="outlined"
                required={true}
                value={role}
                disabled={!isQuotaAvailable}
                onChange={handleRoleChange}
                sx={{ paddingTop: "2px" }}
              >
                {memberRoles.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Tooltip title={option.description}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>{option.icon}</Grid>
                        <Grid item>
                          <Typography scope="row" variant="body1" noWrap={true}>
                            {option.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Tooltip>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={1} sm={1} md={1} xl={1} xs={1}>
              <Button
                variant="text"
                color="primary"
                component="span"
                onClick={() => handleEmailSubmit({ key: "Enter" })}
                disabled={!isQuotaAvailable}
                sx={{
                  marginTop: "10px",
                }}
              >
                Add
              </Button>
            </Grid>
            <Grid item lg={10} sm={9} md={10} xl={10} xs={8}>
              {invitations.map((option) => (
                <Chip
                  key={option.key}
                  label={option.label}
                  variant="outlined"
                  onDelete={(event) => handleEmailDelete(event, option)}
                  color="primary"
                />
              ))}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
              <TextField
                id="invite-dialog-email-message"
                label="Message"
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                placeholder="Add a message (optional)"
                value={message}
                disabled={!inviting && invitations.length == 0}
                onChange={handleMessageChange}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", paddingTop: 2 }}>
            <Typography
              hidden={!errorMessage}
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                margin: 0,
                padding: 1,
                width: "100%",
                color: "error.main",
              }}
            >
              {errorMessage}
            </Typography>
            <Typography
              hidden={!infoMessage}
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                margin: 0,
                padding: 1,
                width: "100%",
                color: "info.main",
              }}
            >
              {infoMessage}
            </Typography>
            <Button
              variant="outlined"
              autoFocus
              onClick={handleCancel}
              disabled={inviting}
              sx={{ marginRight: 1 }}
            >
              {!infoMessage ? "Cancel" : "Close"}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              autoFocus
              onClick={handleInvite}
              disabled={inviting || invitations.length == 0}
              startIcon={inviting ? <CircularProgress /> : <></>}
              sx={{ marginRight: 0 }}
            >
              {inviting ? "Inviting..." : "Invite"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
