import React, { useState } from "react";
import PropTypes from "prop-types";

import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import AssetsIcon from "@mui/icons-material/PagesOutlined";
import SharedAssetsIcon from "@mui/icons-material/ShareOutlined";

import { useGetMyAccountQuery } from "../account";
import { StyledTooltip, useDebounce } from "../../components";

import { useSearchAssetsQuery } from ".";

export const AssetAutocomplete = ({
  onChange,
  disabled,
  label,
  helperText,
}) => {
  const { data: account } = useGetMyAccountQuery();

  const [selectedAsset, setSelectedAsset] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, 100);

  // const skip = debouncedSearchTerm.length <= 3;
  const skip = false;
  const { data } = useSearchAssetsQuery(debouncedSearchTerm, { skip });
  const options = data || [];

  const handleInputChange = (event, value) => {
    const newSelectedAsset =
      options.find((asset) => asset.name === value) || undefined;

    if (newSelectedAsset != selectedAsset) {
      onChange(newSelectedAsset);
      setSelectedAsset(newSelectedAsset);
    }

    setSearchTerm(value);
  };

  const renderAssetLabel = (asset) => {
    const isOrgAsset = account.organizationId === asset.organizationId;
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>{isOrgAsset ? <AssetsIcon /> : <SharedAssetsIcon />}</Grid>
        <Grid item>
          <StyledTooltip
            title={asset.name + (isOrgAsset ? "" : " (shared with me)")}
          >
            <Typography scope="row" variant="body1" noWrap={true}>
              {" "}
              {asset.name}
            </Typography>
          </StyledTooltip>
        </Grid>
      </Grid>
    );
  };

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      autoSelect
      margin="dense"
      size="small"
      options={options}
      filterOptions={(x) => x}
      getOptionLabel={(asset) => asset.name}
      getOptionSelected={(asset, value) => {
        return asset.id === value.id;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id={params.id}
          label={label || "Existing Built Asset"}
          helperText={
            helperText || "Select an existing built asset as destination"
          }
          margin="dense"
          variant="outlined"
        />
      )}
      renderOption={(asset) => renderAssetLabel(asset)}
      onInputChange={handleInputChange}
      disabled={disabled || false}
    />
  );
};

AssetAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
};
