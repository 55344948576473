import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";

import { LoadingCircularProgress, setSelected } from "../../components";
import { useGetMyAccountQuery } from "../account";
import { useGetTaskQuery } from "./";

import { formatTimestampAsDateTime } from "../../helpers";

const TaskItem = (props) => {
  const { label, value } = props;
  return (
    <Box
      key={label}
      sx={{ paddingBottom: 1 }}
      hidden={!value || value.length === 0}
    >
      <Typography variant="body1">{label}</Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
};

TaskItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export const TaskView = () => {
  const [title] = useState("Task Overview");
  const { taskId } = useParams();
  const dispatch = useDispatch();

  const {
    isLoading,
    data: task,
    refetch,
  } = useGetTaskQuery(taskId, {
    skip: taskId === undefined,
  });

  useEffect(() => {
    if (!isLoading && task) {
      dispatch(setSelected([task.id]));
    }
  }, [dispatch, isLoading, task]);

  const { isLoadingAccount, data: account } = useGetMyAccountQuery();

  const capitalize = (phrase) => {
    return phrase.charAt(0).toUpperCase() + phrase.slice(1);
  };

  if (isLoading || isLoadingAccount) {
    return <LoadingCircularProgress />;
  }
  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <IconButton size="small" id="refreshIcon" onClick={refetch}>
            <RefreshIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        <TaskItem label="ID" value={task.id || ""} />
        <TaskItem label="Action" value={task.name} />
        <TaskItem label="State" value={task.state} />
        <TaskItem label="Description" value={task.description} />
        <TaskItem label="Owner" value={task.owner && task.owner.name} />
        <TaskItem label="OrganizationId" value={task.organizationId} />
        <TaskItem label="AssetName" value={task.assetName} />
        <TaskItem label="AssetId" value={task.assetId} />
        <TaskItem label="Asset Type" value={task.assetType} />

        <div hidden={!task.metadata || Object.keys(task.metadata).length == 0}>
          {task.metadata &&
            Object.entries(task.metadata).map(([key, value]) => (
              <TaskItem
                key={key}
                label={capitalize(key)}
                value={value || " "}
              />
            ))}
        </div>

        <div hidden={!task.history || task.history.length == 0}>
          <br />
          <Grid container>
            <Grid container spacing={1}>
              <Grid item xs={3} md={3} lg={3} xl={3}>
                <Typography variant="body1">Modified</Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3} xl={3}>
                <Typography variant="body1">State</Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3} xl={3}>
                <Typography variant="body1">Description</Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3} xl={3}>
                <Typography variant="body1">Progress</Typography>
              </Grid>
            </Grid>
            {task.history &&
              task.history
                .filter(
                  (value) =>
                    // Include any entries without progress updates
                    !value.progress ||
                    // Ignore any sub chunk/block progress updates
                    !(value.progress && value.progress.metadata)
                )
                .map((name, index) => (
                  <Grid container key={`${name}-${index}`} spacing={1}>
                    <Grid
                      item
                      key={`${name}-${index}-ts`}
                      xs={3}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <Typography variant="body2">
                        {formatTimestampAsDateTime(
                          name.ts * 1000,
                          account.preferences.dateFormat
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      key={`${name}-${index}-state`}
                      xs={3}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <Typography variant="body2">{name.state}</Typography>
                    </Grid>
                    <Grid
                      item
                      key={`${name}-${index}-progress-label`}
                      xs={3}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <Typography variant="body2">
                        {name.progress && name.progress.label}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      key={`${name}-${index}-progress-value`}
                      xs={3}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <Typography variant="body2">
                        {name.progress && name.progress.value}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};
