import { useDispatch } from "react-redux";
import { clearSelectedRows } from "../../components";

import { useNotifyShareMemberMutation, useMemberMutation } from ".";

export const formatNotifyShareMemberMessage = (id, member, err) => {
  if (id && member) {
    return (
      `share member ${member.profile.name} (${id}) ` +
      (err === undefined ? "notified" : `notification failed: ${err}`)
    );
  }
};

export const useNotifyShareMembers = () => {
  const dispatch = useDispatch();
  const notifyShareMember = useMemberMutation(
    useNotifyShareMemberMutation,
    (id, member) => {
      return formatNotifyShareMemberMessage(id, member);
    },
    (id, member, err) => {
      return formatNotifyShareMemberMessage(id, member, err);
    }
  );

  return (id, members) => () => {
    notifyShareMember({ id, items: members });
    dispatch(clearSelectedRows());
  };
};
