import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";
import { selectSelectedRows } from "../../components";
import { isEditableRole, isOwnerRole, isViewableRole } from "../../helpers";
import { isFolder } from ".";

export const useSelectedBlobs = (share, blobs) => {
  const { data: account } = useGetMyAccountQuery();
  const { data: organization } = useGetMyOrganizationQuery();
  const selectedRows = useSelector(selectSelectedRows);

  return useMemo(() => {
    let selectedBlobs = [];
    let hasOwnerRole = true;
    let hasEditableRole = true;
    let hasViewableRole = true;
    let hasAnyFolderSelected = false;
    let isSameOrganization = false;

    hasOwnerRole = isOwnerRole(account, organization, share && share.data);
    hasEditableRole = isEditableRole(
      account,
      organization,
      share && share.data
    );
    hasViewableRole = isViewableRole(
      account,
      organization,
      share && share.data
    );
    isSameOrganization =
      organization &&
      share &&
      share.data &&
      organization.id === share.data.organizationId;

    if (blobs.data) {
      selectedBlobs = blobs.data.data.filter((blob) => {
        if (selectedRows.includes(blob.id)) {
          if (!hasAnyFolderSelected) {
            hasAnyFolderSelected = isFolder(blob);
          }
          return true;
        }

        return false;
      });
    }

    const selectedBlob = selectedBlobs.length === 1 && selectedBlobs[0];

    return {
      selectedBlobs,
      hasOwnerRole,
      hasEditableRole,
      hasViewableRole,
      hasAnyFolderSelected,
      isSameOrganization,
      selectedBlob,
    };
  }, [blobs, selectedRows, account, organization, share]);
};
