import { useDispatch } from "react-redux";
import { clearSelectedRows } from "../../components";

import { useDeleteTaskMutation, useMutation } from ".";

export const useDeleteTask = () => {
  const dispatch = useDispatch();
  const deleteTask = useMutation(
    useDeleteTaskMutation,
    (task) => {
      if (task) {
        return `task ${task.name} (${task.description} deleted`;
      }
    },
    (task, err) => {
      if (task && err) {
        return `task ${task.name} (${task.description} delete failed: ${err}`;
      }
    }
  );

  return (tasks) => () => {
    if (tasks === undefined || tasks.length === 0) {
      return;
    }
    deleteTask(tasks);
    dispatch(clearSelectedRows());
  };
};
