import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchOutlined";

import {
  mapToQuery,
  parseQuery,
  requote,
  searchInputSlice,
  useSearchSuggestions,
} from ".";

export const SearchSuggestions = () => {
  const dispatch = useDispatch();
  const { fields, metadata, open, query } = useSelector(
    (state) => state.searchInput
  );
  const { updateSearchInput } = searchInputSlice.actions;

  const { isLoading, filtered } = useSearchSuggestions(
    { fields, metadata, open, query },
    { skip: !open }
  );

  const onSelect = useCallback(
    (result) => {
      const parts = result.data.split(":");
      var formatted = parts[0];
      if (parts.length > 1) {
        formatted = `${parts[0]}:${requote(parts[1])}`;
      }
      const { query, fields } = parseQuery(formatted);

      const data = {
        input: mapToQuery(query, fields),
        query: query,
        fields: fields,
      };

      dispatch(updateSearchInput(data));
    },
    [dispatch, updateSearchInput]
  );

  return (
    <Box sx={{ paddingRight: 1, paddingLeft: 1 }}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          paddingRight: 1,
          paddingLeft: 1,
          height: "48px",
          alignItems: "center",
        }}
      >
        <Typography>Narrow your search</Typography>
      </Box>
      <List>
        {!isLoading &&
          filtered &&
          filtered.map((d) => (
            <ListItem
              button
              key={d.id}
              onClick={() => onSelect(d)}
              sx={{ paddingRight: 1, paddingLeft: 1 }}
            >
              <SearchIcon size="small" sx={{ marginRight: 1 }} />
              <Chip
                variant="outlined"
                margin="dense"
                size="small"
                label={d.data}
                sx={{ marginRight: 1 }}
              />
              <ListItemText primary={d.placeholder} sx={{ marginRight: 1 }} />
            </ListItem>
          ))}
      </List>
    </Box>
  );
};
