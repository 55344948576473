import { useHistory } from "react-router-dom";

export const useOpenInvitation = () => {
  const history = useHistory();

  return (invitation) => {
    if (
      invitation &&
      invitation.asset &&
      invitation.asset.id &&
      invitation.id
    ) {
      history.push(`/share/pending/${invitation.asset.id}/${invitation.id}`);
    }
  };
};
