import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  width: "100%",
  "& .MuiCardHeader-title": {
    // h5
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: "20px",
    // backgroundColor: theme.palette.error.light,
  },
  "& .MuiCardHeader-action": {
    // body2
    color: theme.palette.error.main,
    fontSize: "12px",
    letterSpacing: "-0.04px",
    lineHeight: "18px",
  },
  "& .MuiIconButton-root": {
    color: theme.palette.icon,
  },
}));
