import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Chart } from "react-google-charts";
import { colors, CardContent, CardHeader, Typography } from "@mui/material";

import { LoadingCircularProgress, StyledCard } from "../../components";
import { useGetMyOrganizationQuery } from "../organization";

export const MemberChart = (props) => {
  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("BarChart");
  const [maxValue, setMaxValue] = useState(0);
  const [used, setUsed] = useState(0);

  const { data: organization } = useGetMyOrganizationQuery();

  const title = "Organization Members";

  const options = {
    chartArea: { width: "100%", height: "75%" },
    isStacked: true,
    hAxis: {
      title: "",
      minValue: 0,
      maxValue: maxValue,
      viewWindowMode: "maximized",
    },
    vAxis: {
      title: "",
      ticks: "",
    },
    colors: [
      colors.blue[400],
      colors.orange[400],
      colors.green[400],
      colors.blueGrey[100],
      colors.red[500],
    ],
    legend: { position: "bottom" },
    ticks: "",
  };

  useEffect(() => {
    if (
      typeof organization === "undefined" ||
      organization === null ||
      Object.keys(organization).length === 0
    ) {
      return;
    }

    let allocated = organization.membership.quota;
    let u = "users";
    let activeUsers =
      organization.membership.members.filter((x) => x.status === "active") ||
      [];
    let invitedUsers =
      organization.membership.members.filter((x) => x.status === "invited") ||
      [];

    let active = activeUsers.length;
    let invited = invitedUsers.length;
    let used = active + invited;
    let available = allocated - used;

    const activeLabel = "Active (" + u + ")";
    const invitedLabel = "Invited (" + u + ")";
    const availableLabel = "Available (" + u + ")";
    var value = [
      ["", activeLabel, invitedLabel, availableLabel],
      ["", active, invited, available],
    ];
    if (
      typeof props.chartType === "undefined" ||
      props.chartType === null ||
      props.chartType === "BarChart"
    ) {
      setChartType("BarChart");
    } else {
      setChartType(props.chartType);
      value = [
        ["Status", "Users"],
        [activeLabel, active],
        [invitedLabel, invited],
        [availableLabel, available],
      ];
    }

    setChartData(value);
    setMaxValue(Math.max(allocated, used));
    setUsed(used);
  }, [props, organization]);

  if (typeof organization === "undefined" || organization === null) {
    return <LoadingCircularProgress />;
  }

  return (
    <StyledCard sx={{ padding: 0 }}>
      <CardHeader
        title={title}
        action={
          <Typography
            variant="body2"
            sx={{
              paddingRight: 1,
              paddingTop: 1,
            }}
          >
            {used} of {maxValue} Users
          </Typography>
        }
      />
      <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
        <Chart
          width={"100%"}
          height={"250px"}
          chartType={chartType}
          loader={<LoadingCircularProgress />}
          data={chartData}
          options={options}
        />
      </CardContent>
    </StyledCard>
  );
};

MemberChart.propTypes = {
  chartType: PropTypes.string,
};
