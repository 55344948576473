import React from "react";
import Logger from "js-logger";

import { Box, Button, Grid } from "@mui/material";

const logger = Logger.get("Debug");

export const Debug = () => {
  function handlePlaceholder() {
    logger.debug("handlePlaceholder");
  }

  return (
    <Box sx={{ padding: 2, height: "100%", width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
          <Button
            color="default"
            variant="contained"
            onClick={handlePlaceholder}
          >
            placeholder
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
