import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

import { ActionBarCard, StyledButton } from "../../components";

import {
  useSelectedTasks,
  useCancelTask,
  useDeleteTask,
  useOpenTask,
  usePauseTask,
  useResumeTask,
} from ".";

export const ActionBar = (props) => {
  const { tasks } = props;
  const { taskId } = useParams();

  const cancelTask = useCancelTask();
  const deleteTask = useDeleteTask();
  const openTask = useOpenTask();
  const pauseTask = usePauseTask();
  const resumeTask = useResumeTask();

  const notImplemented = true;

  const {
    hasEditableRole,
    hasSelectedTasks,
    isSameOrganization,
    selectedTask,
    selectedTasks,
  } = useSelectedTasks(tasks);

  const renderOpen = () =>
    selectedTask &&
    taskId === undefined && (
      <StyledButton
        id="actionbar-open"
        onClick={() => openTask(selectedTask)}
        variant="outlined"
      >
        Open
      </StyledButton>
    );

  const renderPause = () =>
    !notImplemented &&
    isSameOrganization &&
    hasEditableRole &&
    hasSelectedTasks && (
      <StyledButton
        id="actionbar-pause"
        onClick={() => pauseTask(selectedTasks)}
        disabled={true}
        variant="outlined"
      >
        Pause
      </StyledButton>
    );

  const renderResume = () =>
    !notImplemented &&
    isSameOrganization &&
    hasEditableRole &&
    hasSelectedTasks && (
      <StyledButton
        id="actionbar-pause"
        onClick={() => resumeTask(selectedTasks)}
        disabled={true}
        variant="outlined"
      >
        Resume
      </StyledButton>
    );

  const renderCancel = () =>
    !notImplemented &&
    isSameOrganization &&
    hasEditableRole &&
    hasSelectedTasks && (
      <StyledButton
        id="actionbar-pause"
        onClick={() => cancelTask(selectedTasks)}
        disabled={true}
        variant="outlined"
      >
        Cancel
      </StyledButton>
    );

  const renderDelete = () =>
    isSameOrganization &&
    hasEditableRole &&
    hasSelectedTasks && (
      <StyledButton
        id="actionbar-delete"
        onClick={deleteTask(selectedTasks)}
        disabled={false}
        variant="outlined"
      >
        Delete
      </StyledButton>
    );

  return (
    <Box sx={{ backgroundColor: "background.default" }}>
      <ActionBarCard
        buttons={[
          renderOpen,
          renderPause,
          renderResume,
          renderCancel,
          renderDelete,
        ]}
      />
    </Box>
  );
};

ActionBar.propTypes = {
  tasks: PropTypes.object.isRequired,
};
