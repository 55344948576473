import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { clearSelectedRows } from "../../components";
import { runTask } from "../../helpers";
import { CopyShareAssetDialog, useCopyAssetMutation } from ".";

import { close } from "./copyShareAssetSlice";

export const CopyShareAsset = ({ shareAssets }) => {
  const dispatch = useDispatch();
  const { open, row } = useSelector((state) => state.copyShareAsset);
  const [copyAsset] = useCopyAssetMutation();

  const title = useMemo(() => {
    return row && row.asset.name ? `Copy built asset '${row.asset.name}'` : "";
  }, [row]);

  const onClose = useCallback(
    (ok, result) => {
      dispatch(close());

      if (!ok) {
        return;
      }

      dispatch(clearSelectedRows());

      runTask(
        "Copy",
        result.src.asset.name,
        async () => await copyAsset(result).unwrap()
      ).then(shareAssets.refetch);
    },
    [dispatch, shareAssets, copyAsset]
  );

  return (
    <CopyShareAssetDialog
      shareAssets={shareAssets}
      onClose={onClose}
      open={open}
      source={row}
      title={title}
    />
  );
};

CopyShareAsset.propTypes = {
  shareAssets: PropTypes.object.isRequired,
};
