import React from "react";
import PropTypes from "prop-types";

import { Badge, Button, IconButton, List, ListItem } from "@mui/material";

import { styled } from "@mui/material/styles";

import { CustomRouterLink } from "../../../components";

const SidebarNavItem = styled(ListItem)(() => ({
  display: "flex",
  paddingTop: 0,
  paddingBottom: 0,
}));

const SidebarNavItemButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  padding: "10px 8px",
  justifyContent: "flex-start",
  textTransform: "none",
  letterSpacing: 0,
  width: "100%",
  fontWeight: theme.typography.fontWeightMedium,
}));

const SidebarNavItemIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  width: 24,
  height: 24,
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(1),
}));

export const SidebarNav = (props) => {
  const { pages, ...rest } = props;

  return (
    <List {...rest}>
      {pages.map((page) => (
        <div hidden={page.hidden} key={page.title + "div"}>
          <SidebarNavItem disableGutters key={page.title}>
            <SidebarNavItemButton
              // activeClassName={classes.active}
              // className={classes.button}
              component={CustomRouterLink}
              to={page.href}
            >
              <Badge
                badgeContent={page.count}
                color="secondary"
                overlap="rectangular"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <SidebarNavItemIcon>{page.icon}</SidebarNavItemIcon>

                {page.title}
              </Badge>
            </SidebarNavItemButton>
          </SidebarNavItem>
        </div>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
};
