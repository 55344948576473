import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { Callback, RouteWithLayout, Signout } from "../components";
import {
  PrivateWithRouter as PrivateLayout,
  PublicWithRouter as PublicLayout,
  InlineWithRouter as InlineLayout,
} from "./layouts";

import {
  Home,
  AssetList,
  ManageAsset,
  Search,
  Shares,
  ShareJoin,
  // Files,
  TaskList,
  ManageTask,
  Organization,
  OrganizationJoin,
  Settings,
  About,
  Pricing,
  Checkout,
  BillingPortal,
  Signin,
  Admin,
  Debug,
} from "../features";

import { NotFound } from "./NotFound";

const Routes = (props) => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      <RouteWithLayout
        component={Home}
        exact
        layout={PrivateLayout}
        path="/home"
        title="Home"
        {...props}
      />
      <RouteWithLayout
        component={AssetList}
        exact
        layout={PrivateLayout}
        path="/assets"
        title="Built Assets"
        {...props}
      />
      <RouteWithLayout
        component={ManageAsset}
        exact
        layout={PrivateLayout}
        path="/asset/:assetId"
        title="Built Assets"
        {...props}
      />
      <RouteWithLayout
        component={ManageAsset}
        exact
        layout={PrivateLayout}
        path="/asset"
        title="Built Assets"
        {...props}
      />
      <RouteWithLayout
        component={Search}
        exact
        layout={PrivateLayout}
        path="/search/:query"
        title="Search"
        {...props}
      />
      <RouteWithLayout
        component={ShareJoin}
        exact
        layout={PrivateLayout}
        path="/share/invitation/:invitationId"
        title="Built Asset Share Invitation"
        {...props}
      />
      <RouteWithLayout
        component={ShareJoin}
        exact
        layout={PublicLayout}
        path="/asset/join/:invitationId"
        title="Join Built Asset Share"
        {...props}
      />
      <RouteWithLayout
        component={ShareJoin}
        exact
        layout={PublicLayout}
        path="/share/join/:invitationId"
        title="Join Built Asset Share"
        {...props}
      />
      <RouteWithLayout
        component={Shares}
        exact
        layout={PrivateLayout}
        path="/share/:tabId/:shareId/:invitationId"
        title="Built Asset Share"
        {...props}
      />
      <RouteWithLayout
        component={Shares}
        exact
        layout={PrivateLayout}
        path="/share/:tabId/:shareId"
        title="Built Asset Share"
        {...props}
      />
      <RouteWithLayout
        component={Shares}
        exact
        layout={PrivateLayout}
        path="/shares/:tabId"
        title="Built Asset Shares"
        {...props}
      />
      <RouteWithLayout
        component={Shares}
        exact
        layout={PrivateLayout}
        path="/shares"
        title="Built Asset Shares"
        {...props}
      />
      {/* <RouteWithLayout
        component={Files}
        exact
        layout={PrivateLayout}
        path="/files"
        title="Files"
        {...props}
      /> */}
      <RouteWithLayout
        component={TaskList}
        exact
        layout={PrivateLayout}
        path="/tasks"
        title="Tasks"
        {...props}
      />
      <RouteWithLayout
        component={ManageTask}
        exact
        layout={PrivateLayout}
        path="/task/:taskId"
        title="Tasks"
        {...props}
      />
      <RouteWithLayout
        component={ManageTask}
        exact
        layout={PrivateLayout}
        path="/task"
        title="Tasks"
        {...props}
      />
      <RouteWithLayout
        component={About}
        exact
        layout={PrivateLayout}
        path="/about"
        title="About"
        {...props}
      />
      <RouteWithLayout
        component={Settings}
        exact
        layout={PrivateLayout}
        path="/settings"
        title="Settings"
        {...props}
      />
      <RouteWithLayout
        component={Settings}
        exact
        layout={PrivateLayout}
        path="/settings/:tabId"
        title="Settings"
        {...props}
      />
      <RouteWithLayout
        component={OrganizationJoin}
        exact
        layout={PrivateLayout}
        path="/organization/invitation/:invitationId"
        title="Organization Invitation"
        {...props}
      />
      <RouteWithLayout
        component={OrganizationJoin}
        exact
        layout={PublicLayout}
        path="/organization/join/:invitationId"
        title="Join Organizatoin"
        {...props}
      />
      <RouteWithLayout
        component={Organization}
        exact
        layout={PrivateLayout}
        path="/organization/:tabId/:invitationId"
        title="Organization"
        {...props}
      />
      <RouteWithLayout
        component={Organization}
        exact
        layout={PrivateLayout}
        path="/organization/:tabId"
        title="Organization"
        {...props}
      />
      <RouteWithLayout
        component={Organization}
        exact
        layout={PrivateLayout}
        path="/organization"
        title="Organization"
        {...props}
      />
      <RouteWithLayout
        component={Organization}
        exact
        layout={PrivateLayout}
        path="/organization/:tabId"
        title="Organization"
        {...props}
      />
      <RouteWithLayout
        component={Signin}
        exact
        layout={PublicLayout}
        path="/signin"
        title="Signin"
        {...props}
      />
      <RouteWithLayout
        component={Signout}
        exact
        layout={PublicLayout}
        path="/signout"
        title="Signout"
        {...props}
      />
      <RouteWithLayout
        component={Callback}
        exact
        layout={PublicLayout}
        path="/callback"
        title="Authentication Callback"
        {...props}
      />
      <RouteWithLayout
        component={Checkout}
        exact
        layout={PrivateLayout}
        path="/checkout"
        title="Checkout"
        {...props}
      />
      <RouteWithLayout
        component={Checkout}
        exact
        layout={PrivateLayout}
        path="/checkout/:priceId"
        title="Checkout"
        {...props}
      />
      <RouteWithLayout
        component={Pricing}
        exact
        layout={InlineLayout}
        path="/pricing"
        title="Subscription Plans"
        {...props}
      />
      <RouteWithLayout
        component={Pricing}
        exact
        layout={InlineLayout}
        path="/pricing/:priceId"
        title="Continue to Checkout"
        {...props}
      />
      <RouteWithLayout
        component={BillingPortal}
        exact
        layout={PublicLayout}
        path="/billing/portal"
        title="Continue to Billing Portal"
        {...props}
      />
      <RouteWithLayout
        component={NotFound}
        exact
        layout={PublicLayout}
        path="/notfound"
        {...props}
      />
      <RouteWithLayout
        component={Debug}
        exact
        layout={PrivateLayout}
        path="/debug"
        title="Debug"
        {...props}
      />
      <RouteWithLayout
        component={Admin}
        exact
        layout={PrivateLayout}
        path="/admin"
        title="Admin"
        {...props}
      />
      <Redirect to="/notfound" {...props} />
    </Switch>
  );
};

export default Routes;
