import React, { useEffect, useState } from "react";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import { useGetMyOrganizationQuery } from "../organization";

export const MemberSummary = () => {
  const [userDetails, setUserDetails] = useState([]);
  const { data: organization } = useGetMyOrganizationQuery();

  const quota_when_unspecified = 10000;

  useEffect(() => {
    if (
      organization &&
      Object.keys(organization).length > 0 &&
      organization.membership.members
    ) {
      let quota = organization.membership.quota;
      if (quota == 0) {
        quota = quota_when_unspecified;
      }

      const details = [
        {
          id: 0,
          name: "Quota",
          total: quota,
        },
        {
          id: 1,
          name: "Active",
          total: organization.membership.members.filter(
            (x) => x.status == "active"
          ).length,
        },
        {
          id: 2,
          name: "Invited",
          total: organization.membership.members.filter(
            (x) => x.status == "invited"
          ).length,
        },
        {
          id: 3,
          name: "Available",
          total:
            quota -
            organization.membership.members.filter(
              (x) => x.status == "invited" || x.status == "active"
            ).length,
        },
      ];
      setUserDetails(details);
    }
  }, [organization]);

  return (
    <Box sx={{ height: "100%" }}>
      <Card>
        <CardHeader title="Member Summary" />
        <Divider />
        <CardContent>
          {userDetails &&
            userDetails.map((userDetail) => (
              <div key={userDetail.id + "row"}>
                <Grid
                  key={userDetail.id + "gridrow"}
                  container
                  wrap="nowrap"
                  spacing={1}
                >
                  <Grid
                    key={userDetail.id + "gridcol1"}
                    item
                    lg={10}
                    sm={10}
                    md={10}
                    xl={10}
                    xs={10}
                    zeroMinWidth
                  >
                    <div key={userDetail.id}>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                        noWrap
                        key={userDetail.id + userDetail.name}
                      >
                        {userDetail.name}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    key={userDetail.ID + "gridcol2"}
                    item
                    lg={2}
                    sm={2}
                    md={2}
                    xl={2}
                    xs={2}
                    zeroMinWidth
                  >
                    <div>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        noWrap
                        key={userDetail.id + userDetail.total}
                      >
                        {userDetail.total}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
        </CardContent>
      </Card>
    </Box>
  );
};
