import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";

export const NoMaxWidthTooltip = styled(Tooltip)(() => ({
  maxWidth: "none",
}));

export const StyledTooltip = styled(Tooltip)(() => ({
  maxWidth: "none",
  display: "inline-block",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
}));
