import React from "react";

import { Box, Typography } from "@mui/material";

import { LoadingCircularProgress } from "../../components";

import localConfig from "../../config.json";

const httpConfig = localConfig.http;

import { useGetVersionQuery } from ".";

export const ApiVersion = () => {
  const { isLoading, data: version } = useGetVersionQuery();

  if (isLoading) {
    return <LoadingCircularProgress />;
  }

  return (
    <Box>
      <Typography variant="h6">
        API Version {version.short}
        <Typography variant="body2">{version.long}</Typography>
      </Typography>
      <Typography variant="body2">{httpConfig.apiUrl}</Typography>
    </Box>
  );
};
