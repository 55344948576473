import { useCallback, useMemo } from "react";

import { isNil } from "../../helpers";
import { useGetMyOrganizationQuery } from "../organization";
import { useGetBillingQuery } from ".";

export const useBilling = () => {
  const {
    isLoading: isLoadingOrganization,
    data: organization,
    refetch: refetchOrganization,
  } = useGetMyOrganizationQuery();

  const billingId = useMemo(() => {
    if (
      isLoadingOrganization ||
      organization === undefined ||
      organization.billingId === undefined ||
      isNil(organization.billingId)
    ) {
      return undefined;
    }
    return organization.billingId;
  }, [isLoadingOrganization, organization]);

  const {
    isLoading: isLoadingBilling,
    isUninitialized: isUninitializedBilling,
    data,
    refetch: refetchBilling,
  } = useGetBillingQuery(billingId, {
    skip: billingId === undefined,
  });

  const isLoading = useMemo(() => {
    return isLoadingBilling || isLoadingOrganization;
  }, [isLoadingBilling, isLoadingOrganization]);

  const refetch = useCallback(() => {
    refetchOrganization();
    if (!isUninitializedBilling) {
      refetchBilling();
    }
  }, [isUninitializedBilling, refetchBilling, refetchOrganization]);

  const {
    name,
    description,
    features,
    renewal,
    interval,
    isTrial,
    trialDescription,
    liveMode,
    providerType,
  } = useMemo(() => {
    var renewal = new Date();
    var name = "";
    var description = "";
    var features = [];
    var interval = "";
    var isTrial = false;
    var trialDescription = "";
    var liveMode = false;
    var providerType = "manual";

    if (isLoading || data === undefined || data.provider === undefined) {
      return {
        name,
        description,
        renewal,
        interval,
        isTrial,
        trialDescription,
      };
    }

    renewal = new Date(data.provider.subscription.tsPeriodEnd * 1000);

    name = data.provider.subscription.items[0].name;

    description = data.provider.subscription.items[0].description;

    interval = data.provider.subscription.items[0].interval;

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const trialEnds = new Date(
      data.provider.subscription.tsTrialEnd * 1000
    ).toLocaleDateString("en-us", options);

    isTrial = data.provider.subscription.status === "trialing";

    trialDescription = "trial ends on " + trialEnds;

    liveMode = data.provider.subscription.liveMode || false;

    features = data.provider.subscription.features;

    providerType = data.provider.type;

    return {
      name,
      description,
      features,
      renewal,
      interval,
      isTrial,
      trialDescription,
      liveMode,
      providerType,
    };
  }, [isLoading, data]);

  return {
    isLoading,
    data,
    refetch,
    name,
    description,
    features,
    renewal,
    interval,
    isTrial,
    trialDescription,
    liveMode,
    providerType,
  };
};
