import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  CardContent,
  CardHeader,
  Grid,
  Switch,
  Typography,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import dayjs from "dayjs";

import { LoadingCircularProgress, StyledCard } from "../../components";
import { useGetMyAccountQuery } from "../account";
import { useSaveMyAccountNotifications } from ".";

export const DoNotDisturb = () => {
  const dispatch = useDispatch();

  const { isLoading, data: account } = useGetMyAccountQuery();

  const saveMyAccountNotifications = useSaveMyAccountNotifications();

  const doNotDisturb = useMemo(() => {
    if (isLoading) {
      return {};
    }

    var dnd = {
      ...account.notifications.doNotDisturb,
    };
    dnd.start = dayjs(dnd.start, "YYYY-MM-DDTHH:mm:ss.SSSZ");
    dnd.end = dayjs(dnd.end, "YYYY-MM-DDTHH:mm:ss.SSSZ");

    return dnd;
  }, [account, isLoading]);

  const handleChange = (event) => {
    if (isLoading) {
      return;
    }

    const { name, checked } = event.target;
    const newNotifications = {
      ...account.notifications,
      doNotDisturb: { ...account.notifications.doNotDisturb, [name]: checked },
    };
    dispatch(saveMyAccountNotifications(account.id, newNotifications));
  };

  const handleTimeChange = (id, data) => {
    if (isLoading) {
      return;
    }

    // const value = new Date(data).toISOString();
    const value = data == null ? "null" : data.format();
    const newNotifications = {
      ...account.notifications,
      doNotDisturb: { ...account.notifications.doNotDisturb, [id]: value },
    };
    dispatch(saveMyAccountNotifications(account.id, newNotifications));
  };

  const title = "Do Not Disturb";
  const description = "Automatically disable notifications:";

  if (isLoading) {
    return <LoadingCircularProgress />;
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledCard>
          <CardHeader
            title={
              <div>
                <Grid container spacing={1}>
                  <Grid item lg={8} md={8} xl={8} xs={8}>
                    <Typography variant="h5">{title}</Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={3}>
                    <Typography
                      variant="body1"
                      sx={{
                        display: "inline",
                        float: "right",
                        justifyContent: "center",
                        alignContent: "center",
                        paddingTop: "5px",
                        height: "20px",
                      }}
                    ></Typography>
                  </Grid>
                  <Grid item lg={1} md={1} xl={1} xs={1}>
                    <Switch
                      checked={doNotDisturb.enabled}
                      onClick={handleChange}
                      color="primary"
                      name="enabled"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      sx={{
                        float: "right",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            }
            sx={{
              paddingBottom: "1px",
            }}
          />
          <CardContent
            sx={{
              paddingTop: "1px",
            }}
          >
            <Grid container spacing={0}>
              <Grid item lg={8} md={8} xl={8} xs={8}>
                <Typography
                  variant="body1"
                  sx={{
                    color: doNotDisturb.enabled
                      ? "text.primary"
                      : "text.disabled",
                    paddingBottom: "15px",
                  }}
                >
                  {description}
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <Typography></Typography>
              </Grid>
              <Grid item lg={8} md={8} xl={8} xs={8}>
                <TimePicker
                  value={doNotDisturb.start}
                  disabled={!doNotDisturb.enabled}
                  onChange={(newValue) => {
                    handleTimeChange({
                      target: {
                        name: "start",
                        value: newValue,
                      },
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: doNotDisturb.enabled
                      ? "text.primary"
                      : "text.disabled",
                    display: "inline",
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  to
                </Typography>
                <TimePicker
                  value={doNotDisturb.end}
                  disabled={!doNotDisturb.enabled}
                  onChange={(newValue) => {
                    handleTimeChange({
                      target: {
                        name: "end",
                        value: newValue,
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <Typography></Typography>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
      </LocalizationProvider>
    </>
  );
};
