import React from "react";

import { Box } from "@mui/material";

import { SearchResultListing } from "./SearchResultListing";

export const Search = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <SearchResultListing />
    </Box>
  );
};
