/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import PauseIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import ResumeIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import RetryIcon from "@mui/icons-material/ReplayOutlined";

export const UploadProgress = (props) => {
  const {
    denominator,
    message,
    numerator,
    onCancel,
    onPause,
    onResume,
    onRetry,
    state,
    value,
    units,
  } = props;
  const [variant, setVariant] = useState("buffer");
  const [isRunning, setIsRunning] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (
      state === "start" ||
      state === "put" ||
      state === "completed" ||
      state === "error"
    ) {
      setVariant("determinate");
    } else {
      setVariant("buffer");
    }
    setIsRunning(state === "start" || state === "put");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        {/* <LinearProgress variant="determinate" {...props} /> */}
        <LinearProgress
          variant={variant}
          value={value}
          valueBuffer={0}
          // {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {value}%
        </Typography>
      </Box>
      <Box minWidth={100}>
        <Typography variant="body2" align="center">
          {numerator}/{denominator} {units}
        </Typography>
      </Box>
      {/* TODO: Implement pause / resume uploadBlob task */}
      <Box minWidth={30}>
        <Tooltip
          title={isRunning ? "cancel" : "retry"}
          aria-label={isRunning ? "cancel" : "retry"}
          hidden={state == "completed"}
        >
          <span>
            <IconButton
              edge="end"
              size="small"
              onClick={isRunning ? onCancel : onRetry}
              disabled={state == "completed"}
              style={
                state == "completed"
                  ? { pointerEvents: "none", opacity: 0 }
                  : { opacity: 1 }
              }
            >
              {isRunning ? (
                <CancelIcon size="sm" sx={{ color: theme.palette.icon }} />
              ) : (
                <RetryIcon size="sm" sx={{ color: theme.palette.icon }} />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box minWidth={30}>
        <div hidden={state != "error"}>
          <Tooltip title={message} aria-label={message}>
            <ErrorIcon
              size="sm"
              style={{
                opacity: state == "error" ? 1 : 0,
              }}
              sx={{ color: theme.palette.error.main }}
            />
          </Tooltip>
        </div>
        <div hidden={state == "error"}>
          <Tooltip title={message} aria-label={message}>
            <SuccessIcon
              size="sm"
              style={{
                opacity: state == "completed" ? 1 : 0,
              }}
              sx={{ color: theme.palette.success.main }}
            />
          </Tooltip>
        </div>
      </Box>
    </Box>
  );
};

UploadProgress.propTypes = {
  denominator: PropTypes.number.isRequired,
  message: PropTypes.string,
  numerator: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onResume: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  state: PropTypes.string,
  value: PropTypes.number.isRequired,
  units: PropTypes.string.isRequired,
};
