/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import Logger from "js-logger";

import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useDropzone } from "react-dropzone";

import { api } from "../api/apiSlice";

import { UploadBlob } from ".";
import { close } from "./uploadBlobsSlice";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
}));

const logger = Logger.get("UploadDialog");

export const UploadDialog = (props) => {
  const { currentPath, asset, open, onRedirect } = props;
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [registrationCompleted, setRegistrationCompleted] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [folder, setFolder] = useState("");
  const [overwrite, setOverwrite] = useState(true);
  const [registrations, setRegistrations] = useState(new Map());

  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();

  const handleFolderChange = (event) => {
    const newFolder = event.target.value
      .replace(/^\//, "")
      .replace(/\/\//, "/")
      .replace(/[|\\:;*?"<>]/g, "");
    setFolder(newFolder);
  };

  const handleOverwriteChange = (event) => {
    logger.debug("handleOverwriteChange: ", event.target.checked);
    setOverwrite(event.target.checked);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const files = [];

    acceptedFiles.forEach((file) => {
      logger.debug("onDrop: file: ", file);
      files.push(file);
    });

    setFiles(files);

    return [];
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (isUploadOpen && !open) {
      setIsUploadOpen(false);
      setUploading(false);
      setCancelling(false);
      setUploadCompleted(false);
      setRegistrationCompleted(false);
      setFiles([]);
      setFolder("");
      setInfoMessage("");
      setOverwrite(true);

      dispatch(api.util.invalidateTags(["ContainerUsage", "Tasks"]));

      if (onRedirect !== null) {
        onRedirect();
      }
    } else if (!isUploadOpen && open) {
      setIsUploadOpen(true);
      setUploading(false);
      setCancelling(false);
      setUploadCompleted(false);
      setRegistrationCompleted(false);
      setFiles([]);
      setFolder(currentPath);
      setInfoMessage("");
      setOverwrite(true);
      setRegistrations(new Map());
    }
  }, [currentPath, dispatch, onRedirect, open, isUploadOpen]);

  const handleCancel = () => {
    setCancelling(true);
  };

  const handleUpload = () => {
    setInfoMessage("");
    setUploading(true);
  };

  const handleRegister = (requestId) => {
    let r = registrations;
    r.set(requestId, "register");
    logger.debug("handleRegister:", requestId);

    let registrationCount = 0;
    // eslint-disable-next-line no-unused-vars
    for (let value of r.values()) {
      // logger.debug("handleRegister.value:", value, registrationCount);
      registrationCount++;
    }

    if (!registrationCompleted && registrationCount == files.length) {
      logger.debug("handleRegister: completed");
      setRegistrationCompleted(true);
      setInfoMessage(
        "uploads have started in background, now safe to close this window"
      );
    }

    setRegistrations(r);
  };

  const handleUnregister = (requestId) => {
    let r = registrations;

    registrations.delete(requestId);
    logger.debug("handleUnregister:", requestId);

    if (registrationCompleted) {
      logger.debug("handleUnregister: not completed");
      setRegistrationCompleted(false);
      setInfoMessage("");
      setUploadCompleted(false);
    }

    setRegistrations(r);
  };

  const handleComplete = (requestId, state) => {
    let r = registrations;

    logger.debug("handleComplete.enter:", requestId, state);

    r.set(requestId, state);

    let completedCount = 0;
    let errorCount = 0;
    for (let value of r.values()) {
      logger.debug("handleComplete.value:", value, completedCount, errorCount);

      if (value == "completed") {
        completedCount++;
      } else if (value == "error") {
        completedCount++;
        errorCount++;
      }
    }

    if (completedCount == files.length) {
      logger.debug("handleComplete: completed");
      setUploadCompleted(true);
      setInfoMessage(
        "Upload" +
          (completedCount > 1 ? "s have" : " has") +
          " completed" +
          (errorCount > 0 ? " with " + errorCount + " errors" : "")
      );
    }

    setRegistrations(r);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      onClose={() => dispatch(close())}
      aria-labelledby="upload-dialog-title"
      open={isUploadOpen}
      sx={{
        // TODO: Make responsive
        flex: 1,
        paddingTop: 2,
      }}
    >
      <DialogTitle>
        Upload Files {asset ? "to '" + asset.name + "'" : ""}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => dispatch(close())}
        sx={{
          position: "absolute",
          right: 1,
          top: 1,
          // color: theme.palette.grey[500],
          // color: "grey[500]",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
            <TextField
              fullWidth
              helperText="Full folder path (/ as path separator)"
              label="Folder Path"
              margin="dense"
              size="small"
              variant="outlined"
              onChange={handleFolderChange}
              value={folder}
              disabled={uploadCompleted || uploading}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <FormGroup sx={{ float: "right" }}>
              <Tooltip
                title={
                  <div>
                    Overwrite any existing files in the cloud destination
                  </div>
                }
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={overwrite}
                      onChange={handleOverwriteChange}
                      name="overwriteSwitch"
                      color="primary"
                      disabled={uploadCompleted || uploading}
                    />
                  }
                  label="Overwrite"
                  sx={{ paddingTop: "5px" }}
                />
              </Tooltip>
            </FormGroup>
          </Grid>
        </Grid>
        <div {...getRootProps({ className: classes.dropzone })}>
          <input disabled={uploadCompleted || uploading} {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag &amp; drop some files here, or click to select files.</p>
          )}
        </div>
        <div>
          {asset &&
            Object.keys(asset).length > 0 &&
            asset.container &&
            Object.keys(asset.container).length > 0 &&
            files.map((file) => (
              <Box
                key={file.name}
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  margin: 0,
                  width: "100%",
                  borderBottom: "none",
                  alignItems: "flex-start",
                  justifyItems: "flex-start",
                }}
              >
                <UploadBlob
                  assetId={asset.id}
                  assetName={asset.name}
                  cancelling={cancelling}
                  containerId={asset.container.id}
                  file={file}
                  folderPath={folder}
                  onComplete={handleComplete}
                  onRegister={handleRegister}
                  onUnregister={handleUnregister}
                  organizationId={asset.organizationId}
                  overwrite={overwrite}
                  registered={registrationCompleted}
                  shareId={asset.shareId}
                  uploading={uploading}
                />
              </Box>
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <div>
          <Typography
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              margin: 0,
              width: "100%",
              padding: 2,
              // color: theme.palette.info.main,
              color: "info.main",
            }}
          >
            {infoMessage}
          </Typography>
        </div>
        <div hidden={uploading || uploadCompleted}>
          <Button
            color="primary"
            variant="text"
            autoFocus
            disabled={
              !asset ||
              Object.keys(asset).length == 0 ||
              files.length <= 0 ||
              uploadCompleted ||
              uploading
            }
            onClick={handleUpload}
          >
            Upload
          </Button>
        </div>
        <Button
          color="primary"
          variant="text"
          autoFocus
          disabled={files.length <= 0}
          onClick={
            !uploading || uploadCompleted || registrationCompleted
              ? () => dispatch(close())
              : handleCancel
          }
        >
          {!uploading || uploadCompleted || registrationCompleted
            ? "Close"
            : "Cancel"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UploadDialog.propTypes = {
  currentPath: PropTypes.string,
  asset: PropTypes.object,
  onRedirect: PropTypes.func,
  open: PropTypes.bool,
};
