import React, { useContext, useEffect } from "react";

import Logger from "js-logger";

import { default as AuthContext } from "./AuthContext";
import { LoadingCircularProgress } from "..";

export const Signout = () => {
  var logger = Logger.get("CallSignoutback");
  const auth = useContext(AuthContext);
  const { logout } = auth;

  useEffect(() => {
    logger.debug("useEffect:");
    logout();
  });

  return <LoadingCircularProgress />;
};
