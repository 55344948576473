import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectSelectedRows } from "../../components";
import { isEditableRole, isOwnerRole } from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";

export const useSelectedAssets = (assets) => {
  const { data: account } = useGetMyAccountQuery();
  const { data: organization } = useGetMyOrganizationQuery();
  const selectedRows = useSelector(selectSelectedRows);

  return useMemo(() => {
    let selectedAssets = [];
    let hasEditableRole = isEditableRole(account, organization, null);
    let hasOwnerRole = isOwnerRole(account, organization, null);
    let hasAnyExternalAssets = false;

    if (assets.data && assets.data.data) {
      selectedAssets = assets.data.data.filter((asset) =>
        selectedRows.includes(asset.id)
      );

      const selectedNotInMyOrganization = selectedAssets.filter(
        (x) => x.organizationId !== organization.id
      );
      hasAnyExternalAssets = selectedNotInMyOrganization.length > 0;
    }

    const selectedAsset = selectedAssets.length == 1 && selectedAssets[0];
    const hasSelectedAssets = selectedAssets.length > 0;

    return {
      hasAnyExternalAssets,
      hasEditableRole,
      hasSelectedAssets,
      hasOwnerRole,
      selectedAssets,
      selectedAsset,
    };
  }, [account, organization, selectedRows, assets.data]);
};
