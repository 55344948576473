/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import { Box, colors, Typography } from "@mui/material";

export const StripeMode = (props) => {
  const { highlight, isTrial, liveMode, trialDescription } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignContent: "flex-start",
        textAlign: "left",
        display: "flex",
        paddingBottom: 1,
      }}
    >
      {highlight !== undefined && highlight.length > 0 ? (
        <Box
          sx={{
            alignContent: "flex-start",
            display: "table",
            paddingRight: theme.spacing(1),
          }}
        >
          <Typography
            variant="caption"
            sx={{
              backgroundColor: theme.palette.primary.light,
              textTransform: "uppercase",
              padding: "4px",
              borderRadius: "2px",
            }}
          >
            {highlight}
          </Typography>
        </Box>
      ) : (
        <div />
      )}
      {isTrial !== undefined && isTrial === true ? (
        <Box
          sx={{
            alignContent: "flex-start",
            display: "table",
            paddingRight: theme.spacing(1),
          }}
        >
          <Typography
            variant="caption"
            sx={{
              backgroundColor: colors.amber["200"],
              color: colors.red["700"],
              textTransform: "uppercase",
              padding: "4px",
              borderRadius: "2px",
            }}
          >
            {trialDescription}
          </Typography>
        </Box>
      ) : (
        <div />
      )}
      {liveMode !== undefined && liveMode === false ? (
        <Box
          sx={{
            alignContent: "flex-start",
            display: "table",
            paddingRight: theme.spacing(1),
          }}
        >
          <Typography
            variant="caption"
            sx={{
              backgroundColor: colors.amber["200"],
              color: colors.red["700"],
              textTransform: "uppercase",
              padding: "4px",
              borderRadius: "2px",
            }}
          >
            test mode
          </Typography>
        </Box>
      ) : (
        <div />
      )}
    </Box>
  );
};

StripeMode.propTypes = {
  highlight: PropTypes.string,
  isTrial: PropTypes.bool,
  liveMode: PropTypes.bool,
  trialDescription: PropTypes.string,
};
