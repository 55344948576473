import Logger from "js-logger";
import { logToastDebug, logToastError } from "../../components";

import { useGenerateFloorplanMutation, useBlobMutation } from ".";

const logger = Logger.get("useGenerateFloorplan");

export const formatGenerateFloorplanMessage = (blob, data, err) => {
  if ((blob, data)) {
    return (
      `floorplan ${blob.name} ` +
      (err === undefined
        ? `generated: ${data.blob.metadata.floorplanResultLocation}) `
        : `generation failed: ${err}`)
    );
  }
};

export const useGenerateFloorplan = () => {
  const configuration = {
    metadata: {},
  };

  const generateFloorplan = useBlobMutation(
    useGenerateFloorplanMutation,
    (blob, data) => {
      if (data.blob && data.blob.metadata) {
        if (data.blob.metadata.floorplanResultLocation) {
          logToastDebug(logger, formatGenerateFloorplanMessage(blob, data));
          return;
        } else if (data.blob.metadata.floorplanError) {
          if (!data.blob.metadata.floorplanError.includes("in progress")) {
            const err = new Error(data.blob.metadata.floorplanError);
            logToastError(
              logger,
              formatGenerateFloorplanMessage(blob, data, err)
            );
            return;
          }
        }
      }
      const msg = `floorplan '${blob.name}' generation in progress`;
      logToastDebug(logger, msg);
    },
    (blob, error) => {
      logToastError(logger, formatGenerateFloorplanMessage(blob, {}, error));
    }
  );

  return (blob) => () => {
    generateFloorplan({ blob, configuration });
  };
};
