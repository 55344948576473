import { useHistory } from "react-router-dom";

export const useOpenShare = () => {
  const history = useHistory();

  return (share) => {
    if (share && share.id) {
      history.push(`/share/asset/${share.id}`);
    }
  };
};
