import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { ActionBarCard, StyledButton } from "../../components";
import { useOpenUploadBlobsDialog } from "../blobs";
import { useInviteShare } from "../share";
import {
  useCopyShareAsset,
  useDeleteShareAsset,
  useOpenShareAsset,
  useSelectedAssets,
  useUpsertShareAsset,
} from ".";

export const ActionBar = ({ shareAssets }) => {
  const {
    hasAnyExternalAssets,
    hasEditableRole,
    hasSelectedAssets,
    hasOwnerRole,
    selectedAssets,
    selectedAsset,
  } = useSelectedAssets(shareAssets);
  const openShareAsset = useOpenShareAsset();
  const upsertShareAsset = useUpsertShareAsset();
  const copyShareAsset = useCopyShareAsset();
  const deleteShareAsset = useDeleteShareAsset();

  const inviteShare = useInviteShare();

  const openUploadBlobsDialog = useOpenUploadBlobsDialog();

  const renderUpsert = () =>
    hasEditableRole &&
    !hasSelectedAssets &&
    !hasAnyExternalAssets && (
      <StyledButton
        id="actionbar-asset-upsert"
        variant="contained"
        onClick={upsertShareAsset()}
      >
        Create
      </StyledButton>
    );

  const renderOpen = () =>
    selectedAsset && (
      <StyledButton
        id="actionbar-open"
        variant="outlined"
        onClick={() => openShareAsset(selectedAsset)}
      >
        Open
      </StyledButton>
    );

  const renderInviteShare = useCallback(() => {
    if (hasEditableRole && hasSelectedAssets && !hasAnyExternalAssets) {
      return (
        <StyledButton
          id="actionbar-invite-share"
          variant="outlined"
          onClick={inviteShare(selectedAssets)}
        >
          Invite
        </StyledButton>
      );
    }
    return false;
  }, [
    hasAnyExternalAssets,
    hasEditableRole,
    selectedAssets,
    hasSelectedAssets,
    inviteShare,
  ]);

  const renderUpload = () =>
    selectedAsset &&
    hasEditableRole && (
      <StyledButton
        id="upload-dialog-button"
        variant="outlined"
        onClick={openUploadBlobsDialog(selectedAsset.asset)}
      >
        Upload
      </StyledButton>
    );

  const renderCopyAsset = () =>
    selectedAsset &&
    hasEditableRole &&
    !hasAnyExternalAssets && (
      <StyledButton
        id="actionbar-asset-copy"
        variant="outlined"
        onClick={copyShareAsset(selectedAsset)}
      >
        Copy
      </StyledButton>
    );

  const renderDeleteAsset = () =>
    hasSelectedAssets &&
    hasOwnerRole &&
    !hasAnyExternalAssets && (
      <StyledButton
        id="actionbar-delete-asset"
        variant="outlined"
        onClick={deleteShareAsset(selectedAssets)}
      >
        Delete
      </StyledButton>
    );

  return (
    <Box sx={{ backgroundColor: "background.default" }}>
      <ActionBarCard
        buttons={[
          renderUpsert,
          renderInviteShare,
          renderOpen,
          renderUpload,
          renderCopyAsset,
          renderDeleteAsset,
        ]}
      />
    </Box>
  );
};

ActionBar.propTypes = {
  shareAssets: PropTypes.object.isRequired,
};
