import React, { useCallback, useEffect, useMemo, useState } from "react";
import Logger from "js-logger";

import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
} from "@mui/material";

import {
  LoadingCircularProgress,
  logToastDebug,
  logToastError,
  StyledCard,
  StyledReadOnly,
} from "../../components";
import { isOwnerRole } from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import {
  useGetMyOrganizationQuery,
  useSaveOrganizationMutation,
  useValidateOrganizationMutation,
} from "../organization";

const logger = Logger.get("OrganizationForm");

export const OrganizationForm = () => {
  const [title] = useState("Details");
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({ name: "" });

  const { data: account } = useGetMyAccountQuery();
  const { data: organization } = useGetMyOrganizationQuery();

  const [org, setOrg] = useState({ name: "" });

  const [validateOrganization] = useValidateOrganizationMutation();
  const [saveOrganization] = useSaveOrganizationMutation();

  const { isOwner } = useMemo(() => {
    const isOwner = isOwnerRole(account, organization, null);
    return { isOwner };
  }, [account, organization]);

  useEffect(() => {
    if (organization) {
      setOrg(organization);
    }
  }, [organization]);

  function validate(field, value) {
    if (field == "name") {
      // the organizations's name needs to become a valid folder
      // name when used with desktop agent

      // eslint-disable-next-line no-useless-escape
      value = value.replace(/[\/|\\:;*?"<>]/g, "");
    }

    setOrg((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  function handleCancel() {
    setOrg(organization);
    setEditing(false);
    setErrors({ name: "" });
    setSaving(false);
  }

  function handleChange(event) {
    if (saving) {
      logger.debug("saving in progress, skipping");
      return;
    }

    const { name, value } = event.target;
    validate(name, value);
  }

  const handleSave = useCallback(async () => {
    if (organization.name == org.name) {
      logger.debug("nothing changed, skipping save changes", org, organization);
      return;
    }

    // the organization's name needs to become a valid folder
    // name when used with desktop agent
    org.name = org.name
      // eslint-disable-next-line no-useless-escape
      .replace(/[\/|\\:;*?"<>]/g, "")
      .trim()
      // eslint-disable-next-line no-useless-escape
      .replace(/[\. ]+$/, "");

    let formError = "";
    const newOrg = {
      ...organization,
      name: org.name,
    };

    try {
      setSaving(true);

      const validatedOrg = await validateOrganization(org).unwrap();
      if (validatedOrg === false) {
        formError =
          "duplicate organization name '" + newOrg.name + "' already exists";
      }
    } catch (err) {
      const msg = `invalid organization name '${org.name}'`;
      logToastError(logger, msg, err);
    } finally {
      setSaving(false);
    }

    setErrors((prev) => ({
      ...prev,
      name: formError,
    }));

    if (formError !== "") {
      return;
    }

    try {
      setSaving(true);
      const updatedOrg = await saveOrganization(org).unwrap();
      setOrg(updatedOrg);
      setEditing(false);

      const msg = `organization name '${updatedOrg.name}' updated`;
      logToastDebug(logger, msg);
    } catch (err) {
      const msg = `update organization name '${org.name}' failed`;
      logToastError(logger, msg, err);
    } finally {
      setSaving(false);
    }
  }, [org, organization, saveOrganization, validateOrganization]);

  if (!org || Object.keys(org).length === 0) {
    return <LoadingCircularProgress />;
  }

  return (
    <StyledCard>
      <CardHeader
        title={title}
        action={
          <div hidden={!isOwner || editing}>
            <Button
              variant="text"
              color="primary"
              onClick={() => setEditing(true)}
              margin="dense"
              size="small"
            >
              Edit
            </Button>
          </div>
        }
      />
      <Divider />
      <CardContent>
        {editing ? (
          <TextField
            fullWidth
            label="Name"
            margin="dense"
            size="small"
            id="organizationName"
            name="name"
            onChange={handleChange}
            required={true}
            variant="outlined"
            error={errors.name !== ""}
            helperText={errors.name}
            value={org.name}
          />
        ) : (
          <StyledReadOnly label="Name" value={org.name} />
        )}
      </CardContent>
      {editing ? (
        <CardActions sx={{ paddingTop: 0, marginTop: 0 }}>
          <Typography
            hidden={true}
            sx={{
              flex: 1,
              display: "flex",
              width: "100%",
            }}
          />
          <Button
            variant="outlined"
            autoFocus
            onClick={handleCancel}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="outlined"
            autoFocus
            onClick={handleSave}
            disabled={saving}
            sx={{ marginRight: 1 }}
          >
            {saving ? "Saving..." : "Save"}
          </Button>
        </CardActions>
      ) : (
        <></>
      )}
    </StyledCard>
  );
};
