import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Form } from "react-final-form";
import { makeValidate } from "mui-rff";
import arrayMutators from "final-form-arrays";
import * as Yup from "yup";

import {
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { LoadingCircularProgress, StyledCard } from "../../components";
import { useGetMyAccountQuery } from "../account";
import { useSaveMyAccountPreferences } from ".";

// Reference:
// https://knowledge.autodesk.com/support/autocad-architecture/learn-explore/caas/CloudHelp/cloudhelp/2018/ENU/AutoCAD-Architecture/files/GUID-652DA67D-C364-43C6-A71D-FD1E676A0374-htm.html

const lengthTypeList = [
  "decimal",
  "architectural",
  "engineering",
  "fractional",
  "scientific",
];

const lengthPrecisionList = {
  architectural: [
    "0'-0\"",
    "0'-0 1/2\"",
    "0'-0 1/4\"",
    "0'-0 1/8\"",
    "0'-0 1/16\"",
    "0'-0 1/32\"",
    "0'-0 1/64\"",
    "0'-0 1/128\"",
    "0'-0 1/256\"",
  ],
  decimal: [
    "0",
    "0.0",
    "0.00",
    "0.000",
    "0.0000",
    "0.00000",
    "0.000000",
    "0.0000000",
    "0.00000000",
  ],
  engineering: [
    "0'-0\"",
    "0'-0.0\"",
    "0'-0.00\"",
    "0'-0.000\"",
    "0'-0.0000\"",
    "0'-0.00000\"",
    "0'-0.000000\"",
    "0'-0.0000000\"",
    "0'-0.00000000\"",
  ],
  fractional: [
    "0",
    "0 1/2",
    "0 1/4",
    "0 1/8",
    "0 1/16",
    "0 1/32",
    "0 1/64",
    "0 1/128",
    "0 1/256",
  ],
  scientific: [
    "0E+01",
    "0.0E+01",
    "0.00E+01",
    "0.000E+01",
    "0.0000E+01",
    "0.00000E+01",
    "0.000000E+01",
    "0.0000000E+01",
    "0.00000000E+01",
    "0.000000000E+01",
  ],
};

const lengthUnitList = ["m", "ft"];

const angleTypeList = [
  "decimal degrees",
  "deg/min/sec",
  "grads",
  "radians",
  "surveyor's units",
];

const anglePrecisionList = {
  "decimal degrees": [
    "0",
    "0.0",
    "0.00",
    "0.000",
    "0.0000",
    "0.00000",
    "0.000000",
    "0.0000000",
    "0.00000000",
  ],
  "deg/min/sec": [
    "0d",
    "0d00'",
    "0d00'00\"",
    "0d00'00.0\"",
    "0d00'00.00\"",
    "0d00'00.000\"",
    "0d00'00.0000\"",
  ],
  grads: [
    "0g",
    "0.0g",
    "0.00g",
    "0.000g",
    "0.0000g",
    "0.00000g",
    "0.000000g",
    "0.0000000g",
    "0.00000000g",
  ],
  radians: [
    "0r",
    "0.0r",
    "0.00r",
    "0.000r",
    "0.0000r",
    "0.00000r",
    "0.000000r",
    "0.0000000r",
    "0.00000000r",
  ],
  "surveyor's units": [
    "N 0d E",
    "N 0d00' E",
    "N 0d00'00\" E",
    "N 0d00'00.0\" E",
    "N 0d00'00.00\" E",
    "N 0d00'00.000\" E",
    "N 0d00'00.0000\" E",
  ],
};

const angleBaseList = {
  "decimal degrees": ["0.00", "90.00", "180.00", "270.00"],
  "deg/min/sec": ["0d0'", "90d0'", "180d0'", "270d0'"],
  grads: ["0.00g", "100.00g", "200.00g", "300.00g"],
  radians: ["0.00r", "1.57r", "3.14r", "4.71r"],
  "surveyor's units": ["E", "N", "W", "S"],
};

// TODO: Revisit area measurements
const areaTypeList = ["square meters", "square feet"];

const areaPrecisionList = {
  "square meters": [
    "0",
    "0.0",
    "0.00",
    "0.000",
    "0.0000",
    "0.00000",
    "0.000000",
    "0.0000000",
    "0.00000000",
  ],
  "square feet": [
    "0",
    "0.0",
    "0.00",
    "0.000",
    "0.0000",
    "0.00000",
    "0.000000",
    "0.0000000",
    "0.00000000",
  ],
};

const areaUnitList = {
  "square meters": ["sq m"],
  "square feet": ["sq ft"],
};

// TODO: Revisit volume measurements
const volumeTypeList = ["cubic meters", "cubic feet"];

const volumePrecisionList = {
  "cubic meters": [
    "0",
    "0.0",
    "0.00",
    "0.000",
    "0.0000",
    "0.00000",
    "0.000000",
    "0.0000000",
    "0.00000000",
  ],
  "cubic feet": [
    "0",
    "0.0",
    "0.00",
    "0.000",
    "0.0000",
    "0.00000",
    "0.000000",
    "0.0000000",
    "0.00000000",
  ],
};

const volumeUnitList = {
  "cubic meters": ["cu m"],
  "cubic feet": ["cu ft"],
};

export const MeasurementsForm = () => {
  const title = "Measurements";
  const [length, setLength] = useState({
    precision: "0.00",
    type: "decimal",
    units: "m",
  });
  const [angle, setAngle] = useState({
    baseAngle: "0.00",
    precision: "0.00",
    type: "decimal degrees",
  });
  const [area, setArea] = useState({
    precision: "0.00",
    type: "square meters",
    units: "sq m",
  });
  const [volume, setVolume] = useState({
    precision: "0.00",
    type: "cubic meters",
    units: "cu m",
  });

  const dispatch = useDispatch();

  const { isLoading, data: account } = useGetMyAccountQuery();

  const saveMyAccountPreferences = useSaveMyAccountPreferences();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setLength(account.preferences.measurements.length);
    setAngle(account.preferences.measurements.angle);
    setArea(account.preferences.measurements.area);
    setVolume(account.preferences.measurements.volume);
  }, [account, isLoading]);

  const schema = Yup.object().shape({
    length: Yup.array().of(
      Yup.object()
        .shape({
          type: Yup.string().required(),
          precision: Yup.string().required(),
          units: Yup.string().required,
        })
        .nullable()
    ),
    angle: Yup.array().of(
      Yup.object()
        .shape({
          type: Yup.string().required(),
          precision: Yup.string().required(),
          baseAngle: Yup.string().required,
        })
        .nullable()
    ),
    area: Yup.array().of(
      Yup.object()
        .shape({
          type: Yup.string().required(),
          precision: Yup.string().required(),
          units: Yup.string().required,
        })
        .nullable()
    ),
    volume: Yup.array().of(
      Yup.object()
        .shape({
          type: Yup.string().required(),
          precision: Yup.string().required(),
          units: Yup.string().required,
        })
        .nullable()
    ),
  });

  const validateSchema = makeValidate(schema);

  async function validate(values) {
    // logger.debug("validateSchema: ", await validateSchema(values));
    return validateSchema(values);
  }

  function handleLengthChange(event) {
    const { name, value } = event.target;
    // logger.debug("handleLengthChange: name, value:", name, value);
    if (name == "type") {
      setLength((prev) => ({
        ...prev,
        [name]: value,
        precision: lengthPrecisionList[value][0],
      }));
    } else {
      setLength((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }
  function handleAngleChange(event) {
    const { name, value } = event.target;
    // logger.debug("handleAngleChange: name, value:", name, value);
    if (name == "type") {
      setAngle((prev) => ({
        ...prev,
        [name]: value,
        precision: anglePrecisionList[value][0],
        baseAngle: angleBaseList[value][0],
      }));
    } else {
      setAngle((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }
  function handleAreaChange(event) {
    const { name, value } = event.target;
    // logger.debug("handleAreaChange: name, value:", name, value);
    if (name == "type") {
      setArea((prev) => ({
        ...prev,
        [name]: value,
        precision: areaPrecisionList[value][0],
        units: areaUnitList[value][0],
      }));
    } else {
      setArea((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }
  function handleVolumeChange(event) {
    const { name, value } = event.target;
    // logger.debug("handleVolumeChange: name, value:", name, value);
    if (name == "type") {
      setVolume((prev) => ({
        ...prev,
        [name]: value,
        precision: volumePrecisionList[value][0],
        units: volumeUnitList[value][0],
      }));
    } else {
      setVolume((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }

  async function onSubmit() {
    const newPreferences = {
      ...account.preferences,
      measurements: {
        length: { ...length },
        angle: { ...angle },
        area: { ...area },
        volume: { ...volume },
      },
    };
    dispatch(saveMyAccountPreferences(account.id, newPreferences));
  }

  if (isLoading) {
    return <LoadingCircularProgress />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={account.preferences}
      subscription={{ submitting: true, pristine: true }}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
      // eslint-disable-next-line no-unused-vars
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <StyledCard>
            <CardHeader title={title} />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <Typography>Length</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-length-type-outlined-label"
                    >
                      Type
                    </InputLabel>
                    <Select
                      labelId="select-length-type-outlined"
                      id="select-length-type-outlined"
                      value={length.type}
                      name="type"
                      onBlur={handleSubmit}
                      onChange={handleLengthChange}
                      margin="dense"
                      size="small"
                      label="Type"
                      disabled={false}
                    >
                      {lengthTypeList.map((row, index) => {
                        const rowId = `length-type-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-length-precision-outlined-label"
                    >
                      Precision
                    </InputLabel>
                    <Select
                      labelId="select-length-precision-outlined"
                      id="select-length-precision-outlined"
                      value={length.precision}
                      name="precision"
                      onBlur={handleSubmit}
                      onChange={handleLengthChange}
                      margin="dense"
                      size="small"
                      label="Precision"
                      disabled={false}
                    >
                      {lengthPrecisionList[length.type].map((row, index) => {
                        const rowId = `length-precision-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-length-units-outlined-label"
                    >
                      Units
                    </InputLabel>
                    <Select
                      labelId="select-length-units-outlined"
                      id="select-length-units-outlined"
                      value={length.units}
                      name="units"
                      onBlur={handleSubmit}
                      onChange={handleLengthChange}
                      margin="dense"
                      size="small"
                      label="Units"
                      disabled={false}
                    >
                      {lengthUnitList.map((row, index) => {
                        const rowId = `length-units-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Divider />

                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <Typography>Angle</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-angle-type-outlined-label"
                    >
                      Type
                    </InputLabel>
                    <Select
                      labelId="select-angle-type-outlined"
                      id="select-angle-type-outlined"
                      value={angle.type}
                      name="type"
                      onBlur={handleSubmit}
                      onChange={handleAngleChange}
                      margin="dense"
                      size="small"
                      label="Type"
                      disabled={false}
                    >
                      {angleTypeList.map((row, index) => {
                        const rowId = `angle-type-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-angle-precision-outlined-label"
                    >
                      Precision
                    </InputLabel>
                    <Select
                      labelId="select-angle-precision-outlined"
                      id="select-angle-precision-outlined"
                      value={angle.precision}
                      name="precision"
                      onBlur={handleSubmit}
                      onChange={handleAngleChange}
                      margin="dense"
                      size="small"
                      label="Precision"
                      disabled={false}
                    >
                      {anglePrecisionList[angle.type].map((row, index) => {
                        const rowId = `angle-precision-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-angle-base-outlined-label"
                    >
                      Base Angle
                    </InputLabel>
                    <Select
                      labelId="select-angle-base-outlined"
                      id="select-angle-base-outlined"
                      value={angle.baseAngle}
                      name="baseAngle"
                      onBlur={handleSubmit}
                      onChange={handleAngleChange}
                      margin="dense"
                      size="small"
                      label="Base Angle"
                      disabled={false}
                    >
                      {angleBaseList[angle.type].map((row, index) => {
                        const rowId = `angle-base-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Divider />

                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <Typography>Area</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-area-type-outlined-label"
                    >
                      Type
                    </InputLabel>
                    <Select
                      labelId="select-area-type-outlined"
                      id="select-area-type-outlined"
                      value={area.type}
                      name="type"
                      onBlur={handleSubmit}
                      onChange={handleAreaChange}
                      margin="dense"
                      size="small"
                      label="Type"
                      disabled={false}
                    >
                      {areaTypeList.map((row, index) => {
                        const rowId = `area-type-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-area-precision-outlined-label"
                    >
                      Precision
                    </InputLabel>
                    <Select
                      labelId="select-area-precision-outlined"
                      id="select-area-precision-outlined"
                      value={area.precision}
                      name="precision"
                      onBlur={handleSubmit}
                      onChange={handleAreaChange}
                      margin="dense"
                      size="small"
                      label="Precision"
                      disabled={false}
                    >
                      {areaPrecisionList[area.type].map((row, index) => {
                        const rowId = `area-precision-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-area-units-outlined-label"
                    >
                      Units
                    </InputLabel>
                    <Select
                      labelId="select-area-units-outlined"
                      id="select-area-units-outlined"
                      value={area.units}
                      name="units"
                      onBlur={handleSubmit}
                      onChange={handleAreaChange}
                      margin="dense"
                      size="small"
                      label="units"
                      disabled={false}
                    >
                      {areaUnitList[area.type].map((row, index) => {
                        const rowId = `area-units-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Divider />

                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <Typography>Volume</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-volume-type-outlined-label"
                    >
                      Type
                    </InputLabel>
                    <Select
                      labelId="select-volume-type-outlined"
                      id="select-volume-type-outlined"
                      value={volume.type}
                      name="type"
                      onBlur={handleSubmit}
                      onChange={handleVolumeChange}
                      margin="dense"
                      size="small"
                      label="Type"
                      disabled={false}
                    >
                      {volumeTypeList.map((row, index) => {
                        const rowId = `volume-type-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-volume-precision-outlined-label"
                    >
                      Precision
                    </InputLabel>
                    <Select
                      labelId="select-volume-precision-outlined"
                      id="select-volume-precision-outlined"
                      value={volume.precision}
                      name="precision"
                      onBlur={handleSubmit}
                      onChange={handleVolumeChange}
                      margin="dense"
                      size="small"
                      label="Precision"
                      disabled={false}
                    >
                      {volumePrecisionList[volume.type].map((row, index) => {
                        const rowId = `volume-precision-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      disabled={false}
                      id="select-volume-units-outlined-label"
                    >
                      Units
                    </InputLabel>
                    <Select
                      labelId="select-volume-units-outlined"
                      id="select-volume-units-outlined"
                      value={volume.units}
                      name="units"
                      onBlur={handleSubmit}
                      onChange={handleVolumeChange}
                      margin="dense"
                      size="small"
                      label="Units"
                      disabled={false}
                    >
                      {volumeUnitList[volume.type].map((row, index) => {
                        const rowId = `volume-units-menu-item-${index}`;
                        return (
                          <MenuItem key={rowId} value={row}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </form>
      )}
    />
  );
};
