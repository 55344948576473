import { createSlice } from "@reduxjs/toolkit";

export const deleteShareAssetSlice = createSlice({
  name: "deleteShareAsset",
  initialState: {
    open: false,
    rows: undefined,
  },
  reducers: {
    open(state, action) {
      state.open = true;
      state.rows = action.payload;
    },
    close(state) {
      state.open = false;
      state.rows = undefined;
    },
  },
});

export const { open, close } = deleteShareAssetSlice.actions;
export const deleteShareAsset = deleteShareAssetSlice.reducer;
