import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setPreferencesPage,
  setPreferencesPageSize,
  setPreferencesSortModel,
  resetPreferencesPage,
  resetPreferencesPageSize,
  resetPreferencesSortModel,
} from "../../components";

import { useGetShareAssetsQuery } from "../share";

export const useShareAssets = () => {
  const preferences = useSelector((state) => state.preferences);
  const dispatch = useDispatch();

  const { page, pageSize, sortModel } = useMemo(() => {
    return {
      page: preferences.page,
      pageSize: preferences.pageSize,
      sortModel: preferences.sortModel,
    };
  }, [preferences]);

  const setPage = (newPage) => {
    dispatch(setPreferencesPage(newPage));
  };
  const setPageSize = (newPageSize) => {
    dispatch(setPreferencesPageSize(newPageSize));
  };
  const setSortModel = (newSortModel) => {
    dispatch(setPreferencesSortModel(newSortModel));
  };

  const resetPage = () => {
    dispatch(resetPreferencesPage());
  };
  const resetPageSize = () => {
    dispatch(resetPreferencesPageSize());
  };
  const resetSortModel = () => {
    dispatch(resetPreferencesSortModel());
  };

  const { isLoading, data, refetch } = useGetShareAssetsQuery({
    page,
    pageSize,
    sortModel,
    all: true,
    include: true,
  });

  return {
    page,
    resetPage,
    setPage,
    pageSize,
    resetPageSize,
    setPageSize,
    isLoading,
    data,
    refetch,
    sortModel,
    resetSortModel,
    setSortModel,
  };
};
