import React, { useEffect, useMemo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import Logger from "js-logger";

import {
  createFilterOptions,
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";

import { LoadingCircularProgress, logToastError } from "../../components";
import { isEditableRole, isNil, isOwnerRole } from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";
import { useGetTagNamesQuery } from "../tag";
import { useSaveAssetTagMutation, useDeleteAssetTagMutation } from ".";

const logger = Logger.get("TagForm");

export const TagForm = (props) => {
  const { shareAsset } = props;
  const [tags, setTags] = useState([]);
  const [saving, setSaving] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { data: account } = useGetMyAccountQuery();
  const { data: organization } = useGetMyOrganizationQuery();

  const [saveAssetTag] = useSaveAssetTagMutation();
  const [deleteAssetTag] = useDeleteAssetTagMutation();

  const {
    isLoading: tagNamesIsLoading,
    data: tagNamesData,
    error: tagNamesError,
    refetch: tagNamesRefetch,
  } = useGetTagNamesQuery();

  const options = useMemo(() => {
    if (tagNamesError) {
      const msg = "fetch existing tags failed";
      logToastError(logger, msg, tagNamesError);
      return [];
    }

    if (tagNamesIsLoading || !tagNamesData) {
      return [];
    }

    const options = tagNamesData
      .map((x) => ({
        id: x.id,
        name: x.name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    return options;
  }, [tagNamesData, tagNamesError, tagNamesIsLoading]);

  useEffect(() => {
    if (!shareAsset || !shareAsset.asset || !shareAsset.asset.tags) {
      setTags([]);
      return;
    }

    const updated = shareAsset.asset.tags
      .map((x) => ({
        id: x.id,
        name: x.name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    setTags(updated);
  }, [shareAsset, tagNamesRefetch]);

  const onChange = useCallback(
    async (changedTag) => {
      try {
        setSaving(true);

        await saveAssetTag({
          assetId: shareAsset.assetId,
          tag: changedTag,
        })
          .unwrap()
          .then((payload) => {
            const updated = payload.tags
              ? payload.tags
                  .map((x) => ({
                    id: x.id,
                    name: x.name,
                  }))
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
              : [];
            setTags(updated);

            const exists = options.find((x) => x.id === changedTag.id);
            if (!exists) {
              tagNamesRefetch();
            }
          })
          .catch((err) => {
            throw err;
          });
      } catch (error) {
        const msg =
          "update tag '" +
          changedTag.name +
          "' for built asset '" +
          shareAsset.asset.name +
          "' failed";
        let err = error.error;
        if (error.data) {
          err = err.data;
        }
        logToastError(logger, msg, err);
      } finally {
        setSaving(false);
      }
    },
    [shareAsset, options, saveAssetTag, tagNamesRefetch]
  );

  const onDelete = useCallback(
    async (deletedTag) => {
      try {
        setSaving(true);

        if (!isNil(deletedTag.id)) {
          await deleteAssetTag({
            assetId: shareAsset.assetId,
            tagId: deletedTag.id,
          })
            .unwrap()
            .then((payload) => {
              const updated = payload.tags
                ? payload.tags
                    .map((x) => ({
                      id: x.id,
                      name: x.name,
                    }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                : [];
              setTags(updated);
            })
            .catch((err) => {
              throw err;
            });
        }
      } catch (error) {
        const msg =
          "delete tag '" +
          deletedTag.name +
          "' for built asset '" +
          shareAsset.name +
          "' failed";
        let err = error.error;
        if (error.data) {
          err = err.data;
        }
        logToastError(logger, msg, err);
      } finally {
        setSaving(false);
      }
    },
    [shareAsset, deleteAssetTag]
  );

  const created = useMemo(
    () =>
      shareAsset &&
      shareAsset.asset !== undefined &&
      shareAsset.asset.id !== null &&
      shareAsset.asset.container !== null,
    [shareAsset]
  );

  // eslint-disable-next-line no-unused-vars
  const { canUpdate, canUpsert } = useMemo(() => {
    let canUpsert = true;
    let canUpdate = false;

    if (shareAsset) {
      const canEdit =
        organization &&
        organization.id === shareAsset.organizationId &&
        isEditableRole(account, organization, shareAsset);
      const owner = isOwnerRole(account, organization, shareAsset);
      canUpsert = canEdit || owner;
      canUpdate = created && canUpsert;
    }

    return { canUpsert, canUpdate };
  }, [account, created, organization, shareAsset]);

  const handleChange = (_event, value, reason) => {
    if (reason === "clear") {
      for (let index = 0; index < tags.length; index++) {
        onDelete(tags[index]);
      }
    } else if (
      // different autoocomplete version return different reason labels
      reason === "select-option" ||
      reason === "selectOption" ||
      reason === "create-option" ||
      reason === "createOption"
    ) {
      for (let index = 0; index < value.length; index++) {
        if (typeof value[index] === "string") {
          continue;
        }

        value[index].name = value[index].name.trim();

        if (isNil(value[index].id)) {
          const existing = options.find((x) => x.name == value[index].name);
          if (existing) {
            value[index].id = existing.id;
          } else {
            value[index].id = null;
          }
        }

        const found = tags.find((x) => x.name === value[index].name);
        if (!found) {
          onChange(value[index]);
          setInputValue("");
        }
      }
    } else if (reason === "remove-option" || reason === "removeOption") {
      for (let index = 0; index < tags.length; index++) {
        const found = value.find((x) => x.name === tags[index].name);
        if (!found && typeof tags[index] !== "string") {
          onDelete(tags[index]);
        }
      }
    } else {
      logger.debug("handleChange unknown reason: ", reason);
    }
  };

  // const renderOption = (tag) => {
  //   return <div key={tag.id}>{tag.name}</div>;
  // };

  const filterOptions = createFilterOptions(
    // TODO: revisit: use https://github.com/kentcdodds/match-sorter
    {
      ignoreAccents: true,
      ignoreCase: true,
      limit: 100,
      matchFrom: "start", // "any"
      stringify: (option) => option.name,
      trim: true,
    }
  );

  return (
    <Autocomplete
      disabled={!canUpdate || saving}
      filterOptions={filterOptions}
      freeSolo
      fullWidth
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      id="tag-form"
      inputValue={inputValue}
      loading={tagNamesIsLoading}
      margin="dense"
      size="small"
      multiple
      noOptionsText="No tags"
      onChange={handleChange}
      onInputChange={(_, v) => setInputValue(v)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.defaultMuiPrevented = true;
          if (event.target.value) {
            const newTags = [
              {
                id: "00000000-0000-0000-0000-000000000000",
                name: event.target.value,
              },
            ];
            handleChange(event, newTags, "create-option");
          }
        }
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          id={params.id}
          label="Tags"
          placeholder="Select an existing tag, or create a custom one"
          margin="dense"
          size="small"
          variant="outlined"
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <React.Fragment>
                {tagNamesIsLoading || saving ? (
                  <LoadingCircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      // renderOption={(option) => renderOption(option)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option.id}
            variant="outlined"
            margin="dense"
            size="small"
            label={option.name}
            {...getTagProps({ index })}
          />
        ))
      }
      value={tags}
    />
  );
};

TagForm.propTypes = {
  shareAsset: PropTypes.object,
};
