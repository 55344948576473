import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import Logger from "js-logger";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import {
  AuthContext,
  LoadingCircularProgress,
  logToastError,
  logToastInfo,
} from "../../components";
import { formatISO8601AsDateTime } from "../../helpers";
import { Troubleshoot } from "../support";

import {
  BillingPortalButton,
  PlanFeatures,
  StripeMode,
  useBilling,
  useCreateCheckoutSessionMutation,
  useGetPriceTableQuery,
} from ".";

const logger = Logger.get("Checkout");

export const Checkout = () => {
  const [message, setMessage] = useState(undefined);
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState(undefined);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const auth = useContext(AuthContext);
  const { isAuthenticated } = auth;
  logger.debug("isAuthenticated, auth:", isAuthenticated(), auth);

  const history = useHistory();
  const { priceId } = useParams();

  const { isLoading, data: pricingtable } = useGetPriceTableQuery();

  const billing = useBilling();

  const [createCheckoutSession] = useCreateCheckoutSessionMutation();

  useEffect(() => {
    logger.debug("priceId:", priceId);
    if (isLoading || !pricingtable) {
      return;
    }
    logger.debug("isloading, pricingtable:", isLoading, pricingtable);

    if (priceId) {
      const price = pricingtable.prices.find((x) => x.id === priceId);
      logger.debug("price:", price);
      if (!price) {
        return;
      }

      const data = {
        id: price.id,
        lookupKey: price.lookup_key,
      };
      logger.debug("data:", data);

      try {
        createCheckoutSession(data)
          .then((result) => {
            logger.debug("result: ", result);
            logger.debug("redirecting to checkout: ", result.data.url);
            window.open(result.data.url, "_self");
          })
          .catch((err) => {
            const msg = "error: ";
            logToastError(logger, msg, err);
          });
      } catch (err) {
        const msg = "checkout session failed";
        setMessage(msg + ": " + err);
        logToastError(logger, msg, err);
      }
    } else {
      const query = new URLSearchParams(window.location.search);
      logger.debug("query:", query);

      if (query.get("success")) {
        setSuccess(true);
        setSessionId(query.get("session_id"));
        setShouldRefetch(true);
      } else if (query.get("canceled")) {
        setSuccess(false);

        const msg = "checkout cancelled";
        setMessage(msg);
        logToastInfo(logger, msg);
      } else {
        setSuccess(false);

        const msg = "checkout canceled, subscription pricing not found";
        setMessage(msg);
        logToastError(logger, msg);
      }
    }
  }, [createCheckoutSession, isLoading, pricingtable, priceId]);

  useEffect(() => {
    if (shouldRefetch) {
      billing.refetch();
      setShouldRefetch(false);
    }
  }, [billing, shouldRefetch]);

  const handleSuccessContinue = () => {
    logger.debug("handleSuccessContinue");
    history.push(`/`);
  };

  const handleMessageContinue = () => {
    logger.debug("handleMessageContinue");
    history.push(`/`);
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* Success */}
      {success && sessionId !== "" ? (
        <Card sx={{ padding: 2 }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h5" sx={{ paddingBottom: 2 }}>
                  Welcome to the builtstream platform!
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8} md={9} lg={9} xl={10}>
                {billing.isLoading ? (
                  <>
                    <LoadingCircularProgress />
                  </>
                ) : (
                  <>
                    <StripeMode
                      isTrial={billing.isTrial}
                      trialDescription={billing.trialDescription}
                      liveMode={billing.liveMode}
                    />
                    <Typography>
                      Your <strong>{billing.name}</strong> subscription plan
                      will renew on{" "}
                      {formatISO8601AsDateTime(
                        billing.renewal,
                        "MMMM DD, YYYY"
                      )}
                      . <br />
                    </Typography>

                    <PlanFeatures features={billing.features} />
                  </>
                )}
              </Grid>
              <Grid item xs={5} sm={4} md={3} lg={3} xl={2}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {billing.isLoading ? <></> : <BillingPortalButton />}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box sx={{ paddingTop: 1 }}>
                  <Button
                    aria-controls="continue-from-checkout"
                    variant="contained"
                    color="primary"
                    onClick={handleSuccessContinue}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    Get Started
                  </Button>
                  <Troubleshoot />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
            </Grid>
            <Box sx={{ paddingTop: 2 }}>
              <Typography variant="caption" sx={{ paddingTop: 4 }}>
                checkout session ID reference <strong>{sessionId}</strong>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <></>
      )}

      {/* Redirect to Checkout */}
      {!success && (message === undefined || message === "") ? (
        <Box sx={{ padding: 2 }}>
          <Typography variant="h5">
            Redirecting to builtstream Checkout on Stripe...
          </Typography>
          <br />
          <LoadingCircularProgress />
        </Box>
      ) : (
        <></>
      )}

      {/* Return message */}
      {message !== undefined && message !== "" ? (
        <Box sx={{ padding: 2 }}>
          <Typography variant="h5">{message}</Typography>
          <br />
          <Button
            aria-controls="continue-to-home-page"
            variant="contained"
            color="primary"
            onClick={handleMessageContinue}
            sx={{ paddingLeft: 2, paddingRight: 2 }}
          >
            Continue
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
