import { createSlice } from "@reduxjs/toolkit";

export const upsertShareAssetSlice = createSlice({
  name: "upsertShareAsset",
  initialState: {
    open: false,
    row: undefined,
  },
  reducers: {
    open(state, action) {
      state.open = true;
      state.row = action.payload;
    },
    close(state) {
      state.open = false;
      state.row = undefined;
    },
  },
});

export const { open, close } = upsertShareAssetSlice.actions;
export const upsertShareAsset = upsertShareAssetSlice.reducer;
