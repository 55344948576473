import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import Logger from "js-logger";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Typography,
} from "@mui/material";

import {
  AuthContext,
  LoadingCircularProgress,
  logToastDebug,
  logToastError,
} from "../../components";
import { isNil } from "../../helpers";

import { useGetMyAccountQuery } from "../account";
import { InvitationFooter, Troubleshoot } from "../support";
import {
  formatAcceptInvitationMessage,
  formatRejectInvitationMessage,
  useAcceptShareInvitationMutation,
  useRejectShareInvitationMutation,
  useGetShareJoinInvitationQuery,
} from ".";

const INVITATIONS = "invitations";
const INVITATION_INPROGRESS = "invitation_inprogress";

const logger = Logger.get("ShareJoin");

const updateLocalInvitation = (invitationId, invitation) => {
  logger.debug(
    "updating local invitation code with invitation:",
    invitationId,
    invitation
  );
  let local_invitations = JSON.parse(localStorage.getItem(INVITATIONS));
  if (!local_invitations) {
    local_invitations = {};
  }
  local_invitations[invitationId] = invitation;
  localStorage.setItem(INVITATIONS, JSON.stringify(local_invitations));
};

const removeInvitationInprogress = (invitation) => {
  const invitation_inprogress = localStorage.getItem(INVITATION_INPROGRESS);
  if (invitation_inprogress) {
    localStorage.removeItem(INVITATION_INPROGRESS);
  }

  let local_invitations = JSON.parse(localStorage.getItem(INVITATIONS));
  if (!local_invitations) {
    local_invitations = {};
  } else {
    if (invitation !== undefined) {
      delete local_invitations[invitation.id];
    }
  }
  localStorage.setItem(INVITATIONS, JSON.stringify(local_invitations));
};

export const ShareJoin = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const auth = useContext(AuthContext);
  const { login, logout, signup } = auth;

  const { invitationId } = useParams();
  const history = useHistory();

  const {
    isLoading: isLoadingAccount,
    data: account,
    refetch: accountRefetch,
  } = useGetMyAccountQuery();

  const {
    isLoading: isLoadingInvitation,
    data: invitation,
    error: invitationError,
  } = useGetShareJoinInvitationQuery(invitationId, {
    skip: !invitationId,
  });

  const [acceptShareInvitation] = useAcceptShareInvitationMutation();
  const [rejectShareInvitation] = useRejectShareInvitationMutation();

  useEffect(() => {
    if (invitationId === undefined || isNil(invitationId)) {
      logger.debug("no invitation code detected");
      return;
    }

    if (
      isLoadingInvitation ||
      (invitation === undefined && invitationError === undefined)
    ) {
      logger.debug("invitation loading");
      return;
    }

    if (invitationError) {
      logger.debug("invitation load error:", invitationError);
      if (
        invitationError.data.includes("Failed to find resource") ||
        invitationError.originalStatus === 404
      ) {
        setErrorMessage("invitation not found");
      } else {
        setErrorMessage(invitationError.data);
      }
      return;
    }

    logger.debug("updating locally cached invitation");
    updateLocalInvitation(invitationId, invitation);
  }, [invitation, invitationId, invitationError, isLoadingInvitation]);

  useEffect(() => {
    if (!auth.isReady() || isLoadingAccount) {
      logger.debug("account loading");
      return;
    }

    if (account === undefined) {
      logger.debug("account error");
      return;
    }

    if (invitation === undefined) {
      return;
    }

    logger.debug("invitation loaded, checking for redirects");

    if (invitation.asset.type === "asset") {
      logger.debug("useEffect2 redirecting to asset share join");

      if (account.status !== "signupCompleted" && account.status !== "active") {
        // Authenticated, and share type, but signup has not
        // been completed, therefore redirect to signup with invitationId
        localStorage.setItem(INVITATION_INPROGRESS, invitationId);
      }

      // If authenticated, any share type, then redirect to share with invitationId
      // setRedirectTo("/share/invitation/" + invitationId);
      if (
        location &&
        (location.pathname.includes("/share/join/") ||
          location.pathname.includes("/asset/join/"))
      ) {
        logger.debug(
          "useEffect2 redirecting from /share/join to /share/invitation"
        );
        history.push("/share/invitation/" + invitationId);
        return;
      }
    } else if (
      invitation.status === "accepted" &&
      account.id === invitation.recipient.id
    ) {
      // already part of a fully signed up account profile and organization
      logger.debug("useEffect2 already accepted invitation");
      history.push("/");
      return;
    } else {
      const msg = `invalid invitation asset type: ${invitation.asset.type}`;
      logger.error(msg);
      setErrorMessage(msg);
    }
    logger.debug("useEffect2 exiting");
  }, [account, auth, history, invitation, invitationId, isLoadingAccount]);

  const handleNoLongerInvited = useCallback(() => {
    setErrorMessage("");
    logger.debug("handleNoLongerInvited");
    removeInvitationInprogress();
    history.push("/");
  }, [history]);

  const onAccept = useCallback(async () => {
    try {
      logger.debug("onAccept:", invitation);
      setAccepting(true);
      setErrorMessage("");

      await acceptShareInvitation(invitation.id, {
        refetchOnMountOrArgChange: true,
        // keepUnusedDataFor: 5,
      }).unwrap();
      logToastDebug(logger, formatAcceptInvitationMessage(invitation));

      removeInvitationInprogress();
      accountRefetch();
      history.push("/");
    } catch (err) {
      logToastError(logger, formatAcceptInvitationMessage(invitation, err));
      setErrorMessage(err.message);
    } finally {
      setAccepting(false);
    }
  }, [acceptShareInvitation, accountRefetch, history, invitation]);

  const onReject = useCallback(async () => {
    try {
      logger.debug("onReject:", invitation);
      setRejecting(true);
      setErrorMessage("");

      await rejectShareInvitation(invitation.id, {
        refetchOnMountOrArgChange: true,
        // keepUnusedDataFor: 5,
      }).unwrap();
      logToastDebug(logger, formatRejectInvitationMessage(invitation));

      removeInvitationInprogress();
      history.push("/");
    } catch (err) {
      logToastError(logger, formatRejectInvitationMessage(invitation, err));
      setErrorMessage(err.message);
    } finally {
      setRejecting(false);
    }
  }, [rejectShareInvitation, history, invitation]);

  const {
    AcceptButton,
    AccountRecipientMismatch,
    Greeting,
    ErrorMessage,
    Message,
    NeedAuthentication,
    NoLongerInvited,
    RejectButton,
  } = useMemo(() => {
    var acceptButton = <></>;
    var accountRecipientMismatch = <></>;
    var greeting = <></>;
    var error = <></>;
    var message = <></>;
    var needAuthentication = <></>;
    var noLongerInvited = <></>;
    var rejectButton = <></>;

    if (invitation !== undefined && invitation.status !== "invited") {
      noLongerInvited = (
        <Box sx={{ paddingTop: 2 }}>
          <Typography variant="body1">
            The invitation is no longer valid.
          </Typography>
          <Button
            variant="contained"
            onClick={handleNoLongerInvited}
            disabled={false}
            sx={{ margin: 1 }}
          >
            Continue
          </Button>
        </Box>
      );
    }

    if (invitation === undefined) {
      if (errorMessage.length > 0) {
        error = (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              // color: theme.palette.error.main,
              color: "error.main",
              fontWeight: 700,
              paddingTop: 2,
            }}
          >
            <Typography variant="body1">
              There was an error retrieving invitation code{" "}
              <strong>{invitationId}</strong>.
            </Typography>
            <br />
            <Typography
              variant="body1"
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                // color: theme.palette.error.main,
                color: "error.main",
                fontWeight: 700,
                paddingTop: 2,
              }}
            >
              Error: {errorMessage}
            </Typography>
            <br />
            <Typography variant="body1">
              Please verify the invitation code and try again.
            </Typography>
          </Box>
        );
      }
    } else {
      greeting = (
        <Typography variant="body1">{invitation.recipient.name},</Typography>
      );

      message = (
        <Typography variant="body1" sx={{ paddingTop: 2 }}>
          <strong>{invitation.owner.name}</strong> invited you to join the{" "}
          <strong>&quot;{invitation.asset.name}&quot;</strong>{" "}
          {invitation.asset.type} on the builtstream platform.
        </Typography>
      );
    }

    if (!auth.isReady() || isLoadingAccount || account === undefined) {
      if (errorMessage.length === 0) {
        needAuthentication = (
          <Typography variant="body1" sx={{ paddingTop: 2 }}>
            Please <Link onClick={login}>log in</Link> to accept and join.
            Don&apos;t have an account? <Link onClick={signup}>Sign up</Link>
          </Typography>
        );
      }
    } else {
      // We're authenticated, allow accept/reject actions
      if (invitation !== undefined && invitation.status === "invited") {
        if (invitation.recipient.email !== account.profile.email) {
          accountRecipientMismatch = (
            <Box sx={{ paddingTop: 2 }}>
              <Typography variant="body1">
                The signed in account {account.profile.email} does not match
                intended invitation recipient {invitation.recipient.email}.
                Please signin with the matching credentials.
              </Typography>
              <br />

              <Button variant="contained" onClick={logout} disabled={false}>
                Continue
              </Button>
            </Box>
          );
        } else if (invitation.status === "invited") {
          acceptButton = (
            <Button
              variant="contained"
              color="primary"
              onClick={() => onAccept()}
              disabled={rejecting || accepting}
              sx={{ margin: 1 }}
            >
              {accepting ? "Accepting" : "Accept"}
            </Button>
          );
          rejectButton = (
            <Button
              variant="contained"
              onClick={() => onReject()}
              disabled={rejecting || accepting}
              sx={{ margin: 1 }}
            >
              {rejecting ? "Rejecting" : "Reject"}
            </Button>
          );
        }
      }
    }

    return {
      AcceptButton: acceptButton,
      AccountRecipientMismatch: accountRecipientMismatch,
      Greeting: greeting,
      ErrorMessage: error,
      Message: message,
      NeedAuthentication: needAuthentication,
      NoLongerInvited: noLongerInvited,
      RejectButton: rejectButton,
    };
  }, [
    accepting,
    account,
    auth,
    errorMessage,
    handleNoLongerInvited,
    invitation,
    invitationId,
    isLoadingAccount,
    login,
    logout,
    onAccept,
    onReject,
    rejecting,
    signup,
  ]);

  if (invitation === undefined && errorMessage.length === 0) {
    return <LoadingCircularProgress />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        height: "100%",
        width: "100%",
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Card>
        <CardHeader title="Join a Built Asset" />
        <CardContent
          sx={{
            flexDirection: "column",
            justifyContent: "start",
            justifyItems: "start",
            alignItems: "start",
            display: "flex",
          }}
        >
          {Greeting}
          {ErrorMessage}
          {Message}
          {NeedAuthentication}
          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
              paddingTop: 2,
            }}
          >
            {AccountRecipientMismatch}
            {NoLongerInvited}
            {RejectButton}
            {AcceptButton}
          </Box>
          <Troubleshoot />
          <InvitationFooter
            invitation={invitation}
            invitationId={invitationId}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
