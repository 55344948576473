import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { setSelected } from "../../components";

import { open } from "./deleteBlobSlice";

export const useOpenDeleteBlobDialog = () => {
  const dispatch = useDispatch();

  return useCallback(
    (items) => () => {
      const ids = items.map((item) => item.id);

      dispatch(setSelected(ids));
      dispatch(open(items));
    },
    [dispatch]
  );
};
