import { useHistory } from "react-router-dom";

export const useOpenShareAsset = () => {
  const history = useHistory();

  return (shareAsset) => {
    var assetId = undefined;

    if (shareAsset && shareAsset.shareId && shareAsset.id) {
      // asset
      assetId = shareAsset.id;
    } else if (shareAsset && shareAsset.assetId) {
      // shareAsset
      assetId = shareAsset.assetId;
    }

    if (assetId) {
      history.push(`/asset/${assetId}`);
    }
  };
};
