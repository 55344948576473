/* eslint-disable no-unused-vars */
import React from "react";

import { Box, Card, CardHeader } from "@mui/material";

import { StyledCard } from "../../components";

export const Admin = () => {
  return (
    <Box
      sx={{
        padding: 2,
        height: "100%",
        width: "100%",
      }}
    >
      <StyledCard sx={{ alignItems: "center", display: "flex" }}>
        <CardHeader title="Admin Placeholder" />
      </StyledCard>
    </Box>
  );
};
