import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { Typography } from "@mui/material";

import { v4 as uuidv4 } from "uuid";

const ToastWithMessageLink = (msg, link) => {
  const component = <Typography variant="body1">{msg}</Typography>;

  if (link === undefined) {
    return <div>{component}</div>;
  }

  return (
    <div>
      <Link to={link}>{component}</Link>
    </div>
  );
};

export function logToastError(log, msg, err, link) {
  const errMessage = msg + (err === undefined ? "" : " " + err.message);

  log.error(errMessage);
  toast.error(ToastWithMessageLink(msg, link), { id: uuidv4() });
}

export function logToastWarn(log, msg, link) {
  log.warn(msg);
  toast.warn(ToastWithMessageLink(msg, link), { id: uuidv4() });
}

export function logToastInfo(log, msg, link) {
  log.info(msg);
  toast.info(ToastWithMessageLink(msg, link), { id: uuidv4() });
}

export function logToastSuccess(log, msg, link) {
  log.debug(msg);
  toast.success(ToastWithMessageLink(msg, link), { id: uuidv4() });
}

export function logToastDebug(log, msg, link) {
  log.debug(msg);
  toast.info(ToastWithMessageLink(msg, link), { id: uuidv4() });
}

export function logToastTrace(log, msg, link) {
  log.verbose(msg);
  toast.info(ToastWithMessageLink(msg, link), { id: uuidv4() });
}
