import { api } from "../api/apiSlice";

export const accountApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyAccount: build.query({
      query: () => `account/me`,
      // eslint-disable-next-line no-unused-vars
      providesTags: (result, error, _arg) =>
        result
          ? [{ type: "Accounts", id: result.id }]
          : error?.status === 401 || error?.status === 403
          ? ["UNAUTHORIZED"]
          : ["UNKNOWN_ERROR"],
    }),
    getAccount: build.query({
      query: (id) => `account/${id}`,
      providesTags: (_result, _error, { id }) => [{ type: "Accounts", id }],
    }),
    saveAccount: build.mutation({
      query(account) {
        return {
          url: "account" + (account.id ? "/" + account.id : ""),
          method: account.id ? "PUT" : "POST",
          body: account,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Accounts", id }],
    }),
    saveMyAccountNotifications: build.mutation({
      query({ id, notifications }) {
        return {
          url: `account/${id}/notifications`,
          method: "PUT",
          body: notifications,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Accounts", id }],
    }),
    saveMyAccountPreferences: build.mutation({
      query({ id, preferences }) {
        return {
          url: `account/${id}/preferences`,
          method: "PUT",
          body: preferences,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Accounts", id }],
    }),
    saveMyAccountProfile: build.mutation({
      query({ id, profile }) {
        return {
          url: `account/${id}/profile`,
          method: "PUT",
          body: profile,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Accounts", id }],
    }),
    saveMyAccountStatus: build.mutation({
      query({ id, status }) {
        return {
          url: `account/${id}/status`,
          method: "PUT",
          body: status,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Accounts", id }],
    }),
  }),
});

export const {
  useGetAccountQuery,
  useGetMyAccountQuery,
  useSaveAccountMutation,
  useSaveMyAccountNotificationsMutation,
  useSaveMyAccountPreferencesMutation,
  useSaveMyAccountProfileMutation,
  useSaveMyAccountStatusMutation,
} = accountApi;
