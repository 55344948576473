import Logger from "js-logger";

import { logToastDebug, logToastError } from "../components";

const logger = Logger.get("taskRunner");

export const runTask = async (taskName, name, taskFn) => {
  // const msg = `${taskName} '${name}' started`;
  // logToastDebug(logger, msg);

  try {
    const data = await taskFn();

    let msg = `${taskName} '${name}' completed`;

    if (data.errorCount > 0) {
      msg += " with errors";
    }

    logToastDebug(logger, msg);
  } catch (err) {
    const msg = `${taskName} '${name}' failed`;
    logToastError(logger, msg, err);
  }
};
