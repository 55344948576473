import { useDispatch } from "react-redux";
import { clearSelectedRows } from "../../components";

import { useInvitationMutation, useAcceptShareInvitationMutation } from ".";

export const formatAcceptInvitationMessage = (invitation, err) => {
  if (invitation) {
    return (
      `share invitation to '${invitation.asset.name}' ` +
      `by ${invitation.recipient.name} ` +
      `(${invitation.recipient.email}) ` +
      (invitation.organizationName && invitation.organizationName.length > 0
        ? `from ${invitation.organizationName} `
        : "") +
      (err === undefined ? `accepted` : `accept failed ${err}`)
    );
  }
};

export const useAcceptInvitations = () => {
  const dispatch = useDispatch();
  const acceptInvitations = useInvitationMutation(
    useAcceptShareInvitationMutation,

    (invitation) => {
      return formatAcceptInvitationMessage(invitation);
    },

    (invitation, err) => {
      return formatAcceptInvitationMessage(invitation, err);
    }
  );

  return (invitations) => () => {
    acceptInvitations(invitations);
    dispatch(clearSelectedRows());
  };
};
