import React, { useContext, useState } from "react";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SignoutIcon from "@mui/icons-material/ExitToAppOutlined";

import { AccountAvatar } from "../../../features/account";

import { AuthContext, CompanyLogo, ThemeButton } from "../../../components";

export const Topbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const auth = useContext(AuthContext);
  const { logout } = auth;

  const theme = useTheme();

  const menuId = "primary-search-account-menu";

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      // getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <AccountAvatar />
      </MenuItem>
      <Divider />
      <MenuItem
        disableGutters
        onClick={handleMenuClose}
        sx={{
          display: "flex",
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <ListItem
          sx={{ display: "flex", paddingTop: 0, paddingBottom: 0 }}
          onClick={logout}
          button
        >
          <ListItemIcon>
            <SignoutIcon fontSize="small" sx={{ color: theme.palette.icon }} />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </ListItem>
      </MenuItem>
    </Menu>
  );

  const renderToolbar = (
    <div>
      <Toolbar
        sx={{
          boxShadow: "none",
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Typography component="h1" variant="h6" color="inherit" noWrap>
          <CompanyLogo isInSidebar={false} />
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <ThemeButton />
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="dialog"
          onClick={handleMenuOpen}
          color="inherit"
          sx={{ color: theme.palette.icon }}
        >
          <AccountCircle />
        </IconButton>
      </Toolbar>
      {renderMenu}
    </div>
  );

  return <div>{renderToolbar}</div>;
};
