import { useState, useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const useBlobQuery = (query, onData, onError) => {
  const [blob, setBlob] = useState(skipToken);
  const [result, setResult] = useState(undefined);
  const [errResult, setErrResult] = useState(undefined);
  const { data, error, isLoading, isError } = query(blob, {
    refetchOnMountOrArgChange: true,
    // keepUnusedDataFor: 5,
  });

  useEffect(() => {
    if (data !== undefined) {
      setResult(data);
    }
  }, [data]);

  useEffect(() => {
    if (error !== undefined) {
      setErrResult(error);
    }
  }, [error]);

  useEffect(() => {
    if (blob && blob != skipToken) {
      if (result) {
        onData(result);
        setResult(undefined);
        setBlob(skipToken);
      }
    }
  }, [blob, result, isLoading, onData]);

  useEffect(() => {
    if (blob && blob != skipToken) {
      if (errResult) {
        onError(blob, errResult);
        setErrResult(undefined);
        setBlob(skipToken);
      }
    }
  }, [blob, errResult, isError, onError]);

  return (blobOrIds) => () => setBlob(blobOrIds);
};
