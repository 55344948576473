import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { ConfirmationDialog, clearSelectedRows } from "../../components";
import { runTask } from "../../helpers";
import { useDeleteShareMutation } from ".";

import { close } from "./deleteShareSlice";

export const DeleteShare = (props) => {
  const { shareAssets } = props;
  const dispatch = useDispatch();
  const { open, rows } = useSelector((state) => state.deleteShare);
  const [deleteShare] = useDeleteShareMutation();

  const title = useMemo(() => {
    if (!rows) {
      return "";
    }

    const name =
      rows.length > 1 ? `${rows.length} items` : `"${rows[0].asset.name}"`;
    return `Delete ${name}?`;
  }, [rows]);

  const description = useMemo(() => {
    if (!rows) {
      return "";
    }

    const name = rows.length > 1 ? `${rows.length}` : `"${rows[0].asset.name}"`;
    const plural = rows.length > 1 ? "s" : "";
    return `Are you sure you want to delete share${plural} to ${name} built asset${plural}?`;
  }, [rows]);

  const onClose = useCallback(
    async (target) => {
      dispatch(close());

      if (!target) {
        return;
      }

      for (var i = 0; i < rows.length; i++) {
        const row = rows[i];

        await runTask("Delete", row.asset.name, row, async () => {
          await deleteShare(row.id).unwrap();
          return { errorCount: 0 };
        });
      }

      shareAssets.refetch();

      dispatch(clearSelectedRows());
    },
    [shareAssets, deleteShare, dispatch, rows]
  );

  return (
    <ConfirmationDialog
      open={open}
      cancelTitle="Cancel"
      okTitle="Delete"
      title={title}
      description={description}
      onClose={onClose}
    />
  );
};

DeleteShare.propTypes = {
  shareAssets: PropTypes.object.isRequired,
};
