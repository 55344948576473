import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import SmallIcon from "@mui/icons-material/AppsOutlined";
import LargeIcon from "@mui/icons-material/ViewModuleOutlined";

import { StyledMenuItem } from "../";

export const ViewAsMenu = (props) => {
  const { onViewAs, ...rest } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // TODO: handle view as
  const handleViewAs = (event) => {
    if (onViewAs) {
      onViewAs(event);
    }
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <IconButton
        edge="end"
        size="small"
        aria-controls="viewas-button-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ListIcon />
      </IconButton>
      <Menu
        id="more-button-menu"
        anchorEl={anchorEl}
        keepMounted
        elevation={0}
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        autoFocus={false}
        PaperProps={{
          style: {
            border: "1px solid #d3d4d5",
          },
        }}
        {...rest}
      >
        {/* TODO: Refactor into data driven menu */}
        <StyledMenuItem id="viewas-menu-list" onClick={handleViewAs}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="List" />
        </StyledMenuItem>
        <StyledMenuItem id="viewas-menu-small" onClick={handleViewAs} disabled>
          <ListItemIcon>
            <SmallIcon />
          </ListItemIcon>
          <ListItemText primary="Small" />
        </StyledMenuItem>
        <StyledMenuItem id="viewas-menu-large" onClick={handleViewAs} disabled>
          <ListItemIcon>
            <LargeIcon />
          </ListItemIcon>
          <ListItemText primary="Large" />
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};

ViewAsMenu.propTypes = {
  onViewAs: PropTypes.func,
};
