import React, { useContext, useMemo } from "react";
import axios from "axios";
import Logger from "js-logger";

import { Button, CardHeader, Typography } from "@mui/material";

import {
  AuthContext,
  logToastError,
  logToastInfo,
  StyledCard,
} from "../../components";
import { useGetMyAccountQuery } from "../account";

const logger = Logger.get("ChangePassword");

export const ChangePassword = () => {
  const { isLoading, data: account } = useGetMyAccountQuery();

  const auth = useContext(AuthContext);

  const authProvider = useMemo(() => {
    if (isLoading || !account) {
      return;
    }

    const provider = account.userIds[0].split("|");
    return provider[0];
  }, [account, isLoading]);

  function handleChangePassword() {
    logger.debug("handleChangePassword");

    auth.getProfile((userProfile) => {
      const url = `https://${auth.auth0.baseOptions.domain}/dbconnections/change_password`;

      axios({
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          client_id: auth.auth0.baseOptions.clientID,
          email: userProfile.email,
          connection: "Username-Password-Authentication",
        },
      })
        .then(() => {
          logToastInfo(logger, "Password change request sent");
        })
        .catch((err) => {
          logToastError(logger, "Password change request failed:" + err);
        });
    });
  }

  const title = "Password";
  const description = "Set a complex password to protect your account.";

  return (
    <StyledCard sx={{ padding: 0 }}>
      <CardHeader
        title={title}
        subheader={description}
        action={
          <>
            <div hidden={authProvider === "auth0"}>
              <Typography
                variant="body1"
                sx={{
                  //   color: statusColors["warning"],
                  fontWeight: "bold",
                  float: "right",
                  justifyContent: "center",
                  alignContent: "center",
                  marginRight: "10px",
                  marginTop: "5px",
                }}
              >
                Registered via external Authentication provider ({authProvider})
              </Typography>
            </div>
            <div hidden={authProvider !== "auth0"}>
              <Button
                variant="contained"
                component="span"
                onClick={handleChangePassword}
                sx={{ marginRight: 1 }}
              >
                Change Password
              </Button>
            </div>
          </>
        }
      />
    </StyledCard>
  );
};
