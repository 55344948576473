/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Box, Tab, Tabs } from "@mui/material";

import { TabPanelProps } from "../../components";

export const SearchResultNav = ({ filtered, setFilters }) => {
  const [tabIndex, setTabIndex] = useState(false);

  const handleTabIndexChange = (event, newValue) => {
    setTabIndex(newValue);
    setFilters({
      type: navHeaders[newValue].type,
    });
  };

  const navHeaders = useMemo(() => {
    if (filtered && filtered.items) {
      return filtered.items.map((x) => {
        // logger.debug("items.map x:", x);

        const showing = (x.data && x.data.length) || 0;
        const total = x.count || 0;

        var it = x.type;
        if (it === "asset") {
          it = "built assets";
        } else if (it === "blob") {
          it = "folders/files";
        } else {
          it = it + "s";
        }

        let countTitle =
          "(" + (showing >= total ? "" : showing + " of ") + total + ")";

        return {
          label: it,
          total: total,
          type: x.type,
          countTitle: countTitle,
        };
      });
    }
    return [];
  }, [filtered]);

  return (
    <>
      <Box sx={{ flexGrow: 1, width: "100%", paddingLeft: 0, margin: 0 }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={handleTabIndexChange}
          scrollButtons={false}
          aria-label="result filter tabs"
          sx={{
            margin: 0,
            padding: 0,
            "& .MuiButtonBase-root.MuiTab-root": {
              // equivalent to body2
              fontWeight: 500,
              fontSize: "12px",
              letterSpacing: "-0.04px",
              lineHeight: "18px",
            },
          }}
        >
          {navHeaders &&
            navHeaders.map((x, idx) => (
              <Tab
                key={idx}
                label={`${x.label} ${x.countTitle}`}
                {...TabPanelProps(x.label, idx)}
              />
            ))}
        </Tabs>
      </Box>
    </>
  );
};

SearchResultNav.propTypes = {
  filtered: PropTypes.object,
  setFilters: PropTypes.func,
};
