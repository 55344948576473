export function isUUID(uuid) {
  let s = "" + uuid;

  s = s.match(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
  );
  if (s === null) {
    return false;
  }
  return true;
}

export function isNil(uuid) {
  if (uuid !== undefined && uuid) {
    return "00000000-0000-0000-0000-000000000000" == uuid;
  }
  return true;
}

export const UUID_NIL = "00000000-0000-0000-0000-000000000000";
