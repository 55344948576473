export function getExtension(path) {
  const basename = path.split(/[\\/]/).pop();
  const pos = basename.lastIndexOf(".");

  if (basename === "" || pos < 1) {
    return "";
  }

  return basename.slice(pos + 1);
}

export function addSuffixBeforeExtension(path, suffix) {
  const ext = getExtension(path);
  if (ext === "") {
    return path + suffix;
  }

  return path.substr(0, path.lastIndexOf("." + ext)) + suffix + "." + ext;
}
