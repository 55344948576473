import { useState, useEffect } from "react";
import Logger from "js-logger";

const logger = Logger.get("useSettingsMutation");

export const useSettingsMutation = (mutation, onSuccess, onFailure) => {
  // Note: accountData = {
  //         id: account.id,
  //         data
  //       }
  const [accountData, setAccountData] = useState();
  const [runMutation, { data, error, isError, isSuccess }] = mutation();

  useEffect(() => {
    if (accountData) {
      if (isSuccess) {
        logger.debug("isSuccess:", accountData, data);
        onSuccess(accountData, data);
        setAccountData();
      }
    }
  }, [accountData, data, isSuccess, onSuccess]);

  useEffect(() => {
    if (accountData) {
      if (isError) {
        logger.debug("isError:", accountData, error);
        onFailure(accountData, error);
        setAccountData();
      }
    }
  }, [accountData, error, isError, onFailure]);

  useEffect(() => {
    if (accountData) {
      logger.debug("running:", accountData);
      runMutation(accountData, {
        refetchOnMountOrArgChange: true,
        // keepUnusedDataFor: 5,
      });
    }
  }, [accountData, runMutation]);

  return setAccountData;
};
