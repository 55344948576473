import React from "react";
import { useParams } from "react-router-dom";

import { Box, Grid } from "@mui/material";

import { Alerts, Delivery, DoNotDisturb } from ".";

export const Notifications = () => {
  const { tabId } = useParams();

  if (tabId === undefined || tabId !== "notifications") {
    return <></>;
  }

  return (
    <Box sx={{ height: "100%", padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Alerts />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <DoNotDisturb />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Delivery />
        </Grid>
      </Grid>
    </Box>
  );
};
