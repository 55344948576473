import { api, providesList } from "../api/apiSlice";
import { pollForTasks } from "../task/pollForTasks";

export const assetApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAssets: build.query({
      query({ page, pageSize, sortModel }) {
        var params = {
          p: page,
          rpp: pageSize,
        };

        if (sortModel) {
          params.sortField = sortModel.field;
          params.sortDir = sortModel.sort;
        }

        return {
          url: "asset",
          params,
        };
      },
      providesTags: (result) => providesList(result && result.items, "Assets"),
    }),
    getRecentAssets: build.query({
      query(totalResults) {
        return {
          url: "asset",
          params: {
            p: 0,
            rpp: totalResults,
            sortField: "ts",
            sortDirection: "desc",
          },
        };
      },
      providesTags: (result) => providesList(result && result.items, "Assets"),
    }),
    searchAssets: build.query({
      query(q) {
        return {
          url: "asset/search",
          params: {
            q,
          },
        };
      },
    }),
    getAsset: build.query({
      async queryFn(assetId, _queryApi, _extraOptions, fetchWithBQ) {
        const assetResult = await fetchWithBQ(`asset/${assetId}`);

        if (assetResult.error) {
          return { error: assetResult.error };
        }

        const asset = assetResult.data;

        if (asset.shareId) {
          const shareResult = await fetchWithBQ(`share/${asset.shareId}`);

          if (shareResult.error) {
            return { error: shareResult.error };
          }

          asset.share = shareResult.data;
        }

        return { data: asset };
      },
      providesTags: (_result, _error, { id }) => [{ type: "Assets", id }],
    }),
    getAssetTags: build.query({
      query: (assetId) => `asset/${assetId}/tag`,

      providesTags: (result) => providesList(result && result.items, "Tags"),
    }),
    getAssetTag: build.query({
      query: (assetId, tagId) => `asset/${assetId}/tag/${tagId}`,
      providesTags: (_result, _error, { id }) => [{ type: "Tags", id }],
    }),
    saveAsset: build.mutation({
      query(asset) {
        return {
          url: "asset" + (asset.id ? "/" + asset.id : ""),
          method: asset.id ? "PUT" : "POST",
          body: asset,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Assets", id },
        "Assets",
        "RecentAssets",
        "Shares",
        "Tags",
      ],
    }),
    saveAssetTag: build.mutation({
      query({ assetId, tag }) {
        return {
          url: `asset/${assetId}/tag` + (tag.id ? "/" + tag.id : ""),
          method: tag.id ? "PUT" : "POST",
          body: tag,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Assets", id },
        "Shares",
        "Tags",
      ],
    }),
    deleteAssetTag: build.mutation({
      query({ assetId, tagId }) {
        return {
          url: `asset/${assetId}/tag/${tagId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Assets", id },
        "Shares",
        "Tags",
      ],
    }),
    deleteAsset: build.mutation({
      query(asset) {
        return {
          url: `asset/${asset.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Assets", "RecentAssets", "Shares", "Tags"],
    }),
    copyAsset: build.mutation({
      async queryFn({ src, dest }, _api, _extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `asset/${src.asset.id}/copy/`,
          method: "PUT",
          body: JSON.stringify({
            srcAsset: src.asset,
            srcFolder: src.folder,
            destAsset: dest.asset,
            destFolder: dest.folder,
          }),
        });

        const registrationIds = response.data.registrationIds;
        const data = await pollForTasks(registrationIds, baseQuery);

        return { data };
      },
      invalidatesTags: ["Assets", "RecentAssets", "Shares"],
    }),
  }),
});

export const {
  useGetAssetTagsQuery,
  useGetAssetTagQuery,
  useGetRecentAssetsQuery,
  useGetAssetQuery,
  useSearchAssetsQuery,
  useSaveAssetMutation,
  useSaveAssetTagMutation,
  useDeleteAssetTagMutation,
  useGetAssetsQuery,
  useDeleteAssetMutation,
  useCopyAssetMutation,
} = assetApi;
