import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { open } from "./uploadBlobsSlice";

export const useOpenUploadBlobsDialog = () => {
  const dispatch = useDispatch();

  return useCallback(
    (row) => () => {
      dispatch(open(row));
    },
    [dispatch]
  );
};
