import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Typography,
} from "@mui/material";

import { StyledCard, StyledReadOnly } from "../../components";
import { isEditableRole, isOwnerRole } from "../../helpers";
import { useGetMyAccountQuery } from "../account";
import { useGetMyOrganizationQuery } from "../organization";
import { useUpsertShareAsset } from ".";

const AssetItem = (props) => {
  const { label, value } = props;
  return (
    <Box
      key={label}
      sx={{ paddingBottom: 1 }}
      hidden={!value || value.length === 0}
    >
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
};

AssetItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export const AssetForm = (props) => {
  const { shareAsset } = props;
  const dispatch = useDispatch();

  const { data: account } = useGetMyAccountQuery();
  const { data: organization } = useGetMyOrganizationQuery();

  const upsertShareAsset = useUpsertShareAsset();

  const { created, name, key, description, address, tags } = useMemo(() => {
    let created = false;
    let name = "";
    let key = "";
    let description = "";
    let address = "";
    let tags = [];

    if (
      shareAsset !== undefined &&
      shareAsset.data !== undefined &&
      shareAsset.data.asset
    ) {
      const asset = shareAsset.data.asset;

      created = asset.id !== null && asset.container !== null;

      name = asset.name;
      key = asset.key;
      description = asset.description;
      address =
        asset.addresses && asset.addresses[0] ? asset.addresses[0].address : "";

      tags = asset.tags;
    }

    return { created, name, key, description, address, tags };
  }, [shareAsset]);

  const title = useMemo(
    () => (created ? "Built Asset Overview" : "Create Built Asset"),
    [created]
  );

  const { canUpdate } = useMemo(() => {
    let canUpsert = true;
    let canUpdate = false;

    if (
      shareAsset !== undefined &&
      shareAsset.data !== undefined &&
      shareAsset.data.asset
    ) {
      const share = shareAsset.data;
      const canEdit =
        organization &&
        share &&
        organization.id === share.organizationId &&
        isEditableRole(account, organization, share);
      const owner = isOwnerRole(account, organization, share);
      canUpsert = canEdit || owner;
      canUpdate = created && canUpsert;
    }

    return { canUpsert, canUpdate };
  }, [account, organization, created, shareAsset]);

  const handleEdit = useCallback(() => {
    dispatch(upsertShareAsset(shareAsset.data));
  }, [dispatch, shareAsset, upsertShareAsset]);

  return (
    <StyledCard>
      <CardHeader
        title={title}
        action={
          <div hidden={!canUpdate}>
            <Button
              variant="text"
              color="primary"
              onClick={handleEdit}
              margin="dense"
              size="small"
            >
              Edit
            </Button>
          </div>
        }
      />
      <Divider />
      <CardContent>
        <StyledReadOnly label="Name" value={name} />
        <StyledReadOnly label="ID" value={key} />
        <StyledReadOnly label="Description" value={description} />
        <StyledReadOnly label="Address" value={address} />

        <Box hidden={!tags || tags.length === 0}>
          <Typography variant="body2">Tags</Typography>
          {tags &&
            tags.map((option) => (
              <Chip
                key={option.id}
                variant="outlined"
                margin="dense"
                size="small"
                label={option.name}
                sx={{ marginRight: 1 }}
              />
            ))}
        </Box>
      </CardContent>
    </StyledCard>
  );
};

AssetForm.propTypes = {
  shareAsset: PropTypes.object,
};
