import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Avatar, AvatarGroup, Grid, Tooltip, Typography } from "@mui/material";

import { LoadingCircularProgress } from "../../components";
import { findMemberRole, formatTimestampAsDateTime } from "../../helpers";
import { useGetMyAccountQuery } from "../account";

const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: "1px solid #dadde9",
  },
}));

export const MemberGroup = (props) => {
  const { isLoading: isLoadingAccount, data: account } = useGetMyAccountQuery();
  const { link, members } = props;

  const history = useHistory();

  const handleClick = useCallback(() => {
    if (link !== undefined) {
      history.push(link);
    }
  }, [history, link]);

  if (isLoadingAccount) {
    return <LoadingCircularProgress />;
  }

  return (
    <AvatarGroup
      max={4}
      onClick={handleClick}
      sx={{
        display: "flex",
        flexDirection: "row-reverse",
        // flexWrap: "nowrap",
        justifyContent: "flex-end",
      }}
    >
      {members &&
        members.map((mrow, mindex) => {
          const role = findMemberRole(mrow.role);
          return (
            <HtmlTooltip
              key={mindex}
              title={
                <React.Fragment>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Avatar
                        key={mindex}
                        alt={mrow.profile.name}
                        src={mrow.profile.avatar}
                      />
                    </Grid>
                    <Grid item xs container direction="column">
                      <Grid item>
                        <Typography
                          scope="row"
                          variant="body1"
                          noWrap={true}
                          color="inherit"
                        >
                          {mrow.profile.name +
                            (account.id == mrow.id ? " (me)" : "")}
                        </Typography>
                        <Typography
                          scope="row"
                          variant="body2"
                          noWrap={true}
                          color="inherit"
                        >
                          {role.label}
                        </Typography>
                        <Typography
                          scope="row"
                          variant="body2"
                          noWrap={true}
                          color="inherit"
                        >
                          {mrow.profile.email}
                        </Typography>
                        <div hidden={mrow.status == "active"}>
                          <Typography
                            scope="row"
                            variant="body2"
                            noWrap={true}
                            color="inherit"
                            sx={{ textTransform: "capitalize" }}
                          >
                            ({mrow.status}{" "}
                            {formatTimestampAsDateTime(
                              mrow.ts * 1000,
                              account.preferences.dateFormat
                            )}
                            )
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Avatar
                key={mindex}
                alt={mrow.profile.name}
                src={mrow.profile.avatar}
              />
            </HtmlTooltip>
          );
        })}
    </AvatarGroup>
  );
};

MemberGroup.propTypes = {
  members: PropTypes.array,
  link: PropTypes.string,
};
